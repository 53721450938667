import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { uploadSVG, onChangeProductTagSearch } from '../Actions';
import { Button } from './Button';
import { Checkbox } from './Checkbox';
import { PercentageLoader } from './PercentageLoader';
import { RenderRowsAndColumn } from './RenderRowsAndColumn';
import { TableBody } from './TableBody';

function UploadTemplateForm({ modal, template_id, template_side, industry_tag, theme_tag, product_tag, model_3d_list,scrollingTo,formRef }) {
  const [side_title, setSideTitle] = useState('')
  const [template_desc, setTemplateDesc] = useState('');
  const [template_height, setTemplateHeight] = useState('');
  const [template_width, setTemplateWidth] = useState('');
  const [template_length, setTemplateLength] = useState('');
  const [max_template_height, setMaxTemplateHeight] = useState('');
  const [max_template_width, setMaxTemplateWidth] = useState('');
  const [max_template_length, setMaxTemplateLength] = useState('');
  const [radioOptions,setRadioOptions] = useState('');
  const [showSingleSide,setShowSingleSide] = useState(null);
  const [svgFile, setSVGFile] = useState(null);
  const [template_thumbnail, setTemplateThumbnail] = useState(null);
  const [rounded_template_thumbnail, setRoundedTemplateThumbnail] = useState(null);
  const [template_industry_tags, setTemplateIndustryTags] = useState([]);
  const [template_theme_tags, setTemplateThemeTags] = useState([]);
  const [template_product_tags, setTemplateProductTags] = useState([]);
  const [industry_tags, setIndustryTags] = useState([]);
  const [theme_tags, setThemeTags] = useState([]);
  const [product_tags, setProductTags] = useState([]);
  const [showFormValidationError, setShowFormValidationError] = useState('');
  const [template_3d_model, setTemplate3DModel] = useState(0);

  const navigate = useNavigate()
  const dispatch = useDispatch()
  // const ref = useRef(null);
  const reduxState = useSelector(state => state.template);

  const { product_tags_search_text, upload_percentage } = reduxState;
  const original_product_tags = reduxState.product_tags;

  // product_tags_search_text
  useEffect(() => {
    // if(!modal){
    //   settingDefault()
    // }else{
    //   setIndustryTags(industry_tag)
    //   setProductTags(product_tag)
    //   setThemeTags(theme_tag)
    // }
    try {
      // console.log('tag',product_tag);
      if (industry_tag.length > 0 || product_tag.length > 0 || theme_tag.length > 0) {
        setIndustryTags(industry_tag)
        setProductTags(product_tag)
        setThemeTags(theme_tag)
      }
    }
    catch (e) {

    }

    // return ()=>{
    //   // settingDefault()
    // }
  }, [dispatch, product_tag])

  function settingDefault() {
    setSVGFile(null)
    setSideTitle('')
    setTemplateDesc('')
    setTemplateHeight('')
    setTemplateWidth('')
    setTemplateLength('')
    setSideTitle('')
    setTemplateIndustryTags([])
    setTemplateThemeTags([])
    setTemplateProductTags([])
    setIndustryTags([])
    setThemeTags([])
    setProductTags([])
    setTemplateThumbnail(null)
    setRoundedTemplateThumbnail(null);
    setMaxTemplateHeight('')
    setShowFormValidationError('')
    setMaxTemplateLength('')
    setMaxTemplateWidth('')
    dispatch(onChangeProductTagSearch('', original_product_tags))
  }

  function handleSubmit(event) {
    event.preventDefault();
    

    var proceed = false;
    if (svgFile != null && side_title !== '') {
      var main_template_id, template_sides, filtered;
      try {
        // console.log('LOCATION:', template_side);
        main_template_id = template_id;
        template_sides = template_side;
        // console.log('Template_sides:', template_sides);

        filtered = Object.keys(template_sides).reduce(function (filtered, key) {
          // console.log('KEY', key);
          if (template_sides[key]['side_title'] === side_title) filtered[key] = template_sides[key];
          return filtered;
        }, {});
      } catch (error) {
        main_template_id = 0;
        filtered = {};
      }

      if (Object.keys(filtered).length === 0 || main_template_id === 0) {
        proceed = true;
      } else {
        proceed = false;
      }

      // console.log('filtered', max_template_width,max_template_height,max_template_length);
      if (proceed) {
        dispatch(
          uploadSVG(
            event,
            svgFile,
            showSingleSide,
            side_title.toUpperCase(),
            main_template_id,
            template_desc,
            template_height,
            template_width,
            template_length,
            max_template_width,
            max_template_height,
            max_template_length,
            radioOptions,
            template_thumbnail,
            rounded_template_thumbnail,
            template_industry_tags,
            template_product_tags,
            template_theme_tags,
            template_3d_model,
            modal,
            navigate
          )
        );
        // console.log('percentage',upload_percentage);
        if (upload_percentage === '') {
          // modal(false)
          settingDefault()
        }
        // modal(false)
        // console.log('upload',main_template_id,template_side);
      } else {
        setShowFormValidationError('Please select a different side name, this side name already used in this template')
        // alert('Please select a different side name, this side name already used in this template');
        scrollingTo()
      }
    } else {      
      setShowFormValidationError('Please select file and enter side name to upload!')
      scrollingTo()
    }

    
    
  }

  function industryTagSelected(id) {
    if (template_industry_tags.includes(id)) {
      var new_template_tags = template_industry_tags
      const index = template_industry_tags.indexOf(id)
      if (index > -1) {
        new_template_tags.splice(index, 1)
        setTemplateIndustryTags([...new_template_tags])
        // return [...new_template_tags]
      }
    } else {
      setTemplateIndustryTags([...template_industry_tags, id])
      // return [...industry_tag, id]
    }
    // console.log('industry',template_industry_tags);
  }

  const renderIndustryTags = () => {
    return (
      <RenderRowsAndColumn dataTitle={'Industry Tags'}>
        {industry_tags.map((industry, index) => {
          const industry_id = industry.id
          if (!template_industry_tags.includes(industry_id)) {
            return (
              <Checkbox key={index} label={industry.industry} selected={false} onPress={() => industryTagSelected(industry_id)} />
            )
          } else {
            return (
              <Checkbox key={index} label={industry.industry} selected={true} onPress={() => industryTagSelected(industry_id)} />
            )
          }
        })}
      </RenderRowsAndColumn>
    )
  }

  function themeTagSelected(id) {
    if (template_theme_tags.includes(id)) {
      var new_template_tags = template_theme_tags
      const index = template_theme_tags.indexOf(id)
      if (index > -1) {
        new_template_tags.splice(index, 1)
        setTemplateThemeTags([...new_template_tags])
      }
    } else {
      setTemplateThemeTags([...template_theme_tags, id])
    }

  }

  function renderThemeTags() {

    return (
      <RenderRowsAndColumn dataTitle={"Theme Tags"}>
        {theme_tags.map((theme, index) => {
          const theme_id = theme.id
          if (!template_theme_tags.includes(theme_id)) {
            return (
              <Checkbox key={index} label={theme.theme} selected={false} onPress={() => themeTagSelected(theme_id)} />
            )
          } else {
            return (
              <Checkbox key={index} label={theme.theme} selected={true} onPress={() => themeTagSelected(theme_id)} />
            )
          }
        })}
      </RenderRowsAndColumn>
    )
  }


  function productTagSelected(id) {
    if (template_product_tags.includes(id)) {
      var new_template_tags = template_product_tags
      const index = template_product_tags.indexOf(id)
      if (index > -1) {
        new_template_tags.splice(index, 1)
        setTemplateProductTags([...new_template_tags])
      }
    } else {
      setTemplateProductTags([...template_product_tags, id])
    }
  }

  function renderProductTags() {
    return (
      <RenderRowsAndColumn tableClassname={'product-tags'} dataTitle={"Products Tags"}>
        <input style={{ marginBottom: '0.5rem' }} type="text" className="form-control template-upload" value={product_tags_search_text} onChange={(e) => dispatch(onChangeProductTagSearch(e.target.value, original_product_tags))} />
        {(product_tags.length !== 0) ? product_tags.map((product, index) => {
          const product_id = product.id
          if (!template_product_tags.includes(product_id)) {
            return (
              <Checkbox key={index} label={product.product} selected={false} onPress={() => productTagSelected(product_id)} />
            )
          } else {
            return (
              <Checkbox key={index} label={product.product} selected={true} onPress={() => productTagSelected(product_id)} />
            )
          }
        }) : <h6>No Product Tags Found!</h6>}
      </RenderRowsAndColumn>
    )
  }

  function render3DModelList() {
    if (model_3d_list) {
      return (
        <RenderRowsAndColumn dataTitle={"Select 3D Model"}>
          <select className='form-control' onChange={(event) => {
            setTemplate3DModel(event.target.value);
          }}>
            <option className='form-control' key={0} value={0}>Select 3d Model</option>
            {model_3d_list.map((model, index) => {
              return (
                <option key={index} value={model['id']}>
                  {model['3d_model_name']}
                </option>
              );
            })}
          </select>
        </RenderRowsAndColumn>
      );
    }
  }

  function renderDescAndDimensions() {
    return (
      <>
        <TableBody tableName={"Also Show Single Side"}>
          <label className="potrait check"><input type={'radio'} checked={showSingleSide == '1'} value={"1"} onChange={(e)=>setShowSingleSide(1)} />Yes</label>
          <label className="landscape check"><input type={'radio'} checked={showSingleSide =='0'} value={"0"} onChange={(e)=>setShowSingleSide(0)} />No</label>
        </TableBody>

        <TableBody tableName={'Template Description'} >
          <textarea value={template_desc} onChange={(e) => setTemplateDesc(e.target.value)} className='form-control' />
        </TableBody>

        <RenderRowsAndColumn dataTitle={'Template Width'} >
          <div className='form-group col-md-5'>
          <input type={'number'} placeholder="min" value={template_width} onChange={e => setTemplateWidth(e.target.value)} className='form-control ' />
          </div>
          <div className='form-group col-md-2'>
            <span>To</span>
          </div>
          <div className='form-group col-md-5'>
          <input type={'number'} placeholder="max" value={max_template_width} onChange={e => setMaxTemplateWidth(e.target.value)} className='form-control' /> 
          </div>
        </RenderRowsAndColumn>

        <RenderRowsAndColumn dataTitle={'Template Height'} >
          <div className='form-group col-md-5'>
          <input type={'number'} placeholder='min' value={template_height} onChange={e => setTemplateHeight(e.target.value)} className='form-control' />
          </div>
          <div className='form-group col-md-2'>
            <span>To</span>
          </div>
          <div className='form-group col-md-5'>
          <input type={'number'} placeholder='max' value={max_template_height} onChange={e => setMaxTemplateHeight(e.target.value)} className='form-control' />
          </div>
        </RenderRowsAndColumn>
        <RenderRowsAndColumn dataTitle={'Template Length'} >
          <div className='form-group col-md-5'>
          <input type={'number'} placeholder='min' value={template_length} onChange={e => setTemplateLength(e.target.value)} className='form-control' />
          </div>
          <div className='form-group col-md-2'>
          <span>To</span>
          </div>
          <div className='form-group col-md-5'>
          <input type={'number'} placeholder='max' value={max_template_length} onChange={e => setMaxTemplateLength(e.target.value)} className='form-control' />
          </div>
        </RenderRowsAndColumn>
        <TableBody tableName={'Template Orientation'} >
          <label className="potrait check"><input type={'radio'} checked={radioOptions ==='potrait'} value={'potrait'} onChange={(e)=>setRadioOptions(e.target.value)} />Potrait</label>
          <label className="landscape check"><input type={'radio'} checked={radioOptions==='landscape'} value={"landscape"} onChange={(e)=>setRadioOptions(e.target.value)} />Landscape</label>
        </TableBody>
      </>
    )
  }

  function handleSVGFileSelect(event) {
    const type = event.target.files[0].name.split('.')[1]
    if(type==='svg'){
      setSVGFile(event.target.files[0])      
    }
    else{
      setShowFormValidationError("No file is supported other than svg")
    }

    console.log(event.target.files[0].name.split('.')[1]);
    // return event.target.files[0]
  }

  function handleThumbnailFileSelect(event, normal_or_rounded) {
    if (normal_or_rounded === 'normal') {
      setTemplateThumbnail(event.target.files[0])
    } else {
      setRoundedTemplateThumbnail(event.target.files[0])
    }
  }

  function showFormValidationAlert() {
    if (showFormValidationError) {
      return <h6 ref={formRef} style={{color: "red"}}>{showFormValidationError}</h6>;
    }
  }
  // console.log('percentage',upload_percentage);
  // if(upload_percentage===''){

  // }
  return (
    <>
    {showFormValidationAlert()}
      <form>
        <div id="table2" className="table-outer table-cards manage-shipper manage-editor-wrap">
          <table width="" cellSpacing={1} className="upload-template-table">
            <thead>
              <tr>
                <th width="">Side Title *</th>
                <th width="" data-title="Action"></th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>
                  <input type={'text'} value={side_title} onChange={(e) => setSideTitle(e.target.value)} className="form-control template-upload" />
                </td>
                <td colSpan={2}><input type={'file'} accept="image/svg+xml" onChange={handleSVGFileSelect} className='form-control' /></td>
              </tr>
              {/* <TableBody tableName={"Also Show Single Side"}>
                <label className="potrait check"><input type={'radio'} checked={showSingleSide == '1'} value={"1"} onChange={(e)=>setShowSingleSide(1)} />Yes</label>
                <label className="landscape check"><input type={'radio'} checked={showSingleSide =='0'} value={"0"} onChange={(e)=>setShowSingleSide(0)} />No</label>
              </TableBody> */}

              {template_id === 0 && (
                <>
                  {renderDescAndDimensions()}
                  <TableBody 
                    accept={'image/jpeg,image/png,image/jpg'} 
                    tableName={'Template Thumbnail Image'} 
                    inputType={'files'} 
                    inputClass={'form-control'} 
                    onChangefun={(e) => handleThumbnailFileSelect(e, 'normal')}
                   />
                  <TableBody 
                    tableName={'Template Rounded Thumbnail Image'} 
                    accept={'image/jpeg,image/png,image/jpg'} 
                    inputType={'files'} 
                    inputClass={'form-control'} 
                    onChangefun={(e) => handleThumbnailFileSelect(e, 'rounded')}
                   />

                  {renderIndustryTags()}
                  {renderThemeTags()}
                  {renderProductTags()}
                  {render3DModelList()}
                </>
              )}
            </tbody>

          </table>
        </div>
      </form>
      <div className="form-group mtp1">
        {!upload_percentage ? (
          <Button onPress={handleSubmit} classgiven="btn-basic" name={'Upload File'} />
        ) : (
          // <p> Uploading {upload_percentage}%</p>
          <PercentageLoader percentage={upload_percentage} />
        )}
      </div>
    </>
  )
}

export { UploadTemplateForm }