import React from 'react';
import './css/SelectableCell.css';

function SelectableCell({
  label,
  onPress,
  selected,
}) {
  // const {
  //   label,
  //   onPress,
  //   selected,
  // } = props;
  label = label.split('-')

  // function returnCell() {
  //   if (selected) {
  //     // console.log('In selected', selected);
  //     return (
  //        <button type="button" onClick={onPress} className='cell' style={{backgroundColor: 'lightblue'}}>{label}</button>
  //     );
  //   } else {
  //     return (
  // 			 <button type="button" onClick={onPress} className='cell' >{label}</button>
  //   	);
  //   }
  // }

  // return returnCell();
  // console.log('sele',selected);
  return (
    <div onClick={onPress} className={`canvas-layer-row ${selected ? 'active' : ''}`}>
      <div className="layer-hd">{label[0]}</div>
      <div className="layer-txt">{label[1]}</div>
    </div>
  )
}

export { SelectableCell };
