import {combineReducers} from 'redux';
import TemplateReducer from './TemplateReducer';
import JewelleryPitchReducer from './JewelleryPitchReducer';
import LoginReducer from './LoginReducer';

export default combineReducers({
    template: TemplateReducer,
    login: LoginReducer,
    jewellery_pitch: JewelleryPitchReducer,
});
