import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import {
  getTemplateList,
  changePageNumber,
  onChangeProductTagSearch,
  searchingTemplate,
  removeSearch,
  openCloseModel,
  sendingChatgptMessage,
} from "../Actions";
import "../css/TemplatesScreen.css";
import {
  Button,
  HeadingTitle,
  Modal,
  UploadTemplateForm,
  Pagination,
  HeadPart,
  CustomAlert,
  Loader,
  ModalLoader,
  RenderRowsAndColumn,
  Checkbox,
} from "../Components";
import { TemplateCard } from "../Components";
import { navigateToEditor } from "../extras/CommonNavigations";
import { SEARCH_FILTERING_TEMPLATE } from "../Actions/ActionTypes";

function TemplatesScreen() {
  const navigate = useNavigate();
  const reduxState = useSelector((state) => state.template);
  var pageNumberLimit = 10
  const {
    template_list,
    templateListLoader,
    fonts_list,
    swatch,
    industry_tags,
    theme_tags,
    product_tags,
    total_templates,
    total_pages,
    current_page,
    filtered_product_tags,
    product_tags_search_text,
    uploadLoader,
    model_3d_list,
    die_list,
    filter_template_list,
    searching_template_name,
  } = reduxState;
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [showTemplateInfo, setShowTemplateInfo] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [template_die_selected_filter, setTemplateDieSelectedFilter] = useState([]);
  const [templateInfoSelected, setTemplateInfoSelected] = useState("");
  const [radioOptions, setRadioOptions] = useState('')
  const [filters, setFilters] = useState([]);
  const [template_industry_tags, setTemplateIndustryTags] = useState([]);
  const [template_theme_tags, setTemplateThemeTags] = useState([]);
  const [template_product_tags, setTemplateProductTags] = useState([]);
  const [layer_dies_model, setLayerDiesModel] = useState(false);
  const [template_selected_dies, setTemplateSelectedDies] = useState('')
  const [maxPageLimit, setMaxPageLimit] = useState(10);
  const [minPageLimit, setMinPageLimit] = useState(0);
  const [newChatGptDescription,setNewChatGptDescription] = useState([]);
  const [chatgptApiResponseMessage,setChatgptApiResponseMessage] = useState('');
  const [productTagDescModel, setProductTagDescModel] = useState(false);

  const controller = new AbortController()
  const ref = useRef(null);
  useEffect(() => {
    let subscribe = true
    try {
      // const industry_tags_template = template_industry_tags.join(",");
      // const product_tags_template = template_product_tags.join(",");
      // const theme_tags_template = template_theme_tags.join(",");
      // const template_dies = template_die_selected_filter.join(',')
      // // console.log('Sending page no:', template_theme_tags,theme_tags_template);
      // dispatch(
      //   getTemplateList(
      //     null,
      //     current_page,
      //     15,
      //     industry_tags_template,
      //     theme_tags_template,
      //     product_tags_template,
      //     template_dies,
      //   )

      // );
      // allFilters();
      if (subscribe) {
        templateListGenerating()
      }

      animateFunctionOfJquery();
      document.documentElement.scrollTop = 0;

    }
    catch (e) {
      console.log('e', e);
    }

    return () => {
      // dispatch({
      //   type:SEARCH_FILTERING_TEMPLATE,
      //   payload:''
      // })
      subscribe = false
      controller.abort()
      document.body.classList.remove("modal-open");
      document.body.style.paddingRight = "0px";
    };
  }, [dispatch, current_page]);

  function animateFunctionOfJquery() {
    $(document).ready(function () {
      $(document).off('click').on('click', '.filter-button', function (e) {
        $('.filter-box').animate({
          width: 'toggle'
        })
      })
    })
  }


  function templateListGenerating(event, searchFrom, clearSearch) {
    if (event) {
      event.preventDefault();
    }

    if ((searchFrom || clearSearch) && searching_template_name.trim() === "") {
      return
    }

    if (searchFrom) {
      changePage(1)
      setMaxPageLimit(10)
      setMinPageLimit(0)
    }
    if (clearSearch) {
      dispatch(removeSearch())
      changePage(1)
      setMaxPageLimit(10)
      setMinPageLimit(0)
    }

    const industry_tags_template = template_industry_tags.join(",");
    const product_tags_template = template_product_tags.join(",");
    const theme_tags_template = template_theme_tags.join(",");
    const template_dies = template_die_selected_filter.join(',');
    // dispatch({})
    // changePage(1)
    dispatch(
      getTemplateList(
        null,
        current_page,
        15,
        0,
        industry_tags_template,
        theme_tags_template,
        product_tags_template,
        template_dies ? template_dies : '0',
        radioOptions,
        !clearSearch ? searching_template_name : '',
        null,
        controller,
        product_tags_search_text
      )
    );
    allFilters();
  }

  function modalOpenClose(open) {
    if (!openModal) {
      setOpenModal(open);
      document.body.classList.add("modal-open");
      document.body.style.paddingRight = "17px";
    } else {
      setOpenModal(open);
      document.body.classList.remove("modal-open");
      document.body.style.paddingRight = "0px";
    }
  }

  function showTemplateModal(openModal, selectedTemplate) {
    if (!showTemplateInfo) {
      setShowTemplateInfo(openModal);
      setTemplateInfoSelected(selectedTemplate);
      document.body.classList.add("modal-open");
      document.body.style.paddingRight = "17px";
    } else {
      setShowTemplateInfo(openModal);
      document.body.classList.remove("modal-open");
      document.body.style.paddingRight = "0px";
    }
  }

  const roundedCornerModal = (template_index) => {
    const template_side = Object.keys(template_list[template_index]);
    const template = template_list[template_index][template_side[0]];
    const template_sides_json = template_list[template_index];
    if (template.template_layers.layer_dies.length > 0) {
      openCloseModel(true,layer_dies_model,setLayerDiesModel);
      setTemplateSelectedDies(template_index)
      // show model asking
    } else {
      // console.log("no layer dies present");
      navigateToEditor(navigate, '', template, template_side, template_sides_json, fonts_list, swatch, false);
    }
  };

  const roundnavigateTo = (round_or_not, template_index) => {
    const template_side = Object.keys(template_list[template_index]);
    const template = template_list[template_index][template_side[0]];
    const template_sides_json = template_list[template_index];
    // console.log('FONTS LIST', fonts_list);
    navigateToEditor(
      navigate,
      round_or_not,
      template,
      template_side,
      template_sides_json,
      fonts_list,
      swatch
    );
  };

  function templateInfo() {
    const template_sides = Object.keys(template_list[templateInfoSelected]);
    const template_info =
      template_list[templateInfoSelected][template_sides[0]];

    
    // console.log('product_tags_desc', product_tags_desc);
    return (
      <>
      {modelOfProductTagsDescription(template_info)}
      <div className="template-info-wrap1">
        <div className="template-row">
          <div className="template-clm1">Name</div>
          <div className="template-clm2">{template_info.name}</div>
        </div>
        {/* <div className="template-row">
          <div className="template-clm1">Template </div>
          <div className="template-clm2">{template_info.description}</div>
        </div> */}
        <div className="template-row">
          <div className="template-clm1">Description</div>
          <div className="template-clm2">{template_info.description}</div>
        </div>
        <div className="template-row">
          <div className="template-clm1">Description based on Product tags</div>
          <div className="template-clm2">
            <Button name={'View'} onPress={()=>{
              // const description = {...newChatGptDescription};
              // newChatGptDescription = template_info.template_description
              setNewChatGptDescription(template_info.template_description)
              openCloseModel(true,productTagDescModel,setProductTagDescModel);
            }} classgiven={'table-action '} />
            {/* <Button name={'Generate'} classgiven={'table-action layers'} /> */}
          </div>
        </div>
        <div className="template-row">
          <div className="template-clm1">Width</div>
          <div className="template-clm2">Min - {template_info.width}, Max - {template_info.max_width}</div>
        </div>
        <div className="template-row">
          <div className="template-clm1">Height</div>
          <div className="template-clm2">Min - {template_info.height}, Max - {template_info.max_height}</div>
        </div>
        <div className="template-row">
          <div className="template-clm1">Length</div>
          <div className="template-clm2">Min - {template_info.length}, Max - {template_info.max_length} </div>
        </div>
        <div className="template-row">
          <div className="template-clm1">Total Sides</div>
          <div className="template-clm2">{template_sides.length}</div>
        </div>
        <div className="template-row">
          <div className="template-clm1 full-w">Industry Tags</div>
          <ul className="tags-list">
            {template_info.industry_tags &&
              template_info.industry_tags.length > 0 ? (
              template_info.industry_tags.map((item, index) => (
                <li key={index}>{item.industry}</li>
              ))
            ) : (
              <li>No Tags Selected</li>
            )}
          </ul>
        </div>
        <div className="template-row">
          <div className="template-clm1 full-w">Theme Tags</div>
          <ul className="tags-list">
            {template_info.theme_tags && template_info.theme_tags.length > 0 ? (
              template_info.theme_tags.map((item, index) => (
                <li key={index}>{item.theme}</li>
              ))
            ) : (
              <li>No Tags Selected</li>
            )}
          </ul>
        </div>
        <div className="template-row">
          <div className="template-clm1 full-w">Product Tags</div>
          <ul className="tags-list">
            {template_info.product_tags &&
              template_info.product_tags.length > 0 ? (
              template_info.product_tags.map((item, index) => (
                <li key={index}>{item.product}</li>
              ))
            ) : (
              <li>No Tags Selected</li>
            )}
          </ul>
        </div>
      </div>
      </>
    );
  }

  const changePage = (page) => {
    dispatch(changePageNumber(page));
  };

  const onNextClick = (page) => {
    if (current_page + 1 > maxPageLimit) {
      setMaxPageLimit((previousMax) => previousMax + pageNumberLimit)
      setMinPageLimit((previousMin) => previousMin + pageNumberLimit)
    }

    dispatch(changePageNumber(page));
  }

  const onPreviousClick = (page) => {
    if ((current_page - 1) % pageNumberLimit === 0) {
      setMaxPageLimit(maxPageLimit - pageNumberLimit)
      setMinPageLimit(minPageLimit - pageNumberLimit)
    }
    dispatch(changePageNumber(page));
  }




 
  const allFilters = () => {
    const industry_template = industry_tags.filter((item) =>
      template_industry_tags.includes(item.id)
    );
    const theme_template = theme_tags.filter((item) =>
      template_theme_tags.includes(item.id)
    );
    const product_template = filtered_product_tags.filter((item) =>
      template_product_tags.includes(item.id)
    );
    const template_die = die_list.filter(item => template_die_selected_filter.includes(item.id))
    if (radioOptions) {
      setFilters([...industry_template, ...theme_template, ...product_template, ...template_die, radioOptions]);
    } else {
      setFilters([...industry_template, ...theme_template, ...product_template, ...template_die]);
    }
  };

  function theme_selected(theme_id) {
    if (template_theme_tags.includes(theme_id)) {
      var new_template_tags = template_theme_tags;
      const index = template_theme_tags.indexOf(`${theme_id}`);
      if (index > -1) {
        new_template_tags.splice(index, 1);
        setTemplateThemeTags([...new_template_tags]);
      }
    } else {
      setTemplateThemeTags([...template_theme_tags, theme_id]);
    }
  }

  function products_selected(product_id) {
    if (template_product_tags.includes(product_id)) {
      var new_template_tags = template_product_tags;
      const index = template_product_tags.indexOf(`${product_id}`);
      if (index > -1) {
        new_template_tags.splice(index, 1);
        setTemplateProductTags([...new_template_tags]);
      }
    } else {
      setTemplateProductTags([...template_product_tags, product_id]);
    }
  }

  function industry_selected(industry_id) {
    if (template_industry_tags.includes(industry_id)) {
      var new_template_tags = template_industry_tags;
      const index = template_industry_tags.indexOf(`${industry_id}`);
      if (index > -1) {
        new_template_tags.splice(index, 1);
        setTemplateIndustryTags([...new_template_tags]);
      }
    } else {
      setTemplateIndustryTags([...template_industry_tags, industry_id]);
    }
  }

  function onChangetemplateDieSelected(template_die_id) {
    if (template_die_selected_filter.includes(template_die_id)) {
      var new_template_die = template_die_selected_filter
      const index = template_die_selected_filter.indexOf(template_die_id);
      if (index > -1) {
        new_template_die.splice(index, 1);
        setTemplateDieSelectedFilter([...new_template_die])
      }
    } else {
      setTemplateDieSelectedFilter([...template_die_selected_filter, template_die_id]);
    }
  }

  function filters_options() {
    return (
      <>
        <form>
          <div
            id="table2"
            className="table-outer table-cards manage-shipper manage-editor-wrap"
          >
            <table width="" cellSpacing={1} className="upload-template-table">
              <tbody>
                <RenderRowsAndColumn dataTitle={'Die'}>
                  {die_list.map((item, index) => {
                    if (!template_die_selected_filter.includes(item.id)) {
                      return (
                        <Checkbox key={index}
                          selected={false}
                          label={item.die_name}
                          onPress={() => onChangetemplateDieSelected(item.id)}
                        />
                      )
                    } else {
                      return (
                        <Checkbox key={index} selected={true} onPress={() => onChangetemplateDieSelected(item.id)} label={item.die_name} />
                      )
                    }
                  })}
                </RenderRowsAndColumn>
                {/* <RenderRowsAndColumn dataTitle={'Template Orientation'}>
                  <label className="potrait check"><input type={'radio'} value={'potrait'} checked={radioOptions === 'potrait'} onChange={(e) => setRadioOptions(e.target.value)} />Potrait</label>
                  <label className="landscape check"><input type={'radio'} value={'landscape'} checked={radioOptions === 'landscape'} onChange={(e) => setRadioOptions(e.target.value)} />Landscape</label>
                </RenderRowsAndColumn> */}
                <RenderRowsAndColumn dataTitle={"Industry Tags"}>
                  {industry_tags.map((item, index) => {
                    if (!template_industry_tags.includes(item.id)) {
                      return (
                        <Checkbox
                          key={index}
                          selected={false}
                          label={item.industry}
                          onPress={() => industry_selected(item.id)}
                        />
                      );
                    } else {
                      return (
                        <Checkbox
                          key={index}
                          selected={true}
                          label={item.industry}
                          onPress={() => industry_selected(item.id)}
                        />
                      );
                    }
                  })}
                </RenderRowsAndColumn>
                <RenderRowsAndColumn dataTitle={"Theme Tags"}>
                  {theme_tags.map((item, index) => {
                    if (!template_theme_tags.includes(item.id)) {
                      return (
                        <Checkbox
                          key={index}
                          selected={false}
                          label={item.theme}
                          onPress={() => theme_selected(item.id)}
                        />
                      );
                    } else {
                      return (
                        <Checkbox
                          key={index}
                          selected={true}
                          label={item.theme}
                          onPress={() => theme_selected(item.id)}
                        />
                      );
                    }
                  })}
                </RenderRowsAndColumn>
                <RenderRowsAndColumn
                  tableClassname={"product-tags"}
                  dataTitle={"Product Tags"}
                >
                  <input
                    style={{ marginBottom: "0.5rem" }}
                    type="text"
                    placeholder="Search products tags"
                    className="form-control template-upload"
                    value={product_tags_search_text}
                    onChange={(e) =>
                      dispatch(
                        onChangeProductTagSearch(e.target.value, product_tags)
                      )
                    }
                  />
                  {filtered_product_tags.map((item, index) => {
                    // console.log('item',item);
                    if (!template_product_tags.includes(item.id)) {
                      return (
                        <Checkbox
                          key={index}
                          selected={false}
                          label={item.product}
                          onPress={() => products_selected(item.id)}
                        />
                      );
                    } else {
                      return (
                        <Checkbox
                          key={index}
                          selected={true}
                          label={item.product}
                          onPress={() => products_selected(item.id)}
                        />
                      );
                    }
                  })}
                </RenderRowsAndColumn>
              </tbody>
            </table>
          </div>
        </form>
        <div className="form-group mtp1">
          <Button
            classgiven={"link btn-basic btn-basic2"}
            onPress={(e) => templateListGenerating(e)}
            name="Apply"
          />
        </div>
      </>
    );
  }

  function removingFilters(removeFrom, item_id) {
    if (removeFrom === "industry") {
      industry_selected(item_id);
      setFilters(filters.filter((obj) => obj.id !== item_id));
    }
    if (removeFrom === "theme") {
      theme_selected(item_id);
      setFilters(filters.filter((obj) => obj.id !== item_id));
    }
    if (removeFrom === "products") {
      products_selected(item_id);
      setFilters(filters.filter((obj) => obj.id !== item_id));
    }
    if (removeFrom === 'dies') {
      onChangetemplateDieSelected(item_id);
      setFilters(filters.filter((obj) => obj.id !== item_id));
      // setFilters(filters.filter((obj) => obj.die_id !== item_id));
    }
    if (removeFrom === 'orientations') {
      setRadioOptions('')
      setFilters(filters.filter((obj) => obj.id !== item_id));
    }
    const industry_tags_template = template_industry_tags.join(",");
    const product_tags_template = template_product_tags.join(",");
    const theme_tags_template = template_theme_tags.join(",");
    const template_dies = template_die_selected_filter.join(',');
    changePage(1);
    dispatch(
      getTemplateList(
        null,
        1,
        15,
        0,
        industry_tags_template,
        theme_tags_template,
        product_tags_template,
        template_dies,
        radioOptions,
        searching_template_name,
      )
    );
  }

  // console.log('tem',radioOptions);
  // console.log('fiklter',filters);

  function renderDiesModalInfo() {
    const template_side = Object.keys(template_list[template_selected_dies]);
    const template = template_list[template_selected_dies][template_side[0]];
    const template_sides_json = template_list[template_selected_dies];
    const dies_layer = template.template_layers.layer_dies
    console.log('dies_layer', template.template_layers);
    return (
      <div>
        <select className="form-control" onChange={(e) => {
          // console.log('template', template.fonts, fonts_list);
          navigateToEditor(navigate, e.target.value, template, template_side, template_sides_json, fonts_list, swatch, false);
        }}>
          <option value={0} >Select Clip</option>
          {dies_layer.map((die, index) => (
            <option value={die.permission} className="form-control" key={index} >
              {die.layer_name}
            </option>
          ))}
        </select>
        <div className='default-die-wrap'>
          <span className='default-die-hd'>Or Select Default Clip</span>
          <Button name={'Default Die'} onPress={() => {
            navigateToEditor(navigate, '', template, template_side, template_sides_json, fonts_list, swatch, false);
          }} classgiven={'table-action'} />
        </div>
      </div>
    )
  }

  
  
  const scrollingToTop = () => {
    ref.current?.scrollIntoView({ block: "center", behaviour: "smooth" });
  };

  function modelOfProductTagsDescription(template) {
    if (productTagDescModel) {
      return (
        <Modal
          headTitle={"Product Tags Description"}
          classGiven={`modal font-popup ${productTagDescModel ? "show" : ""
            }`}
          style={
            productTagDescModel
              ? { display: "block", paddingRight: "17px" }
              : { display: "none" }
          }
          onPress={() => {openCloseModel(false,productTagDescModel,setProductTagDescModel); setChatgptApiResponseMessage('')}}
        >
          {renderingProductTagsDescription(template)}
        </Modal>
      )
    }
  }

  function getGptDescription(template_id,printer_id){
    // console.log('template',template_id,printer_id);
    const formdata = {
      template_id,
      printer_id,      
    }
    dispatch(sendingChatgptMessage(formdata,newChatGptDescription,setNewChatGptDescription,setChatgptApiResponseMessage));
  }
  
  const renderingProductTagsDescription = (template) => {
    const product_tags_key = Object.keys(newChatGptDescription);
    const product_tags = newChatGptDescription;
    
    return (
      <>  
        {chatgptApiResponseMessage && <p>{chatgptApiResponseMessage}</p>}
        <div className="template-info-wrap1">
          {product_tags_key.map(item=>{
            let tag_name = product_tags[item].tag_name.split('- ');
            tag_name = tag_name[tag_name.length - 1];
            // console.log('tag_name ',tag_name );
            return(
            <div key={item} className="template-row">
              <div className="template-clm1">{tag_name}</div>
              <div className="template-clm2 row">
                {/* <label> */}
                {console.log('new',newChatGptDescription[item])}
                <span >{product_tags[item].description ? product_tags[item].description : 'No description' }</span>
                <span ><Button disabled={templateListLoader ? true : false} name={(product_tags[item].description) ? 'Regenerate' : 'Generate'} classgiven={'table-action'} onPress={()=>getGptDescription(template.id,item)} /></span>
                {/* </label> */}
              </div>
              <div>

              </div>
            </div>
          )})}

          
        </div>

      </>
    )
  }


  function createTemplateListTable() {
    return (
      <>
        
        {/* Filter list start here */}
        <div className="filter-box filter-box-slide-out">
          <div
            style={{ height: "38px" }}
            className="close-btn filter-button"
          // filter-button            
          >
            Close
          </div>
          <div className="inner-filter-box">
            {filters_options()}
          </div>
        </div>
        {/* Filter list end here */}

        {/* Here head part start */}
        <HeadPart>
          <div className="nk-block-head full-hd-wrap">
            <HeadingTitle title={"Manage Template"} />
            <div className="search-template">
              Search Template:
              <input className="form-control" type={'text'} onKeyDown={(e) => {
                if (e.key == 'Enter') {
                  templateListGenerating(e, true)
                }
              }} value={searching_template_name} onChange={(e) => dispatch(searchingTemplate(e.target.value))} placeholder={'Search template by name'} />
              <label onClick={(e) => templateListGenerating(e, false, true)}><i className="fa fa-times"></i></label>
              <Button classgiven={'link btn-basic btn-basic2 btn-search'} onPress={(e) => templateListGenerating(e, true)} name='Search' />
            </div>
            <div className="nk-block">
              <Button
                onPress={() => openCloseModel(true,openModal,setOpenModal)}
                name="Add Template"
              />

              {openModal && (
                <Modal
                  // topModalref={ref}
                  headTitle={"Upload Template"}
                  classGiven={`modal fade font-popup ${openModal ? "show" : ""
                    }`}
                  style={
                    openModal
                      ? { display: "block", paddingRight: "17px" }
                      : { display: "none" }
                  }
                  onPress={() => openCloseModel(false,openModal,setOpenModal)}
                >
                  <UploadTemplateForm
                    scrollingTo={scrollingToTop}
                    theme_tag={theme_tags}
                    template_id={0}
                    product_tag={filtered_product_tags}
                    industry_tag={industry_tags}
                    model_3d_list={model_3d_list}
                    formRef={ref}
                    modal={modalOpenClose}
                  />
                </Modal>
              )}
              {layer_dies_model && (
                <Modal
                  modalBodyClass={'noHeight-overflow'}
                  headTitle={"On Which Layer Clip"}
                  classGiven={`modal fade font-popup ${layer_dies_model ? "show" : ""
                    }`}
                  style={
                    layer_dies_model
                      ? { display: "block", paddingRight: "17px" }
                      : { display: "none" }
                  }
                  onPress={() => openCloseModel(false,layer_dies_model,setLayerDiesModel)}
                >
                  {renderDiesModalInfo()}
                </Modal>
              )}

              {/* {showFilters && (
                <Modal headTitle={'Filters'}
                  classGiven={`modal fade font-popup ${showFilters ? 'show' : ''}`}
                  style={showFilters ? { display: 'block', paddingRight: '17px' } : { display: 'none' }}
                  onPress={() => showingFilters(false)}
                >
                  {filters_options()}
                </Modal>
              )} */}

              {showTemplateInfo && (
                <Modal
                  headTitle={"Template Info"}
                  classGiven={`modal fade font-popup ${showTemplateInfo ? "show" : ""
                    }`}
                  style={
                    showTemplateInfo
                      ? { display: "block", paddingRight: "17px" }
                      : { display: "none" }
                  }
                  onPress={() => showTemplateModal(false)}
                >
                  {templateInfo()}
                </Modal>
              )}

              {/* <Button onPress={() => navigate('/client_template')} classgiven="link btn-basic new btn-basic2 clientsavedtemplate" name="Client Saved Template" />
              <Button onPress={() => navigate('/client_draft')} classgiven="link btn-basic new btn-basic2 clientsavedtemplatedraft" name="Client Saved Template Draft" />
              <Button onPress={() => navigate('/fonts_list')} name="Font Family List" /> */}
            </div>
          </div>
          <div className="inside-bg-wrap">
            <div className="manage-template-wrap">
              {filters.length > 0 && (
                <div className="editor-top-btns">
                  <h2 className="hd2 p-0">Filters</h2>
                  <ul className="filter-row2">
                    {filters.map((item, index) => {

                      if (item.industry) {
                        return (
                          <li
                            key={index}
                            onClick={() => removingFilters("industry", item.id)}
                          >
                            <span>{item.industry}</span>
                          </li>
                        );
                      }
                      if (item.theme) {
                        return (
                          <li
                            key={index}
                            onClick={() => removingFilters("theme", item.id)}
                          >
                            <span>{item.theme}</span>
                          </li>
                        );
                      }
                      if (item.die_name) {
                        return (
                          <li
                            key={index}
                            onClick={() => removingFilters("dies", item.id)}
                          >
                            <span>{item.die_name}</span>
                          </li>
                        )
                      }
                      if (item.product) {
                        return (
                          <li
                            key={index}
                            onClick={() => removingFilters("products", item.id)}
                          >
                            <span>{item.product}</span>
                          </li>
                        );
                      }
                      else {
                        <li
                          key={index}
                          onClick={() => removingFilters("orientations", item)}
                        >
                          <span>{item}</span>
                        </li>
                      }
                    })}
                  </ul>
                </div>
              )}
              {uploadLoader && (
                <ModalLoader
                  loadingTitle={"Uploading Loader"}
                  openModal={uploadLoader}
                />
              )}
              <button
                className="filter-button "
              >
                Filter Buttons
              </button>
              {/* <div  className="drawer-wrapper">
                <div className="hd-3d-view">Filters</div>
                <div
                  
                  id="drawer-left"
                  // className={'opened'}
                >
                  <div
                    style={{ height: "38px" }}
                    className="drawer-left-trigger"
                    onClick={() => setShowFilters(!showFilters)}
                  >
                    <span className="glyphicon glyphicon-chevron-right"></span>
                  </div>
                  <div className="drawer-left-content">
                    {filters_options()}
                  </div>
                </div>
              </div> */}

              <div className="catergory-listing">
                <div className="catergory-row">
                  {template_list.length > 0 ? (
                    template_list.map((template, index) => {
                      var all_template_sides = template;
                      var template_side_titles = Object.keys(template);
                      var template = template[template_side_titles[0]];

                      return (
                        <TemplateCard
                          key={index}
                          indexCol={index}
                          template={template}
                          all_template_sides={all_template_sides}
                          template_sides={template_side_titles.length}
                          modalOpenCallback={showTemplateModal}
                          clientSideCallback={roundedCornerModal}
                        />
                      );
                    })
                  ) : (
                    <p>No Templates are added</p>
                  )}
                </div>
              </div>
              {template_list.length > 0 && (
                <Pagination
                  total_pages={total_pages}
                  maxPageLimit={maxPageLimit}
                  minPageLimit={minPageLimit}
                  nextClick={onNextClick}
                  current_page={current_page}
                  onPageChanged={changePage}
                  previousClick={onPreviousClick}
                />
              )}
            </div>
          </div>
        </HeadPart>
        {/* Here head part ends  */}
      </>
    );
  }

  function renderLoader() {
    if (templateListLoader) {
      return (
        <HeadPart>
          <ModalLoader
            loadingTitle={"Loading Templates"}
            openModal={templateListLoader}
          />
        </HeadPart>
      );
    } else {
      return <div>{createTemplateListTable()}</div>;
    }
  }

  return renderLoader();
}

export { TemplatesScreen };
