import React, { useState } from 'react'

function EditableLabel({ onChange, changeValue,classgiven }) {
    const [changeDesignName, setChangeDesignName] = useState(false)
    return (
        <>
            {!changeDesignName ? (

                <a className={classgiven} onClick={()=>{
                    setChangeDesignName(true)
                }}>
                   <span> {changeValue?changeValue :'My Design' }{" "}
                        <img className="edit-icon" src="images/edit.svg" alt="" />
                    </span>
                </a>
            ) : (
                <>
                    <input
                        type={"text"}
                        className="design-input"
                        placeholder="My design"
                        value={changeValue}
                        onChange={onChange}
                    />
                    <span onClick={()=>{
                        setChangeDesignName(false)
                    }} className="tooltip">
                        <img src={`${process.env.PUBLIC_URL}/images/edit-blue.svg`} alt="tooltip" />
                        <span className="tooltiptext label-info">Start by giving your design a name</span>
                    </span>
                </>
            )}
        </>
    )
}

export { EditableLabel }