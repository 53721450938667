import React from 'react'

function Model3dPreview({children}) {
  return (
    // <div className="main-content new-mainwrapper content-wrapper">
      <div className="modal-3d-preview">
        <div className="modal-3d-preview-wrap">
          {children}
        </div>
      </div>
    // </div>
  )
}

export {Model3dPreview}