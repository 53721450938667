import {
    Color,
    PerspectiveCamera,
    PointLight,
    Scene,
    WebGLRenderer,
    AmbientLight,
    HemisphereLight,
    TextureLoader,
    MeshPhongMaterial,
    UVMapping,
    AnimationMixer,
    Clock,
    LinearFilter,
    BufferGeometry,
    LoopOnce,
    MeshStandardMaterial,
    sRGBEncoding,
    ObjectSpaceNormalMap,
    EquirectangularReflectionMapping,
    MixOperation,
    DoubleSide,
    RGBAFormat,
    FrontSide,
    RepeatWrapping,
} from 'three';

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import * as TWEEN from "@tweenjs/tween.js";
import JSZip from 'jszip'
import {
    gettingBrowserInfo,
} from "../extras/CanvasObjectControls";

import { CustomAlert } from "../Components";
import { saveAs } from 'file-saver'
import { GENERATING_IMAGE_THUMBNAIL } from '../Actions/ActionTypes';

export class ThreeDPreview2 {
    constructor() {
        this.scene = null
        this.camera = null
        this.renderer = null
        this.controls = null
        this.frameId = null
        this.mixer = null
        this.clock = null        
        this.animations = null
        this.animate = this.animate.bind(this)
        this.geometry = new BufferGeometry()
        this.generateMultipleImages = this.generateMultipleImages.bind(this)
        this.cameraX = null
        this.cameraY = null
        this.cameraZ = null
        this.current3DModelMaterails = []
    }
    createThreeScene(template_3d_model, cameraX, cameraY, cameraZ, mount_class_name, setBrowserError = null, set3dModel = null, setModelReady = null) {
        // const { scene, camera, renderer, controls, clock } = this.state    
        this.cameraX = cameraX;
        this.cameraY = cameraY;
        this.cameraZ = cameraZ;
        const _this = this
        return new Promise(async function (resolve, reject) {
            try {
                const mount = document.getElementById(mount_class_name);
                // console.log('scene', template_3d_model);
                _this.scene = new Scene()
                // _this.scene.background = new Color(0xEEEEEE)


                _this.clock = new Clock();
                // 0xEEEEEE
                _this.camera = new PerspectiveCamera(70, 2.0, 0.009, 12000)
                _this.camera.position.set(cameraX, cameraY, cameraZ);
                _this.scene.add(_this.camera);
                console.log(cameraX, cameraY, cameraZ);
                _this.scene.add(new AmbientLight(0xffffff, 1));
                // _this.scene.add(new HemisphereLight( 0xffffbb, 0x080820, 1.5 ));

                var light = new PointLight(0xffffff, 0.2);
                var light_2 = new PointLight(0xffffff, 0.4);
                var light_3 = new PointLight(0xffffff, 0.4);
                light_2.position.set( -200, 500, 565 );
                light_3.position.set( 200, 500, -565 );
                _this.camera.add(light);
                _this.scene.add(light_2);
                _this.scene.add(light_3);

                _this.renderer = new WebGLRenderer({ antialias: true, preserveDrawingBuffer: true, alpha: true })
                // _this.renderer.setPixelRatio(window.devicePixelRatio);
                
                _this.renderer.setPixelRatio(2.0) 
                _this.renderer.setClearColor( 0x000000, 0 ); // the default

                _this.camera.aspect = mount.clientWidth / mount.clientHeight;
                _this.camera.updateProjectionMatrix();
                _this.renderer.setSize(mount.clientWidth, mount.clientHeight);
                _this.renderer.outputEncoding = sRGBEncoding; // optional with post-processing


                mount.appendChild(_this.renderer.domElement);
                document.querySelector('.parent')
                _this.controls = new OrbitControls(_this.camera, _this.renderer.domElement)
                _this.controls.enableZoom = true;                


                _this.addModel(template_3d_model, set3dModel, setModelReady).then(model => {
                    resolve(model)
                })
                // _this.scene
            } catch (e) {
                const browser = gettingBrowserInfo()
                CustomAlert.alert(true, `Could not create 3d View because your ${browser} has older version update the modal`)
                setBrowserError && setBrowserError(true);
                console.log('error in 3d', e);
                reject(e)
            }
        })

    }


    resizeCanvasToDisplaySize() {
        const canvas = this.renderer.domElement
        const width = canvas.clientWidth;
        const height = canvas.clientHeight;
        // console.log('height',canvas);
        if (canvas.width !== width ||canvas.height !== height) {
          // you must pass false here or three.js sadly fights the browser
          this.renderer.setSize(width, height, false);
          this.camera.aspect = width / height;
          this.camera.updateProjectionMatrix();
      
          // set render target sizes here
        }
    }

    removeThreeScene(mount) {
        // const { renderer, controls } = this.state
        mount.removeChild(this.renderer.domElement);
        this.controls.update();
    }

    getCameraPositions() {
        return this.camera.position;
    }

    updateCameraPosition(x, y, z) {
        this.camera.position.set(x, y, z)
    }

    addTextureToModel(template_sides, model_materials, model_ready, model_3d, canvas_svg_for_3d) {
        template_sides.forEach((side_name, index) => {
            const material_name = model_materials[index];
            if (canvas_svg_for_3d[side_name]) {
                // console.log('Applying ',side_name,' on ',material_name);
                this.makeTexture(model_ready, model_3d, canvas_svg_for_3d, material_name, side_name);
            }
        })
    }

    makeTexture(model_ready, model_3d, canvas_svg_for_3d, material_name, side_name) {
        if (model_ready) {
            console.log('-----TEXTURE MADE AGAIN-------', model_3d, side_name);
            
            var svg_texture = canvas_svg_for_3d[side_name];
            const src = svg_texture;

            this.textureLoaderPromise(model_3d, src, material_name).then(texture => {
            }).catch(e => console.log(e))
        } else {
            console.log('Model not ready');
        }
    }

    loadMaterials(roughness_texture, metalness_texture, ao_texture, normal_texture) {
        const _this = this;
        return new Promise(async function(resolve, reject) {
            Promise.all([
                _this.loadMaterial(roughness_texture),
                _this.loadMaterial(metalness_texture),
                _this.loadMaterial(ao_texture),
                _this.loadMaterial(normal_texture)
            ]).then(textures => {
                console.log('textures',textures);
                resolve({
                    'roughness': textures[0],
                    'metalness': textures[1],
                    'ao': textures[2],
                    'normal': textures[3],
                });
            }).catch(error => {
                console.log('Error loading textures:', error);
                reject({
                    'roughness': null,
                    'metalness': null,
                    'ao': null,
                    'normal': null,
                });
            });
        });
    }

    loadMaterial(material_url) {
        return new Promise(async function(resolve, reject) {
            console.log('material_url',material_url);
            if (material_url) {
                const loader = new TextureLoader();
                const texture = loader.load(
                    material_url,
                    function(texture) {
                        console.log('material_url texture',texture);
                        resolve(texture);
                    }, 
                    undefined, 
                    function(error) {
                        console.log('Material loading error', error);
                        reject(null);
                    }
                );
            } else {
                resolve(null);
            }

        });
    }

    textureLoaderPromise(model_3d, svg, material_name, roughness_texture = null, metalness_texture = null, ao_texture = null, normal_texture = null, transparent = false, opacity = 0.5) {
        const _this = this
        return new Promise(async function (resolve, reject) {
            const loader = new TextureLoader();
            console.log('Scene',_this.scene);
            const texture = loader.load(
                svg,
                function (texture) {
                    _this.loadMaterials(roughness_texture, metalness_texture, ao_texture, normal_texture).then(physical_textures => {
                        console.log('physical_textures:',physical_textures);
                        let design = model_3d.getObjectByName(material_name);
                        var material = new MeshStandardMaterial({
                            map: texture,
                            opacity: 1,
                            side: FrontSide,
                            // side: DoubleSide,
                        });

                        design.material = material;
                        design.material.needsUpdate = true;

                        if (transparent) {
                            material.transparent = true;
                            material.opacity = opacity ? opacity : 0.5;
                            material.side = FrontSide;
                            // material.color.set("#0000ff");
                            // material.transparent = true;
                            // material.depthWrite = false;
                            // material.format = RGBAFormat;
                            // material.alphaTest = 1;
                        }

                        if (physical_textures['metalness']) {
                            console.log('Metal texture added');
                            var metalness_texture_map = physical_textures['metalness'];
                            material.metalness = 1;
                            metalness_texture_map.flipY = false;
                            metalness_texture_map.userData = {
                                "mimeType": "image/jpeg"
                            } 
                            metalness_texture_map.wrapS = 1000;
                            metalness_texture_map.wrapT = 1000;
                            material.metalnessMap = metalness_texture_map;
                        }
    
                        if (physical_textures['roughness']) {
                            var roughness_texture_map = physical_textures['roughness'];
                            roughness_texture_map.flipY = false;
                            roughness_texture_map.userData = {
                                "mimeType": "image/jpeg"
                            } 
                            material.roughnessMap = roughness_texture_map;
                            material.roughness = 1;
                            console.log('Rough texture added');
                            // const roughnessTexture = new TextureLoader().load(roughness_texture, function(roughness_texture_map) {
                            //     console.log('Rough texture added');
                            //     material.roughnessMap = roughness_texture_map;
                            //     material.roughness = 1;
                            // })
                        }
    
                        if (physical_textures['ao']) {
                            var ao_texture_map = physical_textures['ao'];
                            ao_texture_map.flipY = false;
                            ao_texture_map.userData = {
                                "mimeType": "image/jpeg"
                            }
                            material.aoMap = ao_texture_map;
                            console.log('AO texture added');
                            // const aoTexture = new TextureLoader().load(ao_texture, function(ao_texture_map) {
                            //     console.log('AO texture added');
                            //     material.aoMap = ao_texture_map;
                            // })
                        }
    
                        if (physical_textures['normal']) {
                            var normal_texture_map = physical_textures['normal'];
                            normal_texture_map.flipY = false;
                            normal_texture_map.userData = {
                                "mimeType": "image/jpeg"
                            }
                            material.normalMapType = ObjectSpaceNormalMap;
                            material.normalScale.set(2, 2)
                            material.normalMap = normal_texture_map;
                            console.log('Normal texture added');
                            // const normalTexture = new TextureLoader().load(normal_texture, function(normal_texture_map) {
                            //     console.log('Normal texture added');
                            //     material.normalMapType = ObjectSpaceNormalMap;
                            //     material.normalMap = normal_texture_map;
                            //     // material.normalScale.set(2, 2)
                            // })
                        }

                        texture.mapping = UVMapping;
                        texture.minFilter = LinearFilter
                        texture.magFilter = LinearFilter
                        texture.flipY = false;
                        texture.encoding = 3000;
                        texture.anisotropy = 1;
                        texture.userData = {
                            "mimeType": "image/jpeg"
                        } 
                        texture.encoding = sRGBEncoding;
                        texture.wrapS = RepeatWrapping;
                        texture.wrapT = RepeatWrapping;

                        material.repeat = [1,1]
                        material.generateMipmaps = true

                        // design.material = material;
                        // design.material.needsUpdate = true;

                        console.log('TEXTURE:', texture, material);
                        
                        resolve(texture)
                    }).catch(error => {
                        console.log('Error in texture loader', error);
                        reject(error)
                    })
                },undefined,
                function (err) { reject(err) }
            );
        });
    }



resetCameraPosition() {
    // from coords
    const coords = { x: this.camera.position.x, y: this.camera.position.y, z: this.camera.position.z };
        new TWEEN.Tween(coords)
        .to({ x: this.cameraX, y: this.cameraY, z: this.cameraZ })
        .onUpdate(() =>
            this.camera.position.set(coords.x, coords.y, coords.z)
        )
        .start();
}

playClipByIndex(index) {
    // (mixer.clipAction() will also take a name string if that works better for your setup)
    
    // if (this.action && this.mixer) {
        this.resetCameraPosition();
        this.action = this.mixer.clipAction(this.animations[index]);
        this.action.reset()
        this.action.timeScale = 1;
        this.action.setLoop(LoopOnce);
        this.action.clampWhenFinished = true;
        this.action.play();
    // }
}

// assumes that the mixer has already played
playClipReverseByIndex = (index) => {
    // (mixer.clipAction() will also take a name string if that works better for your setup)
    // if (this.action && this.mixer) { 
        this.resetCameraPosition();
        this.action = this.mixer.clipAction(this.animations[index]);
        this.action.paused = false;
        this.action.timeScale = -1;
        this.action.setLoop(LoopOnce);      
        this.action.play();
    // }
  }

// assumes that the mixer has already played
// playClipReverseByIndex = (index, action, mixer) => {
//     // (mixer.clipAction() will also take a name string if that works better for your setup)
//     if (action && mixer) {
//         action = mixer.clipAction(this.animations[index]);
//         action.paused = false;
//         action.timeScale = -1;
//         action.setLoop(LoopOnce);      
//         action.play();
//     }
//   }

//   playClipByIndex(index, action, mixer) {
//     // (mixer.clipAction() will also take a name string if that works better for your setup)
//     if (action && mixer) {
//         action = mixer.clipAction(this.animations[index]);
//         action.reset()
//         action.timeScale = 1;
//         action.setLoop(LoopOnce);
//         action.clampWhenFinished = true;
//         action.play();
//     }
// }

    renderScene() {
        if (this.renderer) this.renderer.render(this.scene, this.camera)
    }

    start() {
        // const { frameId } = this.state
        if (!this.frameId) {
            this.frameId = requestAnimationFrame(this.animate);
        }
    }

    stop() {
        // cancelAnimationFrame(this.frameId)
        // const { frameId } = this.state
        cancelAnimationFrame(this.frameId);
    }

    animate(time) {
        this.frameId = requestAnimationFrame(this.animate)
        var delta = this.clock.getDelta();
      
        if (this.mixer !== null) {
          this.mixer.update(delta);
        };
        // resizeCanvasToDisplaySize()
        this.resizeCanvasToDisplaySize()
        this.controls.update();
        this.renderScene();
        TWEEN.update(time);
    }

    // var animate = function () {
    //     requestAnimationFrame( animate );
      
    //     var delta = clock.getDelta();
      
    //     if (mixer !== null) {
    //       mixer.update(delta);
    //     };
      
    //     renderer.render( scene, camera );
    //   };


    // someFun (){
    //     console.log('this',this);
    // }

    generateMultipleImages (multiple_svg, model_3d_file, selected_model_material_name,dispatch) {
        dispatch({
            type:GENERATING_IMAGE_THUMBNAIL,
            payload:true,
        })
        // const { renderer } = this.state
        const _this = this
        const zip = new JSZip()
        let archive = zip.folder('images');
        const generate_thumbnail_promise = Object.keys(multiple_svg).map(async (item, index) => {
            return _this.textureLoaderPromise(model_3d_file, multiple_svg[item], selected_model_material_name).then(texture => {
                _this.renderScene()                
                const render_data = _this.renderer.domElement.toDataURL("image/png", 1).split(',')[1]
                archive.file(`image_${index + 1}.png`, render_data, { base64: true })
                return zip;
            }).catch(e => {
                dispatch({
                    type:GENERATING_IMAGE_THUMBNAIL,
                    payload:false,
                })
                console.log(e)
            
            })
        })
        Promise.all(generate_thumbnail_promise).then((value) => {
            zip.generateAsync({ type: 'blob' }).then((content) => {
                saveAs(content, 'AppliedThumbnails.zip')
            })
            dispatch({
                type:GENERATING_IMAGE_THUMBNAIL,
                payload:false,
            })
        })

    }

    addModel = (template_3d_model, set3dModel, setModelReady, returnMaterial = false) => {
        // const { scene, mixer } = this.state
        console.log('template_3d_model',template_3d_model);
        const _this = this
        return new Promise(function (resolve, reject) {

            // Instantiate a loader
            const loader = new GLTFLoader();

            // Optional: Provide a DRACOLoader instance to decode compressed mesh data
            // const dracoLoader = new DRACOLoader();
            // dracoLoader.setDecoderPath('/examples/js/libs/draco/');
            // loader.setDRACOLoader(dracoLoader);

            // Load a glTF resource
            // var INIT_MAP = this.INITIAL_MAP;
            loader.load(
                // resource URL
                template_3d_model,
                // called when the resource is loaded
                gltf => {
                    const model = gltf.scene;

                    console.log('GLTF Model:', model.children[0].position);
                    // console.log(
                    //     'Mesh 579 Material:', 
                    //     model.children[0].children[1].children[0].children[11].children[1].children[0].children[1].material
                    // );
                    console.log('GLTF Model Animation:', gltf.animations);
                    

                    _this.mixer = new AnimationMixer(gltf.scene);
                    // _this.camera.lookAt( model.position.children[0].position );
                    // console.log('Animation:', gltf.animations);
                    _this.animations = gltf.animations
                    if (gltf.animations.length !== 0) {
                        _this.playClipByIndex(0);
                        _this.playClipReverseByIndex(0);
                    }
                    // var action = _this.mixer.clipAction(gltf.animations[0]);
                    // action.setLoop( LoopOnce );
                    // action.play();
                    // gltf.animations.forEach((clip) => {
                    //     console.log('CLIP:', clip);
                    //     _this.mixer.clipAction(clip).play();
                    // });

                    

                    var materials = [];
                    model.traverse(function (object) {
                        // console.log('Object:', object.name);
                        if (object.name !== '') {
                            materials.push(object.name);
                        }

                    });

                    // console.log('Materials:', materials);

                    if (!returnMaterial) {
                        _this.scene.add(model);
                    } else {
                        _this.current3DModelMaterails = materials;
                        returnMaterial(materials);
                    }

                    (set3dModel !== null) && set3dModel(model);

                    (setModelReady !== null) && setModelReady(true);
                    // console.log('model.position',model.position);

                    if (!returnMaterial) _this.start();
                    resolve(model);
                },
                // called while loading is progressing
                function (xhr) {

                },
                // called when loading has errors
                function (error) {
                    reject(false);
                    console.log('An error happened', error);

                }
            );
        });
    }
}