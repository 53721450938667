import {
  SAVE_SVG,
  TOGGLE_UPLOAD_LOADER,
  SAVE_MISSING_FONTS_LIST,
  SAVE_TEMPLATE_FONTS,
  TOGGLE_FONT_UPLOAD_LOADER,
  INIT_CANVAS,
  SAVE_LAYER_LIST,
  SAVE_TEMPLATE_ID,
  SELECT_LAYER,
  SAVE_OBJECT_LIST,
  SAVE_SELECTED_LAYER_INDEX,
  SAVE_PERMISSIONS_LIST,
  SAVE_DIE_LIST,
  UPDATE_LAYER_PERMISSIONS,
  SAVE_DESIGABLE_AREA,
  SAVE_OUTLINE_AREA,
  SAVE_BACKGROUND_AREA,
  TOGGLE_LAYER_UPLOAD_LOADER,
  SAVE_TEMPLATES_LIST,
  TOGGLE_TEMPLATE_LIST_LOADER,
  OBJECT_SELECTED,
  SAVE_ALL_FONTS_LIST,
  SAVE_TEMPLATE_FONT_RULES,
  SAVE_ORIGINAL_FONT_STYLES,
  SAVE_TEMPLATE_ASSIGNED_LAYERS,
  SHOW_IMAGE_PICKER_MODAL,
  CHANGE_IMAGE_UPDATE,
  TOGGLE_IMAGE_CONVERSION_LOADER,
  ADD_IMAGE_PICKER_MODAL,
  ALL_COLOR_LIST,
  COLOR_SELECTED,
  FILE_TYPE_OF_SVG,
  SAVED_TEMPLATE_LIST,
  SAVED_TEMPLATE_LOADER,
  DISPLAY_DIGITAL_PROOF_IMAGE,
  TOGGLE_DIGITAL_PROOF_LOADER,
  UPLOAD_PERCENTAGE,
  CLIENT_NAME,
  APPLIED_FONT_STYLES,
  SAVE_TEMPLATE_LIST_DRAFT,
  TOGGLE_TEMPLATE_SIDES_LOADER,
  SAVE_TEMPLATE_SIDES,
  SAVE_TEMPLATE_SIDES_DICT,
  SHOW_HIDE_GRID,
  BIGGEST_OBJECT,
  FONT_OBSERVER_LOADER,
  GET_FONT,
  GETTING_FONT_LOADER,
  GET_FONT_KEY,
  TOGGLE_LAYER_LOADER,
  SAVE_TEMPLATE_TAGS,
  SAVE_CANVAS_STATE,
  SAVE_TEMPLATE_LIST_TOTAL_PAGES,
  SAVE_TOTAL_TEMPLATES_COUNT,
  UPDATE_PAGE_NO,
  ONCHANGE_PRODUCTS_TAG_FILTER,
  SAVE_TOGGLE_TEMPLATE_LIST_LOADER,
  UPDATE_TEMPLATE_INFO_LOADER,
  ON_CHANGE_TEMPLATE_NAME,
  REPEATED_ORDER_LIST,
  SAVE_3D_MODEL_LIST,
  ON_CHANGE_3D_MODEL_NAME,
  TOGGLE_3D_MODEL_MODAL,
  TOGGLE_3D_MODEL_LOADER,
  SAVE_CANVAS_SVG,
  TOGGLE_CONFIG_3D_MODEL_MODAL,
  TOGGLE_CONFIG_MATERIAL_3D_MODEL_MODAL,
  GENERATING_IMAGE_THUMBNAIL,
  SEARCH_FILTERING_TEMPLATE,
  FILTERED_TEMPLATE_LIST,
  PCJ_TEMPLATE_LOADER,
  TEMPLATE_NAME,
} from '../Actions/ActionTypes';
import { printer_host } from '../extras/APIs';

var INITIAL_STATE = {
    // referrer: '192.168.4.35:1024',
    // referrer: 'https://w2pnx.flexiweb2print.com/',
    referrer: printer_host,
    // svg_url: 'http://192.168.4.35:1024/fp-editor-apis-server/SvgFile/Bcard01_Rounded_v1.svg',
    // referrer: '192.168.0.110',
    // svg_url: 'https://editor.flexiweb2print.com/dev/fp-editor-apis-server/SvgFile/BCARD19.svg',
    svg_url: "https://editor.flexiweb2print.com/fp-editor-apis-server/SvgFile/Birthday_Necklace with photo.svg",
    // svg_url: '',
    uploadLoader: false,
    fontUploadLoader: false,
    layerUploadLoader: false,
    toggleLayerLoader:false,
    missing_fonts_list: [],
    template_fonts: [],
    template_font_rules: [],
    canvas: '',
    template_layer_list: [],
    template_object_list: [],
    layer_permission_list: [],
    template_id: '',
    selected_layer: '',
    type_of_svg:'',
    selected_layer_index: null,
    permissions_list: [],
    die_list: [],
    layer_permissions: {},
    designable_area_layer: null, // Holds the layer id instead of the designable layer index
    designable_area_bounding_box: null,

    background_area_layer: null,
    outline_area_layer: null,

    template_list: [],
    filter_template_list: [],
    searching_template_name:'',
    total_templates: 0,
    total_pages: 1,
    current_page: 1,
    templateListLoader: false,
    pcjTemplateLoader:false,

    industry_tags: [],
    theme_tags: [],
    product_tags: [],
    filtered_product_tags:[],
    product_tags_search_text: '',

    client_selected_layer: null,
    fonts_list: [],
    original_font_styles: null,
    applied_font_styles: null,

    outline_layer_id: null,
    designable_layer_id: null,
    background_layer_id: null,

    show_image_picker_modal: false,
    add_image_modal: false,
    change_image: null,
    image_loader: false,

    swatch:[],
    color:'',
    saved_template_list:[],
    save_template_loader: false,
    digital_proof_image: [],
    digital_proof_loader: false,
    upload_percentage:'',
    client_name:"My Design",
    template_list_draft:[],
    font_loader:false,

    template_sides: [],
    save_list_template_loader:false,
    biggest_object : null,
    templateSidesLoader: false,

    template_sides_json: {},
    show_hide_grid:false,
    fonts_list : [],
    get_font_key:[],
    getting_font_loader:false,
    update_info_loader:false,
    client_canvas_state: [],
    repeated_order_list:[],
    list_of_client_button:['Design','Review','Order'],

    template_design_name: 'My Design',
    template_name:'',

    model_3d_list: [],
    model_3d_name: '',
    show_3d_model_modal: false,
    show_3d_model_loader: false,
    show_3d_config_model_modal: 0,
    show_config_material_3d_model_modal: 0,
    generating_thumbnail:false,

    canvas_svg_for_3d: {},
}

export default (state=INITIAL_STATE,action)=>{  
  // console.log('action',action);
  switch(action.type){
      case SAVE_SVG:
          return {...state, svg_url: action.payload};
      case TOGGLE_UPLOAD_LOADER:
          return {...state, uploadLoader: action.payload};
      case TOGGLE_LAYER_LOADER:
        return {...state,toggleLayerLoader:action.payload}
      case SAVE_MISSING_FONTS_LIST:
          return {...state, missing_fonts_list: action.payload};
      case SAVE_TEMPLATE_FONTS:
        return {...state, template_fonts: action.payload};
      case TOGGLE_FONT_UPLOAD_LOADER:
          return {...state, fontUploadLoader: action.payload};
      case INIT_CANVAS:
        return {...state, canvas: action.payload};
      case SAVE_LAYER_LIST:
        return {...state, template_layer_list: action.payload};
      case SAVE_TEMPLATE_ID:
        return {...state, template_id:action.payload};
      case SELECT_LAYER:
        return {...state, selected_layer:action.payload};
      case SAVE_OBJECT_LIST:
        return {...state, template_object_list:action.payload};
      case SAVE_SELECTED_LAYER_INDEX:
        return {...state, selected_layer_index:action.payload};
      case SAVE_PERMISSIONS_LIST:
        return {...state, permissions_list:action.payload};
      case SAVE_DIE_LIST:
        return {...state, die_list:action.payload};
      case UPDATE_LAYER_PERMISSIONS:
        return {...state, layer_permissions:action.payload};
      case SAVE_DESIGABLE_AREA:
        return {...state, [action.payload.prop]: action.payload.value};
      case SAVE_OUTLINE_AREA:
        return {...state, outline_area_layer:action.payload};
      case SAVE_BACKGROUND_AREA:
        return {...state, background_area_layer:action.payload};
      case TOGGLE_LAYER_UPLOAD_LOADER:
        return {...state, layerUploadLoader:action.payload};
      case SAVE_TEMPLATES_LIST:
        return {...state, template_list:action.payload};
      case TOGGLE_TEMPLATE_LIST_LOADER:
        return {...state, templateListLoader:action.payload};
      case SAVE_TOGGLE_TEMPLATE_LIST_LOADER:
        return {...state,save_list_template_loader:action.payload}
      case OBJECT_SELECTED:
        return {...state, client_selected_layer :action.payload};
      case SAVE_ALL_FONTS_LIST:
        return {...state, fonts_list :action.payload};
      case SAVE_TEMPLATE_FONT_RULES:
        return {...state, template_font_rules :action.payload};
      case SAVE_ORIGINAL_FONT_STYLES:
        return {...state, original_font_styles :action.payload};
      case SAVE_TEMPLATE_ASSIGNED_LAYERS:
        return {...state, [action.payload.prop]: action.payload.value};
      case SHOW_IMAGE_PICKER_MODAL:
        return {...state, show_image_picker_modal: action.payload};
      case CHANGE_IMAGE_UPDATE:
        return {...state, change_image: action.payload};
      case TOGGLE_IMAGE_CONVERSION_LOADER:
        return {...state, image_loader: action.payload};
      case ADD_IMAGE_PICKER_MODAL:
        return {...state,add_image_modal:action.payload};
      case ALL_COLOR_LIST:
        return {...state,swatch:action.payload}
      case COLOR_SELECTED:
        return {...state,color:action.payload}
      case FILE_TYPE_OF_SVG:
        return {...state,type_of_svg:action.payload}
      case SAVED_TEMPLATE_LIST:
        return {...state,saved_template_list:action.payload}
      case SAVED_TEMPLATE_LOADER:
        return {...state,save_template_loader:action.payload}
      case DISPLAY_DIGITAL_PROOF_IMAGE:
        return {...state,digital_proof_image:action.payload}
      case TOGGLE_DIGITAL_PROOF_LOADER:
        return {...state,digital_proof_loader:action.payload}
      case UPLOAD_PERCENTAGE:        
        return {...state,upload_percentage:action.payload}
      case CLIENT_NAME:
        return {...state,client_name:action.payload}
      case APPLIED_FONT_STYLES:
        return{...state,applied_font_styles:action.payload}
      case SAVE_TEMPLATE_LIST_DRAFT:
        return{...state,template_list_draft:action.payload}
      case TOGGLE_TEMPLATE_SIDES_LOADER:
        return{...state, templateSidesLoader: action.payload}
      case SAVE_TEMPLATE_SIDES:
        return{...state, template_sides: action.payload}
      case SAVE_TEMPLATE_SIDES_DICT:
        return{...state, template_sides_canvas_json: action.payload}
      case SHOW_HIDE_GRID:
        return {...state,show_hide_grid:action.payload}
      case BIGGEST_OBJECT:
        return {...state,biggest_object:action.payload}
      case FONT_OBSERVER_LOADER:
        return {...state,font_loader:action.payload}
      case GET_FONT:
        return {...state,fonts_list:action.payload}
      case GET_FONT_KEY:
        return {...state,get_font_key:action.payload}          
      case GETTING_FONT_LOADER:
        return{...state,getting_font_loader:action.payload}
      case SAVE_TEMPLATE_TAGS:
        return {...state, [action.payload.prop]: action.payload.value};
      case SAVE_CANVAS_STATE:
        return {...state, client_canvas_state: action.payload};
      case SAVE_TEMPLATE_LIST_TOTAL_PAGES:
        return {...state, total_templates: action.payload};
      case SAVE_TOTAL_TEMPLATES_COUNT:
        return {...state, total_pages: action.payload};
      case UPDATE_PAGE_NO:
        return {...state, current_page: action.payload};
      case ONCHANGE_PRODUCTS_TAG_FILTER:
        return {...state, product_tags_search_text: action.payload};
      case UPDATE_TEMPLATE_INFO_LOADER:
        return {...state,update_info_loader:action.payload}
      case ON_CHANGE_TEMPLATE_NAME:
        return {...state, template_design_name: action.payload}
      case TEMPLATE_NAME:
      return {...state, template_name: action.payload}
      case REPEATED_ORDER_LIST:
        return {...state,repeated_order_list:action.payload}
      case SAVE_3D_MODEL_LIST:
        return {...state, model_3d_list: action.payload}
      case ON_CHANGE_3D_MODEL_NAME:
        return {...state, model_3d_name: action.payload}
      case TOGGLE_3D_MODEL_MODAL:
        return {...state, show_3d_model_modal: action.payload}
      case TOGGLE_3D_MODEL_LOADER:
        return {...state, show_3d_model_loader: action.payload}
      case SAVE_CANVAS_SVG:
        return {...state, canvas_svg_for_3d: action.payload}
      case TOGGLE_CONFIG_3D_MODEL_MODAL:
        return {...state, show_3d_config_model_modal: action.payload}
      case TOGGLE_CONFIG_MATERIAL_3D_MODEL_MODAL:
        return {...state, show_config_material_3d_model_modal: action.payload}
      case GENERATING_IMAGE_THUMBNAIL:
        return {...state,generating_thumbnail:action.payload}
      case SEARCH_FILTERING_TEMPLATE:
        return {...state,searching_template_name:action.payload}
      case FILTERED_TEMPLATE_LIST:
        return{...state,filter_template_list:action.payload}
      case PCJ_TEMPLATE_LOADER:
        // console.log('pcj',action.payload);
        return {...state,pcjTemplateLoader:action.payload}
      default:
          return state
  }
}
