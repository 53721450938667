import React from 'react'

function HeadingTitle({ title, style,children }) {
    return (
        <div className="nk-block-head">
            <div className="heading">
                <ul className="breadcrumb" style={{backgroundColor:'transparent'}}>
                    <li>Home</li>
                    <li>Manage Editor</li>
                </ul>
                <h2 style={style} className="hd2 marginbtm0 float-none ">{title}</h2>
            </div>
            {children}
        </div>
    )
}

export { HeadingTitle }