import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  
} from "../../Actions";
import { 
    changeProductWrap,
    render3DModel,
    renderProductAnimation,
    renderProductAnimationReverse,
} from "../../extras/3DProductWrappingCommonFunctions";
import {
    HeadPart,
    HeadingTitle,
    ModalLoader,
    Button,
} from "../../Components";
import {PRODUCT_3D_MODEL_WRAPPING} from '../../extras/APIs';

function ImageTextureToiPadScreen() {
    const navigate = useNavigate();
    const reduxState = useSelector((state) => state.jewellery_pitch);
    const {} = reduxState;

    const [model_3d, set3dModel] = useState(null);
    const [model_ready, setModelReady] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        const cameraX = -50;
        const cameraY = 38;
        const cameraZ = 45;
        render3DModel(PRODUCT_3D_MODEL_WRAPPING + "iPadV1/ipad_with_animation.glb", cameraX, cameraY, cameraZ, set3dModel, setModelReady);
    }, [dispatch])

    function createJewelleryProductListTable() {
        const material_name = 'a3_ipad_0';
        // const texture_url = PRODUCT_3D_MODEL_WRAPPING + "iPadV1/Textures/defaultMat_Base_Color.jpg";
        const roughness_texture_url = PRODUCT_3D_MODEL_WRAPPING + "iPadV1/Textures/ipad_metallicRoughness.png";
        // const roughness_texture_url = null;
        const metalness_texture_url = PRODUCT_3D_MODEL_WRAPPING + "iPadV1/Textures/ipad_metalness_grey.png";
        // const metalness_texture_url = null;
        // const ao_texture_url = PRODUCT_3D_MODEL_WRAPPING + "iPadV1/Textures/defaultMat_Mixed_AO.jpg";
        const ao_texture_url = null;
        // const normal_texture_url = PRODUCT_3D_MODEL_WRAPPING + "iPadV1/Textures/ZIPPER_JacketMAT_normal.png";
        const normal_texture_url = null;
        return (
          <>
            {/* Here head part start */}
            <HeadPart hide_menu={true}>
              <div className="nk-block-head full-hd-wrap">
                <HeadingTitle title={"iPad Color Options"} />
              </div>
              <Button 
                onPress={() => {
                    const texture_url = PRODUCT_3D_MODEL_WRAPPING + "iPadV1/Textures/ipad_baseColor_silver.png";
                    changeProductWrap(
                        model_3d,
                        // threedOut,
                        texture_url,
                        material_name,
                        roughness_texture_url,
                        metalness_texture_url,
                        ao_texture_url,
                        normal_texture_url,
                    );

                    // renderProductAnimation(0);

                    // changeProductWrap(
                    //     model_3d,
                    //     // threedOut,
                    //     texture_url,
                    //     material_name,
                    //     roughness_texture_url,
                    //     metalness_texture_url,
                    //     ao_texture_url,
                    //     normal_texture_url,
                    // );

                    // changeProductWrap(
                    //     model_3d,
                    //     // threedOut,
                    //     PRODUCT_3D_MODEL_WRAPPING + "iPadV1/Textures/material_baseColor_silver.png",
                    //     "Plane007_keyboard_plastic_2_0",
                    //     null,
                    //     null,
                    //     ao_texture_url,
                    //     normal_texture_url,
                    // );

                    // changeProductWrap(
                    //     model_3d,
                    //     // threedOut,
                    //     PRODUCT_3D_MODEL_WRAPPING + "iPadV1/Textures/material_baseColor_silver.png",
                    //     "Plane007_keyboard_plastic_0",
                    //     null,
                    //     null,
                    //     ao_texture_url,
                    //     normal_texture_url,
                    // );
                }} 
                classgiven="btn-basic" 
                name={'Style 1'} 
              />

              <Button 
                onPress={() => {
                    const texture_url = PRODUCT_3D_MODEL_WRAPPING + "iPadV1/Textures/ipad_baseColor.png";
                    // changeProductWrap(texture_url);
                    changeProductWrap(
                        model_3d,
                        // threedOut,
                        texture_url,
                        material_name,
                        roughness_texture_url,
                        metalness_texture_url,
                        ao_texture_url,
                        normal_texture_url,
                    );

                    // renderProductAnimation(0);
                }} 
                classgiven="btn-basic" 
                name={'Style 2'} 
              />

              <Button 
                onPress={() => {
                    const texture_url = PRODUCT_3D_MODEL_WRAPPING + "iPadV1/Textures/defaultMat_Base_Color.jpg";
                    // changeProductWrap(texture_url);
                    // changeProductWrap(
                    //     model_3d,
                    //     // threedOut,
                    //     texture_url,
                    //     material_name,
                    //     roughness_texture_url,
                    //     metalness_texture_url,
                    //     ao_texture_url,
                    //     normal_texture_url,
                    // );

                    renderProductAnimation(0);
                }} 
                classgiven="btn-basic" 
                name={'Attach to Keyboard'} 
              />

                <Button 
                    onPress={() => {
                        renderProductAnimationReverse(0);
                    }} 
                    classgiven="btn-basic" 
                    name={'Remove from Keyboard'} 
              />
              <div className="inside-bg-wrap">
                <div className="manage-template-wrap">
                    <div className="catergory-listing">
                        <div id='threejs-div' className='modal-r-3d-preview w-60'>
                            <div className="threejs_preview" id="threejs_preview" style={{width:500,height:500}}></div>
                        </div>
                    </div>
                </div>
              </div>
            </HeadPart>
            {/* Here head part ends  */}
          </>
        );
    }

    function renderLoader() {
        // console.log('jewellery_product_list_loader',jewellery_product_list_loader);
        if (false) { //Add Loader here
            return (
            <HeadPart>
                <ModalLoader
                loadingTitle={"Loading Products List"}
                openModal={false} //Add Loader Variable here
                />
            </HeadPart>
            );
        } else {
        //   return <div>{createTemplateListTable()}</div>;
            return createJewelleryProductListTable();
        }
    }

    return renderLoader();
}

export {ImageTextureToiPadScreen};