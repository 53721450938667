import React, { useEffect, useState, useRef } from "react";
import {ThreeDPreview2} from './ThreeDpreview2'
import { TOGGLE_3D_MODEL_LOADER } from "../Actions/ActionTypes";

const threedOut = new ThreeDPreview2();

export function renderProductAnimation(index) {
    // console.log(threedOut);
    // Redundant Function
    threedOut.playClipByIndex(index)
}

export function renderProductAnimationReverse(index) {
    // console.log(threedOut);
    // Redundant Function
    threedOut.playClipReverseByIndex(index)
}

// function changeProductWrap(texture_url) {
export function changeProductWrap(
    model_3d,
    texture_url,
    material_name,
    roughness_texture_url,
    metalness_texture_url,
    ao_texture_url,
    normal_texture_url,
    transparent = false,
    opacity = 0.5,
    dispatch=null,
) {
    if(dispatch){
        dispatch({
            type:TOGGLE_3D_MODEL_LOADER,
            payload:true
        })
    }
    
    // const material_name = 'shoes';
    // // const texture_url = "http://192.168.1.44:3001/3dModelFiles/ShoeV1/Textures/defaultMat_Base_Color.jpg";
    // const roughness_texture_url = "http://192.168.1.44:3001/3dModelFiles/ShoeV1/Textures/defaultMat_Roughness.jpg";
    // // const metalness_texture_url = "http://192.168.1.44:3001/3dModelFiles/ShoeV1/Textures/defaultMat_Metallic-Blue-Channel.jpg";
    // // const metalness_texture_url = "http://192.168.1.44:3001/3dModelFiles/ShoeV1/Textures/defaultMat_Metallic-RGB.png";
    // const metalness_texture_url = "http://192.168.1.44:3001/3dModelFiles/ShoeV1/Textures/defaultMat_Metallic.jpg";
    // const ao_texture_url = "http://192.168.1.44:3001/3dModelFiles/ShoeV1/Textures/defaultMat_Mixed_AO.jpg";
    // const normal_texture_url = "http://192.168.1.44:3001/3dModelFiles/ShoeV1/Textures/defaultMat_Normal_OpenGL.jpg";
    // console.log('model_3d',model_3d, model_3d.children[0].material.metalnessMap);
    threedOut.textureLoaderPromise(
        model_3d, 
        texture_url, 
        material_name,
        roughness_texture_url, 
        metalness_texture_url,
        ao_texture_url,
        normal_texture_url,
        transparent,
        opacity,
    ).then(() => {
        console.log(threedOut.getCameraPositions());
        console.log('Texture Applied');
        console.log('Model:', model_3d);
        if(dispatch){
            dispatch({
                type:TOGGLE_3D_MODEL_LOADER,
                payload:false
            })
        }
    }).catch(error => {
        console.log('Texture Failed', error);
        if(dispatch){
            dispatch({
                type:TOGGLE_3D_MODEL_LOADER,
                payload:false
            })
        }
    })
}

    export function render3DModel(model_path, cameraX, cameraY, cameraZ, set3dModel, setModelReady,dispatch=null) {
    // -28, -38, -50
        // const cameraX = 48.0863689962824;
        // const cameraY = 111.71306557254681;
        // const cameraZ = 1254.8830269299882;
    if(dispatch){
        dispatch({
            type:TOGGLE_3D_MODEL_LOADER,
            payload:true,
        })
    }
    const threeD_model_div_id = 'threejs_preview';
    threedOut.createThreeScene(model_path, cameraX, cameraY, cameraZ, threeD_model_div_id, null, set3dModel, setModelReady).then(model => {
        console.log('Model Ready!', model);
        const texture_url = "http://192.168.1.44:3001/3dModelFiles/ShoeV1/Textures/defaultMat_Base_Color_A.jpg";
        changeProductWrap(texture_url);
        if(dispatch){
            dispatch({
                type:TOGGLE_3D_MODEL_LOADER,
                payload:false,
            })
        }
    }).catch(error => {
        if(dispatch){
            dispatch({
                type:TOGGLE_3D_MODEL_LOADER,
                payload:false,
            })
        }
        console.log('Error:', error);
    });
    // dispatch({
    //     type: TOGGLE_JEWELLERY_PRODUCT_LIST_LOADER,
    //     payload: true,
    // });
}