import React from 'react'

function GroupButton({ children, className }) {
    return (
        <div className={className} style={{ cursor: 'pointer' }}>
            {children}
        </div>
    )
}

export { GroupButton }