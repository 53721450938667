import {INIT_CANVAS, SHOW_HIDE_GRID} from '../Actions/ActionTypes';
import {getBiggestObject, clientCanvasObjectModified} from '../Actions';
import { fabric } from 'fabric';
import FontFaceObserver from 'fontfaceobserver';
import $ from "jquery";
import { GRID_IMAGE } from './APIs';
import { CustomAlert } from '../Components';
// Delete object is called from TemplateActions.js
var rotation = 0

export const flipHorizontal = (canvas, dispatch) => {
  const object = canvas.getActiveObject();

  if (object) {
    if (!object.lockScalingFlip) {
      object.toggle('flipX');
    } else {
      CustomAlert.alert(true,"You are not allowed to Flip this object!");
    }

    canvas.requestRenderAll();
    clientCanvasObjectModified(dispatch, canvas);

    dispatch({
      type: INIT_CANVAS,
      payload: canvas,
    });
  } else {
    CustomAlert.alert(true,'Select an object first!');
  }
}

export const flipVertical = (canvas, dispatch) => {
  const object = canvas.getActiveObject();

  if (object) {
    if (!object.lockScalingFlip) {
      object.toggle('flipY');
    } else {
      CustomAlert.alert(true,"You are not allowed to Flip this object!" );
    }

    canvas.requestRenderAll();
    clientCanvasObjectModified(dispatch, canvas);
    dispatch({
      type: INIT_CANVAS,
      payload: canvas,
    });
  } else {
    CustomAlert.alert(true,'Select an object first!');
  }
}

export const rotateDirection = (canvas,dispatch,rotating) => {
  const object = canvas.getActiveObject()
  if(object){
    if(object.get('isRotatable')){
      if(rotating==='clockwise'){
        rotation+=30
      }
      if(rotating==='counter_clockwise'){
        rotation-=30
      }
      object.rotate(rotation)
    }else{
      CustomAlert.alert(true,'Your not allowed to rotate this object!')
    }
  }else{
    CustomAlert.alert(true,'Select an object first!')
  }
  canvas.requestRenderAll()
  dispatch({
    type: INIT_CANVAS,
    payload: canvas,
  })
}

export const showHideGrid = (canvas, dispatch) => {
  if (canvas.get('overlayImage')) {
    // console.log('do something');
    canvas.setOverlayImage(null);
    dispatch({
      type:SHOW_HIDE_GRID,
      payload:false
    })
  } else {
    canvas.setOverlayImage(GRID_IMAGE, canvas.renderAll.bind(canvas), {
      // Needed to position overlayImage at 0/0
      originX: 'left',
      originY: 'top',
      opacity: 0.1,
      excludeFromExport: true,
    });
    dispatch({
      type:SHOW_HIDE_GRID,
      payload:true
    })
  }
  console.log('in show hide grid');
  canvas.requestRenderAll();
  dispatch({
    type: INIT_CANVAS,
    payload: canvas,
  });

}

export const textBold = (canvas, dispatch, original_font_styles) => {
  const selected_object = canvas.getActiveObjects()[0];
  // const selected_object_id = selected_object.get('id');
  // const selected_object_font_weight = original_font_styles[selected_object_id].fontWeight;
  const selected_object_font_weight = selected_object.get("fontWeight");
  const font_family = selected_object.get('fontFamily').replaceAll("'", "");
  const italic = selected_object.get('fontStyle');

  console.log(selected_object.get('fontWeight'));
  var apply_font_weight = selected_object_font_weight;

  if (selected_object) {
    if (selected_object_font_weight === 'bold' && selected_object.get('fontWeight') === 'bold') {
      // selected_object.set('fontWeight', 'normal');
      apply_font_weight = 'normal';
    } else {
      if (selected_object.get('fontWeight') === selected_object_font_weight) {
        // selected_object.set('fontWeight', 'bold');
        apply_font_weight = 'bold';
      } else {
        // selected_object.set('fontWeight', selected_object_font_weight);
        apply_font_weight = selected_object_font_weight;
      }
    }

    const checkFont = new FontFaceObserver(font_family, {
      weight: apply_font_weight,
      // style: italic,
    });

    console.log('load font:', checkFont,apply_font_weight);

    checkFont.load()
    .then(function () {
      console.log('Font loaded');
      selected_object.set('fontWeight', apply_font_weight);
      canvas.requestRenderAll();
      dispatch({
        type: INIT_CANVAS,
        payload: canvas,
      });
    }).catch(function (e) {
        // console.log(checkFont, e)
        CustomAlert.alert(true,'font loading failed ');
    });
    // canvas.requestRenderAll();
    // dispatch({
    //   type: INIT_CANVAS,
    //   payload: canvas,
    // });
  } else {
    CustomAlert.alert(true,'Select an object first!');
  }
}

export const textItalic = (canvas, dispatch, original_font_styles) => {
  const selected_object = canvas.getActiveObjects()[0];
  // const selected_object_id = selected_object.get('id');
  const font_family = selected_object.get('fontFamily').replaceAll("'", "");
  const weight = selected_object.get('fontWeight');

  // console.log('selected font ',font_family)
  const selected_object_font_style = selected_object.get("fontStyle");
  var apply_font_style = selected_object_font_style;
  if (selected_object) {
    if (selected_object_font_style === 'italic' && selected_object.get('fontStyle') === 'italic') {
      // selected_object.set('fontStyle', 'normal');
      apply_font_style = 'normal';
    } else {
      if (selected_object.get('fontStyle') === selected_object_font_style) {
        // selected_object.set('fontStyle', 'italic');
        apply_font_style = 'italic';
      } else {
        // selected_object.set('fontStyle', selected_object_font_style);
        apply_font_style = selected_object_font_style;
      }
    }
    const checkFont = new FontFaceObserver(font_family, {
      weight: weight,
      style: apply_font_style,
    });

    checkFont.load()
    .then(function () {
      selected_object.set('fontStyle', apply_font_style);
      canvas.requestRenderAll();
      dispatch({
        type: INIT_CANVAS,
        payload: canvas,
      });
    }).catch(function (e) {
        // console.log(checkFont, e)
        CustomAlert.alert(true,'font loading failed ');
    });
    // canvas.requestRenderAll();
    // dispatch({
    //   type: INIT_CANVAS,
    //   payload: canvas,
    // });
  } else {
    CustomAlert.alert(true,'Select an object first!');
  }
}

export const textUnderline = (canvas,dispatch) => {
  const selected_object = canvas.getActiveObjects()[0];
  const selected_object_font_style = selected_object.get("underline");
  var apply_font_style = selected_object_font_style;
  if(selected_object){
    if(!apply_font_style){
      apply_font_style = true
    }else{
      apply_font_style = false
    }
  }  
  selected_object.set('underline',apply_font_style)
  canvas.requestRenderAll()
  dispatch({
    type: INIT_CANVAS,
    payload: canvas,
  });
}

export const textAlign = (canvas, dispatch, align) => {
  const selected_object = canvas.getActiveObject();
  selected_object.set('textAlign', align);
  canvas.requestRenderAll();
  dispatch({
    type: INIT_CANVAS,
    payload: canvas,
  });
}

export const changeFontColor = (canvas, dispatch, color) => {
  const selected_object = canvas.getActiveObject();

  const hexTorgbConverter = hexToRgb(color)
  // console.log('color',hexTorgbConverter,color);
  const rgb = `rgb(${hexTorgbConverter.r},${hexTorgbConverter.g},${hexTorgbConverter.b})`
  
  selected_object.set('fill', rgb);
  canvas.requestRenderAll();
  dispatch({
    type: INIT_CANVAS,
    payload: canvas,
  });
}

export const changeFontFamily = (canvas, dispatch, selected_font, bold, italic) => {
  const selected_object = canvas.getActiveObject();
  if (selected_font) {
    console.log(selected_font, bold, italic);
    const checkFont = new FontFaceObserver(selected_font, {
      weight: bold,
      style: italic,
    });
    // var myfont = new FontFaceObserver(selected_font)
    checkFont.load(null, 10000)
    .then(function () {
        // when font is loaded, use it.
        // console.log('Waited, font loaded', checkFont);
        selected_object.set("fontFamily", selected_font);
        // client_selected_layer.set("fontFamily", selected_font.family);
        // client_selected_layer.set("fontFamily", selected_font.family);
        canvas.requestRenderAll();
        dispatch({
          type: INIT_CANVAS,
          payload: canvas,
        });
    }).catch(function (e) {
        console.log(e)
        CustomAlert.alert(true,'font loading failed ' + selected_font);
    });
  } else {
    CustomAlert.alert(true,'Select a font to apply');
  }
}

export const addText = (canvas, dispatch) => {
  var objs = canvas.getObjects();
  const biggestObject = getBiggestObject(objs);

  var text = new fabric.IText('Enter Text Here', {
    left: biggestObject.left + 50,
    top: biggestObject.top + 50,
    fill: '#000000',
  }, {
      fontStretch: false,
  });

  text.set('isDepthChange', true);
  text.set('isDeleteable', true);
  text.set('isImageReplaceable', false);
  text.set('isFontStyleChangeable', true);
  text.set('isFontColorChangeable', true);
  text.set('isBGColorChangable', true);
  text.set('fontStyle','normal')
  // text.set('top', biggestObject.top + 50);
  // text.set('left', biggestObject.left + 50);
  // text.set('left', biggestObject.left + 50);

  canvas.add(text);
  canvas.centerObject(text)
  canvas.bringToFront(text);

  canvas.requestRenderAll();
  dispatch({
    type: INIT_CANVAS,
    payload: canvas,
  });

  clientCanvasObjectModified(dispatch, canvas);
}



export const addRectangle = (canvas, dispatch) => {
  var objs = canvas.getObjects();
  const biggestObject = getBiggestObject(objs);
  var rect = new fabric.Rect({
    top: biggestObject.top + 80,
    left: biggestObject.left + 80,
    width: 80,
    height: 80,
    fill: '#1B5ADE',
  });

  rect.set('isDepthChange', true);
  rect.set('isDeleteable', true);
  rect.set('isImageReplaceable', false);
  rect.set('isFontStyleChangeable', true);
  rect.set('isFontColorChangeable', true);
  rect.set('isBGColorChangable', true);
  rect.set('isMaskable',false)
  canvas.centerObject(rect)
  canvas.add(rect);

  canvas.requestRenderAll();
  dispatch({
    type: INIT_CANVAS,
    payload: canvas,
  });

  clientCanvasObjectModified(dispatch, canvas);
}

export const addCircle = (canvas, dispatch) => {
  var objs = canvas.getObjects();
  const biggestObject = getBiggestObject(objs);
  var circle = new fabric.Circle({
    top: biggestObject.top + 80,
    left: biggestObject.left + 80,
    radius: 80,
    fill: '#1B5ADE',
  });

  circle.set('isDepthChange', true);
  circle.set('isDeleteable', true);
  circle.set('isImageReplaceable', false);
  circle.set('isFontStyleChangeable', true);
  circle.set('isFontColorChangeable', true);
  circle.set('isBGColorChangable', true);
  circle.set('isMaskable',false)
  canvas.centerObject(circle)
  canvas.add(circle);

  canvas.requestRenderAll();
  dispatch({
    type: INIT_CANVAS,
    payload: canvas,
  });

  clientCanvasObjectModified(dispatch, canvas);
}

export const addLine = (canvas, dispatch, orient) => {
  var objs = canvas.getObjects();
  const biggestObject = getBiggestObject(objs);

  const top = biggestObject.top;
  const left = biggestObject.left;
  if (orient === 'horizontal') {
    var line = new fabric.Line([left + 100, top + 100, left + 300, top + 100], {
        stroke: 'green'
    });
  } else {
    var line = new fabric.Line([left + 100, 50, left + 100, 250], {
        stroke: 'green'
    });
  }

  line.set('isDepthChange', true);
  line.set('isDeleteable', true);
  line.set('isImageReplaceable', false);
  line.set('isFontStyleChangeable', true);
  line.set('isFontColorChangeable', true);
  line.set('isBGColorChangable', true);
  line.set('isMaskable',false)
  canvas.centerObject(line)
  canvas.setActiveObject(line)
  canvas.add(line);

  canvas.requestRenderAll();
  dispatch({
    type: INIT_CANVAS,
    payload: canvas,
  });

  clientCanvasObjectModified(dispatch, canvas);
}

export const sendForward = (canvas, dispatch) => {
  const selected_object = canvas.getActiveObject();
  if(selected_object.get('isDepthChange')){
    
    canvas.bringForward(selected_object);
    canvas.requestRenderAll();
  }else{
    CustomAlert.alert(true,'Not allowed to Change Depth of these layer')
  }
  // console.log('selected',selected_object);
  dispatch({
    type: INIT_CANVAS,
    payload: canvas,
  });
}

export const sendBackward = (canvas, dispatch) => {
  const selected_object = canvas.getActiveObject();
  if(selected_object.get('isDepthChange')){
    canvas.sendBackwards(selected_object);
    canvas.requestRenderAll();
    
  }else CustomAlert.alert(true,'Not allowed to Change Depth of these layer')
  dispatch({
    type: INIT_CANVAS,
    payload: canvas,
  });
}

function hexToRgb(hex) {
  if(hex){
    
    const withoutSpaceHex = hex.replace('/\s+/g',' ').trim()
    // console.log('hex',hex.replace('/\s+/g',' ').trim())
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(withoutSpaceHex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }else{
    return {
      r:255,
      g:255,
      b:255
    }
  }
  // hex to rgb converter
}
export const changeBGColor = (canvas, dispatch, color) => {
  const selected_object = canvas.getActiveObject();
  // console.log('hex',color);
  const hexTorgbConverter = hexToRgb(color.replace('/\s+/g',''))
  const rgb = `rgb(${hexTorgbConverter.r},${hexTorgbConverter.g},${hexTorgbConverter.b})`
  if (selected_object.get('type') === 'i-text') {
    selected_object.set('backgroundColor', rgb);
  } else if (selected_object.get('type') === 'line') {
    selected_object.set('stroke', rgb);
  }
  else {
    selected_object.set('fill', rgb);
  }
  
  canvas.requestRenderAll();
  dispatch({
    type: INIT_CANVAS,
    payload: canvas,
  });
}

export const changeBorderBGColor = (canvas,dispatch,color) => {
  const selected_object = canvas.getActiveObject();
  // console.log('hex',color);
  const hexTorgbConverter = hexToRgb(color.replace('/\s+/g',''))
  const rgb = `rgb(${hexTorgbConverter.r},${hexTorgbConverter.g},${hexTorgbConverter.b})`
  selected_object.set('stroke', rgb);
  selected_object.set('strokeWidth',5)
  canvas.requestRenderAll();
  dispatch({
    type: INIT_CANVAS,
    payload: canvas,
  });
}

export const applied_font_style = (canvas) => {
  const font_object = {}
  canvas.forEachObject(function(obj){
    if(obj.get('type')==="i-text"){
      var font_styles = {}
      font_styles.fontWeight = obj.get('fontWeight')
      font_styles.fontStyle= obj.get('fontStyle')
      font_styles.fontFamily = obj.get('fontFamily').replaceAll("'", "")
      const font_id = obj.get('layer_id')
      font_object[font_id] = font_styles
    }
  })
  return font_object
  // console.log('object ',font_object)
  
}


export const fontSizeIncrementDecrement = (canvas,dispatch,option) => {
  var size_alter = 1.5
  const client_selected = canvas.getActiveObject()
  const current_size = client_selected.get('fontSize')
  console.log('current_size',current_size);
  if(option == 'increase'){
    client_selected.set('fontSize',current_size + size_alter)
  }else{
    client_selected.set('fontSize',current_size - size_alter)
  }

  canvas.requestRenderAll();
  dispatch({
    type: INIT_CANVAS,
    payload: canvas,
  });

}

export const resetFontStyle = (defaultFontStyle, canvas, dispatch, object) => {
  const object_original_font_style = defaultFontStyle[object.get('id')];
  // console.log( 'fonts startke',defaultFontStyle,object_original_font_style,object.get('id'));

  const font_family = object_original_font_style.fontFamily;
  const font_style = object_original_font_style.fontStyle;
  const font_weight = object_original_font_style.fontWeight;
  const font_underline = object_original_font_style.underline
  const font_color = object_original_font_style.fontColor
  const font_background_color = object_original_font_style.textBackgroundColor
  // const font_underline = object
  const checkFont = new FontFaceObserver(font_family, {
    weight: font_weight,
    style: font_style,
  });
  // client_selected_layer.set('fontFamily', selected_font);
  // var myfont = new FontFaceObserver(selected_font)
  checkFont.load()
  .then(function () {
      // when font is loaded, use it.
      // console.log('Waited, font loaded', checkFont);

      object.set("fontFamily", font_family);
      object.set("fontStyle", font_style);
      object.set("fontWeight", font_weight);
      object.set('underline',font_underline)
      object.set('fill',font_color);
      object.set('backgroundColor',font_background_color);
      canvas.requestRenderAll();
      dispatch({
        type: INIT_CANVAS,
        payload: canvas,
      });
  }).catch(function (e) {
      console.log(e)
      alert('font loading failed ');
  });
}

export const getBase64 = (file)=>{
  return new Promise((resolve,reject)=>{
    const reader = new FileReader()
    reader.readAsDataURL(file);
    reader.onload= () => resolve(reader.result)
    reader.onerror= (err) =>{
      console.log('err',err);
      reject(err)
    
    }
  })
}

export const gettingBrowserInfo = () =>{
  var sBrowser,sUsrAg =navigator.userAgent
    if (sUsrAg.indexOf("Firefox") > -1) {
      sBrowser = "Mozilla Firefox";
      // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
    } else if (sUsrAg.indexOf("SamsungBrowser") > -1) {
      sBrowser = "Samsung Internet";
      // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
    } else if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
      sBrowser = "Opera";
      // "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
    } else if (sUsrAg.indexOf("Trident") > -1) {
      sBrowser = "Microsoft Internet Explorer";
      // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
    } else if (sUsrAg.indexOf("Edge") > -1) {
      sBrowser = "Microsoft Edge";
      // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
    } else if (sUsrAg.indexOf("Chrome") > -1) {
      sBrowser = "Google Chrome or Chromium";
      // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
    } else if (sUsrAg.indexOf("Safari") > -1) {
      sBrowser = "Apple Safari";
      // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
    } else {
      sBrowser = "unknown";
    }
    return sBrowser
}








export function initAligningGuidelines(canvas,dispatch) {

  var ctx = canvas.getSelectionContext(),
      aligningLineOffset = 5,
      aligningLineMargin = 4,
      aligningLineWidth = 1,
      aligningLineColor = 'red',
      viewportTransform,
      zoom = 1;
  function drawVerticalLine(coords) {
    drawLine(
      coords.x + 0.5,
      coords.y1 > coords.y2 ? coords.y2 : coords.y1,
      coords.x + 0.5,
      coords.y2 > coords.y1 ? coords.y2 : coords.y1);
  }

  function drawHorizontalLine(coords) {
    drawLine(
      coords.x1 > coords.x2 ? coords.x2 : coords.x1,
      coords.y + 0.5,
      coords.x2 > coords.x1 ? coords.x2 : coords.x1,
      coords.y + 0.5);
  }

  function drawLine(x1, y1, x2, y2) {
    var originXY = fabric.util.transformPoint(new fabric.Point(x1,y1),canvas.viewportTransform),
      dimensions = fabric.util.transformPoint(new fabric.Point(x2,y2),canvas.viewportTransform);
    ctx.save();
    ctx.lineWidth = aligningLineWidth;
    ctx.strokeStyle = aligningLineColor;
    ctx.beginPath();
    ctx.moveTo(
      ((originXY.x)), 
      ((originXY.y))
      );
    ctx.lineTo(
      ((dimensions.x)), 
      ((dimensions.y))
    );
    ctx.stroke();
    ctx.restore();
  }

  function isInRange(value1, value2) {
    value1 = Math.round(value1);
    value2 = Math.round(value2);
    for (var i = value1 - aligningLineMargin, len = value1 + aligningLineMargin; i <= len; i++) {
      if (i === value2) {
        return true;
      }
    }
    return false;
  }

  var verticalLines = [],
      horizontalLines = [];

  canvas.on('mouse:down', function () {
    viewportTransform = canvas.viewportTransform;
    zoom = canvas.getZoom();
  });

  canvas.on('object:moving', function(e) {

    var activeObject = e.target,
        canvasObjects = canvas.getObjects(),
        activeObjectCenter = activeObject.getCenterPoint(),
        activeObjectLeft = activeObjectCenter.x,
        activeObjectTop = activeObjectCenter.y,
        activeObjectBoundingRect = activeObject.getBoundingRect(),
        activeObjectHeight = activeObjectBoundingRect.height / viewportTransform[3],
        activeObjectWidth = activeObjectBoundingRect.width / viewportTransform[0],
        horizontalInTheRange = false,
        verticalInTheRange = false,
        transform = canvas._currentTransform;

    if (!transform) return;

    // It should be trivial to DRY this up by encapsulating (repeating) creation of x1, x2, y1, and y2 into functions,
    // but we're not doing it here for perf. reasons -- as this a function that's invoked on every mouse move

    for (var i = canvasObjects.length; i--; ) {

      if (canvasObjects[i] === activeObject) continue;

      var objectCenter = canvasObjects[i].getCenterPoint(),
          objectLeft = objectCenter.x,
          objectTop = objectCenter.y,
          objectBoundingRect = canvasObjects[i].getBoundingRect(),
          objectHeight = objectBoundingRect.height / viewportTransform[3],
          objectWidth = objectBoundingRect.width / viewportTransform[0];

      // snap by the horizontal center line
      if (isInRange(objectLeft, activeObjectLeft)) {
        verticalInTheRange = true;
        verticalLines.push({
          x: objectLeft,
          y1: (objectTop < activeObjectTop)
            ? (objectTop - objectHeight / 2 - aligningLineOffset)
            : (objectTop + objectHeight / 2 + aligningLineOffset),
          y2: (activeObjectTop > objectTop)
            ? (activeObjectTop + activeObjectHeight / 2 + aligningLineOffset)
            : (activeObjectTop - activeObjectHeight / 2 - aligningLineOffset)
        });
        activeObject.setPositionByOrigin(new fabric.Point(objectLeft, activeObjectTop), 'center','center');
      }

      // snap by the left edge
      if (isInRange(objectLeft - objectWidth / 2, activeObjectLeft - activeObjectWidth / 2)) {
        verticalInTheRange = true;
        verticalLines.push({
          x: objectLeft - objectWidth / 2,
          y1: (objectTop < activeObjectTop)
            ? (objectTop - objectHeight / 2 - aligningLineOffset)
            : (objectTop + objectHeight / 2 + aligningLineOffset),
          y2: (activeObjectTop > objectTop)
            ? (activeObjectTop + activeObjectHeight / 2 + aligningLineOffset)
            : (activeObjectTop - activeObjectHeight / 2 - aligningLineOffset)
        });
        activeObject.setPositionByOrigin(new fabric.Point(objectLeft - objectWidth / 2 + activeObjectWidth / 2, activeObjectTop), 'center', 'center');
      }

      // snap by the right edge
      if (isInRange(objectLeft + objectWidth / 2, activeObjectLeft + activeObjectWidth / 2)) {
        verticalInTheRange = true;
        verticalLines.push({
          x: objectLeft + objectWidth / 2,
          y1: (objectTop < activeObjectTop)
            ? (objectTop - objectHeight / 2 - aligningLineOffset)
            : (objectTop + objectHeight / 2 + aligningLineOffset),
          y2: (activeObjectTop > objectTop)
            ? (activeObjectTop + activeObjectHeight / 2 + aligningLineOffset)
            : (activeObjectTop - activeObjectHeight / 2 - aligningLineOffset)
        });
        activeObject.setPositionByOrigin(new fabric.Point(objectLeft + objectWidth / 2 - activeObjectWidth / 2, activeObjectTop), 'center', 'center');
      }

      // snap by the vertical center line
      if (isInRange(objectTop, activeObjectTop)) {
        horizontalInTheRange = true;
        horizontalLines.push({
          y: objectTop,
          x1: (objectLeft < activeObjectLeft)
            ? (objectLeft - objectWidth / 2 - aligningLineOffset)
            : (objectLeft + objectWidth / 2 + aligningLineOffset),
          x2: (activeObjectLeft > objectLeft)
            ? (activeObjectLeft + activeObjectWidth / 2 + aligningLineOffset)
            : (activeObjectLeft - activeObjectWidth / 2 - aligningLineOffset)
        });
        activeObject.setPositionByOrigin(new fabric.Point(activeObjectLeft, objectTop), 'center', 'center');
      }

      // snap by the top edge
      if (isInRange(objectTop - objectHeight / 2, activeObjectTop - activeObjectHeight / 2)) {
        horizontalInTheRange = true;
        horizontalLines.push({
          y: objectTop - objectHeight / 2,
          x1: (objectLeft < activeObjectLeft)
            ? (objectLeft - objectWidth / 2 - aligningLineOffset)
            : (objectLeft + objectWidth / 2 + aligningLineOffset),
          x2: (activeObjectLeft > objectLeft)
            ? (activeObjectLeft + activeObjectWidth / 2 + aligningLineOffset)
            : (activeObjectLeft - activeObjectWidth / 2 - aligningLineOffset)
        });
        activeObject.setPositionByOrigin(new fabric.Point(activeObjectLeft, objectTop - objectHeight / 2 + activeObjectHeight / 2), 'center', 'center');
      }

      // snap by the bottom edge
      if (isInRange(objectTop + objectHeight / 2, activeObjectTop + activeObjectHeight / 2)) {
        horizontalInTheRange = true;
        horizontalLines.push({
          y: objectTop + objectHeight / 2,
          x1: (objectLeft < activeObjectLeft)
            ? (objectLeft - objectWidth / 2 - aligningLineOffset)
            : (objectLeft + objectWidth / 2 + aligningLineOffset),
          x2: (activeObjectLeft > objectLeft)
            ? (activeObjectLeft + activeObjectWidth / 2 + aligningLineOffset)
            : (activeObjectLeft - activeObjectWidth / 2 - aligningLineOffset)
        });
        activeObject.setPositionByOrigin(new fabric.Point(activeObjectLeft, objectTop + objectHeight / 2 - activeObjectHeight / 2), 'center', 'center');
      }
    }

    if (!horizontalInTheRange) {
      horizontalLines.length = 0;
    }

    if (!verticalInTheRange) {
      verticalLines.length = 0;
    }
  });

  canvas.on('before:render', function() {
    canvas.clearContext(canvas.contextTop);
  });

  canvas.on('after:render', function() {
    for (var i = verticalLines.length; i--; ) {
      drawVerticalLine(verticalLines[i]);
    }
    for (var i = horizontalLines.length; i--; ) {
      drawHorizontalLine(horizontalLines[i]);
    }

    verticalLines.length = horizontalLines.length = 0;
  });

  canvas.on('mouse:up', function() {
    verticalLines.length = horizontalLines.length = 0;
    canvas.requestRenderAll();
  });  
}

export function animateFunctionOfJquery() {
  $(document).ready(function () {
    var slider_width = $(".pollSlider").height(); //get width automaticly
    console.log('slider',window.innerWidth);
    if(!(window.innerWidth<767)){
      console.log('here');
      $(".pollSlider,#pollSlider-button").animate({
      "margin-bottom": "+=" + slider_width,
      });
    }
    $("#pollSlider-button").click(function () {
      if (
        $(this).css("margin-bottom") == slider_width + "px" &&
        !$(this).is(":animated")
      ) {
        $(".pollSlider,#pollSlider-button").animate({
          "margin-bottom": "-=" + slider_width,
        });
        $("#pollSlider-button").css("background", "#1B5ADE");
        $("#pollSlider-button").toggleClass("active");
      } else {
        if (!$(this).is(":animated")) {
          //perevent double click to double margin
          $(".pollSlider,#pollSlider-button").animate({
            "margin-bottom": "+=" + slider_width,
          });
          $("#pollSlider-button").css("background", "#1B5ADE");
          $("#pollSlider-button").toggleClass("active");
        }
      }
    });
    $(".close-btn").click(function () {
      $(".scroll-fixed").fadeOut(300);
    });
  });
  $(function () {
    /*the left one*/
    $(".drawer-left-trigger").click(function () {
      $(this).parent().toggleClass("opened closed");
    });
  });
}



export function imageRotation(directionOfRotation,rotation,setRotation) {
  const rotateby = 90;
  if (directionOfRotation === "right") {
    setRotation(rotation + rotateby);
  } else {
    setRotation(rotation - rotateby);
  }
}

export function settingAspectRatio(aspectRatio,setAspectRatio) {
  if (aspectRatio === "1/1") {
    setAspectRatio(1 / 1);
  } else if (aspectRatio === "3/4") {
    setAspectRatio(3 / 4);
  } else {
    setAspectRatio(4 / 3);
  }
}


/**
 * Augments canvas by assigning to `onObjectMove` and `onAfterRender`.
 * This kind of sucks because other code using those methods will stop functioning.
 * Need to fix it by replacing callbacks with pub/sub kind of subscription model.
 * (or maybe use existing fabric.util.fire/observe (if it won't be too slow))
 */
// export function initCenteringGuidelines(canvas) {

//   var canvasWidth = canvas.getWidth(),
//       canvasHeight = canvas.getHeight(),
//       canvasWidthCenter = canvasWidth / 2,
//       canvasHeightCenter = canvasHeight / 2,
//       canvasWidthCenterMap = { },
//       canvasHeightCenterMap = { },
//       centerLineMargin = 4,
//       centerLineColor = 'rgba(255,0,241,0.5)',
//       centerLineWidth = 1,
//       ctx = canvas.getSelectionContext(),
//       viewportTransform;

//   for (var i = canvasWidthCenter - centerLineMargin, len = canvasWidthCenter + centerLineMargin; i <= len; i++) {
//     canvasWidthCenterMap[Math.round(i)] = true;
//   }
//   for (var i = canvasHeightCenter - centerLineMargin, len = canvasHeightCenter + centerLineMargin; i <= len; i++) {
//     canvasHeightCenterMap[Math.round(i)] = true;
//   }

//   function showVerticalCenterLine() {
//     showCenterLine(canvasWidthCenter + 0.5, 0, canvasWidthCenter + 0.5, canvasHeight);
//   }

//   function showHorizontalCenterLine() {
//     showCenterLine(0, canvasHeightCenter + 0.5, canvasWidth, canvasHeightCenter + 0.5);
//   }

//   function showCenterLine(x1, y1, x2, y2) {
//     ctx.save();
//     ctx.strokeStyle = centerLineColor;
//     ctx.lineWidth = centerLineWidth;
//     ctx.beginPath();
//     ctx.moveTo(x1 * viewportTransform[0], y1 * viewportTransform[3]);
//     ctx.lineTo(x2 * viewportTransform[0], y2 * viewportTransform[3]);
//     ctx.stroke();
//     ctx.restore();
//   }

//   var afterRenderActions = [],
//       isInVerticalCenter,
//       isInHorizontalCenter;

//   canvas.on('mouse:down', function () {
//     viewportTransform = canvas.viewportTransform;
//   });

//   canvas.on('object:moving', function(e) {
//     var object = e.target,
//         objectCenter = object.getCenterPoint(),
//         transform = canvas._currentTransform;

//     if (!transform) return;

//     isInVerticalCenter = Math.round(objectCenter.x) in canvasWidthCenterMap,
//     isInHorizontalCenter = Math.round(objectCenter.y) in canvasHeightCenterMap;

//     if (isInHorizontalCenter || isInVerticalCenter) {
//       object.setPositionByOrigin(new fabric.Point((isInVerticalCenter ? canvasWidthCenter : objectCenter.x), (isInHorizontalCenter ? canvasHeightCenter : objectCenter.y)), 'center', 'center');
//     }
//   });

//   canvas.on('before:render', function() {
//     canvas.clearContext(canvas.contextTop);
//   });

//   canvas.on('after:render', function() {
//     if (isInVerticalCenter) {
//       showVerticalCenterLine();
//     }
//     if (isInHorizontalCenter) {
//       showHorizontalCenterLine();
//     }
//   });

//   canvas.on('mouse:up', function() {
//     // clear these values, to stop drawing guidelines once mouse is up
//     isInVerticalCenter = isInHorizontalCenter = null;
//     canvas.renderAll();
//   });
// }