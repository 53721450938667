import React from 'react'

function Footer() {
  return (
    <div className="footer-panel">
      <div className="container3">
        <div className="copyright"><span>© Copyright 2022 Flexi Print - Online Printing Company</span></div>
      </div>
    </div>
  )
}

export { Footer }