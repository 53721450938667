import React, { useEffect } from 'react';

function ThreeDViewer(
	{
	id,
	ThreeScene,
	template_3d_model,
	cameraX,
	cameraY,
	cameraZ,
	style,
}
) {
    // const {
	// 	id,
	// 	createThreeScene,
	// 	template_3d_model,
	// 	cameraX,
	// 	cameraY,
	// 	cameraZ,
	// } = props;

	useEffect(() => {
		// console.log('template_3d_model',template_3d_model, cameraX, cameraY , cameraZ);
		ThreeScene.createThreeScene(template_3d_model, cameraX, cameraY , cameraZ, id);
	});

	return (
		<>
			<div id={id} style={style? style :{height: 300, width: 500}}></div>
		</>
	);
}

export {ThreeDViewer};
 