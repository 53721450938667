import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { CustomAlert, Checkbox, SelectableCell, HeadingTitle, Button, HeadPart, Modal, TableBody, PercentageLoader, ModalLoader } from '../Components';
// import Checkbox from '../Components/Checkbox';
import {
  loadSVGFromLink,
  initCanvas,
  selectLayer,
  sendingToBack,
  sendingToFront,
  sendingForward,
  sendingBackward,
  updateLayerPermissions,
  saveDesignableArea,
  saveBackgroundArea,
  saveOutlineArea,
  uploadTemplateLayers,
  deleteObject,
  loadCanvasFromJSONForEditing,
  getBiggestObject,
  addPathObject,
} from '../Actions';
import '../css/LayerListCanvasScreen.css';
import { fabric } from 'fabric';
import {
  disableAllPermissionsOnObject,
  setIsRotatable,
  setIsFlipable,
  setIsScalable,
  setIsMoveable,
  setIsDepthChange,
  setIsDeleteable,
  setIsFontStyleChangeable,
  setIsFontColorChangeable,
  setIsImageReplaceable,
  setIsMaskable,
  setIsBGColorChangeable,
  setIsRoundedMask,
} from '../extras/CanvasPermissions';
import { useLocation } from 'react-router-dom';
import { resetScroll, zooming_in_out } from '../extras/PanandZoom';
import { animateFunctionOfJquery } from '../extras/CanvasObjectControls';
// import { INIT_CANVAS, UPLOAD_PERCENTAGE } from '../Actions/ActionTypes';
// import $ from "jquery";

function LayerListCanvasScreen() {
  const location = useLocation();
  const [pathModal, setPathModal] = useState(false)
  const [charSpacingModal, setCharSpacingModal] = useState(false)
  const [pathText, setPathText] = useState('')
  const [pathStyle, setPathStyle] = useState('')
  const [pathWidth, setPathWidth] = useState('')
  const [pathHeight, setPathHeight] = useState('')
  const [charspacing, setCharspacing] = useState('')
  const [biggestObject, setBiggestObject] = useState([])
  const navigate = useNavigate();
  const reduxState = useSelector(state => state.template);
  const {
    svg_url,
    canvas,
    template_layer_list,
    template_object_list,
    selected_layer,
    selected_layer_index,
    permissions_list,
    die_list,
    layer_permissions,
    designable_area_layer,
    background_area_layer,
    outline_area_layer,
    template_fonts,
    template_id,
    layerUploadLoader,
    template_font_rules,
    upload_percentage,
    toggleLayerLoader,
    font_loader,
  } = reduxState;
  const dispatch = useDispatch();
  const canvasWidth = window.innerWidth * 0.75;
  const canvasHeight = window.innerHeight * 0.7;

  const action_type = (location.state && location.state.action_type) ? location.state.action_type : 'add';

  useEffect(() => {
    // console.log('die_list', die_list);
    try {
      if (!svg_url) return navigate('/')
      // console.log('location.state', location.state);

      if (action_type === 'add') {
        let myPromise = new Promise(function (myResolve, myReject) {
          var sheet = window.document.styleSheets[0];
          for (var i = 0; i < template_font_rules.length; i++) {
            // console.log('Perm list', template_font_rules);
            for (var j = 0; j < template_font_rules[i].length; j++) {
              sheet.insertRule(template_font_rules[i][j], sheet.cssRules.length);
            }
          }
          myResolve(); // when successful
        });

        myPromise.then(
          function (value) {
            // console.log('Wait End', value);
            dispatch(loadSVGFromLink('canvas', svg_url, layer_permissions, template_fonts));
          },
          function (error) { console.log(error); }
        );
      } else if (action_type === 'edit') {
        // console.log('Edit mode, get canvas from json', permissions_list);
        var layer_list = location.state.layer_list;
        var template_layer_permissions = location.state.layer_permissions;
        const canvas_json = location.state.canvas_json;
        const template_fonts = location.state.template_fonts;
        const edit_template_id = location.state.edit_template_id;
        const background_layer_id = location.state.background_layer_id;
        const outline_layer_id = location.state.outline_layer_id;

        // console.log('LAYERS:', background_layer_id, outline_layer_id);

        const layer_list_with_permissions = generateLayerPermissionsForEditTemplate(layer_list, template_layer_permissions);
        layer_list = layer_list_with_permissions[0];
        template_layer_permissions = layer_list_with_permissions[1];

        // console.log('layer_permissions',layer_list, template_layer_permissions);
        dispatch(loadCanvasFromJSONForEditing(edit_template_id, canvas_json, template_fonts, layer_list, template_layer_permissions, background_layer_id, outline_layer_id));
      } else {
        CustomAlert.alert(true, 'Something went wrong, please try again!');
        navigate('/');
      }
      animateFunctionOfJquery()
    } catch (e) {
      console.log('error', e);
      navigate('/')
    }
  }, [dispatch]);


  function generateLayerPermissionsForEditTemplate(layer_list, layer_permissions) {

    var layer_wise_permission = [];
    var template_layer_list = [];

    layer_list.forEach(layer => {
      const fabric_layer_id = layer.fabric_layer_id;

      template_layer_list.push({
        id: fabric_layer_id,
        layer: layer.layer_name,
      });

      const layer_permission_list = (layer_permissions[fabric_layer_id]) ? layer_permissions[fabric_layer_id] : [];

      if (layer_permission_list.length !== 0) {
        var layer_wise_permission_list = [];
        layer_permission_list.forEach(permission => {
          layer_wise_permission_list.push(permission.id);
        });

        layer_wise_permission[fabric_layer_id] = layer_wise_permission_list;
      } else {
        layer_wise_permission[fabric_layer_id] = [];
      }
    });

    return [template_layer_list, layer_wise_permission];
  }

  function layerSelected(layer_index) {
    const selected_object = template_object_list[layer_index];
    // console.log('selected_object',selected_object);
    canvas.discardActiveObject();
    canvas.setActiveObject(selected_object);
    canvas.requestRenderAll();

    dispatch(selectLayer(selected_object, layer_index));
  }

  function renderLayerList() {
    if (template_layer_list.length != 0) {
      return (
        <div className='layerListStyle'>
          <div className='LayerListTableStyle' style={{ height: canvasHeight }}>
            {
              template_layer_list.map((layer, index) => {

                if (index === selected_layer_index) {
                  return <SelectableCell key={index} label={layer.layer} onPress={() => layerSelected(index)} selected={true} />;
                } else {
                  return <SelectableCell key={index} label={layer.layer} onPress={() => layerSelected(index)} selected={false} />;
                }
              })
            }
          </div><br /><br />
        </div>
      );
    } else {
      return null;
    }
  }


  function sendLayerToBack() {
    dispatch(sendingToBack(canvas, selected_layer, layer_permissions));
  }

  function sendLayerToFront() {
    dispatch(sendingToFront(canvas, selected_layer, layer_permissions));
  }

  function sendLayerForward() {
    dispatch(sendingForward(canvas, selected_layer, layer_permissions));
  }

  function sendLayerBackward() {
    dispatch(sendingBackward(canvas, selected_layer, layer_permissions));
  }



  function setBackgroundArea() {
    if (background_area_layer !== null && background_area_layer === template_layer_list[selected_layer_index].id) {
      dispatch(saveBackgroundArea(null));
    } else {
      var proceed = false;
      if (background_area_layer !== null && background_area_layer !== template_layer_list[selected_layer_index].id) {
        proceed = window.confirm("Are you sure you want to make the current layer background and remove the previous one ?");
      } else {
        proceed = true;
      }
      if (proceed) {
        if (selected_layer_index == null) {
          alert('Please select a layer first!');
        } else {
          const background_layer_id = template_layer_list[selected_layer_index].id;
          dispatch(saveBackgroundArea(background_layer_id));
        }
      }
    }
  }

  function renderBackgroundCheckbox() {
    // console.log('BG Layer:',background_area_layer);

    if (background_area_layer === null) {
      return <Checkbox style={{ color: 'red' }} key='background_area' label='Background Area *' onPress={() => setBackgroundArea()} selected={false} />;
    } else {
      try {
        console.log('template_layer_list',template_layer_list, selected_layer_index);
        var current_layer_id;
        try {
          current_layer_id = template_layer_list[selected_layer_index].id;

        } catch (error) {
          current_layer_id = -1;
        }
        // console.log('BG Layer:',background_area_layer, current_layer_id);
        if (background_area_layer === current_layer_id) {
          return <Checkbox style={{ color: 'red' }} key='background_area' label='Background Area *' onPress={() => setBackgroundArea()} selected={true} />;
        } else {
          return <Checkbox style={{ color: 'red' }} key='background_area' label='Background Area *' onPress={() => setBackgroundArea()} selected={false} />;
        }
      } catch (e) {
        console.log('e', e);
      }
    }
  }

  function setOutlineArea() {
    if (outline_area_layer !== null && outline_area_layer === template_layer_list[selected_layer_index].id) {
      dispatch(saveOutlineArea(null))
    } else {
      var proceed = false;
      if (outline_area_layer !== null && outline_area_layer !== template_layer_list[selected_layer_index].id) {
        proceed = window.confirm("Are you sure you want to make the current layer Outline and remove the previous one ?");
        // console.log('prod', proceed);
      } else {
        proceed = true;
      }
      if (proceed) {
        if (selected_layer_index == null) {
          alert('Please select a layer first!');
        } else {
          const outline_layer_id = template_layer_list[selected_layer_index].id;
          dispatch(saveOutlineArea(outline_layer_id));
        }
      }
    }
    // console.log('clicked');
  }

  function renderOutlineCheckbox() {
    if (outline_area_layer === null) {
      return <Checkbox key='outline_area' label='Cutmarks Area' onPress={() => setOutlineArea()} selected={false} />;
    } else {
      var current_layer_id;
      try {
        current_layer_id = template_layer_list[selected_layer_index].id;
      } catch (error) {
        current_layer_id = -1;
      }
      if (outline_area_layer === current_layer_id) {
        return <Checkbox key='outline_area' label='Cutmarks Area' onPress={() => setOutlineArea()} selected={true} />;
      } else {
        console.log('here');
        return <Checkbox key='outline_area' label='Cutmarks Area' onPress={() => setOutlineArea()} selected={false} />;
      }
    }
  }
  

  function renderPermissionList() {

    console.log('layer perms:', layer_permissions, selected_layer_index,);
    // console.log('selected_layer_id',permissions_list);
    return (
      <div className='leftpane'>
        <h2 className='layer-title'>BG / Cutmark Layers</h2>
        {renderBackgroundCheckbox()}
        {renderOutlineCheckbox()}
        <h2 className='layer-title'>Permissions</h2>
        {
          ((selected_layer_index !== null) ? permissions_list.map((permission, index) => {
            try {
              const selected_layer_id = template_layer_list[selected_layer_index].id;

              if (layer_permissions[selected_layer_id].includes(permission.permission_id)) {
                return <Checkbox key={index} label={permission.permission_name} onPress={() => { permissionSelected(permission.permission_id) }} selected={true} />;
              } else {
                return <Checkbox key={index} label={permission.permission_name} onPress={() => { permissionSelected(permission.permission_id) }} selected={false} />;
              }
            } catch (e) {
              console.log('e', e);
            }
          }) : null)
        }
        <h2 className='layer-title'>Die Cuts</h2>
        {
          ((selected_layer_index !== null) ? die_list.map((die, index) => {
            try {
              const selected_layer_id = template_layer_list[selected_layer_index].id;

              if (layer_permissions[selected_layer_id].includes(die.id)) {
                return <Checkbox key={index} label={'DIE - ' + die.die_name} onPress={() => { permissionSelected(die.id) }} selected={true} />;
              } else {
                return <Checkbox key={index} label={'DIE - ' + die.die_name} onPress={() => { permissionSelected(die.id) }} selected={false} />;
              }
            } catch (e) {
              console.log('e', e);
            }
          }) : null)
        }        
      </div>
    )
  }

  function permissionSelected(permission_id) {
    // console.log(layer_permissions, template_layer_list, selected_layer_index, permission_id);
    if (selected_layer_index === null) {
      alert('Please select a layer first!');
    } else {
      dispatch(updateLayerPermissions(layer_permissions, template_layer_list[selected_layer_index].id, permission_id))
    }
  }

  function setPermissionToObject(object, permission_id) {
    var new_object = object;
    switch (permission_id) {
      case "1":
        new_object = setIsRotatable(object);
        return new_object;
        break;

      case "2":
        new_object = setIsDepthChange(object);
        return new_object;
        break;

      case "3":
        new_object = setIsScalable(object);
        return new_object;
        break;

      case "4":
        new_object = setIsMoveable(object);
        return new_object;
        break;

      case "5":
        new_object = setIsFlipable(object);
        return new_object;
        break;

      case "6":
        new_object = setIsDeleteable(object);
        return new_object;
        break;

      case "7":
        new_object = setIsImageReplaceable(object);
        return new_object;
        break;

      case "8":
        new_object = setIsFontStyleChangeable(object);
        return new_object;
        break;

      case "9":
        new_object = setIsFontColorChangeable(object);
        return new_object;
        break;

      case "10":
        object = setIsBGColorChangeable(object);
        return object;
        break;
      case "11":
        object = setIsMaskable(object);
        return object;
        break;
      case "12":
        object = setIsRoundedMask(object);
        return object;
        break;
      default:
        return object;
        break;
    }
  }

  function uploadLayers() {
    // console.log('background_area_layer', background_area_layer);
    if (background_area_layer === null) {
      alert('Please select one layer as a background layer')
    } else {
      var template = {};
      // template['template_id'] = template_id ? template_id : location.state.template_id ;
      template['template_id'] = template_id;
      template['layer_list'] = template_layer_list;
      template['layer_permissions'] = layer_permissions;
      template['designable_layer_id'] = designable_area_layer;
      template['background_layer_id'] = background_area_layer;
      template['outline_layer_id'] = outline_area_layer;

      console.log('layer_permissions', layer_permissions);
      // Updating permissions on canvas objects
      var canvasObjects = canvas.getObjects();
      if (canvasObjects) {
        for (var i = 0; i < canvasObjects.length; i++) {
          var current_object = canvasObjects[i];
          // const layer_name = current_object.get('id');
          // current_object = disableAllPermissionsOnObject(current_object);
          const object_id = Object.keys(template_object_list).find(key => template_object_list[key] === current_object);
          const layer_id = template_layer_list[object_id].id;

          current_object.set('layer_id', layer_id);
          if (designable_area_layer === layer_id) {
            current_object.set('selectable', false);
            current_object.set('designable_area', true);
          }

          if (background_area_layer === layer_id) {
            current_object.set('selectable', false);
            current_object.set('background_area', true);
          }

          if (outline_area_layer === layer_id) {
            current_object.set('selectable', false);
            current_object.set('outline_area', true);
          }

          const layer_permission_list = layer_permissions[layer_id];

          current_object = disableAllPermissionsOnObject(current_object);
          // canvas.requestRenderAll();
          for (var j = 0; j < layer_permission_list.length; j++) {
            const permission_id = layer_permission_list[j];
            current_object = setPermissionToObject(current_object, permission_id);

            // canvas._objects[i] = current_object;
          }

          canvas.requestRenderAll();
        }

        template['template_canvas'] =
          canvas.toJSON([
            'id',
            'lockMovementX',
            'lockMovementY',
            'hasControls',
            'isDepthChange',
            'isDeleteable',
            'lockScalingFlip',
            'isRotatable',
            'layer_id',
            'selectable',
            'designable_area',
            'background_area',
            'outline_area',
            'type',
            'isImageReplaceable',
            'isFontStyleChangeable',
            'isFontColorChangeable',
            'isBGColorChangable',
            "isMaskable",
            "isRoundedMask",
          ]);
        // template['template_canvas'] = canvas.toObject(['id', 'lockMovementX', 'lockMovementY', 'hasControls', 'isDepthChange', 'isDeleteable']);

        dispatch(uploadTemplateLayers(template, navigate, action_type));

      }
    }
  }

  function deleteCanvasObject() {
    if (canvas.getActiveObject()) {
      var proceed = false;
      proceed = window.confirm("Are you sure you want to delete the object ?");
      if (proceed) {
        // console.log('cna',canvas.getActiveObject().get('id'),layer_permissions);
        dispatch(deleteObject(canvas, layer_permissions))
      }
    } else {
      alert('Select a layer first');
    }
  }

  // function changeBackground() {
  //   const object = canvas.getActiveObject();
  //   object.set('backgroundColor', '#000');
  //   canvas.requestRenderAll();
  // }

  function maskingObjectModal(open) {
    if (!pathModal) {
      var objects = canvas.getObjects()
      var biggestObject = getBiggestObject(objects)
      console.log('canva', biggestObject.width * 0.2645833333);
      setBiggestObject(biggestObject)
      setPathModal(open)
      document.body.classList.add('modal-open')
      document.body.style.paddingRight = '17px'
    } else {
      setPathModal(open)
      settingDefault()
      document.body.classList.remove('modal-open')
      document.body.style.paddingRight = '0'
    }
  }

  function handleCharSpacingSubmit(e) {
    e.preventDefault()
    const spacing = fabric.util.parseUnit(parseFloat(charspacing) + 'mm')
    // selected_layer.charSpacing = spacing
    selected_layer.set('charSpacing', spacing)
    selected_layer.setCoords()
    canvas.requestRenderAll()
    // dispatch({
    //   type: INIT_CANVAS,
    //   payload: canvas
    // });
    characterSpacingModal(false)
  }

  function characterSpacingModal(open) {
    if (!charSpacingModal) {
      setCharSpacingModal(open)
      document.body.classList.add('modal-open')
      document.body.style.paddingRight = '17px'
    } else {
      settingDefault()
      setCharSpacingModal(open)
      document.body.classList.remove('modal-open')
      document.body.style.paddingRight = '0'
    }
  }

  function settingDefault() {
    setPathHeight('')
    setPathStyle('')
    setPathText('')
    setPathWidth('')
    setCharspacing('')
  }

  function handleMaskingsubmit(e) {
    e.preventDefault()
    maskingObjectModal(false)
    if (parseFloat(pathWidth) > biggestObject.width || biggestObject.height < parseFloat(pathHeight)) {
      CustomAlert.alert(false, 'Path value must less than biggest object height and width')
    } else {
      if (!pathText) {
        CustomAlert.alert(true, 'Object of path must be there')
      } else {
        const widthOfPath = parseFloat(pathWidth) ? fabric.util.parseUnit(pathWidth + 'mm') : biggestObject.width
        const heightOfPath = parseFloat(pathHeight) ? fabric.util.parseUnit(pathHeight + 'mm') : biggestObject.height

        dispatch(addPathObject(canvas, layer_permissions, template_layer_list, widthOfPath, heightOfPath, biggestObject, pathText, pathStyle));
      }
    }
  }

  function renderSliderOptions() {
    return (
      <>
        <div className="editor-options-clm1 editor-clm-1">
          <div className="editor-options-img">
            <div className="add-clm1" onClick={() => deleteCanvasObject()}>
              <span>
                <img src={"images/icons/delete-object.svg"} />
              </span>
              <span>Delete</span>
            </div>
            <div className="add-clm1" onClick={() => maskingObjectModal(true)}>
              <span>
                <img src={"images/icons/add-path-object.svg"} />
              </span>
              <span>Add path Object</span>
            </div>
            {/* {selected_layer && (selected_layer.get('type')=='text' || selected_layer.get('type') == 'i-text') && ( */}
            <div
              className="add-clm1"
              onClick={() => {
                if (selected_layer.get('type') === 'text' || selected_layer.get('type') === 'i-text') {
                  characterSpacingModal(true)
                  setCharspacing((selected_layer.charSpacing * 0.2645833333).toFixed(2))
                } else {
                  CustomAlert.alert(true, 'Selected Layer is not Text')
                }
              }}
            >
              <span>
                <img src={"images/img-shapes.svg"} />
              </span>
              <span>Character Spacing</span>
            </div>
            {/* // )} */}
          </div>
          <div className="editor-options-txt">Manage Object</div>
        </div>
        <div className="editor-options-clm1 editor-clm-2">
          <div className="editor-options-img">
            <div className="add-clm1" onClick={() => sendLayerToBack()}>
              <span>
                <img src={"images/icons/send-back.svg"} alt='backward' />
              </span>
              <span>Send Back</span>
            </div>
            <div className="add-clm1" onClick={() => sendLayerToFront()}>
              <span>
                <img src={"images/icons/bring-front.svg"} />
              </span>
              <span>Send Front</span>
            </div>
            <div
              className="add-clm1"
              onClick={() => sendLayerBackward()}
            >
              <span>
                <img src={"images/icons/send-backward.svg"} />
              </span>
              <span>Send Backward</span>
            </div>
            <div
              className="add-clm1"
              onClick={() => sendLayerForward()}
            >
              <span>
                <img src={"images/icons/bring-forward.svg"} />
              </span>
              <span>Send Forward</span>
            </div>
          </div>
          <div className="editor-options-txt">Arrange Object</div>
        </div>
        <div className="editor-options-clm1 editor-clm-3">
          <div className="editor-options-img">
            <div className="add-clm1" onClick={() => resetScroll(canvas, dispatch)}>
              <span>
                <img src='images/icons/reset-scroll.svg' />
              </span>
              <span>Reset Scroll</span>
            </div>
            <div className="add-clm1" onClick={() => zooming_in_out("in", canvas, dispatch)}>
              <span>
                <img src={"images/zoom-in-img1.svg"} />
              </span>
              <span>Zoom in</span>
            </div>
            <div className="add-clm1" onClick={() => zooming_in_out("out", canvas, dispatch)}>
              <span>
                <img src={"images/zoom-out-img1.svg"} />
              </span>
              <span>Zoom out</span>
            </div>
          </div>
          <div className="editor-options-txt">View</div>
        </div>
        <div className="editor-options-clm1 editor-clm-1 editor-save-btn">
          <div className="editor-options-img">
            <div className="add-clm1" onClick={() => uploadLayers()}>
              <span>
                <img src={"images/icons/save.svg"} />
              </span>
              <span>Save</span>
            </div>
          </div>
          <div className="editor-options-txt">Save</div>
        </div>
      </>
    )
  }

  // console.log('client_selected_layer',selected_layer.get('type')=='text')
  function renderLoader() {
    if (layerUploadLoader) {
      return (
        <>
          <ModalLoader openModal={layerUploadLoader} loadingTitle={"Uploading Layer"} >
            <PercentageLoader percentage={upload_percentage} />
          </ModalLoader>
          {/* <Dots /> */}
        </>
      );
    } else {
      return (
        <>
          <HeadPart notWantFooter={true}>
            <HeadingTitle title={'Template Editor'} />
            {/* {layerUploadLoader} */}
            {toggleLayerLoader && (
              <ModalLoader openModal={toggleLayerLoader} loadingTitle={'Layer Loading'} />
            )}
            {pathModal && (
              <Modal headTitle={'Adding Path'}
                classGiven={`modal fade font-popup ${pathModal ? 'show' : ''}`}
                style={pathModal ? { display: 'block', paddingRight: '17px' } : { display: 'none' }}
                onPress={() => maskingObjectModal(false)}
              >
                <form>
                  <div id="table2" className="table-outer table-cards manage-shipper manage-editor-wrap">
                    <table width="" cellSpacing={1}>
                      <thead>
                        <tr>
                          <th width="">Adding path</th>
                          <th width="" data-title="Action"></th>
                        </tr>
                      </thead>

                      <tbody>
                        <TableBody tableName={'Path Object'}>
                          <input type={'text'} className="form-control template-upload" value={pathText} onChange={(e) => setPathText(e.target.value)} />
                        </TableBody>
                        <TableBody tableName={'Path fill Color'}>
                          <input type={'text'} className="form-control template-upload" value={pathStyle} onChange={(e) => setPathStyle(e.target.value)} />
                        </TableBody>
                        <TableBody tableName={'Path Width'}>
                          <p>Biggest object width is: {biggestObject.width && (biggestObject.width * 0.2645833333).toFixed(2) + 'mm'}</p>
                          <input type={'number'} className="form-control template-upload" value={pathWidth} onChange={(e) => setPathWidth(e.target.value)} />
                        </TableBody>
                        <TableBody tableName={'Path Height'}>
                          <p>Biggest object height is: {biggestObject.height && (biggestObject.height * 0.2645833333).toFixed(2) + 'mm'}</p>
                          <input type={'number'} className="form-control template-upload" value={pathHeight} onChange={(e) => setPathHeight(e.target.value)} />
                        </TableBody>

                      </tbody>
                    </table>
                  </div>
                  <Button onPress={handleMaskingsubmit} classgiven={"btn-basic"} name={'Add Path'} />
                  {/* <Button onPress={handleMaskingsubmit} classgiven="btn-basic" name={'Add Character Spacing'} /> */}
                </form>
              </Modal>
            )}

            {charSpacingModal && (
              <Modal modalBodyClass={'noHeight-overflow'} headTitle={'Adding Character Spacing'}
                classGiven={`modal fade font-popup ${charSpacingModal ? 'show' : ''}`}
                style={charSpacingModal ? { display: 'block', paddingRight: '17px' } : { display: 'none' }}
                onPress={() => characterSpacingModal(false)}
              >
                <form>
                  <div id="table2" className="table-outer table-cards manage-shipper manage-editor-wrap">
                    <table width="" cellSpacing={1}>
                      <thead>
                        <tr>
                          <th width="">Adding Character Spacing</th>
                          <th width="" data-title="Action"></th>
                        </tr>
                      </thead>
                      <TableBody tableName={'Charater spacing'}>
                        <p>Character spacing must be in 'mm'</p>
                        <input type={'number'} className="form-control template-upload" value={charspacing} onChange={(e) => setCharspacing(e.target.value)} />
                      </TableBody>
                      <tbody>
                      </tbody>
                    </table>
                  </div>
                  <Button onPress={handleCharSpacingSubmit} classgiven="btn-basic" name={'Add Character Spacing'} />
                </form>
              </Modal>
            )}
            {/* <div className="inside-bg-wrap mrb-15">
              <div className="editor-top-btns">
                <ul className="editor-btns-list">
                  <li><Button classgiven={"delete2"} onPress={() => deleteCanvasObject()} name={"Delete the Object"} /></li>
                  <li><Button classgiven={"sendback"} onPress={() => sendLayerToBack()} name={"Send Back"} /></li>
                  <li><Button classgiven={"sendbackwards"} onPress={() => sendLayerBackward()} name={"Send Backwards"} /></li>
                  <li><Button classgiven={"bringfront"} onPress={() => sendLayerToFront()} name={"Bring Front"} /></li>
                  <li><Button classgiven={"bringforward"} onPress={() => sendLayerForward()} name={"Bring Forward"} /></li>
                  <li><Button classgiven={"addPathImage btn-basic btn-basic2"} onPress={() => maskingObjectModal(true)} name={"Add Path object"} /></li>
                  {selected_layer && (selected_layer.get('type') == 'text' || selected_layer.get('type') == 'i-text') && (
                    <li><Button onPress={(e) => {
                      characterSpacingModal(true)
                      setCharspacing((selected_layer.charSpacing * 0.2645833333).toFixed(2))
                    }} classgiven="btn-basic" name={'Add Character Spacing'} /></li>
                  )}
                  <li><Button classgiven={"saveresetscroll"} onPress={() => resetScroll(canvas, dispatch)} name={"Save Reset Scroll"} /></li>
                  <li><Button classgiven={"saveresetscroll"} onPress={() => uploadLayers()} name={"Save"} /></li>
                </ul>
              </div>
            </div> */}
            <div className="template-editor-wrap">
              <div className="editor-canvas-l">
                <div className="inside-bg-wrap">
                  <div className="box2 box3 full-box d-flex flex-wrap">
                    {renderPermissionList()}
                  </div>
                </div>
              </div>

              <div className="editor-canvas-m">
                <div className="inside-bg-wrap">
                  <canvas id='canvas' width={750} height={700} className='canvasStyle' />
                </div>
              </div>
              <div className="editor-canvas-r">
                <div className="inside-bg-wrap">
                  <div className="canvas-layer-wrap">
                    {renderLayerList()}
                  </div>
                </div>

              </div>
              <div className="design-container">
                <div className="pollSlider">
                  <div className="editor-options-wrap admin-editor">{renderSliderOptions()}</div>
                  <div id="pollSlider-button"></div>
                </div>
              </div>

            </div>
          </HeadPart>
        </>


      );
    }
  }

  return renderLoader();
}

export { LayerListCanvasScreen };
