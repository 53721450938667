import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  Button,
  Checkbox,
  CustomAlert,
  DeleteConfirmation,
  HeadingTitle,
  HeadPart,
  LayerModal,
  Modal,
  ModalLoader,
  PercentageLoader,
  RenderRowsAndColumn,
  TableBody,
  UploadTemplateForm,
} from "../Components";
import {
  deleteTemplate,
  onChangeProductTagSearch,
  updatingTemplateForm,
} from "../Actions";
import "../css/TemplatesScreen.css";

function TemplateSidesScreen() {
  const navigate = useNavigate();
  const [templateSides, setTemplateSides] = useState([]);
  const [showTemplate, setShowTemplate] = useState(false);
  const [showLayerModal, setShowLayerModal] = useState(false);
  const [template_desc, setTemplateDesc] = useState("");
  const [template_height, setTemplateHeight] = useState("");
  const [template_thumnail, setTemplateThumbnail] = useState(null);
  const [template_length, setTemplateLength] = useState('');
  const [max_template_height, setMaxTemplateHeight] = useState('');
  const [showSingleSide,setShowSingleSide] = useState(null);
  const [max_template_width, setMaxTemplateWidth] = useState('');
  const [max_template_length, setMaxTemplateLength] = useState('');
  const [radioOptions, setRadioOptions] = useState('');
  const [rounded_template_thumbnail, setRoundedTemplateThumbnail] =
    useState(null);
  const [changeImg, setChangeImg] = useState(false);
  const [roundedImgChange, setRoundedImgChange] = useState(false);
  const [template_width, setTemplateWidth] = useState("");
  const [searching, setSearching] = useState(false);
  const [editTemplateInfoModel, setEditTemplateInfoModel] = useState(false);
  const [template_industry_tags, setTemplateIndustryTags] = useState([]);
  const [template_theme_tags, setTemplateThemeTags] = useState([]);
  const [template_product_tags, setTemplateProductTags] = useState([]);
  const [industry_tag, setIndustryTags] = useState([]);
  const [theme_tag, setThemeTags] = useState([]);
  const [product_tag, setProductTags] = useState([]);
  const [layers, setLayers] = useState([]);
  const [mainTemplateId, setMainTemplateId] = useState("");

  const location = useLocation();
  const reduxState = useSelector((state) => state.template);
  const {
    template_sides,
    upload_percentage,
    industry_tags,
    theme_tags,
    filtered_product_tags,
    update_info_loader,
    product_tags_search_text,
    model_3d_list,
  } = reduxState;
  const original_products = reduxState.product_tags;
  const dispatch = useDispatch();

  const [template_3d_model, setTemplate3DModel] = useState(0);

  useEffect(() => {
    try {
      setMainTemplateId(location.state.main_template_id);
      setTemplateSides(location.state.all_template_sides);
      setIndustryTags(industry_tags);
      setThemeTags(theme_tags);
      setProductTags(filtered_product_tags);

      if (!searching) {
        console.log('template',location.state.template.show_on_single_side);

        setShowSingleSide(location.state.template.show_on_single_side ? location.state.template.show_on_single_side : '');

        setMaxTemplateHeight(location.state.template.max_height ? location.state.template.max_height : 0)

        setMaxTemplateWidth(location.state.template.max_width ? location.state.template.max_width : 0)

        setMaxTemplateLength(location.state.template.max_length ? location.state.template.max_length : 0)

        setTemplateDesc(location.state.template.description);

        setTemplateLength(location.state.template.length ? location.state.template.length : 0);

        setRoundedTemplateThumbnail(location.state.template.round_thumb_image);

        setRadioOptions(location.state.template.orientation);

        setTemplateHeight(
          location.state.template.height ? location.state.template.height : 0
        );

        setTemplateWidth(
          location.state.template.width ? location.state.template.width : 0
        );

        setTemplateThumbnail(location.state.template.thumb_image);

        setTemplateThemeTags(
          location.state.template.theme_tags.map((item) => item.id)
        );

        setTemplateIndustryTags(
          location.state.template.industry_tags.map((item) => item.id)
        );

        setTemplateProductTags(
          location.state.template.product_tags.map((item) => item.id)
        );

        setTemplate3DModel(location.state.template['3d_model']['3d_model_id'])
      }
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    } catch (e) {
      navigate("/");
      console.log("e", e);
    }
  }, [dispatch, filtered_product_tags]);

  const onConfirm = (yesOrNo, side_name) => {
    // console.log('yes', yesOrNo, templateSides[side_name].id)
    if (yesOrNo) {
      dispatch(
        deleteTemplate(templateSides, side_name, mainTemplateId, navigate)
      );
    }
  };

  const modalOpenCloseTemplate = (open) => {
    if (!showTemplate) {
      setShowTemplate(open);
      document.body.classList.add("modal-open");
      document.body.style.paddingRight = "17px";
    } else {
      setShowTemplate(open);
      document.body.classList.remove("modal-open");
      document.body.style.paddingRight = "0px";
    }
  };

  function renderInlineBlock(property_name, property_type, callbackFunction) {
    if (property_type === "img") {
      return (
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <img
            src={property_name ? property_name : "images/corporate-orders.jpg"}
            alt="Thumbnail image"
            width={100}
            height={100}
          />
          <Button
            name={`change ${property_type}`}
            onPress={callbackFunction}
            classgiven={"btn btn-link"}
          />
        </div>
      );
    } else {
      return (
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          {property_name}
          <Button
            name={`change ${property_type}`}
            onPress={callbackFunction}
            classgiven={"btn btn-link"}
          />
        </div>
      );
    }
  }

  function industryTagSelected(id) {
    if (template_industry_tags.includes(id)) {
      var new_template_tags = template_industry_tags;
      const index = template_industry_tags.indexOf(id);
      if (index > -1) {
        new_template_tags.splice(index, 1);
        setTemplateIndustryTags([...new_template_tags]);
      }
    } else {
      setTemplateIndustryTags([...template_industry_tags, id]);
    }
  }

  const renderIndustryTags = () => {
    return (
      <RenderRowsAndColumn dataTitle={"Industry Tags"}>
        {industry_tag.map((industry, index) => {
          const industry_id = industry.id;
          if (!template_industry_tags.includes(industry_id)) {
            return (
              <Checkbox
                key={index}
                label={industry.industry}
                selected={false}
                onPress={() => industryTagSelected(industry_id)}
              />
            );
          } else {
            return (
              <Checkbox
                key={index}
                label={industry.industry}
                selected={true}
                onPress={() => industryTagSelected(industry_id)}
              />
            );
          }
        })}
      </RenderRowsAndColumn>
    );
  };

  function themeTagSelected(id) {
    if (template_theme_tags.includes(id)) {
      var new_template_tags = template_theme_tags;
      const index = template_theme_tags.indexOf(id);
      if (index > -1) {
        new_template_tags.splice(index, 1);
        setTemplateThemeTags([...new_template_tags]);
      }
    } else {
      setTemplateThemeTags([...template_theme_tags, id]);
    }
  }

  function renderThemeTags() {
    return (
      <RenderRowsAndColumn dataTitle={"Theme Tags"}>
        {theme_tag.map((theme, index) => {
          const theme_id = theme.id;
          if (!template_theme_tags.includes(theme_id)) {
            return (
              <Checkbox
                key={index}
                label={theme.theme}
                selected={false}
                onPress={() => themeTagSelected(theme_id)}
              />
            );
          } else {
            return (
              <Checkbox
                key={index}
                label={theme.theme}
                selected={true}
                onPress={() => themeTagSelected(theme_id)}
              />
            );
          }
        })}
      </RenderRowsAndColumn>
    );
  }

  function productTagSelected(id) {
    if (template_product_tags.includes(id)) {
      var new_template_tags = template_product_tags;
      const index = template_product_tags.indexOf(id);
      if (index > -1) {
        new_template_tags.splice(index, 1);
        setTemplateProductTags([...new_template_tags]);
      }
    } else {
      setTemplateProductTags([...template_product_tags, id]);
    }
  }

  const renderProductTags = () => {
    // console.log('filerte',filtered_product_tags);
    return (
      <RenderRowsAndColumn tableClassname={'product-tags'} dataTitle={"Products Tags"}>
        <input
          style={{ marginBottom: "0.5rem" }}
          type="text"
          className="form-control template-upload"
          value={product_tags_search_text}
          onChange={(e) => {
            dispatch(
              onChangeProductTagSearch(e.target.value, original_products)
            );
            setSearching(true);
          }}
        />
        {product_tag.length !== 0 ? (
          product_tag.map((product, index) => {
            const product_id = product.id;
            if (!template_product_tags.includes(product_id)) {
              return (
                <Checkbox
                  key={index}
                  label={product.product}
                  selected={false}
                  onPress={() => productTagSelected(product_id)}
                />
              );
            } else {
              return (
                <Checkbox
                  key={index}
                  label={product.product}
                  selected={true}
                  onPress={() => productTagSelected(product_id)}
                />
              );
            }
          })
        ) : (
          <h6>No Product Tags Found!</h6>
        )}
      </RenderRowsAndColumn>
    );
  };

  function onScrollBlur(event){
    event.target.blur();
  }

  function renderDescAndDimensions() {
    // console.log('radio',radioOptions);
    return (
      <>
        <TableBody tableName={"Also Show Single Side"}>
          <label className="potrait check"><input type={'radio'} checked={showSingleSide == '1'} value={"1"} onChange={(e)=>setShowSingleSide(1)} />Yes</label>
          <label className="landscape check"><input type={'radio'} checked={showSingleSide =='0'} value={"0"} onChange={(e)=>setShowSingleSide(0)} />No</label>
        </TableBody>

        <TableBody tableName={"Template Description"}>
          <textarea
            value={template_desc}
            onChange={(e) => setTemplateDesc(e.target.value)}
            className="form-control"
          />
        </TableBody>

        <RenderRowsAndColumn dataTitle={"Template Width"}>
          <div className='form-group col-md-5'>
            <input type={'number'} onWheel={onScrollBlur} placeholder="min" value={template_width} onChange={e => setTemplateWidth(e.target.value)} className='form-control ' />
          </div>
          <div className='form-group col-md-2'>
            <span>To</span>
          </div>
          <div className='form-group col-md-5'>
            <input type={'number'} placeholder="max"  onWheel={onScrollBlur} value={max_template_width} onChange={e => setMaxTemplateWidth(e.target.value)} className='form-control' />
          </div>
        </RenderRowsAndColumn>

        <RenderRowsAndColumn dataTitle={"Template Height"}>
          <div className='form-group col-md-5'>
            <input type={'number'} placeholder='min'  onWheel={onScrollBlur} value={template_height} onChange={e => setTemplateHeight(e.target.value)} className='form-control' />
          </div>
          <div className='form-group col-md-2'>
            <span>To</span>
          </div>
          <div className='form-group col-md-5'>
            <input type={'number'} placeholder='max'  onWheel={onScrollBlur} value={max_template_height} onChange={e => setMaxTemplateHeight(e.target.value)} className='form-control' />
          </div>
        </RenderRowsAndColumn>
        <RenderRowsAndColumn dataTitle={'Template Length'} >
          <div className='form-group col-md-5'>
            <input type={'number'} placeholder='min'  onWheel={onScrollBlur} value={template_length} onChange={e => setTemplateLength(e.target.value)} className='form-control' />
          </div>
          <div className='form-group col-md-2'>
            <span>To</span>
          </div>
          <div className='form-group col-md-5'>
            <input type={'number'} placeholder='max'  onWheel={onScrollBlur} value={max_template_length} onChange={e => setMaxTemplateLength(e.target.value)} className='form-control' />
          </div>
        </RenderRowsAndColumn>

        <TableBody tableName={'Template Orientation'} >
          <label className="potrait check"><input type={'radio'} checked={radioOptions === 'potrait'} value={'potrait'} onChange={(e) => setRadioOptions(e.target.value)} />Potrait</label>
          <label className="landscape check"><input type={'radio'} checked={radioOptions === 'landscape'} value={"landscape"} onChange={(e) => setRadioOptions(e.target.value)} />Landscape</label>
        </TableBody>
      </>
    );
  }

  function handleThumbnailFileSelect(event, normal_or_rounded) {
    if (normal_or_rounded === "rounded") {
      setRoundedTemplateThumbnail(event.target.files[0]);
    } else {
      setTemplateThumbnail(event.target.files[0]);
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    const formData = new FormData();
    if (template_industry_tags.length > 0) {
      const industry = template_industry_tags.join(",");
      formData.append("industry_tags", industry);
    }

    if (template_product_tags.length > 0) {
      const product = template_product_tags.join(",");
      formData.append("product_tags", product);
    }

    if (template_theme_tags.length > 0) {
      const theme = template_theme_tags.join(",");
      formData.append("theme_tags", theme);
    }
    // console.log('teplate',mainTemplateId);
    formData.append("main_template_id", mainTemplateId);
    formData.append("template_description", template_desc);
    formData.append("thumb_image", template_thumnail);
    formData.append("round_thumb_image", rounded_template_thumbnail);
    
    formData.append("show_on_single_side", showSingleSide);
    formData.append("height", template_height);
    formData.append("width", template_width);
    formData.append('templateLength', template_length);
    formData.append('max_width',max_template_width);
    formData.append('max_height',max_template_height);
    formData.append('max_length',max_template_length);
    formData.append('templateOrientation', radioOptions);
    formData.append("template_3d_model", template_3d_model);
    // console.log(template_industry_tags)
    // dispatch(updatingTemplateForm(mainTemplateId, template_desc, template_thumnail, template_height, template_width, template_industry_tags, template_theme_tags, template_product_tags, navigate))
    dispatch(updatingTemplateForm(formData, navigate));
    settingDefault();
    editModal(false);
  }

  function settingDefault() {
    setMainTemplateId("");
    setTemplateThumbnail(null);
    setRoundedTemplateThumbnail(null);
    setTemplateHeight("");
    setTemplateDesc("");
    setTemplateWidth("");
    setRadioOptions('');
    setTemplateLength('');
    setChangeImg(false);
    setRoundedImgChange(false);
    setTemplateIndustryTags([]);
    setTemplateThemeTags([]);
    setTemplateProductTags([]);
  }

  function render3DModelList() {
    const template_3d_mapping = location.state.template_3d_mapping;
    if (model_3d_list) {
      return (
        <RenderRowsAndColumn dataTitle={"Select 3D Model"}>
          <select className="form-control" onChange={(event) => {
            // console.log('event',event.target.value);
            setTemplate3DModel(event.target.value);
          }} value={template_3d_model}>
            <option key={0} className="form-control" value={0}>Select 3d Model</option>
            {model_3d_list.map((model, index) => {
              return (
                <option key={index} value={model.id}>
                  {model['3d_model_name']}
                </option>
              );

            })}
          </select>
        </RenderRowsAndColumn>
      );
    }
  }

  function editTemplateInfo() {
    return (
      <>
        <form>
          <div
            id="table2"
            className="table-outer table-cards manage-shipper manage-editor-wrap"
          >
            <table width="" cellSpacing={1} className="upload-template-table">
              <thead>
                <tr>
                  <th width="">Side Title</th>
                  <th width="" data-title="Action"></th>
                </tr>
              </thead>

              <tbody>
                <>
                  {renderDescAndDimensions()}
                  <TableBody tableName={"Template Thumbnail Image"}>
                    {!changeImg ? (
                      renderInlineBlock(template_thumnail, "img", (e) => {
                        e.preventDefault();
                        setChangeImg(true);
                      })
                    ) : (
                      <input
                        type={"file"}
                        onChange={(e) => handleThumbnailFileSelect(e, "normal")}
                        accept="image/png, image/gif, image/jpeg, image/jpg"
                        className="form-control"
                      />
                    )}
                  </TableBody>

                  <TableBody tableName={"Rounded Template Thumbnail Image"}>
                    {!roundedImgChange ? (
                      renderInlineBlock(
                        rounded_template_thumbnail,
                        "img",
                        (e) => {
                          e.preventDefault();
                          setRoundedImgChange(true);
                        }
                      )
                    ) : (
                      <input
                        type={"file"}
                        onChange={(e) =>
                          handleThumbnailFileSelect(e, "rounded")
                        }
                        accept="image/png, image/gif, image/jpeg, image/jpg"
                        className="form-control"
                      />
                    )}
                  </TableBody>

                  {renderIndustryTags()}
                  {renderThemeTags()}
                  {renderProductTags()}
                  {render3DModelList()}
                </>
              </tbody>
            </table>
          </div>
        </form>
        <div className="form-group mtp1">
          {!upload_percentage ? (
            <Button
              onPress={handleSubmit}
              classgiven="btn-basic"
              name={"Save Updates"}
            />
          ) : (
            <PercentageLoader percentage={upload_percentage} />
          )}
        </div>
      </>
    );
  }

  const layerModal = (open, side_layer) => {
    if (!showLayerModal) {
      setShowLayerModal(open);
      // console.log('side',templateSides[side_layer]);
      setLayers(templateSides[side_layer].template_layers);
      document.body.classList.add("modal-open");
      document.body.style.paddingRight = "17px";
    } else {
      setShowLayerModal(open);
      setLayers([]);
      document.body.classList.remove("modal-open");
      document.body.style.paddingRight = "0px";
    }
  };

  const editModal = (open_modal) => {
    if (!editTemplateInfoModel) {
      setEditTemplateInfoModel(open_modal);
      document.body.classList.add("modal-open");
      document.body.style.paddingRight = "17px";
    } else {
      setEditTemplateInfoModel(open_modal);
      document.body.classList.remove("modal-open");
      document.body.style.paddingRight = "0px";
    }
  };
  function createTemplateSidesTable() {
    return (
      <>
        <HeadingTitle title={"Template Sides"} />
        <Button onPress={() => modalOpenCloseTemplate(true)} name="Add Side" />
        <Button
          name={"Edit template info"}
          onPress={() => editModal(true)}
          classgiven="link btn-basic new btn-basic2 m-2"
        />
        <Modal
          classGiven={`modal fade font-popup ${showTemplate ? "show" : ""}`}
          onPress={() => modalOpenCloseTemplate(false)}
          style={
            showTemplate
              ? { display: "block", paddingRight: "17px" }
              : { display: "none" }
          }
          headTitle={"Template Upload"}
        >
          <UploadTemplateForm
            template_id={mainTemplateId}
            template_side={template_sides}
            modal={modalOpenCloseTemplate}
          />
        </Modal>

        <Modal
          classGiven={`modal fade font-popup ${editTemplateInfoModel ? "show" : ""
            }`}
          onPress={() => editModal(false)}
          style={
            editTemplateInfoModel
              ? { display: "block", paddingRight: "17px" }
              : { display: "none" }
          }
          headTitle={"Edit Template Info"}
        >
          {editTemplateInfo()}
        </Modal>
        <div
          id="table2"
          className="table-outer table-cards manage-shipper manage-editor-wrap"
        >
          {upload_percentage && (
            <PercentageLoader percentage={upload_percentage} />
          )}
          <Modal
            classGiven={`modal fade font-popup ${showLayerModal ? "show" : ""}`}
            style={
              showLayerModal
                ? { display: "block", paddingRight: "17px" }
                : { display: "none" }
            }
            onPress={() => layerModal(false)}
            headTitle="Layers"
          >
            <LayerModal layers={layers} />
          </Modal>
          <table width="" cellSpacing="1">
            <thead>
              <tr>
                <th width="">Sides</th>
                <th width="" data-title="Action">
                  Action
                </th>
              </tr>
            </thead>

            <tbody>
              {Object.keys(templateSides).map((side, index) => {
                const side_name = side;
                const template_details = templateSides[side_name];
                // console.log('side',templateSides[side_name].id);
                const canvas_json = template_details.canvas_json;
                const layer_list = template_details.template_layers.layers;
                const layer_permissions =
                  template_details.template_layers.layer_permissions;
                const template_fonts = template_details.fonts;
                const edit_template_id = template_details.id;
                return (
                  <TableBody
                    key={index}
                    tableName={`${index + 1}. ${side_name}`}
                  >
                    <Button
                      onPress={() => layerModal(true, side_name)}
                      classgiven={"table-action layers"}
                      name="Layers"
                    />
                    <Button
                      onPress={() => {
                        CustomAlert.yes_or_no(
                          "Are you sure you want to delete this template!",
                          "YES",
                          "NO",
                          onConfirm,
                          side                          
                        );
                      }}
                      classgiven={"table-action delete-btn"}
                      name="Delete"
                    />
                    <Button
                      onPress={() =>
                        navigate("/layer_permissions", {
                          state: {
                            action_type: "edit",
                            layer_list,
                            layer_permissions,
                            canvas_json,
                            template_fonts,
                            background_layer_id: template_details.layers.BG,
                            outline_layer_id: template_details.layers.outline,
                            edit_template_id,
                          },
                        })
                      }
                      classgiven={"table-action edt-btn"}
                      name="Edit"
                    />
                  </TableBody>
                );
              })}
            </tbody>
          </table>
        </div>
      </>
    );
  }

  function renderLoader() {
    return (
      <>
        <HeadPart>
          {update_info_loader && (
            <ModalLoader
              openModal={update_info_loader}
              loadingTitle={"Updating Info"}
            />
          )}
          {createTemplateSidesTable()}
        </HeadPart>
      </>
    );
  }

  return renderLoader();
}

export { TemplateSidesScreen };
