import axios from 'axios';
import FontFaceObserver from 'fontfaceobserver';
import { OVERLAY_IMAGE_URL } from '../extras/URLs';
import { fabric } from 'fabric';
import {
  SAVE_SVG,
  TOGGLE_UPLOAD_LOADER,
  SAVE_MISSING_FONTS_LIST,
  SAVE_TEMPLATE_FONTS,
  TOGGLE_FONT_UPLOAD_LOADER,
  INIT_CANVAS,
  SAVE_LAYER_LIST,
  SAVE_TEMPLATE_ID,
  SELECT_LAYER,
  SAVE_OBJECT_LIST,
  SAVE_SELECTED_LAYER_INDEX,
  UPDATE_LAYER_PERMISSIONS,
  SAVE_DESIGABLE_AREA,
  SAVE_OUTLINE_AREA,
  SAVE_BACKGROUND_AREA,
  TOGGLE_LAYER_UPLOAD_LOADER,
  SAVE_TEMPLATES_LIST,
  TOGGLE_TEMPLATE_LIST_LOADER,
  SAVE_PERMISSIONS_LIST,
  SAVE_DIE_LIST,
  OBJECT_SELECTED,
  SAVE_ALL_FONTS_LIST,
  SAVE_TEMPLATE_FONT_RULES,
  SAVE_ORIGINAL_FONT_STYLES,
  SAVE_TEMPLATE_ASSIGNED_LAYERS,
  SHOW_IMAGE_PICKER_MODAL,
  CHANGE_IMAGE_UPDATE,
  TOGGLE_IMAGE_CONVERSION_LOADER,
  ADD_IMAGE_PICKER_MODAL,
  ALL_COLOR_LIST,
  FILE_TYPE_OF_SVG,
  PERMISSION_LIST,
  SAVED_TEMPLATE_LIST,
  SAVED_TEMPLATE_LOADER,
  DISPLAY_DIGITAL_PROOF_IMAGE,
  TOGGLE_DIGITAL_PROOF_LOADER,
  UPLOAD_PERCENTAGE,
  CLIENT_NAME,
  SAVE_TEMPLATE_LIST_DRAFT,
  TOGGLE_TEMPLATE_SIDES_LOADER,
  SAVE_TEMPLATE_SIDES,
  SAVE_TEMPLATE_SIDES_DICT,
  BIGGEST_OBJECT,
  FONT_OBSERVER_LOADER,
  GET_FONT,
  GETTING_FONT_LOADER,
  GET_FONT_KEY,
  TOGGLE_LAYER_LOADER,
  SAVE_TEMPLATE_TAGS,
  SAVE_CANVAS_STATE,
  SAVE_TEMPLATE_LIST_TOTAL_PAGES,
  SAVE_TOTAL_TEMPLATES_COUNT,
  UPDATE_PAGE_NO,
  ONCHANGE_PRODUCTS_TAG_FILTER,
  SAVE_TOGGLE_TEMPLATE_LIST_LOADER,
  UPDATE_TEMPLATE_INFO_LOADER,
  ON_CHANGE_TEMPLATE_NAME,
  REPEATED_ORDER_LIST,
  SAVE_3D_MODEL_LIST,
  ON_CHANGE_3D_MODEL_NAME,
  TOGGLE_3D_MODEL_MODAL,
  TOGGLE_CONFIG_MATERIAL_3D_MODEL_MODAL,
  TOGGLE_3D_MODEL_LOADER,
  TOGGLE_CONFIG_3D_MODEL_MODAL,
  SAVE_CANVAS_SVG,
  SEARCH_FILTERING_TEMPLATE,
  FILTERED_TEMPLATE_LIST,
  TEMPLATE_NAME,
} from './ActionTypes';
import {
  TEMPLATE_UPLOAD_API,
  UPLOAD_FONT_API,
  UPLOAD_LAYERS_API,
  GET_TEMPLATE_LIST_API,
  DELETE_TEMPLATE_API,
  GET_TEMPLATE_JSON_API,
  IMAGE_CONVERTER_API,
  EDIT_TEMPLATE_API,
  SAVE_TEMPLATE_API,
  SAVED_DESIGN_TEMPLATE_LIST_API,
  CREATE_DIGITAL_PROOF_API,
  CLIENT_DRAFT_API,
  SAVED_DESIGN_TEMPLATE_LIST_DRAFT_API,
  DELETE_SAVE_DRAFT_API,
  DELETE_SAVE_TEMPLATE_API,
  GET_TEMPLATE_SIDES_API,
  GRID_IMAGE,
  FONTS_LIST,
  GET_REPEATED_ORDERS_LIST,
  GET_3D_MODEL_LIST,
  SAVE_3D_MODEL,
  UPDATE_3D_MODEL_CAMERA_API,
  UPDATE_3D_MODEL_MATERIAL_ORDER_API,
  ADD_TEMPLATE_TO_CART,
  CHAT_GPT_API,
} from '../extras/APIs';

import getCroppedImg from "../extras/CropImage";

import { useNavigate } from "react-router-dom";
import {
  OnMouseDownForPan,
  OnMouseMoveForPan,
  OnMouseUpForPan,
  CanvasZoom,
} from '../extras/PanandZoom';

// import {makeTexture} from '../extras/ThreeDPreview';
// import { ThreeDPreview2 } from '../extras/ThreeDpreview2'

import { applied_font_style, creatingSnaping, initAligningGuidelines, initCenteringGuidelines, removingSnap } from '../extras/CanvasObjectControls';
import { CustomAlert } from '../Components';

import { enableAllPermissionsOnObject } from '../extras/CanvasPermissions';
import { createDispatchHook } from 'react-redux';
import { navigateToEditor } from '../extras/CommonNavigations';

var global_canvas;
var template_layers;
var template_objects;
var global_dispatch;
var layer_ids = new Array();
var global_desinable_bounding_box;
var global_svg_obj = new Array();
let lastHeight
var global_original_fonts = {}
var biggest_object_side = {}
var template_sides_svg = {};
var template_original_length = []
var canvas_rendering_first_time = false;

var global_canvas_states = [];

export const uploadSVG = (
  event,
  svgFile,
  havesingleSide,
  side_title,
  main_template_id,
  template_desp,
  template_height,
  template_width,
  template_length,
  template_max_width,
  template_max_height,
  template_max_length,
  template_orientation,
  template_thumbnail,
  rounded_template_thumbnail,
  template_industry_tags,
  template_product_tags,
  template_theme_tags,
  template_3d_model,
  modalClosingFun,
  navigate
) => {
  return async dispatch => {
    event.preventDefault()

    // dispatch({
    //   type: TOGGLE_UPLOAD_LOADER,
    //   payload: true,
    // });
    template_industry_tags = template_industry_tags.join(',')
    template_product_tags = template_product_tags.join(',')
    template_theme_tags = template_theme_tags.join(',')

    const formData = new FormData();
    formData.append("svgFile", svgFile);
    formData.append("sideTitle", side_title);
    formData.append("mainTemplateId", main_template_id);

    if (main_template_id == 0) {
      formData.append("template_desp", template_desp);
      formData.append("height", template_height);
      formData.append("width", template_width);
      formData.append("showSingleSide",havesingleSide);
      formData.append("thumb_image", template_thumbnail);
      formData.append("rounded_thumb_image", rounded_template_thumbnail);
      formData.append("template_3d_model", template_3d_model);
      formData.append('industry_tags', template_industry_tags);
      formData.append('theme_tags', template_theme_tags);
      formData.append('product_tags', template_product_tags);
      formData.append('length', template_length ? template_length :0);
      formData.append('max_width', template_max_width ? template_max_width :0);
      formData.append('max_height', template_max_height ? template_max_height:0);
      formData.append('max_length', template_max_length ? template_max_length :0);
      formData.append('template_orientation', template_orientation ? template_orientation :null)
    }
    // const myPromise = new Promise((resolve,reject)=>{})
    try {
      const response = await axios({
        method: "post",
        url: TEMPLATE_UPLOAD_API,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: function (progressEvent) {
          // uploadingProgress(progressEvent,null,null,dispatch,TOGGLE_UPLOAD_LOADER)
          var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          dispatch({
            type: UPLOAD_PERCENTAGE,
            payload: percentCompleted
          })
          // console.log('percnetage uploading',percentCompleted);
          if (percentCompleted === 100) {
            dispatch({
              type: UPLOAD_PERCENTAGE,
              payload: ''
            })
            dispatch({
              type: TOGGLE_UPLOAD_LOADER,
              payload: true
            })
          }
        },
      });

      const response_data = response.data;
      // console.log('Response:', response_data);
      if (response.status === 200) {
        modalClosingFun(false)
        if (response_data.status) {
          dispatch({
            type: SAVE_SVG,
            payload: response_data.file_location
          });
          dispatch({
            type: SAVE_MISSING_FONTS_LIST,
            payload: response_data.missing_font_list,
          });
          // console.log(response_data.template_id);
          dispatch({
            type: SAVE_TEMPLATE_ID,
            payload: response_data.template_id
          });

          dispatch({
            type: SAVE_TEMPLATE_FONTS,
            payload: response_data.template_fonts,
          });

          // dispatch({
          //   type: SAVE_PERMISSIONS_LIST,
          //   payload: response_data.permission,
          // });

          dispatch({
            type: FILE_TYPE_OF_SVG,
            payload: response_data.svgFileType
          })
          if (response_data.missing_font_list !== null) {
            navigate('/upload_fonts')
          } else {
            navigate('/layer_permissions')
          }
        } else {
          CustomAlert.alert(false, response_data.message);
        }
      } else {
        CustomAlert.alert(true, 'Something went wrong, please try again!');
      }
    } catch (error) {
      // console.log(error)
      CustomAlert.alert(true, 'Something went wrong, please try again!');
    }

    dispatch({
      type: TOGGLE_UPLOAD_LOADER,
      payload: false,
    });
  };
}

export const uploadFonts = (event, missing_fonts, svg_type, navigate) => {
  return async dispatch => {
    event.preventDefault()

    // dispatch({
    //   type: TOGGLE_FONT_UPLOAD_LOADER,
    //   payload: true,
    // });


    const formData = new FormData();
    for (const [index, font_data] of missing_fonts.entries()) {
      console.log('fonts', font_data)
      formData.append(encodeURI(font_data['name']), font_data['file']);
      formData.append(`fontWeight_${encodeURI(font_data['name'])}`, font_data['fontWeight'])
      formData.append(`aiFontName_${encodeURI(font_data['name'])}`, font_data['aiFontName'])
      formData.append(`coralFontName_${encodeURI(font_data['name'])}`, font_data['coralFontName'])
      formData.append(`fontFamily_${encodeURI(font_data['name'])}`, font_data['fontFamily'])
      formData.append(`italic_${encodeURI(font_data['name'])}`, font_data['italic'])
    }
    formData.append('template_id', missing_fonts[0]['template_id']);
    formData.append('svgFile', svg_type);
    // formData.append('fontWeight',fontWeight);
    // formData.append('aiFontName',aiFontName);
    // formData.append('coralFontName',coralFontName);
    // formData.append('fontFamily',fontFamily);
    // formData.append('italic',italicFont)

    // console.log('template_id',missing_fonts[0]['template_id']);

    // // console.log('Files:',missing_fonts.entries());
    try {
      const response = await axios({
        method: "post",
        url: UPLOAD_FONT_API,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: function (progressEvent) {
          uploadingProgress(progressEvent, null, null, dispatch, TOGGLE_FONT_UPLOAD_LOADER)
        },
      });

      const response_data = response.data;
      console.log('response_data', response_data);
      if (response.status === 200) {
        if (response_data.status) {
          dispatch({
            type: SAVE_TEMPLATE_FONT_RULES,
            payload: response_data.font_rules,
          });
          CustomAlert.alert(false, response_data.message);
          if (missing_fonts[0]['template_id'] !== 0) {
            navigate('/layer_permissions');
          } else {
            navigate(-1);
            // console.log('navigate to main screen')
          }
        } else {
          CustomAlert.alert(false, response_data.message);
        }
      } else {
        CustomAlert.alert(true, 'Something went wrong, please try again!');
      }
    } catch (error) {
      console.log(error)
      CustomAlert.alert(true, 'Something went wrong, please try again!');
    }

    dispatch({
      type: TOGGLE_FONT_UPLOAD_LOADER,
      payload: false,
    });
  }
}

export const loadSVGFromLink = (canvasName, svg_url, layer_permissions, template_fonts) => {
  // console.log('In loadSVGFromLink', svg_url);
  fabric.Object.prototype.objectCaching = false
  fabric.Object.NUM_FRACTION_DIGITS = 15
  fabric.Object.prototype.set({
    transparentCorners: false,
    borderColor: '#1b5ade',
    cornerColor: '#1b5ade',
    cornerSize: 8,
    cornerRadius: 100,
  });
  // PENDING
  return async dispatch => {
    dispatch({
      type: TOGGLE_LAYER_LOADER,
      payload: true,
    });

    var needed_fonts = new Array();
    console.log('Template Fonts in loadSVGFromLink:', template_fonts);
    for (var i = 0; i < template_fonts.length; i++) {
      const checkFont = new FontFaceObserver(template_fonts[i][0], {
        weight: parseInt(template_fonts[i][1]),
        // weight: 100,
        style: template_fonts[i][2],
      });
      // const checkFont = new FontFaceObserver(template_fonts[i][0]);
      needed_fonts.push(checkFont.load(null, 90000));
    }

    console.log('needed_fonts', needed_fonts);
    var line_spacing_list = new Array();
    await Promise.all(needed_fonts).then(function () {
      console.log('Family A & B have loaded');
      // var canvas = new fabric.Canvas();
      let canvas
      try {
        canvas = new fabric.Canvas(canvasName, {
          backgroundColor: 'pink',
          preserveObjectStacking: true,
          // selection: false,
        })
        dispatch({
          type: TOGGLE_LAYER_LOADER,
          payload: false,
        });
        canvas.on('after:render', canvasSelection);
        canvas.on('object:moving', objectMoving);
        canvas.on('mouse:up', function (opt) {
          canvas.forEachObject(function (o) {
            o.setCoords();
          })
        })
        initAligningGuidelines(canvas, dispatch)
        canvas.on('object:scaling', objectScaling);

        // canvas.on('mouse:wheel', function(opt) {
        //   // console.log(global_canvas.viewportTransform);
        //   CanvasZoom(opt, global_canvas);
        // });
        // canvas.on('mouse:down',function(opt) {
        //   OnMouseDownForPan(opt, global_canvas);
        // });
        // // canvas.on('mouse:move',OnMouseMoveForPan);
        // canvas.on('mouse:move', function(opt) {
        //   OnMouseMoveForPan(opt, global_canvas);
        // });
        // canvas.on('mouse:up',function(opt) {
        //   OnMouseUpForPan(opt, global_canvas);
        // });

        dispatch({
          type: INIT_CANVAS,
          payload: canvas
        })

        global_canvas = canvas;
        global_dispatch = dispatch;

      } catch (e) {
        dispatch({
          type: TOGGLE_LAYER_LOADER,
          payload: false,
        });
        // console.log('error in actuin',e);
      }

      // Get SVG from link in Canvas);
      fabric.loadSVGFromURL(svg_url, function (objects, options) {
        setupSVGOnCanvas(canvas, objects, dispatch, layer_permissions, line_spacing_list)
      }, function (svg_obj, o) {
        dispatch({
          type: TOGGLE_LAYER_LOADER,
          payload: false,
        });
        // svg_obj = String(svg_obj);
        const object_id = o.id;
        const object_y = svg_obj.getAttribute('y')
        if (line_spacing_list[object_id]) {
          var y_list = line_spacing_list[object_id]
          y_list.push(object_y);
          line_spacing_list[object_id] = y_list;
        } else {
          line_spacing_list[object_id] = [object_y];
        }
        // svg_obj = svg_obj.replaceAll('<', '');
        // svg_obj = svg_obj.replaceAll('>', '');

        global_svg_obj[o] = svg_obj;
      });
    })
      .catch(function (e) {
        dispatch({
          type: TOGGLE_LAYER_LOADER,
          payload: false,
        });
        console.log('error in svg link ', e)
      })

    // console.log('line_spacing_list',line_spacing_list);
  }
}

export function centerObjectToCanvas(canvas) {
  //get all the objects into an array
  var objs = canvas.getObjects();
  const biggestObject = getBiggestObject(objs);

  //group all the objects
  var alltogetherObj = new fabric.Group(objs);

  //clear previous objects
  objs.forEach(function (obj) {
    canvas.remove(obj);
  });

  canvas.add(alltogetherObj);
  // console.log(`biggest object hieght ${biggestObject.height} and canvas height ${canvas.height}`);
  // console.log(`biggest object wdith ${biggestObject.width} and canvas width ${canvas.width}`);
  // alltogetherObj.scaleToHeight(canvas.height);
  // if (biggestObject.height > biggestObject.width) {
  //   alltogetherObj.scaleToHeight(canvas.height - 20);
  // } else {
  //   alltogetherObj.scaleToWidth(canvas.width - 100);
  // }
  if (biggestObject.height > canvas.height) {
    alltogetherObj.scaleToHeight(canvas.height);
  }
  if (biggestObject.width > canvas.width) {
    alltogetherObj.scaleToWidth(canvas.width);
  }
  alltogetherObj.center();

  var group = alltogetherObj;
  var items = group._objects;
  group._restoreObjectsState();
  canvas.remove(group);
  for (var i = 0; i < items.length; i++) {
    canvas.add(items[i]);
  }

  // alltogetherObj.setCoords();
  canvas.requestRenderAll();
}

const layerGrouping = (objects) => {
  var layers = {};
  var group_array = new Array();
  for (var i = 0; i < objects.length; i++) {
    const current_object_id = objects[i].id;
    if (layers[current_object_id]) {
      layers[current_object_id].push(objects[i]);
    } else {
      layers[current_object_id] = [objects[i]];
    }
  }
  return layers;
}

const calculateLineSpacing = (object_1_space, object_2_space, font_size) => {
  var line_height;
  console.log(parseInt(object_1_space) > parseInt(object_2_space));
  if (parseFloat(object_1_space) > parseFloat(object_2_space)) {
    line_height = object_1_space - object_2_space;
  } else {
    // console.log('In else');
    line_height = object_2_space - object_1_space;
  }
  console.log('LINE HEIGHT:', line_height);
  // line_height = (line_height / font_size).toFixed(1);
  line_height = Math.floor((line_height / font_size) * 10) / 10; // Round down to 2 decimal points
  console.log('LINE HEIGHT:', line_height);
  if (line_height !== 0) {
    return line_height
  } else {
    return 1.16; // 1.16 is the default line height
  }
}

const getTextAlignment = (group) => {
  var x_end_values = new Array();
  var x_start_values = new Array();

  for (let i = 0; i < group.length; i++) {
    const text_object = group[i].getBoundingRect();
    const x = text_object.left;
    const width = text_object.width;

    const end_x = Math.ceil(x + width);
    x_end_values.push(end_x);
    x_start_values.push(Math.ceil(x));
  }

  const check_end_x_values = [... new Set(x_end_values)]
  const check_start_x_values = [... new Set(x_start_values)]

  // console.log(check_end_x_values, check_start_x_values);

  if (check_end_x_values.length === 1) {
    // console.log('Right Align');
    return 'right';
  } else {
    if (check_start_x_values.length === 1) {
      // console.log('Left Align');
      return 'left';
    } else {
      if (check_end_x_values === 1 && check_start_x_values === 1) {
        // console.log('Justify');
        return 'justify';
      } else {
        // console.log('Center Align');
        return 'center';
      }
    }
  }
}

const createTextBox = (object, line_spacing_list) => {
  const object_type = object.get('type');
  if (object_type === 'group') {

    // console.log('Json:',JSON.stringify(object.getObjects()[1].get('type')),object);
    if (object.getObjects()[0].get('type') === 'text') {
      // console.log('object at 0 ', object.getObjects()[0].get('type'));
      var text = '';
      var objects = object.getObjects();

      console.log('HEIGHT:', line_spacing_list[0], line_spacing_list[1], objects[0].get('height'));

      const line_height = calculateLineSpacing(line_spacing_list[0], line_spacing_list[1], objects[0].get('height'));
      for (var i = 0; i < objects.length; i++) {
        var object_text = objects[i].get('text') + '\n';
        // console.log('Json:',object);
        text = text + object_text
      }
      // console.log('object_text',object_text);
      text = text.replace(/^\n|\n$/g, '');

      var keys_list = Object.keys(objects[0].toObject());
      // var keys_list = Object.keys(objects[1].toObject());

      // console.log('fontWeight:', objects[0].get('fontWeight'));

      var t1 = new fabric.IText(text, objects[0].toObject(), {
        fontStretch: false,
      });
      t1.set('text', text);
      t1.set('top', object.get('top'));
      t1.set('left', object.get('left'));
      // t1.set('lineHeight', line_height);
      t1.set('textAlign', getTextAlignment(objects))
      // t1.set('fill','#000000')
      // t1.set('charSpacing', 75);

      // console.log(object, objects[0].get('top'));
      return t1;
    } else {
      return object;
    }
  } else {
    if (object.get('type') === 'text') {
      // console.log('Json:',JSON.stringify(object.get('type')),object.get('left'));
      // const text = object.get('text').replace(/\./,'\n')
      // console.log('text is ',text);
      t1 = new fabric.IText(object.get('text'), object.toObject(), {
        fontStretch: false,
      });

      t1.set('top', object.get('top'))
      t1.set('left', object.get('left'))
      t1.set('width', object.get('width'))
      return t1;
    } else {
      return object;
    }
  }
}

const updateTextSize = (canvas, textbox) => {

  const controlPoint = textbox.__corner;

  //mr and ml are the only controlPoints that dont modify textbox height
  if (controlPoint && controlPoint != "mr" && controlPoint != "ml") {
    lastHeight = textbox.height * textbox.scaleY;
  }

  textbox.set({
    height: lastHeight || textbox.height,
    scaleY: 1
  });

  canvas.requestRenderAll();
};



const setupSVGOnCanvas = (canvas, objects, dispatch, layer_permissions, line_spacing_list) => {
  console.log('in setupsvgoncanvas');
  dispatch({
    type: TOGGLE_LAYER_LOADER,
    payload: false,
  });

  // Create Layer list and store it in Redux
  // console.log('setupSVGOnCanvas', line_spacing_list);
  var layer_list = new Array();

  if (canvas.getObjects().length === 0) {
    var grid = 50;
    var grid_lines = new Array();
    // This IF is from when the function is being called from loadSVGFromLink, ie, for the first time we are adding thw whole SVG to the canvas
    const layers = layerGrouping(objects);
    var i = -1;

    for (const [key, value] of Object.entries(layers)) {
      i = i + 1;
      if (value.length === 1) {
        const layer_name = 'Layer ' + (i + 1) + ' - ' + key;
        layer_list.push({ id: i, layer: layer_name });
        layer_permissions[i] = [];
        const canvas_object = createTextBox(value[0], line_spacing_list[key]);
        layer_ids.push({ id: i, object: canvas_object })
        canvas_object.set('id', key);

        // Adding other permission parameters to objects here
        canvas_object.set('isDepthChange', true);
        canvas_object.set('isDeleteable', true);
        canvas_object.set('isImageReplaceable', true);
        canvas_object.set('isFontStyleChangeable', true);
        canvas_object.set('isFontColorChangeable', true);
        canvas_object.set('isBGColorChangable', true);

        // console.log('key are',key, "value are",value[0])
        canvas.add(canvas_object);
      } else {
        const layer_name = 'Layer ' + (i + 1) + ' - ' + key;
        layer_list.push({ id: i, layer: layer_name });
        layer_permissions[i] = [];
        var group = new fabric.Group(value);
        group.set('id', key);
        // console.log('value are ',key, value);
        // group.set('width', group.width);
        const canvas_object = createTextBox(group, line_spacing_list[key]);
        canvas_object.set('id', key);

        // Adding other permission parameters to objects here
        canvas_object.set('isDepthChange', true);
        canvas_object.set('isDeleteable', true);
        canvas_object.set('isImageReplaceable', true);
        canvas_object.set('isFontStyleChangeable', true);
        canvas_object.set('isFontColorChangeable', true);
        canvas_object.set('isBGColorChangable', true);

        layer_ids.push({ id: i, object: canvas_object })
        canvas.add(canvas_object);
      }
    }
    centerObjectToCanvas(canvas);
  } else {
    // This ELSE if for when the function is being called from other functions pertaining to the levelling of layers, with the help of this function we are getting the updated orders of the layer list and the objects

    for (var i = 0; i < objects.length; i++) {
      // console.log('Object type:', objects[i].get('type'));
      console.log('Layer ' + (i + 1) + ' - ' + objects[i].id);
      const layer_name = 'Layer ' + (i + 1) + ' - ' + objects[i].id;
      var result = layer_ids.find(layer => {
        return layer.object === objects[i]
      })
      // console.log('layer are ', objects);
      layer_list.push({ id: result.id, layer: layer_name });
    }
  }
  console.log('layers ids', layer_list);
  dispatch({
    type: SAVE_LAYER_LIST,
    payload: [],
  });

  dispatch({
    type: SAVE_LAYER_LIST,
    payload: layer_list,
  });

  template_layers = layer_list;

  dispatch({
    type: SAVE_OBJECT_LIST,
    // payload: objects,
    payload: canvas.getObjects(),
  });

  dispatch({
    type: UPDATE_LAYER_PERMISSIONS,
    payload: layer_permissions,
  });

  // template_objects = objects;
  template_objects = canvas.getObjects();

  canvas.on('mouse:wheel', function (opt) {
    const biggestObject = getBiggestObject(template_objects);
    CanvasZoom(opt, canvas, biggestObject.height, biggestObject.width, dispatch);
  });


  // canvas.clear();
  // canvas.renderAll();
  // const fontFamily = 'Roboto';
  // fabric.util.clearFabricFontCache();
  // var grid = 30;
  //
  // // create grid
  //
  // for (var i = 0; i < (6000 / grid); i++) {
  //   var line_1 = new fabric.Line([ i * grid, 0, i * grid, 6000], { stroke: '#ccc', selectable: false });
  //   var line_2 = new fabric.Line([ 0, i * grid, 6000, i * grid], { stroke: '#ccc', selectable: false });
  //   canvas.add(line_1);
  //   canvas.add(line_2);
  //
  //   // canvas.sendToBack(line_1);
  //   // canvas.sendToBack(line_2);
  // }
  for (var i = 0; i < (6000 / grid); i++) {
    var line_1 = new fabric.Line([i * grid, 0, i * grid, 6000], { stroke: '#ccc', selectable: false });
    line_1.set('id', 'grid');
    var line_2 = new fabric.Line([0, i * grid, 6000, i * grid], { stroke: '#ccc', selectable: false });
    line_2.set('id', 'grid');
    grid_lines.push(line_1);
    grid_lines.push(line_2);
  }
  var grids = new fabric.Group(grid_lines);
  // canvas.add(grids);
  grids.set('id', 'grids');
  grids.set('selectable', false);

  canvas.setOverlayImage(GRID_IMAGE, canvas.renderAll.bind(canvas), {
    // Needed to position overlayImage at 0/0
    originX: 'left',
    originY: 'top',
    opacity: 0.1,
    excludeFromExport: true,
  });
  // canvas.add(grids);
  canvas.requestRenderAll();
}

export const deleteObject = (canvas, layer_permissions, user_type = 'admin') => {
  return dispatch => {
    const delete_object = canvas.getActiveObject();
    canvas.remove(delete_object);
    clientCanvasObjectModified(dispatch, canvas);
    if (user_type === 'admin') {
      setupSVGOnCanvas(canvas, canvas.getObjects(), dispatch, layer_permissions);
    }
  }
}

export const addPathObject = (canvas, layer_permissions, layer_list, widthOfPath, heightOfPath, biggestObject, pathText, pathStyle) => {
  // setupSVGOnCanvas(canvas, canvas.getObjects(), global_dispatch, layer_permissions);
  return dispatch => {
    var path = new fabric.Path(pathText)

    path.scaleToWidth(widthOfPath)
    path.scaleToHeight(heightOfPath)
    path.set({ left: biggestObject.left, top: biggestObject.top, fill: pathStyle ? pathStyle : "#ffffff" })
    path.setCoords()

    const i = (Math.max.apply(Math, layer_list.map(function (o) { return o.id; })) + 1).toString();
    console.log('ID:', i, layer_list);
    const layer_name = 'Layer ' + (i + 1) + ' - Path';
    layer_list.push({ id: i, layer: layer_name });
    layer_permissions[i] = [];
    layer_ids.push({ id: i, object: path })
    path.set('id', layer_name);

    // Adding other permission parameters to objects here
    path.set('isDepthChange', true);
    path.set('isDeleteable', true);
    path.set('isImageReplaceable', true);
    path.set('isFontStyleChangeable', true);
    path.set('isFontColorChangeable', true);
    path.set('isBGColorChangable', true);

    // console.log('key are',key, "value are",value[0])
    canvas.add(path);
    canvas.centerObject(path);
    canvas.requestRenderAll()
    // canvas.setActiveObject(path)

    console.log(layer_ids, layer_list, layer_permissions);

    dispatch({
      type: UPDATE_LAYER_PERMISSIONS,
      payload: layer_permissions,
    });

    dispatch({
      type: SAVE_LAYER_LIST,
      payload: [],
    });

    dispatch({
      type: SAVE_LAYER_LIST,
      payload: layer_list,
    });

    dispatch({
      type: INIT_CANVAS,
      payload: canvas,
    });

    dispatch({
      type: SAVE_OBJECT_LIST,
      // payload: objects,
      payload: canvas.getObjects(),
    });

    template_objects = canvas.getObjects();

  }
}

function onObjectModified(e) {
  // console.log('e:',e.target);
  // e.target.setCoords();
  // e.target.line.curve.pathOffset = null;
  // e.target.line.curve._setPositionDimensions({});
  // global_canvas.setActiveObject(e.target.line.curve);
}

function getTextFromObject(object) {
  const objects = object.getObjects();
  // console.log(objects);
}

const objectMoving = () => {
  var movingBox = global_canvas.getActiveObject();
  var bound = movingBox.getBoundingRect();
  var top = movingBox.top;
  var bottom = top + bound.height;
  var left = movingBox.left;
  var right = left + bound.width;

  var boundingBox = global_desinable_bounding_box;
  if (boundingBox) {
    var topBound = boundingBox.top;
    var bottomBound = topBound + boundingBox.height;
    var leftBound = boundingBox.left;
    var rightBound = leftBound + boundingBox.width;

    if (left < leftBound) {
      movingBox.set('left', leftBound);
    }

    if (top < topBound) {
      movingBox.set('top', topBound);
    }
    if (right > rightBound) {
      movingBox.set('left', rightBound - bound.width);
    }

    if (bottom > bottomBound) {
      movingBox.set('top', bottomBound - bound.height);
    }

  } else {
    console.log('No designable_area_bounding_box selected');
  }
}

const objectScaling = (e) => {
  var scaledObject = e.target;
  console.log(scaledObject.flipX);
  if (scaledObject.flipX == true || scaledObject.flipY == true) {
    scaledObject.flipX = false;
    scaledObject.flipY = false

    global_canvas.requestRenderAll();
  }
}

const clientCanvasObjectAdded = (dispatch, canvas) => {
  // console.log('clientCanvasObjectAdded', canvas.getObjects().length);
  // const new_client_canvas_state = UpdateCanvasStates(canvas.toJSON());
  // global_canvas_states = new_client_canvas_state;
  //   dispatch ({
  //     type: SAVE_CANVAS_STATE,
  //     payload: new_client_canvas_state,
  //   });
}

export const clientCanvasObjectModified = (dispatch, canvas, undo = false) => {
  // Used for Undo/redo state management
  // console.log('clientCanvasObjectModified',{...canvasToJSON(canvas)});
  const new_client_canvas_state = UpdateCanvasStates(canvasToJSON(canvas), undo);
  global_canvas_states = [...new_client_canvas_state];
  dispatch({
    type: SAVE_CANVAS_STATE,
    payload: new_client_canvas_state,
  });
}

const UpdateCanvasStates = (current_canvas_state, undo) => {
  var new_states_array = [];
  if (!undo) {
    if (global_canvas_states.length === 0) {
      // Original State Added
      global_canvas_states.push(null);
      global_canvas_states.push(current_canvas_state);
      new_states_array = global_canvas_states;
    } else if (global_canvas_states.length >= 1) {
      new_states_array.push(global_canvas_states[1]);
      new_states_array.push(current_canvas_state);
    }
    // else if(global_canvas_states.length === 2) {
    //   // Swapping needed
    //   new_states_array.push(global_canvas_states[1]);
    //   new_states_array.push(current_canvas_state);
    // }
  } else {
    new_states_array.push(null);
    new_states_array.push(global_canvas_states[0]);
  }

  return new_states_array;
}

export const canvasToImage = (newCanvas, biggest_object) => {
  const { left, top, width, height } = biggest_object
  // console.log('i/mage',newCanvas.toDataURL('image/jpeg',0.7));
  var canvas_image = newCanvas.toDataURL({
    format: 'png',
    left: left,
    top: top,
    width: width,
    height: height,
    enableRetinaScaling: true,
    multiplier: 2,
    quality: 1,
  }, 1);

  return canvas_image;
}


export const clientCanvasSelection = (dispatch, canvas, biggest_object, template_active_side, model_3d, material_name, template_sides_image, threed) => {
  // Function to create canvas image to map 2D svg on the 3D model
  // const {left,top,width,height} = biggest_object
  canvas.clone((newCanvas) => {
    const canvas_image = canvasToImage(newCanvas, biggest_object);
    template_sides_image[template_active_side] = canvas_image;
    if (model_3d) threed.makeTexture(true, model_3d, template_sides_image, material_name, template_active_side)
    dispatch({
      type: SAVE_CANVAS_SVG,
      payload: template_sides_image,
    });
  })
}

const canvasSelection = () => {
  // console.log('iin here');
  // global_canvas.contextContainer.strokeStyle = '#555';
  //
  // global_canvas.forEachObject(function(obj) {
  //   var bound = obj.getBoundingRect();
  //   var getScaledWidth = obj.getScaledWidth()
  //   // console.log(getScaledWidth, bound);
  //   console.log(obj.get('text'), obj.get('fontFamily'), bound, obj.get('width'));
  //
  //   global_canvas.contextContainer.strokeRect(
  //     bound.left + 0.5,
  //     bound.top + 0.5,
  //     bound.width,
  //     bound.height
  //   );
  // })

  global_canvas.getActiveObjects().forEach(obj => {
    const { height, width, fontFamily } = obj
    // console.log('OBJ:', obj);
    if (obj && obj.isType('text')) {
      const { text, height, width } = obj
      if (!text) {
        getTextFromObject(obj);
      }
    }

    global_dispatch({
      type: SELECT_LAYER,
      payload: obj,
    });

    const layer_index = template_objects.indexOf(obj);
    global_dispatch({
      type: SAVE_SELECTED_LAYER_INDEX,
      payload: layer_index,
    });
  })
}


export const selectLayer = (object, layer_index) => {
  return async dispatch => {
    dispatch({
      type: SELECT_LAYER,
      payload: object
    });

    dispatch({
      type: SAVE_SELECTED_LAYER_INDEX,
      payload: layer_index,
    });
  }
}

export const sendingToFront = (canvas, selected_layer, layer_permissions) => {
  return async dispatch => {
    canvas.bringToFront(selected_layer);
    setupSVGOnCanvas(canvas, canvas.getObjects(), dispatch, layer_permissions)

    dispatch({
      type: INIT_CANVAS,
      payload: canvas
    });
  }
}

export const sendingToBack = (canvas, selected_layer, layer_permissions) => {
  return async dispatch => {
    canvas.sendToBack(selected_layer);
    setupSVGOnCanvas(canvas, canvas.getObjects(), dispatch, layer_permissions)

    dispatch({
      type: INIT_CANVAS,
      payload: canvas
    });
  }
}

export const sendingForward = (canvas, selected_layer, layer_permissions) => {
  return async dispatch => {
    canvas.bringForward(selected_layer);
    setupSVGOnCanvas(canvas, canvas.getObjects(), dispatch, layer_permissions)

    dispatch({
      type: INIT_CANVAS,
      payload: canvas
    });
  }
}

export const sendingBackward = (canvas, selected_layer, layer_permissions) => {
  return async dispatch => {
    canvas.sendBackwards(selected_layer);
    setupSVGOnCanvas(canvas, canvas.getObjects(), dispatch, layer_permissions)

    dispatch({
      type: INIT_CANVAS,
      payload: canvas
    })
  }
}

export const updateLayerPermissions = (layer_permissions, layer_id, permission_id) => {
  return async dispatch => {
    var permissions_array = layer_permissions[layer_id];
    // console.log('permissions_array', layer_permissions, layer_id);

    if (permissions_array.includes(permission_id)) {
      const index = permissions_array.indexOf(permission_id);
      permissions_array.splice(index, 1); // 2nd parameter means remove one item only
    } else {
      // console.log('layer_permissions[layer_id]', layer_permissions[layer_id]);
      layer_permissions[layer_id].push(permission_id);
    }
    dispatch({
      type: UPDATE_LAYER_PERMISSIONS,
      payload: layer_permissions,
    });
  }
}

export const saveDesignableArea = (designable_layer_id) => {
  // Removed designable_object from params list as we no more need to use bounding box, we are now directly using canvas clipping
  return async dispatch => {
    // if (proceed) {
    // console.log(designable_layer_id, designable_object);
    dispatch({
      type: SAVE_DESIGABLE_AREA,
      payload: { prop: 'designable_area_layer', value: designable_layer_id }
    });

    // const desinable_bounding_box = designable_object.getBoundingRect();
    // global_desinable_bounding_box = desinable_bounding_box;
    // // designable_object.set('selectable', false);
    // dispatch({
    //   type: SAVE_DESIGABLE_AREA,
    //   payload: {prop: 'designable_area_bounding_box', value: desinable_bounding_box}
    // });
    // }
  }
}

export const saveBackgroundArea = (background_layer_id) => {
  return {
    type: SAVE_BACKGROUND_AREA,
    payload: background_layer_id,
  };
}

export const saveOutlineArea = (outline_layer_id) => {
  return {
    type: SAVE_OUTLINE_AREA,
    payload: outline_layer_id,
  };
}

export const getBiggestObject = (objects) => {
  var biggestObject = null;
  var biggestArea = 0;
  for (var i = 0; i < objects.length; i++) {
    var bound = objects[i].getBoundingRect();
    const width = bound.width;
    const height = bound.height;

    const objectArea = width * height;
    if (objectArea > biggestArea) {
      biggestArea = objectArea;
      biggestObject = bound;
    }
  }
  return biggestObject;
}

export const uploadTemplateLayers = (template, navigate, action_type) => {
  // console.log('Upload template');
  return async dispatch => {

    // dispatch({
    //   type: TOGGLE_LAYER_UPLOAD_LOADER,
    //   payload: true,
    // });

    const formData = new FormData();
    formData.append('template_id', template['template_id']);
    console.log('SENDING TEMP ID:', template['template_id']);
    formData.append('layer_list', JSON.stringify(template['layer_list']));
    formData.append('layer_permissions', JSON.stringify(template['layer_permissions']));
    // formData.append('designable_layer_id', template['designable_layer_id']);
    formData.append('background_layer_id', template['background_layer_id']);
    formData.append('outline_layer_id', template['outline_layer_id']);
    const blob = new Blob([JSON.stringify(template['template_canvas'])])
    const file = new File([blob], 'filename.txt')
    // console.log('file',file);
    formData.append('template_canvas', file);
    formData.append('action_type', action_type);
    // console.log('json string',JSON.stringify(template['template_canvas']));

    console.log('FORM DATA');
    console.log(template['template_id']);
    console.log(JSON.stringify(template['layer_list']));
    console.log(JSON.stringify(template['layer_permissions']));
    console.log(template['background_layer_id']);
    console.log(template['outline_layer_id']);
    console.log(new Blob([JSON.stringify(template['template_canvas'])]));
    console.log(new File([blob], 'filename.txt'));
    console.log(file);
    console.log(action_type);

    try {
      const response = await axios({
        method: "post",
        url: UPLOAD_LAYERS_API,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: function (progressEvent) {
          uploadingProgress(progressEvent, null, null, dispatch, TOGGLE_LAYER_UPLOAD_LOADER)
        },
      });

      const response_data = response.data;
      // console.log('Response:', response);

      dispatch({
        type: TOGGLE_LAYER_UPLOAD_LOADER,
        payload: false,
      });

      if (response.status === 200) {
        if (response_data.status) {
          CustomAlert.alert(false, response_data.message);
          navigate('/template_list');
        } else {
          CustomAlert.alert(false, response_data.message);
        }
      } else {
        CustomAlert.alert(true, 'Something went wrong, please try again!');
      }
    } catch (error) {
      dispatch({
        type: TOGGLE_LAYER_UPLOAD_LOADER,
        payload: false,
      });
      // console.log(error)
      CustomAlert.alert(true, 'Something went wrong, please try again!');
    }
  }
}

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}


export const getTemplateList = (template_id = null, page_no = 1, no_of_templates_on_page = 15, return_canvas_json, industry_tags, theme_tags, product_tags, template_die, orientation,searching_template, navigate = null,controller,product_tags_search_text='') => {
  // console.log('Get template list', template_id);
  return async dispatch => {
    dispatch({
      type: TOGGLE_TEMPLATE_LIST_LOADER,
      payload: true,
    });
    const formData = new FormData()
    if (template_id) {
      formData.append('template_id', template_id)
    }
    if (industry_tags) {
      formData.append('industry_tags', industry_tags)
    }
    if (theme_tags) {
      formData.append('theme_tags', theme_tags)
    }
    if (product_tags) {
      formData.append('editor_product_tags', product_tags)
    }
    if (template_die) {
      console.log('in if  template die',template_die);
      formData.append('template_die', template_die)
    }

    if (orientation) {
      formData.append('orientation', orientation)
    }
    if (searching_template){
      formData.append('searching',searching_template)
    }
    formData.append('page_no', page_no)
    formData.append('no_of_templates_on_page', no_of_templates_on_page)
    
    if (return_canvas_json) {
      formData.append('return_canvas_json', return_canvas_json)
    }
    try {
      const response = await axios({
        method: "post",
        url: GET_TEMPLATE_LIST_API + '?dt=' + Date.now(),
        data: formData && formData,
        headers: { "Content-Type": "multipart/form-data" },
        signal:controller?.signal
      });

      // await sleep(500);

      const response_data = response.data;
      console.log('Response:', response_data);

      dispatch({
        type: TOGGLE_TEMPLATE_LIST_LOADER,
        payload: false,
      });

      if (response.status === 200) {
        if (response_data.status) {
          
          dispatch({
            type: SAVE_TEMPLATES_LIST,
            payload: [],
          });
          if (response_data.template_list !== null || response_data.total_templates !== null || response_data.total_pages !== null) {
            const response_template_list = response_data.template_list;
            if(!Array.isArray(response_template_list)){

              const first_side = response_template_list[Object.keys(response_template_list)[0]]
              const template_name = first_side['product_tags'].length > 0 ? first_side['product_tags'][0]['product']?.split('-')[0] : '';
              dispatch({
                type:TEMPLATE_NAME,
                payload:template_name
              })
            }
            
            dispatch({
              type: SAVE_TEMPLATES_LIST,
              payload: response_data.template_list,
            });
            dispatch({
              type:FILTERED_TEMPLATE_LIST,
              payload:response_data.template_list,
            })

            dispatch({
              type: SAVE_TEMPLATE_LIST_TOTAL_PAGES,
              payload: response_data.total_templates,
            });

            dispatch({
              type: SAVE_TOTAL_TEMPLATES_COUNT,
              payload: response_data.total_pages,
            });
          }

          dispatch({
            type: SAVE_ALL_FONTS_LIST,
            payload: response_data.font_list,
          });

          dispatch({
            type: ALL_COLOR_LIST,
            payload: response_data.swatch
          });

          dispatch({
            type: SAVE_PERMISSIONS_LIST,
            payload: response_data.permission_list
          });

          dispatch({
            type: SAVE_DIE_LIST,
            payload: response_data.die_list
          });

          dispatch({
            type: SAVE_3D_MODEL_LIST,
            payload: response_data['3d_models'],
          });

          dispatch({
            type: SAVE_TEMPLATE_TAGS,
            payload: { prop: 'industry_tags', value: response_data.industry_tags }
          });

          dispatch({
            type: SAVE_TEMPLATE_TAGS,
            payload: { prop: 'theme_tags', value: response_data.theme_tags }
          });

          dispatch({
            type: SAVE_TEMPLATE_TAGS,
            payload: { prop: 'product_tags', value: response_data.product_tags }
          });


          if(product_tags_search_text.trim() !==""){
            const product_tags = response_data.product_tags;

            const filtered = product_tags.filter(prod => prod.product.toLowerCase().includes(product_tags_search_text.toLowerCase()))

            dispatch({
              type: SAVE_TEMPLATE_TAGS,
              payload: { prop: 'filtered_product_tags', value: filtered }
            });


          }else{

            dispatch({
              type: SAVE_TEMPLATE_TAGS,
              payload: { prop: 'filtered_product_tags', value: response_data.product_tags }
            });
          }



          if (navigate) {
            // In case of opening editor directly from Printer server
            const template_sides_title = Object.keys(response_data.template_list);
            const first_template_side = response_data.template_list[template_sides_title[0]];

            // navigate('/editor', { 
            //   state: {
            //     'template_json_file': first_template_side.canvas_json,
            //     'type': 'user',
            //     'template_id': first_template_side.id,
            //     'template_fonts': first_template_side.fonts,
            //     'fonts_list': response_data.font_list,
            //     'layers': first_template_side.layers,
            //     'template_sides': template_sides_title,
            //     'swatch': response_data.swatch,
            //     'all_template_sides': response_data.template_list,
            //     "rounded": false,
            //   }
            // })

            navigateToEditor(navigate, response_data.template_layers.isRounded, first_template_side, template_sides_title, response_data.template_list, response_data.font_list, response_data.swatch);
          }
        } else {
          CustomAlert.alert(false, response_data.message);
        }
      } else {
        CustomAlert.alert(true, 'Something went wrong, please try again!');
      }
    } catch (error) {
      dispatch({
        type: TOGGLE_TEMPLATE_LIST_LOADER,
        payload: false,
      });
      console.log(error)
      // CustomAlert.alert(true, 'Something went wrong, please try again!');
    }
  }
}

export const onChangeProductTagSearch = (product_tag_search, product_tags) => {
  return dispatch => {
    dispatch({
      type: ONCHANGE_PRODUCTS_TAG_FILTER,
      payload: product_tag_search,
    });

    if (product_tag_search === '') {
      dispatch({
        type: SAVE_TEMPLATE_TAGS,
        payload: { prop: 'filtered_product_tags', value: [] }
      });
      dispatch({
        type: SAVE_TEMPLATE_TAGS,
        payload: { prop: 'filtered_product_tags', value: product_tags }
      });
    } else {
      // const filtered = product_tags.filter((a)=>{
      //   if(a.product.toLowerCase().search(product_tag_search.toLowerCase()) !== -1)
      //   {
      //     return a;
      //   }
      // });
      const filtered = product_tags.filter(prod => prod.product.toLowerCase().includes(product_tag_search.toLowerCase()))
      // console.log('filtered',filtered);
      dispatch({
        type: SAVE_TEMPLATE_TAGS,
        payload: { prop: 'filtered_product_tags', value: [] }
      });
      dispatch({
        type: SAVE_TEMPLATE_TAGS,
        payload: { prop: 'filtered_product_tags', value: filtered }
      });
    }
  }
}

export const changePageNumber = (new_page_number) => {
  // console.log('new_page_number', new_page_number);
  return dispatch => {
    dispatch({
      type: UPDATE_PAGE_NO,
      payload: new_page_number,
    });
  }
}

export const getTemplateSides = (template_id) => {
  return async dispatch => {
    dispatch({
      type: TOGGLE_TEMPLATE_SIDES_LOADER,
      payload: true,
    });
    const formData = new FormData()
    formData.append('main_template_id', template_id)

    try {
      const response = await axios({
        method: "post",
        url: GET_TEMPLATE_SIDES_API,
        data: formData && formData,
        headers: { "Content-Type": "multipart/form-data" },
      });

      const response_data = response.data;

      dispatch({
        type: TOGGLE_TEMPLATE_SIDES_LOADER,
        payload: false,
      });

      if (response.status === 200) {
        if (response_data.status) {

          dispatch({
            type: SAVE_TEMPLATE_SIDES,
            payload: response_data.template_sides,
          });
        } else {
          CustomAlert.alert(false, response_data.message);
        }
      } else {
        CustomAlert.alert(true, 'Something went wrong, please try again!');
      }
    } catch (error) {
      dispatch({
        type: TOGGLE_TEMPLATE_SIDES_LOADER,
        payload: false,
      });
      console.log(error)
      CustomAlert.alert(true, 'Something went wrong, please try again!');
    }
  }
}

export const deleteTemplate = (template_list, index, main_template_id, navigate) => {
  return async dispatch => {
    var delete_template_id
    if (Object.keys(template_list).length === 1) {
      delete_template_id = template_list[index].id
    } else {
      if (template_list[index].id === main_template_id) {
        // console.log('delete first other template');
        CustomAlert.alert(false, "This is the main template you can't delete this ");
      } else {
        delete_template_id = template_list[index].id
      }
    }
    // const delete_template = template_list[index];    
    const formData = new FormData();

    if (delete_template_id) {
      formData.append('template_id', delete_template_id);
      dispatch({
        type: TOGGLE_TEMPLATE_LIST_LOADER,
        payload: true,
      });

      try {
        const response = await axios({
          method: "post",
          url: DELETE_TEMPLATE_API,
          data: formData,
          onUploadProgress: function (progressEvent) {
            uploadingProgress(progressEvent, null, null, dispatch, TOGGLE_TEMPLATE_LIST_LOADER)
          },
          // headers: { "Content-Type": "multipart/form-data" },
        });

        const response_data = response.data;
        // console.log('Response:', response_data);

        dispatch({
          type: TOGGLE_TEMPLATE_LIST_LOADER,
          payload: false,
        });

        if (response.status === 200) {
          if (response_data.status) {
            CustomAlert.alert(false, response_data.message);
            // Object.keys(template_list).splice();
            delete template_list[index]
            // dispatch({
            //   type: SAVE_TEMPLATES_LIST,
            //   payload: template_list,
            // });
            if (Object.keys(template_list).length === 0) {
              navigate('/')
            }
          } else {
            CustomAlert.alert(false, response_data.message);
          }
        } else {
          CustomAlert.alert(true, 'Something went wrong, please try again!');
        }
      } catch (error) {
        dispatch({
          type: TOGGLE_TEMPLATE_LIST_LOADER,
          payload: false,
        });
        console.log(error)
        CustomAlert.alert(true, 'Something went wrong, please try again!');
      }
    } else {
      console.log('not there');
    }
    // const delete_template_id = delete_template.id;


  }
}

export const deleteSaveDraft = (draft_list, index) => async dispatch => {
  try {
    const formData = new FormData()
    const deleteDraft = draft_list[index]
    const draft_id = deleteDraft.draft_id
    formData.append('draft_id', draft_id)
    dispatch({
      type: TOGGLE_TEMPLATE_LIST_LOADER,
      payload: true,
    });

    const response = await axios({
      url: DELETE_SAVE_DRAFT_API,
      method: 'post',
      data: formData
    })

    if (response.status === 200) {
      if (response.data.status) {
        draft_list.splice(index, 1);
        dispatch({
          type: TOGGLE_TEMPLATE_LIST_LOADER,
          payload: false
        })
        dispatch({
          type: SAVE_TEMPLATE_LIST_DRAFT,
          payload: draft_list
        })
      } else {
        dispatch({
          type: TOGGLE_TEMPLATE_LIST_LOADER,
          payload: false
        })
        CustomAlert.alert(false, response.data.message);
      }
    } else {
      dispatch({
        type: TOGGLE_TEMPLATE_LIST_LOADER,
        payload: false
      })
    }


  } catch (e) {
    dispatch({
      type: TOGGLE_TEMPLATE_LIST_LOADER,
      payload: false
    })
    console.log('error in deleting draft', e);
  }
}

export const deleteSaveTemplate = (save_list, index) => async dispatch => {
  try {
    const formData = new FormData()
    const deleteSave = save_list[index]
    const save_id = deleteSave.output_id
    formData.append('save_id', save_id)
    dispatch({
      type: SAVE_TOGGLE_TEMPLATE_LIST_LOADER,
      payload: true,
    });

    const response = await axios({
      url: DELETE_SAVE_TEMPLATE_API,
      method: 'post',
      data: formData
    })
    console.log('response',response.data);
    if (response.status === 200) {
      if (response.data.status) {
        save_list.splice(index, 1);
        dispatch({
          type: SAVE_TOGGLE_TEMPLATE_LIST_LOADER,
          payload: false
        })
        CustomAlert.alert(false,response.data.message)
        dispatch({
          type: SAVED_TEMPLATE_LIST,
          payload: save_list
        })
      } else {
        dispatch({
          type: SAVE_TOGGLE_TEMPLATE_LIST_LOADER,
          payload: false
        })
        CustomAlert.alert(false, response.data.message);
      }
    } else {
      dispatch({
        type: SAVE_TOGGLE_TEMPLATE_LIST_LOADER,
        payload: false
      })
    }


  } catch (e) {
    dispatch({
      type: SAVE_TOGGLE_TEMPLATE_LIST_LOADER,
      payload: false
    })
    console.log('error in deleting saved template', e);
  }
}


export const setSideCanvasJSON = (side_json, template_sides_json, existing_template_side) => {
  return dispatch => {
    template_sides_json[existing_template_side] = side_json;
    // console.log('te',template_sides_json);

    dispatch({
      type: SAVE_TEMPLATE_SIDES_DICT,
      payload: template_sides_json,
    });

    // dispatch({
    //   type:SAVE_ORIGINAL_FONT_STYLES,
    //   payload:original_font_style_applied
    // })
  }
}

export const loadCanvasFromJSONForEditing = (
  edit_template_id,
  json_file,
  template_fonts,
  layer_list,
  layer_permissions,
  background_layer_id,
  outline_layer_id
) => {
  return async dispatch => {
    fabric.Object.prototype.objectCaching = false
    fabric.Object.NUM_FRACTION_DIGITS = 15
    fabric.Object.prototype.set({
      transparentCorners: false,
      borderColor: '#1b5ade',
      cornerColor: '#1b5ade',
      cornerSize: 8,
      cornerRadius: 100,
    });
    dispatch({
      type: TOGGLE_LAYER_LOADER,
      payload: true,
    });
    dispatch({
      type: FONT_OBSERVER_LOADER,
      payload: true,
    });

    var needed_fonts = new Array();

    // console.log('response data',response.data);
    for (var i = 0; i < template_fonts.length; i++) {
      const checkFont = new FontFaceObserver(template_fonts[i][0], {
        weight: parseInt(template_fonts[i][1]),
        style: template_fonts[i][2],
      });
      needed_fonts.push(checkFont.load(null, 30000));
    }

    console.log('edit template, needed_fonts', needed_fonts);

    await Promise.all(needed_fonts).then(async function () {
      var canvas = new fabric.Canvas('canvas', { containerClass: 'canvas', selection: false, preserveObjectStacking: true, });
      try {

        const response = await axios({
          method: "post",
          url: json_file,
          headers: { "Content-Type": "multipart/form-data" },
        });

        if (response.status == 200) {
          var canvas_json = response.data;

          canvas.loadFromJSON(canvas_json, function (obj) {
            canvas.on('after:render', canvasSelection);
            canvas.on('object:moving', objectMoving);
            canvas.on('mouse:up', function (opt) {
              canvas.forEachObject(function (o) {
                o.setCoords();
              })
            })
            initAligningGuidelines(canvas, dispatch)
            canvas.on('object:scaling', objectScaling);
            canvas.on('mouse:wheel', function (opt) {
              const biggestObject = getBiggestObject(template_objects);
              CanvasZoom(opt, canvas, biggestObject.height, biggestObject.width, dispatch);
            });
            canvas.on('mouse:down', (opt) => {
              if (opt.e.altKey) {
                canvas.isDragging = true

                console.log('mouse down', canvas.isDragging);
                canvas.requestRenderAll()
              }
            })

            global_canvas = canvas;
            global_dispatch = dispatch;
            template_objects = canvas.getObjects();

            dispatch({
              type: UPDATE_LAYER_PERMISSIONS,
              payload: layer_permissions,
            });

            dispatch({
              type: SAVE_LAYER_LIST,
              payload: [],
            });

            dispatch({
              type: SAVE_LAYER_LIST,
              payload: layer_list,
            });

            dispatch({
              type: SAVE_TEMPLATE_ID,
              payload: edit_template_id,
            });

            template_layers = layer_list;

            dispatch({
              type: SAVE_OBJECT_LIST,
              // payload: objects,
              payload: canvas.getObjects(),
            });
            // console.log('background',background_layer_id);
            if (background_layer_id !== -1) {
              dispatch({
                type: SAVE_BACKGROUND_AREA,
                payload: background_layer_id,
              });
            }

            if (outline_layer_id !== -1) {
              dispatch({
                type: SAVE_OUTLINE_AREA,
                payload: outline_layer_id,
              });
            }

            dispatch({
              type: TOGGLE_LAYER_LOADER,
              payload: false,
            });
            dispatch({
              type: INIT_CANVAS,
              payload: canvas,
            })

          }, function (o, object) {
            object = enableAllPermissionsOnObject(object);
            layer_ids.push({ id: object.get('layer_id'), object: object })
          });
        } else {

          CustomAlert.alert(true, 'Something went wrong, please try again!');
        }
      } catch (error) {
        dispatch({
          type: TOGGLE_LAYER_LOADER,
          payload: false,
        });
        dispatch({
          type: FONT_OBSERVER_LOADER,
          payload: false
        });
        CustomAlert.alert(true, 'Something went wrong, please try again!');
      }

      dispatch({
        type: FONT_OBSERVER_LOADER,
        payload: false
      });
    })
      .catch(function (error) {
        console.log('Failed to load fonts', error);
      })
  }
}


const _undoAndRedoFunction = () => {

  /**
   * Override the initialize function for the _historyInit();
   */
  fabric.Canvas.prototype.initialize = (function (originalFn) {
    return function (...args) {
      originalFn.call(this, ...args);
      this._historyInit();
      return this;
    };
  })(fabric.Canvas.prototype.initialize);

  /**
   * Override the dispose function for the _historyDispose();
   */
  fabric.Canvas.prototype.dispose = (function (originalFn) {
    return function (...args) {

      originalFn.call(this, ...args);
      this._historyDispose();
      return this;
    };
  })(fabric.Canvas.prototype.dispose);

  /**
   * Returns current state of the string of the canvas
   */
  fabric.Canvas.prototype._historyNext = function () {
    // console.log('this.extraProps',this.toDatalessJSON(this.extraProps));
    return JSON.stringify(this.toDatalessJSON(this.extraProps));
  }

  /**
   * Returns an object with fabricjs event mappings
   */
  fabric.Canvas.prototype._historyEvents = function () {
    return {
      'object:added': this._historySaveAction,
      'object:removed': this._historySaveAction,
      'object:modified': this._historySaveAction,
      'object:skewing': this._historySaveAction
    }
  }

  /**
   * Initialization of the plugin
   */
  fabric.Canvas.prototype._historyInit = function () {
    this.historyUndo = [];
    this.historyRedo = [];
    this.extraProps = ['selectable'];
    this.historyNextState = this._historyNext();

    this.on(this._historyEvents());
  }

  /**
   * Remove the custom event listeners
   */
  fabric.Canvas.prototype._historyDispose = function () {
    this.off(this._historyEvents())
  }

  /**
   * It pushes the state of the canvas into history stack
   */
  fabric.Canvas.prototype._historySaveAction = function () {

    if (this.historyProcessing)
      return;

    const json = this.historyNextState;
    this.historyUndo.push(json);
    template_original_length.push(json)
    this.historyNextState = this._historyNext();
    this.fire('history:append', { json: json });
  }
  

  
  /**
   * Undo to latest history. 
   * Pop the latest state of the history. Re-render.
   * Also, pushes into redo history.
   */
  fabric.Canvas.prototype.undo = function (callback) {
    // The undo process will render the new states of the objects
    // Therefore, object:added and object:modified events will triggered again
    // To ignore those events, we are setting a flag.
    this.historyProcessing = true;
    console.log('this',this.historyUndo.length,template_original_length.length);
    const history = this.historyUndo.pop();
    if (history) {
      // Push the current state to the redo history
      this.historyRedo.push(this._historyNext());
      this.historyNextState = history;
      this._loadHistory(history, 'history:undo', callback);
    } else {
      this.historyProcessing = false;
    }
  }

  /**
   * Redo to latest undo history.
   */
  fabric.Canvas.prototype.redo = function (callback) {
    // The undo process will render the new states of the objects
    // Therefore, object:added and object:modified events will triggered again
    // To ignore those events, we are setting a flag.
    this.historyProcessing = true;
    const history = this.historyRedo.pop();
    if (history) {
      // Every redo action is actually a new action to the undo history
      this.historyUndo.push(this._historyNext());
      this.historyNextState = history;
      this._loadHistory(history, 'history:redo', callback);
    } else {
      this.historyProcessing = false;
    }
  }

  fabric.Canvas.prototype._loadHistory = function (history, event, callback) {
    var that = this;
   
    this.loadFromJSON(history, function () {
      that.renderAll();
      that.fire(event);
      that.historyProcessing = false;

      if (callback && typeof callback === 'function')
        callback();
    });
  }

  /**
   * Clear undo and redo history stacks
   */
  fabric.Canvas.prototype.clearHistory = function () {
    console.log('this',this.historyUndo.length);
    this.historyUndo = [];
    this.historyRedo = [];
    this.fire('history:clear');
  }

  /**
   * Off the history
   */
  fabric.Canvas.prototype.offHistory = function () {
    console.log(' off this',this.historyUndo.length);
    this.historyProcessing = true;
  }

  /**
   * On the history
   */
  fabric.Canvas.prototype.onHistory = function () {
    console.log(' on this',this.historyUndo.length);
    this.historyProcessing = false;

    this._historySaveAction();
  }
}

export const loadCanvasFromJSON = (
    fabric_canvas_div_id,
    json_file, 
    template_fonts, 
    background_layer_id, 
    template_sides_json, 
    clip_layer_id = '', 
    template_active_side, 
    existing_canvas, 
    client_draft
  ) => {
  // console.log('HERE AGAIN',clip_layer_id);
  return dispatch => {
    return new Promise(async (resolve, reject) => {
      fabric.Object.prototype.objectCaching = false
      fabric.Object.NUM_FRACTION_DIGITS = 15
      fabric.Object.prototype.set({
        transparentCorners: false,
        borderColor: '#1b5ade',
        cornerSize: 8,
        cornerColor: '#1b5ade',
        cornerRadius: 100,
      });
      fabric.Canvas.prototype.getAbsoluteCoords = function (object, canvasZoom) {
        // console.log('object',object.top,object.left,this._offset.left);
        return {
          left: (object.left + this._offset.left) * canvasZoom,
          top: (object.top + this._offset.top) * canvasZoom
        };
      }
      const formData = new FormData();
      var side_json_exists = false;

      try {
        var canvas_json
        var canvasAlreadyLoaded = false
        
        // var 
        // console.log('canvas_json',template_sides_json);

        if (!client_draft) {
          // console.log('template_sides_json',template_sides_json);
          if (template_sides_json[template_active_side] !== null) {
            // fontLoaded = true;
            console.log('----------EXISTING----------');
            side_json_exists = true;
            canvasAlreadyLoaded = true

            canvas_json = template_sides_json[template_active_side];
          } else {
            console.log('----------NEW----------');
            side_json_exists = false;
            // console.log('json_file',json_file)
            const response = await axios({
              method: "post",
              url: json_file,
              data: formData,
              headers: { "Content-Type": "multipart/form-data" },
            });

            if (response.status == 200) {
              canvas_json = response.data;
              if (template_sides_json[template_active_side] === null) {
                template_sides_json[template_active_side] = canvas_json;
                // console.log('side',template_sides_json);
                dispatch({
                  type: SAVE_TEMPLATE_SIDES_DICT,
                  payload: template_sides_json,
                });

                // console.log('Updated json saved:', template_sides_json);
              }
            } else {
              CustomAlert.alert(true, 'Something went wrong, please try again!');
            }
          }
        } else {
          if (template_sides_json[template_active_side] === null) {
            // console.log('json_file', json_file);
            canvas_json = json_file[template_active_side]
            template_sides_json[template_active_side] = json_file[template_active_side];
            // console.log('template_sides_json',template_sides_json);
            dispatch({
              type: SAVE_TEMPLATE_SIDES_DICT,
              payload: template_sides_json,
            });
          } else {
            canvas_json = template_sides_json[template_active_side]
          }
        }

        var needed_fonts = new Array();

        // console.log('response data',response.data);
        for (var i = 0; i < template_fonts.length; i++) {
          const checkFont = new FontFaceObserver(template_fonts[i][0], {
            weight: parseInt(template_fonts[i][1]),
            style: template_fonts[i][2],
          });
          needed_fonts.push(checkFont.load(null, 30000));
        }

        // console.log('needed_fonts', needed_fonts);

        await Promise.all(needed_fonts).then(function () {
          var canvas;
          if (existing_canvas !== null) {
            // console.log('In if');
            canvas = existing_canvas;
          } else {
            const html_canvas = document.getElementById(fabric_canvas_div_id);
            // console.log('html_canvas',html_canvas);
            // const context = html_canvas.getContext('2d');
            // context.clearRect(0, 0, html_canvas.width, html_canvas.height);
            canvas = new fabric.Canvas(fabric_canvas_div_id, { containerClass: fabric_canvas_div_id, controlsAboveOverlay: true, allowTouchScrolling: true, selection: false, preserveObjectStacking: true, });
            // console.log('canvas',canvas);
          }

          var original_text_styles = {};
          var canvas_objects = new Array();
          
          // if (!client_draft) {
          canvas.loadFromJSON(canvas_json, function (obj) {
            processCanvasAfterLoadFromJSON(
              dispatch,
              canvas,
              background_layer_id,
              clip_layer_id,
              template_active_side,
              canvasAlreadyLoaded,
              resolve,
            );
          }, function (o, object) {
            // console.log('HEREE FIRST');
            const isDepthChange = o.isDepthChange;
            const isDeleteable = o.isDeleteable;
            const isRotatable = o.isRotatable;
            const lockMovementX = o.lockMovementX;
            const lockScalingFlip = o.lockScalingFlip;
            const hasControls = o.hasControls;

            // console.log('SEARCHING PATH',o);
            if (!isRotatable) {
              object.setControlVisible('mtr', false);
            }
            if (!canvasAlreadyLoaded) {
              // console.log('font loaded or not ',fontAlreadyLoaded);
              if (!client_draft) {

                if (object.get('type') === 'i-text') {
                  const object_id = object.get('id');
                  var font_styles = {}
                  font_styles.fontFamily = object.get('fontFamily').replaceAll("'", "");
                  font_styles.fontWeight = object.get('fontWeight');
                  font_styles.fontColor = object.get('fill');
                  font_styles.textBackgroundColor = object.get('backgroundColor');
                  font_styles.fontStyle = object.get('fontStyle');
                  font_styles.underline = object.get('underline');
                  // console.log(font_styles);
                  original_text_styles[object_id] = font_styles;
                  global_original_fonts[object_id] = font_styles
                  // console.log('original text',original_text_styles)
                  dispatch({
                    type: SAVE_ORIGINAL_FONT_STYLES,
                    payload: original_text_styles,
                  });

                } else {
                  // console.log(global_original_fonts);
                  dispatch({
                    type: SAVE_ORIGINAL_FONT_STYLES,
                    payload: global_original_fonts,
                  });
                }
              }
            }


            // console.log('isImageReplaceable', object.get('isImageReplaceable'));
            if (object.get('isImageReplaceable') || object.get('isMaskable')) {
              // console.log('ehere setting', object.get('isImageReplaceable'));
              object.setControlsVisibility({
                tr: true,
                br: true,
                bl: true,
                ml: false,
                mt: false,
                mr: false,
                mb: false,
                mtr: true
              });
            }
          });
        });
      } catch (error) {
        dispatch({
          type: FONT_OBSERVER_LOADER,
          payload: false
        })
        console.log(error)
        CustomAlert.alert(true, 'Something went wrong, please try again!');
        reject(false);
      }

    })
  }
}

const processCanvasAfterLoadFromJSON = (dispatch, canvas, background_layer_id, clip_layer_id, template_active_side, canvasAlreadyLoaded, resolve = null) => {
  dispatch({
    type: FONT_OBSERVER_LOADER,
    payload: false
  })
  // canvas.requestRenderAll();
  // console.log('CANVAS:', obj);
   console.log('Canvas loaded',template_active_side);
  global_canvas = canvas;
  var isBorder = false;
  var designable_object;
  const myPromise = new Promise((resolve, reject) => {
    //  console.log('canvas', canvas);
    const objects = canvas.getObjects();
    
    for (var i = 0; i < objects.length; i++) {
      const object = objects[i];
      if (clip_layer_id !== '' && object.get('layer_id') == clip_layer_id) {
        console.log('objects clip_layer_id', clip_layer_id, object.get('layer_id'), background_layer_id);
        if (!canvasAlreadyLoaded) {
          canvas.bringForward(object);
        }
        canvas.clipPath = object;
        designable_object = object;
        object.selectable = true;
        // object.setCoords();
      } else {
        if (clip_layer_id == '' && object.get('layer_id') == background_layer_id) {
          console.log('BG LAYER FOUND', object);
          // canvas.bringBackward(object)                 
          canvas.clipPath = object;
          designable_object = object;
          object.selectable = true;
        }
        if (object.get('id') === 'border_1' || object.get('id') === 'border_2' || object.get('id') === 'border_3' || object.get('id') === 'border_4') {
          isBorder = true
        }
      }
    }
    // canvas.requestRenderAll()
    // canvas.setCoords();
    //  console.log('designable_object',designable_object);
    if (resolve !== null) resolve([designable_object, isBorder]);
  });


  myPromise.then((designable_object) => {
    //  dispatch({
    //    type: SAVE_ORIGINAL_FONT_STYLES,
    //    payload: original_text_styles,
    //  });              
    // Initiate a Rect instance       
    if (!canvasAlreadyLoaded || !designable_object[1]) {
      var bleed_spacing = 11;
      var boundingBox = designable_object[0].getBoundingRect();
      var top = boundingBox.top + bleed_spacing;
      var left = boundingBox.left + bleed_spacing;
      var width = boundingBox.width + left - (bleed_spacing * 2); // Additional 3 for dotted line stroke width
      var height = boundingBox.height + top - (bleed_spacing * 2);

      var stroke_width = 1;
      var stroke_color = 'green';
      // console.log(top, height);

      var dottedLines = [];

      var dottedLeft = new fabric.Line([left, top, left, height], {
        stroke: stroke_color,
        strokeDashArray: [15, 15],
        strokeWidth: stroke_width,
        selectable: false,
        id: 'border_1',
      });
      dottedLines.push(dottedLeft);
      canvas.add(dottedLeft);

      var dottedRight = new fabric.Line([width, top, width, height], {
        stroke: stroke_color,
        strokeDashArray: [15, 15],
        strokeWidth: stroke_width,
        selectable: false,
        id: 'border_2',
      });
      dottedLines.push(dottedRight);
      canvas.add(dottedRight);

      var dottedTop = new fabric.Line([left, top, width, top], {
        stroke: stroke_color,
        strokeDashArray: [15, 15],
        strokeWidth: stroke_width,
        selectable: false,
        id: 'border_3',
      });
      dottedLines.push(dottedTop);
      canvas.add(dottedTop);

      var dottedBottom = new fabric.Line([left, height, width, height], {
        stroke: stroke_color,
        strokeDashArray: [15, 15],
        strokeWidth: stroke_width,
        selectable: false,
        id: 'border_4',
      });
      dottedLines.push(dottedBottom);
      canvas.add(dottedBottom);
    }

    centerObjectToCanvas(canvas);

    const template_objects = canvas.getObjects();
    // console.log('template_objects',template_objects);
    biggest_object_side[template_active_side] = getBiggestObject(template_objects);
    dispatch({
      type: BIGGEST_OBJECT,
      payload: biggest_object_side
    })

    dispatch({
      type: SAVE_CANVAS_SVG,
      payload: template_sides_svg,
    });

    
    dispatch({
      type: INIT_CANVAS,
      payload: canvas
    })
    canvas.requestRenderAll();
    resolve([canvas, biggest_object_side[template_active_side]]);

    clientCanvasObjectModified(dispatch, canvas);
  });
}

export const getAllTemplateSideJSONs = (all_template_sides) => {
  return async dispatch => {
    return new Promise(function (resolve, reject) {
      console.log('all_template_sides', all_template_sides);
      const template_side_titles = Object.keys(all_template_sides);

      var json_template_sides = [];
      var outline_layer;
      for (let i = 0; i < template_side_titles.length; i++) {
        const side_title = template_side_titles[i];
        const template_side_details = all_template_sides[side_title];
        outline_layer = template_side_details.layers.outline;
        const json_link = template_side_details['canvas_json'];

        json_template_sides.push(getTemplateJSONFromServer(json_link, side_title));
      }

      var template_sides_json = {};
      var template_sides_image = {};
      Promise.all(json_template_sides).then(function (canvas_jsons) {
        for (let i = 0; i < canvas_jsons.length; i++) {
          const canvas_json = canvas_jsons[i];
          const side_name = Object.keys(canvas_json)[0];
          // template_sides_json.update(canvas_json)
          template_sides_json[side_name] = canvas_json[side_name];

          var temp_canvas = new fabric.Canvas(null);
          temp_canvas.loadFromJSON(canvas_json[side_name], function (obj) {
            const canvas_objects = temp_canvas.getObjects();
            const biggest_object = getBiggestObject(canvas_objects);
            for (let i = 0; i < canvas_objects.length; i++) {
              if (outline_layer) {
                if (canvas_objects[i].get("layer_id") == outline_layer) {
                  console.log('canvas_objects[i]', canvas_objects[i]);
                  temp_canvas.clipPath = canvas_objects[i];
                }
              }
            }
            console.log('biggest_object', biggest_object);
            const canvas_side_image = canvasToImage(temp_canvas, biggest_object)
            template_sides_image[side_name] = canvas_side_image;
          }, undefined);
        }
        dispatch({
          type: SAVE_TEMPLATE_SIDES_DICT,
          payload: template_sides_json,
        });

        dispatch({
          type: SAVE_CANVAS_SVG,
          payload: template_sides_image,
        });
        resolve([template_sides_json, template_sides_image]);
      });
    });
  }
}

const getTemplateJSONFromServer = (json_link, side_title) => {
  return new Promise((resolve, reject) => {
    axios({
      method: "post",
      url: json_link,
      headers: { "Content-Type": "multipart/form-data" },
    }).then((response) => {
      var canvas_side_json = {};
      canvas_side_json[side_title] = response.data;
      resolve(canvas_side_json);
    }).catch((error) => {
      reject(error);
    });
  })
}

export const convertImageToCMYK = (imageFilename, imageType, canvas, client_selected_layer) => async dispatch => {
  dispatch({
    type: TOGGLE_IMAGE_CONVERSION_LOADER,
    payload: true
  })
  const image = imageFilename.split('data:' + imageType + ';base64,')[1]
  const blob = new Blob([image])
  const file = new File([blob], `image.txt`)
  const formData = new FormData()
  formData.append('imageFilename', file);
  formData.append('imageFileType', imageType);
  try {

    const response = await axios({
      method: "POST",
      url: IMAGE_CONVERTER_API,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
    const change_image = response.data.image_data;
    console.log('response',response.data);

    importImageInCanvas(change_image, canvas, client_selected_layer, dispatch);
  }
  catch (e) {
    console.log('error in uploading change image', e);
    dispatch({
      type: TOGGLE_IMAGE_CONVERSION_LOADER,
      payload: false,
    });
  }

}

// export const importImageInCanvas = (image_base64, canvas, client_selected_layer) => async dispatch => {
export const importImageInCanvas = (image_base64, canvas, client_selected_layer, dispatch) => {
  try {
    canvas.controlsAboveOverlay = true

    // console.log('im',image_base64);
    fabric.Image.fromURL(image_base64, function (img) {
      img.set('isImageReplaceable', true);
      img.setControlsVisibility({
        tr: true,
        br: true,
        bl: true,
        ml: false,
        mt: false,
        mr: false,
        mb: false,
        mtr: true
      });

      var image_layer_level = 0;
      if (client_selected_layer) {
        const canvas_objects = canvas.getObjects();

        image_layer_level = canvas_objects.indexOf(client_selected_layer);

        // img.scaleToHeight(75);
        // img.scaleToWidth(50);
        const old_width = client_selected_layer.getScaledWidth();
        const old_height = client_selected_layer.getScaledHeight();

        const new_width = img.getScaledWidth();
        const new_height = img.getScaledHeight();

        // console.log('Old, new', client_selected_layer.getBoundingRect().top, client_selected_layer.getBoundingRect().left);
        const widthFactor = old_width / new_width;
        const heightFactor = old_height / new_height;
        const minFactor = Math.min(widthFactor, heightFactor)
        img.scale(minFactor)

        img.set('top', client_selected_layer.top);
        img.set('left', client_selected_layer.left);

        const lockMovementX = client_selected_layer.lockMovementX;
        const lockScalingFlip = client_selected_layer.lockScalingFlip;
        const hasControls = client_selected_layer.hasControls;

        const isRotatable = client_selected_layer.get('isRotatable');
        const isDeleteable = client_selected_layer.get('isDeleteable');
        const isFontStyleChangeable = client_selected_layer.get('isFontStyleChangeable');
        const isFontColorChangeable = client_selected_layer.get('isFontColorChangeable');
        const isBGColorChangable = client_selected_layer.get('isBGColorChangable');
        const isMaskable = client_selected_layer.get('isMaskable');
        img.set('isDeleteable', isDeleteable);
        img.set('isRotatable', isRotatable);
        img.set('isFontStyleChangeable', isFontStyleChangeable);
        img.set('isFontColorChangeable', isFontColorChangeable);
        img.set('isBGColorChangable', isBGColorChangable); // Permission config pending
        img.set('isMaskable', isMaskable)
        img.hasControls = hasControls;
        img.lockMovementX = lockMovementX;
        img.lockMovementY = lockMovementX;

        // console.log('clipLayer:', client_selected_layer.clipLayer);
        if (client_selected_layer.get('isMaskable')) {
          img.hasControls = true
          img.lockMovementX = false
          img.lockMovementY = false
          img.set('clipLayer', client_selected_layer)
          client_selected_layer.set('absolutePositioned', true);
          img.clipPath = client_selected_layer;
          img.set("isMaskable", false)

        }

        if (client_selected_layer.clipLayer) {
          client_selected_layer.clipLayer.set('absolutePositioned', true);
          img.set('clipLayer', client_selected_layer.clipLayer)
          img.clipPath = client_selected_layer.clipLayer
        }
        if (client_selected_layer.clipPath) {
          client_selected_layer.clipPath.set('absolutePositioned', true);
          img.set('clipLayer', client_selected_layer.clipPath)
          img.clipPath = client_selected_layer.clipPath
        }
        const patternSourceCanvas = new fabric.StaticCanvas();
        patternSourceCanvas.add(img);
        patternSourceCanvas.renderAll();
        var pattern = new fabric.Pattern({
          source: patternSourceCanvas.getElement(),
          repeat: 'repeat',
        });
        // img.moveTo(image_layer_level);
        client_selected_layer.fill = pattern
        console.log('client',client_selected_layer);
        canvas.remove(client_selected_layer);
        // console.log('NEW IMG OBJECT:', client_selected_layer.getBoundingRect().top);
        dispatch({
          type: SHOW_IMAGE_PICKER_MODAL,
          payload: false,
        });
      } else {
        // Add new Image Code
        var objs = canvas.getObjects();
        
        const biggestObject = getBiggestObject(objs);
        const imageFromTop = biggestObject.top + 80
        const imageFromLeft = biggestObject.left + 80;
        img.set('top', imageFromTop);
        img.set('isDeleteable', true);
        img.set('left', imageFromLeft)

        // const biggestObjectSize = img.width / biggestObject.width
        const imageheight = img.get('height') > biggestObject.height ? 0.5 * biggestObject.height : img.get('height')
        const imagewidth = img.get('width') > biggestObject.width ? 0.5 * biggestObject.width : img.get('width')
        // const imageBigsizee = biggestObject.width / img.width
        // const scaling = biggestObject.width > imageBigsizee ? imageBigsizee : biggestObjectSize
        img.scaleToHeight(imageheight)
        img.scaleToWidth(imagewidth)
        img.setCoords()

        // console.log('no client selected layer', scaling);
        dispatch({
          type: ADD_IMAGE_PICKER_MODAL,
          payload: false
        })
        canvas.centerObject(img)
      }
      // img.center()

      canvas.setActiveObject(img)
      // patternSourceCanvas.add(img);
      // patternSourceCanvas.renderAll();
      // var pattern = new fabric.Pattern({
      //   source: patternSourceCanvas.getElement(),
      //   repeat: 'repeat',
      // });
      canvas.add(img)
      if (client_selected_layer) {
        img.moveTo(image_layer_level);
      }

      canvas.requestRenderAll();
      // console.log('IMG:', img);
      // console.log('Canvas objects hierarchy:', canvas.getObjects());
      dispatch({
        type: INIT_CANVAS,
        payload: canvas
      })
    });
    dispatch({
      type: TOGGLE_IMAGE_CONVERSION_LOADER,
      payload: false,
    });

    clientCanvasObjectModified(dispatch, canvas);

  } catch (e) {
    dispatch({
      type: ADD_IMAGE_PICKER_MODAL,
      payload: false
    })
    console.log('error in importing image ', e)
  }
}


function uploadingProgress(progressEvent, navigate, navigateTo, dispatch, loaderType) {
  var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
  dispatch({
    type: loaderType,
    payload: true
  })
  dispatch({
    type: UPLOAD_PERCENTAGE,
    payload: percentCompleted
  })
  if (percentCompleted === 100) {
    dispatch({
      type: UPLOAD_PERCENTAGE,
      payload: ''
    })
    if (navigate && navigateTo) {
      navigate(`/${navigateTo}`)
    }
  }
}

export const checkJsonIsEmpty = (template_sides_canvas_json) => {
  const allobjectThere = []
  for (const [key, value] of Object.entries(template_sides_canvas_json)) {
    if (value) {
      allobjectThere.push(true)
    } else {
      allobjectThere.push(false)
    }
    // console.log('it is empty stop',value);
  }
  return allobjectThere
  // return Object.keys(template_sides_canvas_json).length===0
}

export const save_svg = (all_side_svg, template_id, canvas_json, client_name,modalClosing, printer_server_identifier = 0, navigate = null) => async dispatch => {
  try {
    dispatch({
      type: SAVED_TEMPLATE_LOADER,
      payload: true
    })
    // console.log('svg canvas', all_side_svg,template_id,client_name,canvas_json)
    const formData = new FormData();
    for (const [key, value] of Object.entries(all_side_svg)) {

      // console.log('value',value.svgName);
      const blob = new Blob([value.svgObject])
      const file = new File([blob], `${value.svgName}.svg`, { type: 'image/svg+xml' })
      formData.append(encodeURI(value.svgName), file)
    }
    const blob = new Blob([JSON.stringify(canvas_json)])
    const file = new File([blob], 'filename.txt')
    formData.append('json_file', file);
    formData.append('main_template_id', template_id);
    formData.append('client_name', client_name)

    const response = await axios({
      url: SAVE_TEMPLATE_API,
      method: 'post',
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
      onUploadProgress: function (progressEvent) {

        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

        dispatch({
          type: UPLOAD_PERCENTAGE,
          payload: percentCompleted
        })
        if (percentCompleted === 100) {
          dispatch({
            type:UPLOAD_PERCENTAGE,
            payload: ''
          })
        }
        console.log('percent',percentCompleted);
      }
    })
    console.log('response in saving ', response.data)
    if (response.status === 200) {
      // navigate('/client_template')
      if (response.data.status) {
        dispatch({
          type: SAVED_TEMPLATE_LOADER,
          payload: false
        })    
        modalClosing(false)    
        if (navigate) {
          console.log('Navigate admin')
          navigate('/client_template')
        } else {
          console.log('Navigate to flexiprint');
          const template_id_length = template_id.length;
          console.log('template_id_length',template_id_length);
      
          const response_printer_server_identifier = printer_server_identifier.slice(
            0,
            printer_server_identifier.length - template_id_length
          ) + response.data.main_output_id;

          // const response_user_id = printer_server_identifier.slice(
          //   1,
          //   printer_server_identifier.length - (template_id_length + 3) //+ 3 is for the repeat order and die identifier 3 characters
          // )

          const response_cart_id = printer_server_identifier.slice(
            1,
            printer_server_identifier.length - (template_id_length + 3) //+ 3 is for the repeat order and die identifier 3 characters
          )

          console.log('ADD_TEMPLATE_TO_CART',ADD_TEMPLATE_TO_CART);
          // window.location.replace("https://w2pnx.flexiweb2print.com/flexiprint/api/saveoutputid/" + response_printer_server_identifier);
          console.log('printer_server_identifier after removing template id:', response_printer_server_identifier);
          window.location.replace(ADD_TEMPLATE_TO_CART + "/" + response_cart_id + "/" + response.data.main_output_id);
      
        }
        dispatch({
          type: SAVE_TOGGLE_TEMPLATE_LIST_LOADER,
          payload: true
        })
        // dispatch(savedTemplateList())
        // console.log(response.data.message)
      } else {
        // navigate(-1)
        dispatch({
          type: SAVED_TEMPLATE_LOADER,
          payload: false
        })
        CustomAlert.alert(false, response.data.message);
      }
    } else {
      dispatch({
        type: SAVED_TEMPLATE_LOADER,
        payload: false
      })
      CustomAlert.alert(true, 'Something went wrong, please try again!');
    }

  } catch (e) {
    console.log('error in saving svg', e)
    dispatch({
      type: SAVED_TEMPLATE_LOADER,
      payload: false
    })
    CustomAlert.alert(true, 'Something went wrong, please try again!');
  }
}

export const canvasToJSON = (canvas) => {
  return canvas.toJSON([
    'id',
    'lockMovementX',
    'lockMovementY',
    'hasControls',
    'isDepthChange',
    'isDeleteable',
    'lockScalingFlip',
    'isRotatable',
    'layer_id',
    'selectable',
    'designable_area',
    'background_area',
    'outline_area',
    'type',
    'isImageReplaceable',
    'isFontStyleChangeable',
    'isFontColorChangeable',
    'isBGColorChangable',
    "isMaskable",
    "isRoundedMask",
  ]);
}

export const saveSvgDraft = (canvasJsonFile, template_id, client_name, nameOfFile, navigate) => async dispatch => {
  try {
    dispatch({
      type: TOGGLE_TEMPLATE_LIST_LOADER,
      payload: true
    })
    const formData = new FormData();

    // console.log('client', decodeURI(nameOfFile['FRONT']));
    // const blob = new Blob([JSON.stringify(canvasJsonFile)])
    const blob = new Blob([JSON.stringify(canvasJsonFile)])
    const file = new File([blob], 'filename.txt')
    formData.append('json_file', file);

    formData.append('all_sides_name', JSON.stringify(nameOfFile))

    formData.append('client_name', client_name);

    formData.append('template_id', template_id)

    const response = await axios({
      url: CLIENT_DRAFT_API,
      method: 'post',
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
      onUploadProgress: function (progressEvent) {
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        dispatch({
          type: UPLOAD_PERCENTAGE,
          payload: percentCompleted
        })
        // console.log('here', percentCompleted);

        if (percentCompleted === 100) {
          dispatch({
            type: UPLOAD_PERCENTAGE,
            payload: ''
          })
        }
       
        // uploadingProgress(progressEvent,null,null,dispatch,TOGGLE_TEMPLATE_LIST_LOADER)
      },
    })

    if (response.status === 200) {
      if (response.data.status) {
        // console.log('response from draft',response.data)
        navigate('/client_draft')
      } else {
        // console.log('response from draft', response.data)
        CustomAlert.alert(false, response.data.message)
      }
    } else {
      console.log('somethinhg went wrong ', response.data);
    }
  } catch (e) {
    console.log('error in saving in draft', e);
  }
}

export const getClientDraft = () => async dispatch => {
  try {
    const response = await axios({
      url: SAVED_DESIGN_TEMPLATE_LIST_DRAFT_API + '?dt=' + Date.now(),
      method: 'GET',
    })
    if (response.data) {
      // console.log('response in draft', response.data)
      dispatch({
        type: TOGGLE_TEMPLATE_LIST_LOADER,
        payload: false
      })
      dispatch({
        type: SAVE_TEMPLATE_LIST_DRAFT,
        payload: response.data
      })

    } else {
      dispatch({
        type: TOGGLE_TEMPLATE_LIST_LOADER,
        payload: false,
      });
      console.log('error', response.data)
    }
  } catch (e) {
    dispatch({
      type: TOGGLE_TEMPLATE_LIST_LOADER,
      payload: false,
    });
    console.log('error in getting draft', e);
  }
}

export const getListOfSaveRepeatedOrders = () => async dispatch => {
  dispatch({
    type: TOGGLE_TEMPLATE_LIST_LOADER,
    payload: true,
  });
  try {
    const response = await axios({
      url: GET_REPEATED_ORDERS_LIST + '?dt=' + Date.now(),
      method: 'GET',
    })

    // console.log('response', response.data);
    const response_data = response.data;
    dispatch({
      type: REPEATED_ORDER_LIST,
      payload: response_data
    })
    dispatch({
      type: TOGGLE_TEMPLATE_LIST_LOADER,
      payload: false,
    });
  } catch (e) {
    CustomAlert.alert(true, 'Something went Wrong!')
    console.log('error', e);
    dispatch({
      type: TOGGLE_TEMPLATE_LIST_LOADER,
      payload: false,
    });
  }

}

export const get3DModelList = () => async dispatch => {
  dispatch({
    type: TOGGLE_3D_MODEL_LOADER,
    payload: true,
  });
  try {
    const response = await axios({
      url: GET_3D_MODEL_LIST + '?dt=' + Date.now(),
      method: 'GET',
    })

    console.log('response', response.data);
    const response_data = response.data;
    dispatch({
      type: SAVE_3D_MODEL_LIST,
      payload: response_data['3d_models'],
    })
    dispatch({
      type: TOGGLE_3D_MODEL_LOADER,
      payload: false,
    });
  } catch (e) {
    CustomAlert.alert(true, 'Something went Wrong!')
    console.log('error', e);
    dispatch({
      type: TOGGLE_3D_MODEL_LOADER,
      payload: false,
    });
  }

}

export const toggleConfig3dModelModal = (show) => {
  if (show !== 0) {
    document.body.classList.add('modal-open')
    document.body.style.paddingRight = '17px'
  } else {
    document.body.classList.remove('modal-open')
    document.body.style.paddingRight = '0'
  }
  return dispatch => {
    dispatch({
      type: TOGGLE_CONFIG_3D_MODEL_MODAL,
      payload: show,
    });
  }
}

export const onChange3DModelName = (model_name) => {

  return dispatch => {
    dispatch({
      type: ON_CHANGE_3D_MODEL_NAME,
      payload: model_name,
    });
  }
}

export const showHide3dModelModal = (show) => {
  return dispatch => {
    dispatch({
      type: TOGGLE_3D_MODEL_MODAL,
      payload: show,
    });
  }
}

export const upload3DModel = (model_name, model, model_list) => {
  return async dispatch => {
    try {
      const formData = new FormData();
      formData.append("3d_model_file", model);
      formData.append("3d_model_name", model_name);

      const response = await axios({
        method: 'post',
        url: SAVE_3D_MODEL,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: function (progressEvent) {
          // uploadingProgress(progressEvent,null,null,dispatch,TOGGLE_UPLOAD_LOADER)
          var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          dispatch({
            type: UPLOAD_PERCENTAGE,
            payload: percentCompleted
          })
        },
      })
      console.log('response data', response.data)
      if (response.status === 200) {
        const model_details = response.data;
        if (model_details.status == true) {

          model_list.push({
            'id': model_details.id,
            '3d_model': model_details['3d_model'],
            '3d_model_name': model_name,
          });

          dispatch({
            type: SAVE_3D_MODEL_LIST,
            payload: [],
          })

          dispatch({
            type: SAVE_3D_MODEL_LIST,
            payload: model_list,
          })

          dispatch({
            type: TOGGLE_3D_MODEL_MODAL,
            payload: false,
          });

          dispatch({
            type: UPLOAD_PERCENTAGE,
            payload: ''
          })

          dispatch({
            type: ON_CHANGE_3D_MODEL_NAME,
            payload: ''
          })
        } else {
          dispatch({
            type: TOGGLE_3D_MODEL_MODAL,
            payload: false,
          });
          dispatch({
            type: UPLOAD_PERCENTAGE,
            payload: ''
          })
          CustomAlert.alert(true, model_details.message);
        }
      }
    } catch (e) {
      console.log('error in output showing', e)
      CustomAlert.alert(true, 'Something went wrong, please try again!');
      dispatch({
        type: TOGGLE_3D_MODEL_MODAL,
        payload: false,
      });
      dispatch({
        type: UPLOAD_PERCENTAGE,
        payload: ''
      })
    }
  }
}

export const update3DModelCamera = (model_3d_id, cameraX, cameraY, cameraZ, model_3d_list) => {
  return async dispatch => {
    try {
      const formData = new FormData();
      cameraX = cameraX == 0 ? 1 : cameraX;
      cameraY = cameraY == 0 ? 1 : cameraY;
      cameraZ = cameraZ == 0 ? 1 : cameraZ;
      console.log('Sending camera', cameraX, cameraY, cameraZ);
      formData.append("cameraX", cameraX);
      formData.append("cameraY", cameraY);
      formData.append("cameraZ", cameraZ);
      formData.append("3d_model_id", model_3d_id);

      const response = await axios({
        method: 'post',
        url: UPDATE_3D_MODEL_CAMERA_API,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
      console.log('response data', response.data)
      if (response.status === 200) {
        const status = response.data.status;
        const message = response.data.message;
        if (status == true) {
          // Update camera values in local list
          var model_details = model_3d_list.find(item => item.id === model_3d_id);
          var model_index = model_3d_list.findIndex(item => item.id === model_3d_id);
          model_details.cameraX = cameraX;
          model_details.cameraY = cameraY;
          model_details.cameraZ = cameraZ;

          model_3d_list[model_index] = model_details;

          dispatch({
            type: SAVE_3D_MODEL_LIST,
            payload: [],
          })

          dispatch({
            type: SAVE_3D_MODEL_LIST,
            payload: model_3d_list,
          })

          CustomAlert.alert(false, message);
        } else {
          console.log('Here in else');
          CustomAlert.alert(true, message);
        }

        dispatch({
          type: TOGGLE_CONFIG_3D_MODEL_MODAL,
          payload: 0,
        });
      }
    } catch (e) {
      console.log('error in output showing', e)
      CustomAlert.alert(true, 'Something went wrong, please try again!');
      dispatch({
        type: TOGGLE_CONFIG_3D_MODEL_MODAL,
        payload: 0,
      });
    }
  }
}

export const update3DModelMaterialOrder = (model_3d_id, final_material_order, model_3d_list) => {
  return async dispatch => {
    try {
      const formData = new FormData();
      formData.append("model_material_order", JSON.stringify(final_material_order));
      formData.append("3d_model_id", model_3d_id);

      const response = await axios({
        method: 'post',
        url: UPDATE_3D_MODEL_MATERIAL_ORDER_API,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
      console.log('response data', response.data)
      if (response.status === 200) {
        const status = response.data.status;
        const message = response.data.message;
        if (status == true) {
          // Update material values in local list


          // model_3d_list[model_index] = model_details;

          // dispatch({
          //   type: SAVE_3D_MODEL_LIST,
          //   payload: [],
          // }) 

          // dispatch({
          //   type: SAVE_3D_MODEL_LIST,
          //   payload: model_3d_list,
          // })

          CustomAlert.alert(false, message);
        } else {
          CustomAlert.alert(true, message);
        }

        dispatch({
          type: TOGGLE_CONFIG_MATERIAL_3D_MODEL_MODAL,
          payload: 0,
        });
      }
    } catch (e) {
      console.log('error in output showing', e)
      CustomAlert.alert(true, 'Something went wrong, please try again!');
      dispatch({
        type: TOGGLE_CONFIG_MATERIAL_3D_MODEL_MODAL,
        payload: 0,
      });
    }
  }
}

export const toggleConfigMaterial3DModelModal = (show) => {
  if (show !== 0) {
    document.body.classList.add('modal-open')
    document.body.style.paddingRight = '17px'
  } else {
    document.body.classList.remove('modal-open')
    document.body.style.paddingRight = '0'
  }
  return dispatch => {
    dispatch({
      type: TOGGLE_CONFIG_MATERIAL_3D_MODEL_MODAL,
      payload: show,
    });
  }
}

export const savedTemplateList = () => async dispatch => {
  dispatch({
    type: SAVE_TOGGLE_TEMPLATE_LIST_LOADER,
    payload: true,
  });
  try {
    const response = await axios({
      method: 'get',
      url: SAVED_DESIGN_TEMPLATE_LIST_API + '?dt=' + Date.now()
    })
    
    console.log('response data', response.data)
    if (response.status === 200) {
      dispatch({
        type: SAVED_TEMPLATE_LIST,
        payload: response.data
      })
    }
    dispatch({
      type: SAVE_TOGGLE_TEMPLATE_LIST_LOADER,
      payload: false,
    });

    // console.log('response ', response.data);
  } catch (e) {
    dispatch({
      type: SAVE_TOGGLE_TEMPLATE_LIST_LOADER,
      payload: false,
    });
    console.log('error in output showing', e)
  }
}

export const selectedLayer = (layer, dispatch, canvas) => {

  // console.log('leyr',layer.get('isImageReplaceable'));
  dispatch({
    type: OBJECT_SELECTED,
    payload: layer,
  })
}


export const addImagePickerModal = (show) => async dispatch => {
  dispatch({
    type: ADD_IMAGE_PICKER_MODAL,
    payload: show
  })
}

export const showImagePickerModal = (show) => {
  return dispatch => {
    dispatch({
      type: SHOW_IMAGE_PICKER_MODAL,
      payload: show,
    });
  }
}

export const createDigitalProof = (output_svg) => {
  return async dispatch => {

    const formData = new FormData();
    formData.append("output_svg_id", output_svg);
    dispatch({
      type: TOGGLE_DIGITAL_PROOF_LOADER,
      payload: true,
    });

    try {
      const response = await axios({
        method: "post",
        url: CREATE_DIGITAL_PROOF_API,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });

      const response_data = response.data.image_data;
      console.log('Response in digital proof:', response.data);

      if (response.status === 200) {
        dispatch({
          type: TOGGLE_DIGITAL_PROOF_LOADER,
          payload: false,
        });

        dispatch({
          type: DISPLAY_DIGITAL_PROOF_IMAGE,
          payload: response_data,
        });
        // dispatch({
        //   type: DISPLAY_DIGITAL_PROOF_IMAGE,
        //   payload: [],
        // });
      } else {
        CustomAlert.alert(true, 'Something went wrong, please try again!');
      }
    } catch (error) {
      dispatch({
        type: TOGGLE_DIGITAL_PROOF_LOADER,
        payload: false,
      });
      // console.log(error)
      CustomAlert.alert(true, 'Something went wrong, please try again!');
    }
  }
}

export const clearDigitalProofImage = () => {
  return ({
    type: DISPLAY_DIGITAL_PROOF_IMAGE,
    payload: [],
  });
}

export const savingClientName = (name) => async dispatch => {
  dispatch({
    type: CLIENT_NAME,
    payload: name
  })
}


export const updatingTemplateForm = (edited_data, navigate) => async dispatch => {
  dispatch({
    type: UPDATE_TEMPLATE_INFO_LOADER,
    payload: true
  })

  try {
    const response = await axios({
      method: 'POST',
      url: EDIT_TEMPLATE_API,
      data: edited_data,
      headers: { "Content-Type": "multipart/form-data" },
    })
    console.log('response', response.data);
    if (response.status === 200) {
      if (response.data.status) {
        CustomAlert.alert(false, response.data.message)
        navigate('/');
        dispatch({
          type: UPDATE_TEMPLATE_INFO_LOADER,
          payload: false
        })
      } else {
        CustomAlert.alert(true, response.data.message)
        dispatch({
          type: UPDATE_TEMPLATE_INFO_LOADER,
          payload: false
        })
      }
    }
  } catch (e) {
    dispatch({
      type: UPDATE_TEMPLATE_INFO_LOADER,
      payload: false
    })
    CustomAlert.alert(true, 'Something Went Wrong');
  }

}


export const getFontsList = () => async dispatch => {
  dispatch({
    type: GETTING_FONT_LOADER,
    payload: true
  })
  try {
    const response = await axios({
      url: FONTS_LIST + '?dt=' + Date.now(),
      method: 'get',
    })

    if (response.status === 200) {
      const fonts_key = Object.keys(response.data)
      const response_data = response.data
      dispatch({
        type: GET_FONT_KEY,
        payload: fonts_key
      })
      dispatch({
        type: GETTING_FONT_LOADER,
        payload: false
      })
      dispatch({
        type: GET_FONT,
        payload: response_data
      })

      // console.log('response',response.data);
    }
  } catch (e) {
    dispatch({
      type: GETTING_FONT_LOADER,
      payload: false
    })
    console.log('errror in getting fonts', e);
  }
}

export const searchingTemplate = (search_value) => async dispatch => {
  dispatch({
    type:SEARCH_FILTERING_TEMPLATE,
    payload:search_value
  })
}

export const removeSearch = () => async dispatch =>{
  dispatch({
    type:SEARCH_FILTERING_TEMPLATE,
    payload:''
  })
}

// export const filteringTemplate = (original_template_list,search) => async dispatch => {

// }

export const onDesignNameChanged = (design_name) => {
  return dispatch => {
    dispatch({
      type: ON_CHANGE_TEMPLATE_NAME,
      payload: design_name,
    });
  }
}

export const openCloseModel =(type,value,setValue)=>{
  if (!value) {
    document.body.classList.add("modal-open");
    document.body.style.paddingRight = "17px";
  } else {
    document.body.classList.remove("modal-open");
    document.body.style.paddingRight = "0";
  }
  setValue(type);
}


export const sendingChatgptMessage = (formdata,previousMessage,setMessage,setApiMessage) => async dispatch => {
  dispatch({
    type: TOGGLE_TEMPLATE_LIST_LOADER,
    payload: true,
  });
  try{
    const response = await axios({
      method: 'POST',
      url: CHAT_GPT_API,
      data: formdata,
      headers: { "Content-Type": "multipart/form-data" },
    })
    const response_data = response.data;
    console.log('response', response.data);
    if (response.status === 200) {
      setApiMessage(response_data.message);
      if(response_data.status){
        // setApiMessage(response_data.message);
        // CustomAlert.alert(false,response_data.message);
        // const array_key = response_data[0].key; 
        const new_data = {...previousMessage};
        new_data[formdata['printer_id']]['description'] = response_data.result
        setMessage({...new_data});
      }else{
        // CustomAlert.alert(true,response_data.message)
      }
      dispatch({
        type: TOGGLE_TEMPLATE_LIST_LOADER,
        payload: false,
      });
      
    }
  }catch(e){
    dispatch({
      type: TOGGLE_TEMPLATE_LIST_LOADER,
      payload: false,
    });
    CustomAlert.alert('Something went wrong');
    console.log('errror',e);

  }
}