import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FontName from 'fontname';
import { useNavigate } from "react-router-dom";
import Dots from "react-activity/dist/Dots";
import { uploadFonts } from '../Actions';
import { Button, HeadingTitle, HeadPart, ModalLoader, PercentageLoader, TableBody } from '../Components';

function UploadFontsScreen(props) {

  // console.log('In fonts');
  const reduxState = useSelector(state => state.template);
  const { missing_fonts_list, type_of_svg, fontUploadLoader, template_id, upload_percentage } = reduxState;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [fonts, setFonts] = useState([]);
  const [fontWeight, setFontWeight] = useState('');
  const [aiFontName, setAIFontName] = useState('');
  const [coralFontName, setCoralFontName] = useState('');
  const [fontFamily, setFontFamily] = useState('');
  const [italic, setItalic] = useState(0);

  useEffect(() => {
    // if (!template_id || !missing_fonts_list) navigate('/upload_template')
    // Update the document title using the browser API
    document.title = `Upload Font`;
  });

  function handleFontFileSelected(event, font_name, index) {
    const e = event;
    const fontFile = e.target.files[0]; // File
    const file_name_array = e.target.value.split('.');
    const file_type = file_name_array[file_name_array.length - 1].toLowerCase();
    if (file_type === 'ttf') {
      const reader = new FileReader();
      reader.onload = e => {
        const buffer = e.target.result; // ArrayBuffer
        try {
          const fontMeta = FontName.parse(e.target.result)[0];
          console.log('fontMeta', fontMeta);
          const metaFontFullName = fontMeta.fullName;
          const metaPostScriptName = fontMeta.postScriptName;
          // console.log(metaFontFullName, metaPostScriptName);
          const metaSubFamily = ((fontMeta.typoSubfamilyName) ? fontMeta.typoSubfamilyName.toLowerCase() : fontMeta.fontSubfamily.toLowerCase());
          console.log('metaSubFamily', metaSubFamily);
          setFontWeight(metaSubFamily);
          setAIFontName(metaPostScriptName);
          setCoralFontName(metaFontFullName);
          setFontFamily(fontMeta.fontFamily);
          var fontFullName
          const italicFont = metaFontFullName.toLowerCase().includes('italic');
          // if(metaFontFullName.toLowerCase().includes('regular')){
          //   fontFullName = metaFontFullName.split(' ')[0]
          // }else{
          //   fontFullName = metaFontFullName
          // }

          console.log(fontMeta, font_name, metaFontFullName);
          if (metaFontFullName === font_name || metaPostScriptName === font_name) {
            const new_fonts = fonts;

            new_fonts[index] = {
              'name': font_name,
              'template_id': template_id,
              file: event.target.files[0],
              'fontWeight': metaSubFamily,
              'aiFontName': metaPostScriptName,
              'coralFontName': metaFontFullName,
              'fontFamily': fontMeta.fontFamily,
              'italic': italicFont ? 1 : 0
            }
            setFonts(new_fonts);
          } else {
            event.target.value = null;
            e.target.value = null;
            // const new_fonts = fonts;
            // new_fonts.pop(index);
            // setFonts(new_fonts);
            alert('The font file you selected has font name ' + metaFontFullName + '/' + metaPostScriptName + ' we need ' + font_name);
          }
        } catch (e) {
          // FontName may throw an Error
          event.target.value = null;
          // const new_fonts = fonts;
          // new_fonts.pop(index);
          // setFonts(new_fonts);
          console.log('FontName error:', e);
          alert('Font file seems to be corrupt, please upload another file or contact the admin for help!');
        }
      };
      reader.readAsArrayBuffer(fontFile);
      // const new_fonts = fonts;

      // new_fonts[index] = {'name': font_name,'template_id':template_id, file: event.target.files[0]}
      // setFonts(new_fonts);
      console.log('Fonts:', fonts);
    } else {
      event.target.value = null;
      alert('We only accept TTF Font files, support for OTF coming soon!');
    }
  }

  function handleSubmit(event) {
    var upload_fonts = fonts.filter(function (el) {
      return el != null;
    });
    if (missing_fonts_list.length == upload_fonts.length) {
      console.log('Proceed', fonts);
      dispatch(uploadFonts(event, fonts, type_of_svg, navigate));
    } else {
      alert('Please upload all fonts to proceed');
    }
    event.preventDefault();
  }

  function singleFontUpload(font_name, index) {
    console.log('index',index);
    return (
      <>
        <TableBody key={index} tableName={`${font_name}`} inputType={'files'} onChangefun={(e) => handleFontFileSelected(e, font_name, index)} />
      </>
    )
  }

  function renderFontUploads(missing_fonts_list) {
    if (fontUploadLoader) {
      return (
        <>
        <ModalLoader loadingTitle={'Font Uploding'} openModal={fontUploadLoader} >
            <PercentageLoader percentage={upload_percentage} />
        </ModalLoader>
          {/* <Dots /> */}
        </>
      );
    } else {
      return (
        <>
        <form onSubmit={handleSubmit} encType="multipart/form-data">
          <div id="table2" className="table-outer table-cards manage-shipper manage-editor-wrap">
            <table width="" cellSpacing="1">
              <thead>
                <tr>
                  <th width="">Upload required Template Font</th>
                  <th width="" data-title="Action">Action</th>
                </tr>
              </thead>

              <tbody>

                {missing_fonts_list && missing_fonts_list.map((missing_font, index) => (
                  singleFontUpload(missing_font[0], index)
                ))}

              </tbody>
            </table>
          </div>
          <input type="submit" className='table-action' value="Upload Fonts" />
        </form>
        </>
      )
    }
  }

  return (
    <>
      <HeadPart>
        <HeadingTitle title={'Add Fonts'} />
        {renderFontUploads(missing_fonts_list)}
      </HeadPart>
    </>
  );
}

export { UploadFontsScreen };
