import React from 'react'

function PercentageLoader({percentage}) {
    return (
        <div className="progress-bar">
            <div className="bar text-center" data-size="100" style={{width:`${percentage ? percentage :100}%`}}>
                <span className="perc">{percentage?percentage :'100' }%</span>
            </div>
        </div>
    )
}

export { PercentageLoader }