import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  getJewelleryProductList,
  saveBrandName,
  getTemplateList,
} from "../../Actions";
import {
    Button,
    HeadingTitle,
    Modal,
    UploadTemplateForm,
    Pagination,
    HeadPart,
    CustomAlert,
    Loader,
    ModalLoader,
    RenderRowsAndColumn,
    Checkbox,
    TemplateCard
  } from "../../Components";

  import { JewelleryProductCard } from '../../Components/JewelleryPitch';

function ProductOptionsScreen() {
    const {brand} = useParams();
    const navigate = useNavigate();
    const reduxState = useSelector((state) => state.jewellery_pitch);
    const {
        jewellery_product_list,
        jewellery_product_list_loader,
    } = reduxState;

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getJewelleryProductList());
        dispatch(saveBrandName(brand));
        // console.log('From redux:',jewellery_product_list, jewellery_product_list_loader);
    }, [dispatch])

    function createJewelleryProductListTable() {
        return (
          <>
            {/* Here head part start */}
            <HeadPart>
              <div className="nk-block-head full-hd-wrap">
                <HeadingTitle title={"Jewellery Product List"} />
              </div>
              <div className="inside-bg-wrap">
                <div className="manage-template-wrap">
                  <div className="catergory-listing">
                    <div className="catergory-row">
                      {jewellery_product_list.length > 0 ? (
                        jewellery_product_list.map((jewellery_product, index) => {
                          // console.log('jewellery_product',jewellery_product)    
                          return (
                            <JewelleryProductCard 
                                key={jewellery_product.id}
                                product_name={jewellery_product.name}
                                product_image={null}
                                OnClickViewButton={() => {
                                  const controller = new AbortController()
                                  // dispatch(
                                  //   getTemplateList(
                                  //     null,
                                  //     1,
                                  //     15,
                                  //     0,
                                  //     [],
                                  //     [],
                                  //     jewellery_product.id,
                                  //     '0',
                                  //     '',
                                  //     '',
                                  //     null,
                                  //     controller
                                  //   )
                                  // );

                                  navigate('/'+brand+'/template_options', {
                                    state: {
                                      'product_tags': jewellery_product.id,
                                    }
                                  });
                                }}
                            />
                          )
    
                          // return (
                          //   <JewelleryProductCard 
                          //       key={index}
                          //       // product_name={jewellery_product.name}
                          //       // product_image={jewellery_product.product_image}
                          //       // OnClickViewButton={() => console.log('Clicked')}
                          //   />
                          // );
                        })
                      ) : (
                        <p>No Product found</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </HeadPart>
            {/* Here head part ends  */}
          </>
        );
      }

    function renderLoader() {
        console.log('jewellery_product_list_loader',jewellery_product_list_loader);
        if (jewellery_product_list_loader) {
            return (
            <HeadPart>
                <ModalLoader
                loadingTitle={"Loading Products List"}
                openModal={jewellery_product_list_loader}
                />
            </HeadPart>
            );
        } else {
        //   return <div>{createTemplateListTable()}</div>;
            return createJewelleryProductListTable();
        }
    }

    return renderLoader();
}

export {ProductOptionsScreen};