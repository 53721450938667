import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  
} from "../../Actions";
import { changeProductWrap, render3DModel } from "../../extras/3DProductWrappingCommonFunctions";
import {
    HeadPart,
    HeadingTitle,
    ModalLoader,
    Button,
} from "../../Components";

import {PRODUCT_3D_MODEL_WRAPPING} from '../../extras/APIs';

function ImageTextureToShoeScreen() {
    const navigate = useNavigate();
    const reduxState = useSelector((state) => state.jewellery_pitch);
    const {} = reduxState;

    const [model_3d, set3dModel] = useState(null);
    const [model_ready, setModelReady] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        // const cameraX = 48.0863689962824;
        // const cameraY = 111.71306557254681;
        // const cameraZ = 1254.8830269299882;

        const cameraX = -610;
        const cameraY = 380;
        const cameraZ = 427;
        console.log('data',PRODUCT_3D_MODEL_WRAPPING + "ShoeV1/Shoes-withoutTex.glb");
        render3DModel(PRODUCT_3D_MODEL_WRAPPING + "ShoeV1/Shoes-withoutTex.glb", cameraX, cameraY, cameraZ, set3dModel, setModelReady);
        // render3DModel(PRODUCT_3D_MODEL_WRAPPING + "ShoeV1/Shoes-withTex-NEWNEWNEW.glb");
    }, [dispatch])

    function createJewelleryProductListTable() {
        const material_name = 'shoes';
        // const texture_url = PRODUCT_3D_MODEL_WRAPPING + "ShoeV1/Textures/defaultMat_Base_Color.jpg";
        const roughness_texture_url = PRODUCT_3D_MODEL_WRAPPING + "ShoeV1/Textures/defaultMat_Roughness.jpg";
        // const metalness_texture_url = PRODUCT_3D_MODEL_WRAPPING + "ShoeV1/Textures/defaultMat_Metallic-Blue-Channel.jpg";
        // const metalness_texture_url = PRODUCT_3D_MODEL_WRAPPING + "ShoeV1/Textures/defaultMat_Metallic-RGB.png";
        const metalness_texture_url = PRODUCT_3D_MODEL_WRAPPING + "ShoeV1/Textures/defaultMat_Metallic.jpg";
        const ao_texture_url = PRODUCT_3D_MODEL_WRAPPING + "ShoeV1/Textures/defaultMat_Mixed_AO.jpg";
        const normal_texture_url = PRODUCT_3D_MODEL_WRAPPING + "ShoeV1/Textures/defaultMat_Normal_OpenGL.jpg";
        return (
          <>
            {/* Here head part start */}
            <HeadPart hide_menu={true}>
              <div className="nk-block-head full-hd-wrap">
                <HeadingTitle title={"Shoes Color Options"} />
              </div>
              <Button 
                onPress={() => {
                    const texture_url = PRODUCT_3D_MODEL_WRAPPING + "ShoeV1/Textures/defaultMat_Base_Color_A.jpg";
                    changeProductWrap(
                        model_3d,
                        texture_url,
                        material_name,
                        roughness_texture_url,
                        metalness_texture_url,
                        ao_texture_url,
                        normal_texture_url,
                    );
                }} 
                classgiven="btn-basic" 
                name={'Style 1'} 
              />

              <Button 
                onPress={() => {
                    const texture_url = PRODUCT_3D_MODEL_WRAPPING + "ShoeV1/Textures/defaultMat_Base_Color_B.jpg";
                    // changeProductWrap(texture_url);
                    changeProductWrap(
                        model_3d,
                        texture_url,
                        material_name,
                        roughness_texture_url,
                        metalness_texture_url,
                        ao_texture_url,
                        normal_texture_url,
                    );
                }} 
                classgiven="btn-basic" 
                name={'Style 2'} 
              />

              <Button 
                onPress={() => {
                    const texture_url = PRODUCT_3D_MODEL_WRAPPING + "ShoeV1/Textures/defaultMat_Base_Color.jpg";
                    // changeProductWrap(texture_url);
                    changeProductWrap(
                        model_3d,
                        texture_url,
                        material_name,
                        roughness_texture_url,
                        metalness_texture_url,
                        ao_texture_url,
                        normal_texture_url,
                    );
                }} 
                classgiven="btn-basic" 
                name={'Style 3'} 
              />
              <div className="inside-bg-wrap">
                <div className="manage-template-wrap">
                    <div className="catergory-listing">
                        <div id='threejs-div' className='modal-r-3d-preview w-60'>
                            <div className="threejs_preview" id="threejs_preview" style={{width:500,height:500}}></div>
                        </div>
                    </div>
                </div>
              </div>
            </HeadPart>
            {/* Here head part ends  */}
          </>
        );
    }

    function renderLoader() {
        // console.log('jewellery_product_list_loader',jewellery_product_list_loader);
        if (false) { //Add Loader here
            return (
            <HeadPart>
                <ModalLoader
                loadingTitle={"Loading Products List"}
                openModal={false} //Add Loader Variable here
                />
            </HeadPart>
            );
        } else {
        //   return <div>{createTemplateListTable()}</div>;
            return createJewelleryProductListTable();
        }
    }

    return renderLoader();
}

export {ImageTextureToShoeScreen};