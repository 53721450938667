import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom';
import { Material } from 'three';
import {
  get3DModelList,
  onChange3DModelName,
  showHide3dModelModal,
  upload3DModel,
  toggleConfig3dModelModal,
  update3DModelCamera,
  toggleConfigMaterial3DModelModal,
  update3DModelMaterialOrder,
} from '../Actions';
import { TOGGLE_TEMPLATE_LIST_LOADER } from '../Actions/ActionTypes';
import { Button, HeadingTitle, ModalLoader, HeadPart, Modal, PercentageLoader, ThreeDViewer, TableBody, RenderRowsAndColumn } from '../Components';
// import {
//   createThreeScene,
//   getCameraPositions,
//   updateCameraPosition,
//   addModel,
// } from '../extras/ThreeDPreview';
import { ThreeDPreview2 } from '../extras/ThreeDpreview2'
var model_3d_item = []
var selected_model_material_name;
const threeJs = new ThreeDPreview2()
function ThreeDModelScreen() {

  const reduxState = useSelector((state) => state.template);
  const {
    model_3d_list,
    templateListLoader,
    upload_percentage,
    model_3d_name,
    show_3d_model_modal,
    show_3d_model_loader,
    show_3d_config_model_modal,
    show_config_material_3d_model_modal,
  } = reduxState;
  const dispatch = useDispatch();

  const [showFormValidationError, setShowFormValidationError] = useState(null);
  const [model3DFile, set3DModelFile] = useState(null);
  const [modelMaterials, setModelMaterials] = useState([]);
  const [modelMaterialOrder, setModelMaterialOrder] = useState({});
  const [generateModel, setGenerateModel] = useState(false)
  const [canvas3dWidth, setCanvas3dWidth] = useState(0)
  const [canvas3dHeight, setCanvas3dHeight] = useState(0)
  const [cameraX, setCameraX] = useState(10);
  const [cameraY, setCameraY] = useState(10);
  const [cameraZ, setCameraZ] = useState(10);
  // const [modelPreviewReady, setModelPreviewReady] = useState(false);

  var modelPreviewReady = false;
  const navigate = useNavigate();

  useEffect(() => {
    if (model_3d_list.length === 0) {
      dispatch(get3DModelList())
    } else {
      // console.log('model list:', model_3d_list);
    }
  }, [dispatch])

  const ModelList = () => {
    
    return (
      <>
        <table id="templates">
          <thead>
            <tr>
              <th>SR No</th>
              <th>3D Model Name</th>
              <th>Configure 3D Model</th>
              <th>Configure 3D Model Material</th>
              <th>Generate Thumbnail Image</th>
              <th>Delete Template</th>
            </tr>
          </thead>
          <tbody>
            {model_3d_list.map((item, index) => {
              
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item['3d_model_name']}</td>
                  <td><Button onPress={() => {
                    dispatch(toggleConfig3dModelModal(item.id));
                  }} name={returnCameraConfigName(item.cameraX, item.cameraY, item.cameraZ)} classgiven={"table-action"} />
                  </td>
                  <td><Button onPress={() => {
                    console.log("item['3d_model_material_order']", item);
                    setModelMaterialOrder(item['3d_model_material_order'])
                    threeJs.addModel(item['3d_model'], null, null, setModelMaterials); // Sets list of materials of the model in the useState variable: 'modelMaterials'
                    dispatch(toggleConfigMaterial3DModelModal(item.id));
                  }} name={renderConfigureMaterialButtonName(item)} classgiven={"table-action"} />
                  </td>
                  <td>
                    {renderGenerateThumbnailButton(item)}
                  </td>
                  <td><Button onPress={() => console.log('Delete code pending')} name="Delete" classgiven={"table-action delete-btn"} /></td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </>
    )
  }

  function renderGenerateThumbnailButton(item) {
    console.log('item',Array.isArray(item['3d_model_material_order']));  
    try{
      const model_materials_length = Object.keys(item['3d_model_material_order']) ? Object.keys(item['3d_model_material_order']) : 0;
      console.log('model_materials_length',item,model_materials_length);
      if (model_materials_length == 1) {
        // Show generate
        return (
          <Button classgiven={'table-action '} name="Generate" onPress={() => {
            // console.log('item',item['3d_model'])
            model_3d_item = item
            console.log('0th:', Object.keys(item['3d_model_material_order'])[0]);
            selected_model_material_name = Object.keys(item['3d_model_material_order'])[0];
            // console.log(selected_model_material_name);
            generate3dModalOpen(true)
  
          }} />
        );
      } else if (model_materials_length > 1) {
        // Error of only 1 side
        return <p>Only possible for Single Material Models</p>
      } else {
        // Error not configured
        return <p>Configure Model Materials first</p>
      }
    }catch(e){}
    
  }

  function returnCameraConfigName(x, y, z) {
    if (x == 0 && y == 0 && z == 0) {
      return 'Configure';
    } else {
      return 'Edit Configuration';
    }
  }

  function modalOpenClose(open) {
    setShowFormValidationError(null);
    dispatch(onChange3DModelName(''));
    if (!show_3d_model_modal) {
      dispatch(showHide3dModelModal(open));
      document.body.classList.add('modal-open')
      document.body.style.paddingRight = '17px'
    } else {
      dispatch(showHide3dModelModal(open));
      document.body.classList.remove('modal-open')
      document.body.style.paddingRight = '0px'
    }
  }

  function handleSubmit() {
    if (model_3d_name !== '' && model3DFile !== null) {
      setShowFormValidationError(null);
      dispatch(upload3DModel(model_3d_name, model3DFile, model_3d_list));
      set3DModelFile(null);
    } else {
      setShowFormValidationError('Please input all the fields');
    }
  }

  function handle3DFileSelect(event) {
    const model_3d_file = event.target.files[0];
    const model_name = model_3d_file.name;
    const model_name_array = model_name.split('.');
    const model_file_extension = model_name_array[model_name_array.length - 1];

    if (model_file_extension === 'glb') {
      set3DModelFile(model_3d_file);
      setShowFormValidationError(null);
    } else {
      set3DModelFile(null);
      setShowFormValidationError('You can only upload GLB File');
      event.target.value = null;
    }
  }

  function showFormValidationAlert() {
    if (showFormValidationError) {
      return <h6 style={{ color: "red" }}>{showFormValidationError}</h6>;
    }
  }

  function renderAdd3DModelModal() {
    if (show_3d_model_modal) {
      return (
        <Modal headTitle={'Upload 3D Model'} classGiven={`modal fade font-popup ${show_3d_model_modal ? 'show' : ''}`} style={show_3d_model_modal ? { display: 'block', paddingRight: '17px' } : { display: 'none' }} onPress={() => modalOpenClose(false)}>
          <>
            {showFormValidationAlert()}
            <form>
              <div id="table2" className="table-outer table-cards manage-shipper manage-editor-wrap">
                <table width="" cellSpacing={1} className="upload-template-table">
                  <thead>
                    <tr>
                      <th width="">Side Title</th>
                      <th width="" data-title="Action"></th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>
                        <input type={'text'} value={model_3d_name} onChange={(e) => dispatch(onChange3DModelName(e.target.value))} className="form-control template-upload" />
                      </td>
                      <td colSpan={2}><input type={'file'} onChange={(e) => handle3DFileSelect(e)} className='form-control' /></td>
                    </tr>
                  </tbody>

                </table>
              </div>
            </form>
            <div className="form-group mtp1">
              {!upload_percentage ? (
                <Button onPress={handleSubmit} classgiven="btn-basic" name={'Upload File'} />
              ) : (
                // <p> Uploading {upload_percentage}%</p>
                <PercentageLoader percentage={upload_percentage} />
              )}
            </div>
          </>
        </Modal>
      );
    }
  }

  function updateCamera(x, y, z) {
    (x !== cameraX) && setCameraX(x);
    (y !== cameraY) && setCameraY(y);
    (z !== cameraZ) && setCameraZ(z);

    threeJs.updateCameraPosition(x, y, z);
  }

  function renderCameraPositions() {
    return (
      <TableBody tableName={'Camera Position'} >
        <label>
          x:
        </label>
        <input type={'text'} value={cameraX} onChange={(e) => updateCamera(e.target.value, cameraY, cameraZ)} className="form-control template-upload" />
        <label>
          y:
        </label>
        <input type={'text'} value={cameraY} onChange={(e) => updateCamera(cameraX, e.target.value, cameraZ)} className="form-control template-upload" />
        <label>
          z:
        </label>
        <input type={'text'} value={cameraZ} onChange={(e) => updateCamera(cameraX, cameraY, e.target.value)} className="form-control template-upload" />
      </TableBody>
    );
  }

  // function renderModelMaterials() {
  //   var materials = [];
  //   model3DFile.traverse(function(object) {
  //     console.log('Object:', object);
  //     if (object.material) materials.push(object.material);
  //   });
  //   if (model3DFile !== null) {
  //   }

  //   console.log('Materials:', materials);

  //   return null;
  // }

  function renderConfig3DModelModal() {
    // console.log('model_3d_list',model_3d_lists);
    if (model_3d_list.length !== 0 && show_3d_config_model_modal !== 0) {
      const model_3d_details = model_3d_list.find(item => item.id === show_3d_config_model_modal);
      const model_3d_url = model_3d_details['3d_model'];

      // console.log('model_3d_details', model_3d_details);

      return (
        <Modal headTitle={'Config 3D Model'} classGiven={`modal fade font-popup ${(show_3d_config_model_modal !== 0) ? 'show' : ''}`} style={(show_3d_config_model_modal !== 0) ? { display: 'block', paddingRight: '17px' } : { display: 'none' }} onPress={() => dispatch(toggleConfig3dModelModal(0))}>
          <>
            <div id="table2" className="table-outer table-cards manage-shipper manage-editor-wrap">
              <table width="" cellSpacing={1} className="upload-template-table">
                <thead>
                  <tr>
                    <th width="">Set Camera Angle</th>
                    <th width="" data-title="Action"></th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>
                      <ThreeDViewer
                        id="threejs_preview"
                        ThreeScene={threeJs}
                        template_3d_model={model_3d_url}
                        cameraX={(model_3d_details.cameraX == 0) ? 10 : model_3d_details.cameraX}
                        cameraY={(model_3d_details.cameraY == 0) ? 10 : model_3d_details.cameraY}
                        cameraZ={(model_3d_details.cameraZ == 0) ? 10 : model_3d_details.cameraZ}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="form-group mtp1">
              <Button name="Save Camera Position" onPress={() => {
                const camera_positions = threeJs.getCameraPositions();
                // console.log('camera_positions', camera_positions);
                dispatch(update3DModelCamera(model_3d_details.id, camera_positions.x, camera_positions.y, camera_positions.z, model_3d_list));
              }} />
            </div>
          </>
        </Modal>
      )
    }
  }

  function renderConfigureMaterialButtonName(model_details) {
    const model_material = model_details['3d_model_material_order'] ? model_details['3d_model_material_order'] : [];
    if (Object.keys(model_material).length === 0) return 'Configure';
    else {
      // setModelMaterialOrder(model_details['3d_model_material_order'])
      return 'Edit Configuration'
    };
  }

  function onChangeMaterialSideOrder(selected, material) {

    if (modelMaterialOrder[material] == selected) {
      setShowFormValidationError('Same ID reassigned')
    } else {
      // var new_material_order = modelMaterialOrder;
      modelMaterialOrder[material] = selected;
      setModelMaterialOrder({ ...modelMaterialOrder })
      setShowFormValidationError(null)
    }
  }
    
  function renderMaterialOptions(material) {
    console.log('material', material, modelMaterialOrder);
    return (
      <select className='form-control'
        onChange={(e) => onChangeMaterialSideOrder(e.target.value, material)}
        value={modelMaterialOrder[material]}
      >
        <option className='form-control' key={0} value={0}>Ignore Side</option>
        {modelMaterials.map((order, index) => {
          return <option className='form-control' key={index} value={index + 1}>{index + 1}</option>;
        })}
      </select>
    )
  }

  function renderModelMaterialList() {
    if (modelMaterials.length === 0) {
      return <tr><td>Loading Material list...</td></tr>
    } else {
      return (
        modelMaterials?.map((material, index) => {
          return (
            <TableBody key={index} tableName={material} >
              {renderMaterialOptions(material)}
            </TableBody>
          )
        })
      );
    }
  }

  function renderModel3dHeightAndWidth() {
    return (
      <Modal headTitle={'Set Width and Height'}
        modalBodyClass={"noHeight-overflow"}
        classGiven={`modal fade font-popup ${generateModel ? 'show' : ''}`}
        style={generateModel ? { display: 'block', paddingRight: '17px' } : { display: 'none' }}
        onPress={() => generate3dModalOpen(false)}
      >
        <form>
          <div id='table2' className='table-outer table-cards manage-shipper manage-editor-wrap'>
            <table>
              <thead>
                <tr>
                  <th>3d Model</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <TableBody tableName={'Width'}>
                  <input type={'number'} className="form-control" value={canvas3dWidth} onChange={(e) => setCanvas3dWidth(e.target.value)} />
                </TableBody>
                <TableBody tableName={'Height'}>
                  <input type={'number'} className="form-control" value={canvas3dHeight} onChange={e => setCanvas3dHeight(e.target.value)} />
                </TableBody>
              </tbody>
            </table>
          </div>
        </form>
        <div className='form-group mtp1'>
          <Button onPress={handle3dWidthHeightSubmit} name="Submit" />
        </div>
      </Modal>
    )
  }

  function handle3dWidthHeightSubmit(event) {
    event.preventDefault()
    // console.log('item',model_3d_item, selected_model_material_name);
    navigate('/generate_thumbnail', {
      state: {
        'model_3d': model_3d_item,
        'canvas_width': canvas3dWidth,
        'canvas_height': canvas3dHeight,
        'selected_model_material_name': selected_model_material_name,
      }
    })
    generate3dModalOpen(false)
  }


  function generate3dModalOpen(open) {
    if (!generateModel) {
      setGenerateModel(open)
      document.body.classList.add('modal-open')
      document.body.style.paddingRight = '17px'
    } else {
      setGenerateModel(open);
      document.body.classList.remove('modal-open')
      document.body.style.paddingRight = '0px'
    }
  }


  function renderConfigureModelMaterialModal() {
    // console.log('Materials usestate',modelMaterials);
    if (model_3d_list.length !== 0 && show_config_material_3d_model_modal !== 0) {
      const model_3d_details = model_3d_list.find(item => item.id === show_config_material_3d_model_modal);
      const model_3d_url = model_3d_details['3d_model'];

      return (
        <Modal headTitle={'Config 3D Model Material'} classGiven={`modal fade font-popup ${(show_config_material_3d_model_modal !== 0) ? 'show' : ''}`} style={(show_config_material_3d_model_modal !== 0) ? { display: 'block', paddingRight: '17px' } : { display: 'none' }} onPress={() => dispatch(toggleConfigMaterial3DModelModal(0))}>
          <>
            {showFormValidationAlert()}
            <div id="table2" className="table-outer table-cards manage-shipper manage-editor-wrap">
              <table width="" cellSpacing={1} className="upload-template-table">

                <tbody>
                  {renderModelMaterialList()}
                  {/* <tr>
                    <td>
                    </td>
                  </tr> */}
                </tbody>
              </table>
            </div>
            <div className="form-group mtp1">
              <Button name="Save Material Order" onPress={() => {
                if (showFormValidationError !== null) {
                  setShowFormValidationError('Please assign proper side order to materials');
                } else {
                  if (Object.values(modelMaterialOrder).length !== 0) {
                    var final_material_order = Object.fromEntries(Object.entries(modelMaterialOrder).filter(([k, v]) => v > 0));
                    // console.log(final_material_order);
                    dispatch(update3DModelMaterialOrder(model_3d_details.id, final_material_order, model_3d_list));
                  } else {
                    dispatch(toggleConfigMaterial3DModelModal(0));
                  }
                }
              }} />
            </div>
          </>
        </Modal>
      );
    }
  }

  return (
    <>
      {show_3d_model_loader ? <ModalLoader openModal={show_3d_model_loader} loadingTitle="Loading 3D Models.." />
        : (
          <HeadPart>
            <HeadingTitle title={'Manage 3D Models'} />
            <div className="nk-block">
              <div className="nk-block-head full-hd-wrap">
                <Button onPress={() => modalOpenClose(true)} name='Add 3D Model' />
              </div>
              <div className="inside-bg-wrap">
                <div className="manage-template-wrap">
                  <div className="catergory-listing">
                    <div className="catergory-row">
                      {ModelList()}
                    </div>
                    {renderAdd3DModelModal()}
                    {renderConfig3DModelModal()}
                    {renderModel3dHeightAndWidth()}
                    {renderConfigureModelMaterialModal()}
                  </div>
                </div>
              </div>
            </div>
          </HeadPart>
        )}
    </>
  )
}

export { ThreeDModelScreen }