import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeProductWrap,
  render3DModel,
} from "../../extras/3DProductWrappingCommonFunctions";
import { useNavigate, useParams } from "react-router-dom";
import { GroupButton, ModalLoader, Model3dPreview } from "../../Components";
import styles from '../../css/ModelsOf3dProduct/model3d.module.css'
function Product3DModelRendering() {
  const all3dProductsData = useSelector(
    (state) => state.jewellery_pitch.all_3d_products_data
  );
  const toggle_3d_model_loader = useSelector(
    (state) => state.template.show_3d_model_loader
  );
  let { model_name } = useParams();

  const [model_3d, set3dModel] = useState(null);
  const [model_ready, setModelReady] = useState(false);

  const model_3d_data = all3dProductsData[model_name];

  const dispatch = useDispatch();

  useEffect(() => {
    let proccessing = true;
    console.log(model_3d_data);
    if (proccessing) {
      render3DModel(
        model_3d_data.render_3d_model,
        model_3d_data.cameraX,
        model_3d_data.cameraY,
        model_3d_data.cameraZ,
        set3dModel,
        setModelReady,
        dispatch
      );
    }
    return () => {
      proccessing = false;
    };
  }, [dispatch]);

  function RenderTemplateCard({ thumbnail, onClick }) {
    return (
      <div
        className={`template-img `}
        style={{ cursor: "pointer" }}
        onClick={onClick}
      >
        <img src={thumbnail} />
      </div>
    );
  }

  const productChangingWrap = (event, item_thumbnail_texture,index) => {
    // event.preventDefault();
    const texture_url = item_thumbnail_texture;
    
    changeProductWrap(
      model_3d,
      texture_url,
      model_3d_data.material_name,
      model_3d_data.roughness_texture_url,
      model_3d_data.metalness_texture_url,
      model_3d_data.ao_texture_url,
      model_3d_data.normal_texture_url,
      false,
      0.5,
      dispatch
    );

    if(model_3d_data.product_addition_texture){
     const additional_texture_url =  model_3d_data.product_addition_texture[index]
     if(model_name == 'ring'){
       
        changeProductWrap(
            model_3d,
            // threedOut,
            additional_texture_url,
            "emerald",
            model_3d_data.stone_roughness_texture_url,
            model_3d_data.stone_metalness_texture_url,
            model_3d_data.ao_texture_url,
            model_3d_data.stone_normal_texture_url,
            true,
            0.8,
          // null,
          // null,
          // null,
          // null,
          );
     }
    }
  };
  // console.log('toggle_3d_model_loader',toggle_3d_model_loader);
  return (
    <Model3dPreview>
      <div className={styles["modal-l-editor"]}>
        {toggle_3d_model_loader && <ModalLoader loadingTitle={'Loading'} />}
        <div className={styles["editor-wrap1"]}>
          <h2 className={styles["modal-hd2"]}>PRODUCT OPTIONS</h2>
          <div className={styles["editor-txt1"]}>
            Choose from various product options
          </div>
        </div>
        <div className={"choose-template-wrap"}>
          <h2 className={styles["modal-hd3"]}>Choose Option:</h2>
          <div className="template-list">
            {console.log('images:',model_3d_data.product_3d_textures_thumbnail_urls)}
            {model_3d_data.product_3d_textures_urls.map((item, index) => (
              <RenderTemplateCard
                key={index}
                thumbnail={model_3d_data.product_3d_textures_thumbnail_urls[index]}
                onClick={(e) => productChangingWrap(e, item,index)}
              />
            ))}
          </div>
        </div>
      </div>
      <div id="threejs-div" className={`${styles['modal-r-3d-preview']} w-60`}>
        <div
          className="threejs_preview"
          id="threejs_preview"
          style={{ width: "100%", height: "100%" }}
        ></div>
        <GroupButton className={styles["open-close-tabs"]}>
        {model_3d_data.button && model_3d_data.button.map((item,index)=>(
          <a key={index} onClick={item.btn_fun.bind(this)}>{item.btn_name}</a>
        ))}
          {/* <a>Open Box</a>
          <a>Close Box</a> */}
        </GroupButton>
      </div>
    </Model3dPreview>
  );
}

export { Product3DModelRendering };
