export const SAVE_SVG = 'save_svg';
export const TOGGLE_UPLOAD_LOADER = 'toggle_upload_loader';
export const TOGGLE_LAYER_LOADER = 'toggle_layer_loader';
export const SAVE_MISSING_FONTS_LIST = 'save_missing_fonts_list';
export const SAVE_TEMPLATE_ID = 'save_template_id';
export const SAVE_TEMPLATE_FONTS = 'save_template_fonts';
export const TOGGLE_LAYER_UPLOAD_LOADER = 'toggle_layer_upload_loader';
export const FILE_TYPE_OF_SVG = 'file_type_of_svg';

export const TOGGLE_FONT_UPLOAD_LOADER = 'toggle_font_upload_loader';
export const SAVE_TEMPLATE_FONT_RULES = 'save_template_font_rules';
export const TEMPLATE_NAME = 'TEMPLATE_NAME';

export const INIT_CANVAS = 'init_canvas';
export const SAVE_LAYER_LIST = 'save_layer_list';
export const SAVE_OBJECT_LIST = 'save_object_list';
export const SAVE_SELECTED_LAYER_INDEX = 'save_selected_layer_index';

export const SELECT_LAYER = 'select_layer';

export const SAVE_PERMISSIONS_LIST = 'save_permissions_list';
export const SAVE_DIE_LIST = 'save_die_list';
export const UPDATE_LAYER_PERMISSIONS = 'update_layer_permssions';
export const SAVE_DESIGABLE_AREA = 'save_designable_area';
export const SAVE_OUTLINE_AREA = 'save_outline_area';
export const SAVE_BACKGROUND_AREA = 'save_background_area';

export const SAVE_TEMPLATES_LIST = 'save_template_list';
export const SAVE_TEMPLATE_LIST_TOTAL_PAGES = 'save_template_list_total_pages';
export const UPDATE_PAGE_NO = 'update_page_number';
export const SAVE_TOTAL_TEMPLATES_COUNT = 'save_total_templates_count';
export const SAVE_TEMPLATE_LIST_DRAFT = 'save_template_list_draft';
export const TOGGLE_TEMPLATE_LIST_LOADER = 'toggle_template_list_loader';
export const SAVE_TOGGLE_TEMPLATE_LIST_LOADER = 'save_toggle_template_list_loader'
export const SAVE_TEMPLATE_TAGS = 'save_template_tags';

export const ONCHANGE_PRODUCTS_TAG_FILTER = 'on_change_product_tags_filter';

// Client Side
export const OBJECT_SELECTED = 'object_selected';
export const SAVE_ALL_FONTS_LIST = 'save_all_fonts_list';
export const SAVE_ORIGINAL_FONT_STYLES = 'save_original_font_styles';
export const APPLIED_FONT_STYLES = 'applied_font_styles';
export const SAVE_TEMPLATE_ASSIGNED_LAYERS = 'save_template_assigned_layers';
export const SHOW_IMAGE_PICKER_MODAL = 'open_image_picker_modal';
export const CHANGE_IMAGE_UPDATE = 'change_image_update';
export const TOGGLE_IMAGE_CONVERSION_LOADER = 'toggle_image_conversion_loader';
export const ADD_IMAGE_PICKER_MODAL = 'add_image_loader';
export const ALL_COLOR_LIST = 'all_color_list';
export const COLOR_SELECTED = 'color_selected';
export const PERMISSION_LIST = 'permission_list';
export const SAVED_TEMPLATE_LIST = "saved_template_list";
export const SAVED_TEMPLATE_LOADER = "saved_template_loader";

export const DISPLAY_DIGITAL_PROOF_IMAGE = "display_digital_proof_image";
export const TOGGLE_DIGITAL_PROOF_LOADER = 'toggle_digital_proof_loader';
export const UPLOAD_PERCENTAGE = 'upload_percentage';
export const CLIENT_NAME = "client_name";

export const SAVE_TEMPLATE_SIDES = 'save_template_sides';
export const TOGGLE_TEMPLATE_SIDES_LOADER = 'toggle_template_sides_loader';
export const SHOW_HIDE_GRID = 'show_hide_grid'
export const BIGGEST_OBJECT = 'biggest_object';

export const SAVE_TEMPLATE_SIDES_DICT = 'save_template_sides_dict';
export const FONT_OBSERVER_LOADER = 'font_observer_loader'
export const GET_FONT = 'get_font';
export const GET_FONT_KEY = 'get_font_key';
export const GETTING_FONT_LOADER = 'getting_font_loader';

export const SAVE_CANVAS_STATE = 'save_canvas_state';
export const UPDATE_TEMPLATE_INFO_LOADER = 'upload_template_info_loader';

export const REPEATED_ORDER_LIST = 'repeated_order_list'

export const ON_CHANGE_TEMPLATE_NAME = 'on_change_template_name';

export const SAVE_3D_MODEL_LIST = 'save_3d_model_list';
export const ON_CHANGE_3D_MODEL_NAME = 'on_change_3d_model_name';
export const TOGGLE_3D_MODEL_MODAL = 'toggle_3d_model_modal';
export const TOGGLE_3D_MODEL_LOADER = 'toggle_3d_model_loader';
export const TOGGLE_CONFIG_3D_MODEL_MODAL = 'toggle_config_3d_model_modal';
export const TOGGLE_CONFIG_MATERIAL_3D_MODEL_MODAL = 'toggle_config_material_3d_model_modal';
export const FILTERED_TEMPLATE_LIST = 'filtered_template_list';
export const SEARCH_FILTERING_TEMPLATE = 'search_filtering_template';
export const SAVE_CANVAS_SVG = 'save_canvas_svg';
export const GENERATING_IMAGE_THUMBNAIL = 'GENERATING_IMAGE_THUMBNAIL';

export const PCJ_TEMPLATE_LOADER = 'PCJ_TEMPLATE_LOADER';



// Jewellery Pitch Action types
export const SAVE_BRAND_NAME = 'SAVE_BRAND_NAME';
export const TOGGLE_JEWELLERY_PRODUCT_LIST_LOADER = 'TOGGLE_JEWELLERY_PRODUCT_LIST_LOADER';
export const SAVE_JEWELLERY_PRODUCT_LIST = 'SAVE_JEWELLERY_PRODUCT_LIST';
export const TEMPLATE_LOADER = 'PCJ_TEMPLATE_LOADER';

// Login Action Types
export const USER_DETAILS = 'USER_DETAILS';
export const USER_PASSWORD = 'USER_PASSWORD';
export const USER_NAME = 'USER_NAME';
export const USER_LOGGED_IN = 'USER_LOGGED_IN';
export const USER_LOADER = 'USER_LOADER';