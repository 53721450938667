import axios from 'axios';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { getListOfSaveRepeatedOrders } from '../Actions'
import { TOGGLE_TEMPLATE_LIST_LOADER } from '../Actions/ActionTypes';
import { Button, HeadingTitle, ModalLoader } from '../Components'
import { navigateToEditorFromDraft } from '../extras/CommonNavigations';

function RepeatedOrder() {

    const reduxState = useSelector((state) => state.template);
    const {
      repeated_order_list,
      templateListLoader,
    } = reduxState;
    const dispatch = useDispatch()
    const navigate = useNavigate();
    useEffect(()=>{
        dispatch(getListOfSaveRepeatedOrders())
    },[dispatch])

    const jsonFileGetting = async(json_file,client_name,template_id,template_name) => {
      dispatch({
        type:TOGGLE_TEMPLATE_LIST_LOADER,
        payload:true,
      })
      const json_response = await axios({
        url:json_file,
        method:'get'
      })
      if(json_response.status===200){
        const json_data = json_response.data
        console.log('navigate',json_data)
        navigateToEditorFromDraft(navigate,json_data,client_name,template_id)
        // navigate('/editor',{
        //   state:{
        //     "draft":true,
        //     "all_template_sides":json_data.all_template_side,
        //     "template_id":template_id,
        //     "client_name":client_name,
        //     "template_fonts":json_data.original_template_fonts,
        //     "original_font_applied":json_data.original_font_applied,
        //     "layers":json_data.layers,
        //     "template_sides":json_data.template_sides,
        //     'template_name':json_data.templates_name,
        //     "template_font_face":json_data.font_face,
        //     'swatch':json_data.swatch,
        //     "clip_layer_id":json_data.clip_layer_id,
        //     "template_3d_model":json_data.template_3d_model,
        //   }
        // })
        // navigateToEditor(navigate, isRounded, first_template_side, template_sides_title, template_list, fonts_list, swatch);
        dispatch({
          type:TOGGLE_TEMPLATE_LIST_LOADER,
          payload:false,
        })
      }
      // console.log('json response',json_response.data.canvas);
    }
  const repeatedTemplateList= ()=>{
    return (
      <>      
      <table id="templates">
            <thead>
              <tr>
                <th>SR No</th>
                <th>Client Name</th>
                <th>Edit Template</th>
                <th>Delete Template</th>
              </tr>
            </thead>
            <tbody>
              {repeated_order_list.map((item,index)=>{
                // console.log(item.client_name);
                return (
                  <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.client_name}</td>
                  <td> <Button onPress={()=>jsonFileGetting(item.json_file_name,item.client_name,item.main_template_id,item.svg_file_name)} name="Repeat order" classgiven={'table-action edt-btn'} /></td>
                  <td><Button onPress={()=>console.log()} name="Delete" classgiven={"table-action delete-btn"} /></td>
                  </tr>
                )
              })}
            </tbody>
          </table>
      </>
    )
  }

  return (
    <>
    <HeadingTitle title={'Manage Draft'} />
    {templateListLoader ? <ModalLoader openModal={templateListLoader} loadingTitle="Loading.." /> 
    :(
      <>
      {repeatedTemplateList()}
      </>
    ) }
    </>
  )
}

export {RepeatedOrder}