import React, { Component } from 'react'
import { Button } from './Button';
import { Modal } from './Modal';

class CustomAlert extends Component {
    static alertInstance;

    constructor(props){
        super(props)
        this.state= {
            visible:false,
            text:'',
            error:false,
            info_text:'',
            yes_text:'',
            no_text:'',
            callbackFunction:[],
            callbackFunction2:[],
            side_name:'',
            args: '',
        }

        CustomAlert.alertInstance = this
    }

    static alert(error,text){
        CustomAlert.alertInstance._alert(error,text)
    }

    static yes_or_no(info_text,yes,no,callbackFunction2,side_name,args){
        CustomAlert.alertInstance._show_yes_no(info_text,yes,no,callbackFunction2,side_name,args)
    }

    _show_yes_no(info_text,yes,no,callbackFunction2,side_name,args){
        this.setState({visible:true,info_text,yes_text:yes,no_text:no,callbackFunction2:callbackFunction2,side_name:side_name,args:args})
    }

    _alert(error,text){
        this.setState({visible:true,text,error})
    }

    componentDidUpdate(){
        if(this.state.visible){
            document.body.classList.add('modal-open')

        }else{
            document.body.classList.remove('modal-open')

        }
    }

    componentWillUnmount(){
        this.settingDefault()
    }

    settingDefault(){
        this.setState({visible:false,yes_text:'',no_text:'',info_text:'',callbackFunction:[],text:''})
    }
  render() {

    const {visible,text,error,info_text,yes_text,no_text, callbackFunction2,side_name,args} = this.state    
    return (
        <>
        <Modal modalBodyClass={'noHeight-overflow'} headTitle={'Alert'} classGiven={`modal fade font-popup ${visible ? 'show' : ''}`} onPress={() => this.settingDefault()}  style={visible ? { display: 'block', paddingRight: '17px' } : { display: 'none' }} >
            {text ? (
                <p style={error ? {color:'red'} :{color:'blue'}}>{text}</p>
            ):(
                <div className={'dialog'}>
                <div>{info_text}</div>
                <Button classgiven={'link btn-basic btn-basic2'} name={yes_text} onPress={()=>{
                    callbackFunction2(true,side_name,args)
                this.settingDefault()
                }} />
                <Button classgiven={'link btn-basic btn-basic2'} name={no_text} onPress={()=>{
                    callbackFunction2(false,side_name,args)
                // this.setState({visible:false})
                this.settingDefault()
                }} />
                </div>

            )}
        </Modal>
        </>
    )
  }
}

export {CustomAlert}