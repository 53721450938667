import React from 'react';

function Checkbox({
  label,
  onPress,
  selected,
  style,
}) {
  //   const {
	// 	label,
	// 	onPress,
  //   selected,
  //   style,
	// } = props;

  // const returnCheckBox = ()=> {
  //   if (selected) {
  //     return (
  //       <div className='checkbox-div' onClick={onPress}>
  //         <div className='checkbox' style={{backgroundColor: 'green'}} />
  //         <label>{label}</label>
  //       </div>
  //     )
  //   } else {
  //     return (
  //       <label className='checkbox-div' onClick={onPress}>
  //         <span className='checkmark' />
  //         <label style={style}>{label}</label>
  //       </label>
  //     )
  //   }
  // }
  // console.log('name',label);
  return (
    <label><input style={style} checked={selected} onChange={onPress} type={'checkbox'} /><span className='checkmark'></span><span
    className="checkmark"></span>{label}</label>
  );
  // return returnCheckBox()
}

export {Checkbox};
