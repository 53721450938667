import React, {useCallback, useEffect, useState} from 'react';
import {uploadSVG} from '../Actions';
import {useDispatch, useSelector} from 'react-redux';
import Dots from "react-activity/dist/Dots";
import "react-activity/dist/Dots.css";
import { useNavigate, useLocation } from "react-router-dom";
import {Checkbox, HeadingTitle, PercentageLoader} from '../Components';


function UploadTemplateScreen(props) {
  const reduxState = useSelector(state=>state.template);
  const {
    uploadLoader, 
    svg_url, 
    missing_fonts_list,
    upload_percentage,
    // industry_tags,
    // theme_tags,
    // product_tags,
  } = reduxState;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [side_title, setSideTitle] = useState('');
  const [template_desp, setTemplateDesp] = useState('');
  const [template_height, setTemplateHeight] = useState('');
  const [template_width, setTemplateWidth] = useState('');
  const [svgFile, setSVGFile] = useState(null);
  const [template_thumbnail, setTemplateThumbnail] = useState(null);
  const [template_industry_tags, setTemplateIndustryTags] = useState([]);
  const [template_theme_tags, setTemplateThemeTags] = useState([]);
  const [template_product_tags, setTemplateIProductTags] = useState([]);
  const [industry_tags, setIndustryTags] = useState([]);
  const [theme_tags, setThemeTags] = useState([]);
  const [product_tags, setProductTags] = useState([]);

  useEffect(() => {
    document.title = `Upload Template`;
    try{
        setProductTags(location.state.product_tags)
        setThemeTags(location.state.theme_tags)
        setIndustryTags(location.state.industry_tags);
    }catch(e){
      console.log();
    }
  }, [dispatch,svg_url, missing_fonts_list]);


  function handleSVGFileSelect(event) {
    setSVGFile(event.target.files[0])
  }

  function handleThumbnailFileSelect(event) {
    setTemplateThumbnail(event.target.files[0])
  }

  function handleSubmit(event) {
    event.preventDefault();
    var proceed = false;
    if (svgFile != null && side_title !== '') {
      var main_template_id, template_sides, filtered;
      try {
        // console.log('LOCATION:', location.state);
        main_template_id = location.state.main_template_id;
        template_sides = location.state.template_sides;
        // console.log('Template_sides:', template_sides);

        filtered = Object.keys(template_sides).reduce(function (filtered, key) {
          // console.log('KEY', key);
          if (template_sides[key]['side_title'] === side_title) filtered[key] = template_sides[key];
          return filtered;
        }, {});
      } catch (error) {
        main_template_id = 0;
        filtered = {};
      }

      if (Object.keys(filtered).length === 0 || main_template_id === 0) {
        proceed = true;
      } else {
        proceed = false;
      }

      console.log('filtered', template_industry_tags);
      if (proceed) {
        dispatch(
          uploadSVG(
            event, 
            svgFile, 
            side_title.toUpperCase(), 
            main_template_id, 
            template_desp,
            template_height,
            template_width,
            template_thumbnail,
            template_industry_tags,
            template_product_tags,
            template_theme_tags,
            navigate
          )
        );
      } else {
        alert('Please select a different side name, this side name already used in this template');
      }
    } else {
      event.preventDefault();
      alert('Please select file and enter side name to upload!');
    }
  }

  function industryTagSelected(id) {
    if(template_industry_tags.includes(id)){
      var new_template_tags = template_industry_tags
      const index = template_industry_tags.indexOf(id)
      if(index>-1){
        new_template_tags.splice(index,1)
        setTemplateIndustryTags([...new_template_tags])
      }
    }else{
      setTemplateIndustryTags([...template_industry_tags,id])
    }
    
  }

  function themeTagSelected(id) {
    if(template_theme_tags.includes(id)){
      var new_template_tags = template_theme_tags
      const index = template_theme_tags.indexOf(id)
      if(index>-1){
        new_template_tags.splice(index,1)
        setTemplateThemeTags([...new_template_tags])
      }
    }else{
      setTemplateThemeTags([...template_theme_tags,id])
    }
    
  }

  function productTagSelected(id) {
    if(template_product_tags.includes(id)){
      var new_template_tags = template_product_tags
      const index = template_product_tags.indexOf(id)
      if(index>-1){
        new_template_tags.splice(index,1)
        setTemplateIProductTags([...new_template_tags])
      }
    }else{
      setTemplateIProductTags([...template_product_tags,id])
    }
    
  }

   
  function renderThemeTags() {
    return theme_tags && theme_tags.length !== 0 &&(
      <>
      <hr></hr>
      <div>
        <p>Theme</p>
        {theme_tags.map((theme,index)=>{
          const theme_id = theme.id
          if(!template_theme_tags.includes(theme_id)){
            return (
              <Checkbox key={index} label={theme.theme} selected={false} onPress={()=>themeTagSelected(theme_id)} />
            )
          }else{
            return (
              <Checkbox key={index} label={theme.theme} selected={true} onPress={()=>themeTagSelected(theme_id)} />
            )
          }
        })}
      </div>
      </>
    )
  }

  function renderProductTags() {
    return product_tags &&  product_tags.length !== 0 &&(
      <>
      <hr></hr>
      <div>
      <p>Product</p>
        {product_tags.map((product,index)=>{
          const product_id = product.id
          if(!template_product_tags.includes(product_id)){
            return (
              <Checkbox key={index} label={product.product} selected={false}  onPress={()=>productTagSelected(product_id)} />
            )
          }else{
            return (
              <Checkbox key={index} label={product.product} selected={true} onPress={()=>productTagSelected(product_id)} />
            )
          }
        })}
        </div>
      </>
    )   
  }


  const renderIndustryTags = ()=> {
    return industry_tags && industry_tags.length !== 0 &&(
      <>
      <hr></hr>
      <div>
      <p>Industry</p>
        {industry_tags.map((industry,index)=>{
          const industry_id = industry.id
          if(!template_industry_tags.includes(industry_id)){
            return (
              <Checkbox key={index} label={industry.industry} selected={false}  onPress={()=>industryTagSelected(industry_id)} />
            )
          }else{
            return (
              <Checkbox key={index} label={industry.industry} selected={true} onPress={()=>industryTagSelected(industry_id)} />
            )
          }
        })}
        </div>
      </>
    )      
  }

  const renderPage = () =>{
    if (uploadLoader) {
      return (
        <>
        {upload_percentage && (
          <PercentageLoader percentage={upload_percentage} />
        )}
        <Dots />
        </>
        );
    } else {

      return (
        <form onSubmit={handleSubmit}>
          {/* onSubmit={handleSubmit} */}
          <label>Template SVG*: </label>
          <input type="file" onChange={handleSVGFileSelect}/><br></br>
          <label>Side Title*: </label>
          <input type={'text'} value={side_title} onChange={(e) => setSideTitle(e.target.value)} style={{height:'20px',marginRight: 10, marginTop: 20, marginBottom: 10}} /><br></br>
          {renderMainTemplateForm()}
          {renderIndustryTags()}
          {renderThemeTags()}
          {renderProductTags()}
          <input type="submit" value="Upload File" />
        </form>
      )
    }
  }

  function renderMainTemplateForm() {
    var main_template_id = 0;
    try {
      main_template_id = location.state.main_template_id;
    } catch {
      console.log(' No main temp id');
    }
    if (main_template_id == 0) {
      return (
        <div>
          <label>Template Desp: </label>
          <input type={'text'} value={template_desp} onChange={(e) => setTemplateDesp(e.target.value)} style={{height:'20px',marginRight: 10, marginTop: 10, marginBottom: 10}} /><br></br>

          <label>Template Height: </label>
          <input type={'text'} value={template_height} onChange={(e) => setTemplateHeight(e.target.value)} style={{height:'20px',marginRight: 10, marginTop: 10, marginBottom: 10}} /><br></br>

          <label>Template Width: </label>
          <input type={'text'} value={template_width} onChange={(e) => setTemplateWidth(e.target.value)} style={{height:'20px',marginRight: 10, marginTop: 10, marginBottom: 10}} /><br></br>

          <label>Template Thumbnail Image: </label>
          <input type="file" onChange={handleThumbnailFileSelect} style={{marginRight: 10, marginTop: 10, marginBottom: 20}} /><br></br>
        </div>
      )
    }
  }

 
  return (
    <div>      
      <HeadingTitle title={'Upload Template'} />
      <br></br>
      {renderPage()}
    </div>
  )
}

export {UploadTemplateScreen};
