import React from 'react'
import { Button, HeadingTitle, HeadPart } from '../Components'

function AllDiesScreen() {
    return (
        <>
            <HeadPart>
                <HeadingTitle title={'All Dies List'}>
                    <div className='nk-block'>
                        <Button classgiven={'link btn-basic new btn-basic2'} onPress={() => console.log('Add')} name='Add Dies' />
                    </div>
                </HeadingTitle>
            </HeadPart>
        </>
    )
}

export { AllDiesScreen }