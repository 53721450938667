import React from 'react'

function ClientObjectButton({onPress,children,className,nameOfObject,style}) {
  return (
    <div onClick={onPress} className='object-element-clm'>
        <div className={`element-img ${className ? className : ''}`} style={style}>{children}</div>
        <div className='element-hd'>{nameOfObject}</div>
    </div>
  )
}

export {ClientObjectButton}