import React from 'react'
import { Modal } from './Modal'

function ModalLoader({ openModal, loadingTitle, children }) {
    const public_url = process.env.PUBLIC_URL
    return (
        <div className="overlay">
            <div className="overlayDoor"></div>
            <div className="overlayContent">
                <div>
                    {/* <img src={public_url+"/images/spinner-loader.gif"} /> */}
                    <span className="loader"></span>
                </div>
                <div className="skip">{loadingTitle}</div>
                {children}
            </div>
        </div>
    )
}

export { ModalLoader }