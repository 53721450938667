// // This will be a reusable component
// function RenderTemplateCard({thumbnail, canvas_json_data, template_side,first_side}) {
//     return (
//         <div  className={`template-img ${first_side.id == templateSelected.id ? 'active' : ''}`} style={{cursor:'pointer'}}  onClick={() => {
//             // renderTextureTo3DModel(template_side);
//             console.log('model_3d');
//             const template_side_titles = Object.keys(template_side);
            
//             setTemplateSelected(template_side[template_side_titles[0]])
//             const texture_url = template_side[template_side_titles[0]]['model_image_texture'];
//             const model_materials = template_side[template_side_titles[0]]['3d_model']['material'];
//             const material_name = model_materials[0];
            
//             applyTemplateTextureTo3DModel(texture_url, model_3d, model_ready, material_name)
//         }}>
            
//             <img src={thumbnail} style={{width:150, height: 200}} />
            
//         </div>
//     )
// }

import React from 'react'

// ${first_side.id == templateSelected.id ? 'active' : ''}
function JewelleryTemplateCard({
    active,
    OnTemplateSelected,
    template_thumbnail,
    templateSelected,
}) {
    return (
        <div  className={`template-img ${active.id == templateSelected.id ? 'active' : ''}`} style={{cursor:'pointer'}}  onClick={OnTemplateSelected}>
            <img src={template_thumbnail ? template_thumbnail : "../images/corporate-orders.jpg"} style={{width:150, height: 200}} />
        </div>
    )
}

export { JewelleryTemplateCard }