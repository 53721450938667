import React from 'react'
import { SideBar, Header, Footer } from './'
function HeadPart({ children,notWantFooter,hide_menu }) {
    
    return (
        <>
            <Header hide_menu = {hide_menu ? false : true} />
            <div className='main-container'>
                <div className='container2'>
                    {/* <SideBar />  */}
                    <div className='main-content new-wrapper content-wrapper' style={{ minHeight: '549px' }}>
                        <div className='form-inside table-panel table-main-1 x_panel no-bg'>
                            {children}
                        </div>
                    </div>
                    {!notWantFooter &&(
                    <Footer />
                    )  }
                </div>
            </div>
        </>
    )
}

export { HeadPart }