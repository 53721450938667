import React from 'react'

function ClientListButton({buttonList,active,completed}) {
  return (
    <>
      <ul>
        {buttonList.map((item,index)=>(
          <li key={index} className={(item===active)?'active' : (item===completed) ?'active completed' :'last'}>
            <a>{item}</a>
          </li>
        ))}
      </ul>
    </>
  )
}

export {ClientListButton}