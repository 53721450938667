import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, ModalLoader } from "../Components";
import { Navigate } from "react-router";
import { loginAuth, onChangeUserAction } from "../Actions/loginAction";
import { USER_NAME, USER_PASSWORD } from "../Actions/ActionTypes";

function LoginScreen() {
  const dispatch = useDispatch();
  const [passwordShow,setPasswordShow]= useState(false);
  const loginReducer = useSelector((state) => state.login);
  const { logging_loader, password, username, user_logged_in } = loginReducer;

  function loginAuthentication(event){
    event.preventDefault();
    const formdata = {
        user_name: username,
        password
    }

    dispatch(loginAuth(formdata));
  }

  function handleKeyDown (event) {
    if(event.key === "Enter"){
      loginAuthentication(event)
    }
  }
  return !user_logged_in ? (
    <div className="main-container">
      {logging_loader && (
        <ModalLoader openModal={logging_loader} loadingTitle={"Loading.."} />
      )}
      <div className="container2">
        <div
          className="main-content new-wrapper content-wrapper"
          style={{ minHeight: "549px" }}
        >
          <div className="form-inside table-panel table-main-1 x_panel no-bg">
            <div className="inside-bg-wrap">
              <div className="manage-template-wrap">
                <label>
                    UserName
                    <input
                        className="form-control"
                        type="text"
                        onKeyDown={handleKeyDown}
                        value={username}
                        onChange={(e) =>
                        dispatch(onChangeUserAction(e.target.value, USER_NAME))
                        }
                    />
                </label>
                <label>
                    Password
                    <input
                        className="form-control"
                        type={!passwordShow ? 'password' : 'text'}
                        value={password}
                        onKeyDown={handleKeyDown}
                        onChange={(e) =>
                        dispatch(onChangeUserAction(e.target.value, USER_PASSWORD))
                      }
                    />
                </label>
                      <Button onPress={loginAuthentication} name={'Login'} classgiven={'link btn-basic btn-basic2'} />
                    <span
                      style={{ cursor: "pointer", userSelect: "none" }}
                      onClick={() => setPasswordShow(!passwordShow)}
                    >
                      Show Password
                    </span>

            
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  ) : (
    <Navigate to={"/"} replace />
  );
}

export { LoginScreen };
