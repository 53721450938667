import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom';
import { Button, CustomAlert } from '../Components';
// import { createThreeScene, generateMultipleImages } from '../extras/ThreeDPreview';
import { getBase64 } from '../extras/CanvasObjectControls';
import { ThreeDPreview2 } from '../extras/ThreeDpreview2'
const threeJsScene = new ThreeDPreview2()
function Generate3dThumbnailScreen() {
    // const [model_3d,setModal3d] = useState([])
    const [threeHeight,setThreeHeight]= useState(500);
    const [threeWidth,setThreeWidth] = useState(1000)
    const [model3dFile,setModel3dFile] = useState(null);
    const [multiple_svg,setMultipleSvg] = useState([])
    const reduxState = useSelector((state) => state.template);
    const {generating_thumbnail} = reduxState;
    const dispatch = useDispatch();
    const location = useLocation();
    useEffect(()=>{
        try{
            var three_model = location.state.model_3d 
            var height = parseInt(location.state.canvas_height)
            var width = parseInt(location.state.canvas_width)
            if(width>0){
                setThreeWidth(width)
            }
            if(height>0){
                setThreeHeight(height)
            }     
            const cameraX= three_model['cameraX'] == 0 ? 10 :three_model['cameraX']; 
            const cameraY= three_model['cameraY'] == 0 ? 10 :three_model['cameraY'];
            const cameraZ= three_model['cameraZ'] == 0 ? 10 :three_model['cameraZ'];
            threeJsScene.createThreeScene(three_model['3d_model'],cameraX,cameraY,cameraZ,'threejs_preview').then(model=>{
                setModel3dFile(model)
            })
            // createThreeScene(three_model['3d_model'],cameraX,cameraY,cameraZ,'threejs_preview').then(model=>{
            //     setModel3dFile(model)
            // })
            // setModal3d(three_model)
        }catch(e){

        }
    },[dispatch])
    function handleSubmit (event){
        event.preventDefault()        
        const model_material_name = location.state.selected_model_material_name
                
        if(Object.keys(multiple_svg).length > 0){
            // threeJsScene.someFun()
            threeJsScene.generateMultipleImages(multiple_svg,model3dFile,model_material_name,dispatch)
        }
        else{
            CustomAlert.alert(false,'Select Atleast one Image to save')
        }
        // Object.keys(files).map((file,index)=>{
        //     let file_data = files[file].split(',')[1]
        //     console.log('files',file_data);
        //     archive.file(`images_${file}.png`,file_data,{base64:true})
        //     // console.log('file',files[file],index);
        // })
        // zip.generateAsync({type:'blob'}).then((content)=>{
        //     saveAs(content,'example.zip')
        // })
        // createThreeScene(three_3d_model,cameraX,cameraY,cameraZ,'threejs_preview',null,null,null).then(model=>{
    }
    // console.log("threeWidth,threeHeight",threeWidth,threeHeight);
    function settingFiles(event){
        const files = [...event.target.files]        
        var all_images = {}
        files.map(async(file,index)=>{

            all_images[index] = await getBase64(file)
        })
        console.log('allImage',all_images);
        setMultipleSvg(all_images)
    }
  return (
    <div>
        <div style={{display:'flex'}}>
        <label>Select svg</label>
        <input className='form-control' type={'file'} accept="image/jpeg,image/png,image/jpg"  multiple onChange={settingFiles} />
        {generating_thumbnail ? (
            <p className='t-bold'>Please Wait its converting...</p>
        ):(
        <Button classgiven={'table-action'} name={'Save'} onPress={handleSubmit} />
        )}
        </div>
        <div id='threejs_preview' style={{width:threeWidth,height:threeHeight}}></div>
    </div>
  )
}

export {Generate3dThumbnailScreen}