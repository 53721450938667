import React, { useEffect } from 'react'
import {useNavigate} from "react-router-dom";
import { ModelContainer } from '../../Components';
import '../../css/PCJeweller/PCJStyle.css';
import '../../css/PCJeweller/bootstrap.min.css';

function CustomisationTypeScreen() {
    const public_url = process.env.PUBLIC_URL
    var navigate = useNavigate();

    useEffect(() => {
      document.title = 'PC Jeweller'
        
      return () => {
      
      }
    }, [])
    
    
    return (
        <div className="modal-container">
            <div className='modal-inner-wrap'>
                <h2 className='modal-hd'>Choose customization type</h2>
                <div className='modal-contact-wrap'>
                    <ModelContainer 
                        mainClass={'modal-l'} 
                        imgName={public_url+'/images/PCJIcons/write-gift-message-icon.svg'} 
                        title={'WRITE A GIFT MESSAGE'} 
                        desc={`Personalize your box ${'\n'} with a custom gift message \n for your loved ones`} 
                        // 1382
                        linkToGo={() => navigate('/pcj/customise_message_screen',{state:{templateId:1420}})} 
                        price={'+ Rs.50'} 
                    />
                    
                    <ModelContainer 
                        mainClass={'modal-r'} 
                        imgName={public_url+'/images/PCJIcons/customize-your-entire-box-icon.svg'} 
                        title={'CUSTOMIZE YOUR ENTIRE BOX'} 
                        desc={`Choose from various templates,\nadd photos, messages or backgrounds \nto make the box unmistakably yours`} 
                        linkToGo={() => navigate('/pcj/choose_customisation_template')} 
                        price={'+ Rs.350'} 
                    />
                </div>
            
            {/* <a onClick={() => navigate('/pcj/customise_message_screen')}>
                <div class="split left">
                        <div class="centered">
                            <h2>Custom Message</h2>
                            <p>Option to add a Custom Message to your Box!</p>
                        </div>
                </div>
            </a> */}
            {/* <a onClick={() => navigate('/pcj/customise_message_screen')}>
            
                <div class="split right">
                        <div class="centered">
                            <h2>Custom Box</h2>
                            <p>Customise your box, All for yourself!</p>
                        </div>
                </div>
            </a> */}
            </div>
        </div>
    )
}

export { CustomisationTypeScreen };