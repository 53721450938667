import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getTemplateList } from '../../Actions';
import '../../css/JewelleryPitch/ChooseTemplateScreen.css';
import { CustomAlert, GroupButton, ModalLoader, JewelleryPitchPreview, JewelleryTemplateCard } from '../../Components';
import { TOGGLE_JEWELLERY_PRODUCT_LIST_LOADER, SAVE_TEMPLATES_LIST } from '../../Actions/ActionTypes';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import {ThreeDPreview2} from '../../extras/ThreeDpreview2'
import {create3DScene, openBox, closeBox} from '../../extras/FabricCanvasSetup';
import {JEWELLERY_TEMPLATE_TEXTURE_URL} from '../../extras/APIs';

function TemplateOptionsScreen() {
    const dispatch = useDispatch();
    const reduxState = useSelector((state) => state.template);
    const jewellery_pitch_reduxState = useSelector((state) => state.jewellery_pitch);
    const [boxOpen,setBoxOpen] = useState(false)
    const [model_3d, set3dModel] = useState(null);
    const [model_ready, setModelReady] = useState(false);
    const [templateSelected,setTemplateSelected] = useState({})
    const [current3DModelLink,setCurrent3DModelLink] = useState('')

    const {
        templateListLoader,
        template_list,
    } = reduxState;

    const {jewellery_product_list_loader} = jewellery_pitch_reduxState;
    
    const {brand} = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const threedOut = new ThreeDPreview2();

    // var current_3d_model_link = 'old';

    console.log('template_list',template_list);

    useEffect(() => {
        var product_tags = location.state.product_tags; 
        console.log('product_tags',product_tags);
        const controller = new AbortController()
        
        // dispatch(getTemplateList(product_tags.toString(), 1, 15, 1,'','','','','','',null,controller));
        dispatch(
            getTemplateList(
                null,
                1,
                15,
                0,
                [],
                [],
                product_tags,
                '0',
                '',
                '',
                null,
                controller
            )
        );

        return () => {
            settingDefault()
            controller.abort()
        }
    }, [dispatch])


    useEffect(() => {
        if (template_list.length > 0) {
            const template_side = template_list[0];
            const threejs_div = document.getElementById("threejs-div") ? document.getElementById("threejs-div") : 0;
            const threejs_client_width = parseInt(threejs_div.clientWidth, 10)
            const threejs_client_height = threejs_client_width * (window.screen.availHeight / window.screen.availWidth);
            if (document.getElementById("threejs")) {
                const threejs_canvas_div = document.getElementById("threejs");
                threejs_canvas_div.style.width = threejs_client_width;
                threejs_canvas_div.style.height = threejs_client_height;
            }
            render3DModel(template_side)
        }
    }, [template_list]);

    function settingDefault(){
        dispatch({
          type: SAVE_TEMPLATES_LIST,
          payload: [],
        });
        set3dModel(null)
    }


    function wrapTextureToModel(model_3d, texture_url, material_name) {
        return new Promise(async function (resolve, reject) {
            threedOut.textureLoaderPromise(model_3d, texture_url, material_name).then(() => {
                resolve(true);
            }).catch(error => {
                reject(error);
            })
        })
    }

    function render3DModel(template_side) {
        dispatch({
            type: TOGGLE_JEWELLERY_PRODUCT_LIST_LOADER,
            payload: true,
        });
        console.log('HEREEEEE');
        const template_sides = Object.keys(template_side);
        const first_template_side = template_side[template_sides[0]];
        const template_fonts_face = first_template_side['font_face'] 
            ? first_template_side['font_face'] 
            : [];
        const model_materials = first_template_side['3d_model']['material'];
        const template_3d_model = first_template_side['3d_model']["link"];
        const cameraX = parseInt(first_template_side['3d_model']["cameraX"]);
        const cameraY = parseInt(first_template_side['3d_model']["cameraY"]);
        const cameraZ = parseInt(first_template_side['3d_model']["cameraZ"]);
        
        const threeD_model_div_id = 'threejs_preview';

        if (template_3d_model !== 0) {
            console.log('1:',template_3d_model);
            console.log('2:',current3DModelLink,);
            if (current3DModelLink == '' || template_3d_model != current3DModelLink) {
                var mount = document.getElementById(threeD_model_div_id);
                mount.replaceChildren();
                // threedOut.createThreeScene(
                create3DScene(
                    template_3d_model,
                    cameraX,
                    cameraY,
                    cameraZ,
                    threeD_model_div_id,
                    null,
                    set3dModel,
                    setModelReady
                ).then(model => {
                    console.log('Model Loaded');
                    // current_3d_model_link = template_3d_model;
                    setCurrent3DModelLink(template_3d_model);
                    console.log('current_3d_model_link', model);
                    set3dModel(model);
                    dispatch({
                        type: TOGGLE_JEWELLERY_PRODUCT_LIST_LOADER,
                        payload: false,
                    })
                }).catch(error => {
                    dispatch({
                        type: TOGGLE_JEWELLERY_PRODUCT_LIST_LOADER,
                        payload: false,
                    });
                });
            }
        }
    }

    return (
        <JewelleryPitchPreview>
            {(templateListLoader || jewellery_product_list_loader) && <ModalLoader loadingTitle={'Loading templates...'} />}
            <div className='modal-l-editor '>
                <div className="editor-wrap1">
                    <h2 className="modal-hd2">CUSTOMIZE YOUR ENTIRE BOX</h2>
                    <div className="editor-txt1">Choose from various templates, add photos, messages <br/>or backgrounds to make the box unmistakably yours</div>
                </div>
                <div className="choose-template-wrap">
					<h2 className="modal-hd3">Choose Template</h2>
                    <div className="template-list">
                        {template_list.length > 0 && template_list.map((template_side, index) => {
                            const template_sides = Object.keys(template_side);
                            const first_template_side = template_side[template_sides[0]];
                            const thumbnail = first_template_side['thumb_image'];
                            const canvas_json_data = first_template_side['canvas_json_data'];
                            
                            return (
                                <JewelleryTemplateCard 
                                    key={index} 
                                    active={first_template_side} 
                                    template_thumbnail={thumbnail} 
                                    canvas_json_data={canvas_json_data}  
                                    template_side={template_side} 
                                    templateSelected={templateSelected}
                                    OnTemplateSelected={() => {
                                        console.log('model_3d');
                                        render3DModel(template_side)
                                        const template_side_titles = Object.keys(template_side);
                                        
                                        setTemplateSelected(template_side[template_side_titles[0]])

                                        var wrap_all_sides = [];
                                        console.log('template_side',template_side);
                                        var first_template_side = template_side[Object.keys(template_side)[0]];
                                        console.log('first_template_side:',template_side, Object.keys(template_side));
                                        const model_materials = first_template_side['3d_model']['material'];
                                        var side_index = 0;
                                        Object.entries(template_side).map(([side_name, template]) => {
                                            dispatch({
                                                type: TOGGLE_JEWELLERY_PRODUCT_LIST_LOADER,
                                                payload: true,
                                            });
                                            const material_name = model_materials[side_index];
                                            const texture_url = JEWELLERY_TEMPLATE_TEXTURE_URL + brand + '/' + template['id'] + '.png';
                                            // const model_materials = template['3d_model']['material'];
                                            // const material_name = model_materials[0];

                                            console.log(texture_url, model_materials, material_name);
                                            
                                            model_3d.traverse( function( object ) {

                                                if ( object.isMesh ) {
                                                    console.log('MESHHHH:', object.material );
                                                    object.material.map = null;
                                                }
                                            
                                            } );
                                            console.log('model_3d.children.length', model_3d.children.length);
                                            // for (let i = model_3d.children.length - 1; i >= 0; i--) {
                                            //     if(model_3d.children[i].type === "Mesh")
                                            //         model_3d.remove(model_3d.children[i]);
                                            // }

                                            wrap_all_sides.push(
                                                wrapTextureToModel(model_3d, texture_url, material_name).then(() => {
                                                    console.log('Texture fetched');
                                                }).catch((error) => {
                                                    console.log('Texture failed', error);
                                                })
                                            );

                                            side_index = side_index + 1;
                                        })

                                        Promise.all(wrap_all_sides).then((value) => {
                                            console.log('Wrapping donee!!!!');
                                            dispatch({
                                                type: TOGGLE_JEWELLERY_PRODUCT_LIST_LOADER,
                                                payload: false,
                                            });
                                        }).catch(error => {
                                            dispatch({
                                                type: TOGGLE_JEWELLERY_PRODUCT_LIST_LOADER,
                                                payload: false,
                                            });
                                        });
                                    }}
                                />
                            )
                        })}
                    </div>
                </div>
                <div className='editor-wrap3 next-btn'>
                    <GroupButton className={'add-msg-btn'}><a onClick={()=>{
                        if(templateSelected.id){
                            navigate('/pcj/customise_message_screen',{state:{templateId:templateSelected.id}})                            
                        }else{
                            const template_side = template_list[0]
                            const template_sides = Object.keys(template_side);
                            const first_template_side = template_side[template_sides[0]];
                            navigate('/pcj/customise_message_screen',{state:{templateId:first_template_side.id}})
                            
                            
                        }
                        settingDefault()
                        
                    }}>Next</a></GroupButton>
                </div>
            </div>

            <div id='threejs-div' className='modal-r-3d-preview w-60'>
                <div className="threejs_preview" id="threejs_preview" style={{width:'100%',height:'100%'}}></div>
                <GroupButton  className={'open-close-tabs'}>
                    <a  onClick={()=>{
                    // boxOpen = true;
                        if(!boxOpen){
                            openBox();
                            console.log('threedOut in fabricCanvasSetup',threedOut);
                            // threedOut.playClipByIndex(0)
                        }
                        setBoxOpen(true)
                    }}>Open Box</a>
                    <a onClick={()=>{
                    // boxOpen = false;
                        
                        setBoxOpen(false)
                        closeBox();
                    }}>
                    Close Box
                    </a>  
                </GroupButton>
            </div>

        </JewelleryPitchPreview>
        
    )
}

export {TemplateOptionsScreen};