import React, { useEffect, useState } from 'react'
import { Image, Carousel } from 'react-bootstrap'
import { Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { savingClientName } from '../Actions';
import {
  ClientHeader,
  ClientListButton,
  EditableLabel,
  ToggleButton
} from './';
// import {
//   createThreeScene,
//   makeTexture,
//   removeThreeScene,
// } from '../extras/ThreeDPreview';

import { ThreeDPreview2 } from '../extras/ThreeDpreview2'

function ImagePreviewer({
  images,
  onPressSave,
  onPressBack,
  model_3d,
  children,
}) {
  const threeDin = new ThreeDPreview2()
  const [slideIndex, setSlideIndex] = useState(0)
  const [toggleViewer, setToggleViewer] = useState('2D')
  const [keyArrays, setKeyArrays] = useState([])
  const dispatch = useDispatch()
  const reduxState = useSelector((state) => state.template);
  const {
    list_of_client_button,
    client_name,
  } = reduxState;
  const location = useLocation();

  useEffect(() => {
    const key_array = Object.keys(images)
    setKeyArrays(key_array)
    // animateusingJquery()
    // setSlideIndex(key_array[0])
  }, [])
  // var original_threejs_mount;
  const moveDot = index => {
    setSlideIndex(index)
  }


  const nextSlide = () => {

    setSlideIndex(slideIndex === keyArrays.length - 1 ? 0 : slideIndex + 1)
  }

  const prevSlide = () => {
    setSlideIndex(slideIndex === 0 ? keyArrays.length - 1 : slideIndex - 1)
  }
  function changingClientName(event) {
    dispatch(savingClientName(event.target.value))
  }


  function renderToggleOption2d() {
    const key_array = Object.keys(images)
    if (Object.keys(images).length === 2) {
      return (
        <div className="slider-nxt-bck">
          <div className="design-frnt-bck frnt-bck position-a">
            <a onClick={() => setSlideIndex(0)} className={0 === slideIndex ? 'active' : ''}>
              {key_array[0]}
            </a>{" "}
            <a onClick={() => setSlideIndex(1)} className={1 === slideIndex ? 'active' : ''}>{key_array[1]}</a>
          </div>
        </div>
      )
    }
    else if (key_array.length > 2) {
      return (
        <div className="design-frnt-bck frnt-bck position-a next-prev-buttons">
          <Button className='prev-arrow' onClick={() => prevSlide()}>Prev</Button>
          <Button className='next-arrow' onClick={() => nextSlide()}>Next</Button>
        </div>
      )
    }
  }

  function render2D3DViewer() {
    if (toggleViewer === '2D') {
      // console.log('Return 2D');
      {/* <!-- The slideshow --> */ }
      return (
        <div className='d-block'>
          {renderToggleOption2d()}
          <Carousel activeIndex={slideIndex} onSelect={moveDot} controls={false} >
            {Object.keys(images).map((key, index) => (
              <Carousel.Item key={index} >
                <Image src={images[key].svgObject} className='carousel-image' />
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      )
    } else {
      // console.log('Return 3D');

      const template_3d_model = location.state.template_3d_model['link'];
      const cameraX = parseInt(location.state.template_3d_model['cameraX']);
      const cameraY = parseInt(location.state.template_3d_model['cameraY']);
      const cameraZ = parseInt(location.state.template_3d_model['cameraZ']);


      threeDin.createThreeScene(template_3d_model, cameraX, cameraY, cameraZ, 'threejs_preview', null, null, null).then(model => {
        var template_sides_images = {};
        for (const [side_name, side_image] of Object.entries(images)) {
          template_sides_images[side_name] = side_image.svgObject;
        }
        const materials = location.state.template_3d_model['material'];
        // console.log('materials', materials, template_sides_images);
        // var index = 0;
        Object.keys(template_sides_images).forEach((side_name, index) => {
          // console.log('SIDENAME:', side_name, materials[index]);
          threeDin.makeTexture(true, model, template_sides_images, materials[index], side_name);
          // index = index + 1;
        });
      })
    }
  }

  return (
    <>
      <div className="upload-section height-120">
        <ClientHeader />

        <div className="design-section review review-screen">
          <div className="design-container">
            <div className="upload-steps upload-steps2">
              <ClientListButton buttonList={list_of_client_button} active="Review" completed={'Design'} />
            </div>
            <div className="upload-container1 upload-review d-flex flex-wrap review1 ">
              <div className="back-arrow back-arrow-align">
                <a onClick={onPressBack}>
                  <img src="images/arrow-dw6.svg" alt="" />
                </a>
                {model_3d && (
                  <ToggleButton value1={'3D'} value2={'2D'} changeValueFun={() => {
                    setToggleViewer('3D')
                  }}
                    changeValueFun2={() => {
                      setToggleViewer('2D')
                      const mount = document.getElementById('threejs_preview');
                      threeDin.removeThreeScene(mount);
                    }}
                    currentVal={toggleViewer} />
                )}
              </div>
              <div className="design-review edit-design w-2">
                <EditableLabel onChange={(e) => changingClientName(e)} changeValue={client_name} />
                <div className="design-review-row1 d-flex flex-wrap" id="threejs_preview">
                  {render2D3DViewer()}
                </div>
                <div className="continue-btns d-flex flex-wrap mrgt-75">
                  {/* <a className="bt1" href="">
                    CONTINUE SHOPPING
                  </a> */}
                  <a onClick={onPressSave} className="active hr1">
                    ADD TO CART
                  </a>
                </div>
              </div>
              {/* <div className="next-cncl w-1 hide-m">
                <div className="w-100 download-pdf">
                  <a href="">Download PDF proof</a>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );


}

export { ImagePreviewer }