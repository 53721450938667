import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import CacheBuster from 'react-cache-buster';
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,  
} from "react-router-dom";
import {
  UploadTemplateScreen,
  UploadFontsScreen,
  LayerListCanvasScreen,
  TemplatesScreen,
  TemplateEditorScreen2,
  TemplateEditorScreen,
  UploadFontFamilyScreen,
  EditingScreen,
  ClientTemplatesScreen,
  ClientTemplateDraft,
  TemplateSidesScreen,
  FontList,
  TemplateDesignNameScreen,
  ImagePreviewer,
  RepeatedOrder,
  ThreeDModelScreen,
  ReviewTemplate,
  TempModal,
  Generate3dThumbnailScreen,
  AllDiesScreen,
  LoginScreen,
} from "./Screens";
import {
  CustomisationTypeScreen,
  CustomMessageScreen,
  ChooseTemplateScreen,
} from './Screens/PCJeweller';
import {
  ProductOptionsScreen,
  TemplateOptionsScreen,
} from './Screens/JewelleryPitch';

import {
  ImageTextureToShoeScreen,
  ImageTextureToHoddyScreen,
  ImageTextureToJacketScreen,
  ImageTextureToiPadScreen,
  ImageTextureToChairScreen,
  ImageTextureToRingScreen,
  ImageTextureToApartmentScreen,
  ImageTextureToBedroomScreen,
  ImageTextureToRoomScreen,
  Product3DOptionsScreen,
  Product3DModelRendering,
} from './Screens/3DModelImageTextureWrapping';
import './index.css';
// import './css'
import packageJson from '../package.json';
import reportWebVitals from './reportWebVitals';
import store from './Reducers/store';
import { CustomAlert, Header, SideBar } from './Components';
import ProtectedRoutes from './Screens/ProtectedRoutes';

const container = document.getElementById('root');
const root = createRoot(container);
const isProduction = process.env.NODE_ENV === 'production';
const {version} = packageJson;


root.render(
  <CacheBuster
      currentVersion={version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      // loadingComponent={<Loading />} //If not pass, nothing appears at the time of new version check.
    >
    <Provider store={store}>
      <>
      <CustomAlert />
      <BrowserRouter>
      {/* <Header />
      <div className='main-container'>
        <div className='container2'>
          <SideBar />
          <div className='main-content new-wrapper content-wrapper' style={{minHeight:'549px'}}>
          <div className='form-inside table-panel table-main-1 x_panel no-bg'> */}
        <Routes>
          {/* Common Routes */}
          <Route path="/login" element={<LoginScreen />} />

          {/* <Route path="/template_list" element={<TemplatesScreen />} /> */} 

          {/* Protected Routes start here */}
          <Route  element={<ProtectedRoutes /> } >
            <Route path="/template_list" element={<TemplatesScreen />} />
            <Route path="/"  element={<TemplatesScreen />} />
            <Route path="/upload_template" element={<UploadTemplateScreen />} />
            <Route path="/upload_fonts" element={<UploadFontsScreen />} />
            <Route path="/layer_permissions" element={<LayerListCanvasScreen />} />
            
            <Route path="/add_fonts" element={<UploadFontFamilyScreen />} />
            <Route path='/edit_template' element={<EditingScreen />} />
            <Route path='/client_template' element={<ClientTemplatesScreen />} />
            <Route path='/client_draft' element={<ClientTemplateDraft />} />
            <Route path='/template_sides' element={<TemplateSidesScreen />} />
            <Route path='/fonts_list' element={<FontList />} />
            <Route path='repeated_orders' element={<RepeatedOrder/>} />
            <Route path='preview_template' element={<ReviewTemplate />} />
            <Route path='3dModel' element={<ThreeDModelScreen />} />
            <Route path='/modal' element={<TempModal />} />
            <Route path='/generate_thumbnail' element={<Generate3dThumbnailScreen />} />
            <Route path='/all_dies' element={<AllDiesScreen />} />

            {/* PCJ Routes */}
            <Route path="/pcj" element={<CustomisationTypeScreen />} />
            <Route path="/pcj/customisation_type_screen" element={<CustomisationTypeScreen />} />
            <Route path="/pcj/customise_message_screen" element={<CustomMessageScreen />} />
            <Route path="/pcj/choose_customisation_template" element={<ChooseTemplateScreen />} /> 
            <Route path="/PCJ" element={<Navigate to="/pcj/customisation_type_screen"/>} />


            {/* Jewellery Pitch routes */}
            <Route path="/:brand/jewellery_pitch" element={<ProductOptionsScreen />} />
            <Route path="/:brand/template_options" element={<TemplateOptionsScreen />} /> 

            {/* Product Image Texture to 3D Model Routes */}
            {/* <Route path="/shoes" element={<ImageTextureToShoeScreen />} />
            <Route path="/hoddy" element={<ImageTextureToHoddyScreen />} />
            <Route path="/jacket" element={<ImageTextureToJacketScreen />} />
            <Route path="/ipad" element={<ImageTextureToiPadScreen />} />
            <Route path="/chair" element={<ImageTextureToChairScreen />} />
            <Route path="/ring" element={<ImageTextureToRingScreen />} />
            <Route path="/3dProducts" element={<Product3DOptionsScreen />} /> */}

          </Route>

          {/* end here */}
          <Route path="/editor" element={<TemplateEditorScreen />} />
          <Route path="/design/:param" element={<TemplateDesignNameScreen />} />

                  
          <Route
            path="*"
            element={
              <main style={{ padding: "1rem" }}>
                <p>There's nothing here!</p>
              </main>
            }
          />

          
          <Route path='/3dmodels/:model_name' element={<Product3DModelRendering />} />
          
          {/* Product Image Texture to 3D Model Routes */}
          <Route path="/shoes" element={<ImageTextureToShoeScreen />} />
          <Route path="/hoddy" element={<ImageTextureToHoddyScreen />} />
          <Route path="/jacket" element={<ImageTextureToJacketScreen />} />
          <Route path="/ipad" element={<ImageTextureToiPadScreen />} />
          <Route path="/chair" element={<ImageTextureToChairScreen />} />
          <Route path="/ring" element={<ImageTextureToRingScreen />} />
          <Route path="/apartment" element={<ImageTextureToApartmentScreen />} />
          <Route path="/bedroom" element={<ImageTextureToBedroomScreen />} />
          <Route path="/room" element={<ImageTextureToRoomScreen />} />
          <Route path="/3dProducts" element={<Product3DOptionsScreen />} />
          ImageTextureToRoomScreen
        </Routes>
        {/* </div>
        </div>
        </div>
        </div> */}
      </BrowserRouter>
      </>
    </Provider>
  </CacheBuster>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(// console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
