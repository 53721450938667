import React from 'react'

function RenderRowsAndColumn({dataTitle,children,tableClassname}) {
    return (
        <tr>
            <td colSpan={2}>
                <table className={`tags-wrap ${tableClassname ? tableClassname :''}`}>
                    <tbody>
                        <tr><td data-title={dataTitle} className='p-0-td'>{dataTitle}</td></tr>
                        <tr><td data-title={dataTitle} className='p-0-td'>
                            {children}
                        </td></tr>
                    </tbody>
                </table>
            </td>
        </tr>
    )
}

export { RenderRowsAndColumn }