import React from 'react'
import '../css/TempModal.css'
function TempModal() {
	return (
		<>
			<div className="overlay">
				<div className="overlayDoor"></div>
				<div className="overlayContent">
					{/* <div className="loader">
						<div className="inner"></div>
					</div> */}
					<div>
						<img src="../images/spinner-loader.gif" />
					</div>
					<div className="skip">Loading Images....</div>
				</div>
			</div>
		</>
	)
}

export { TempModal }