import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { fabric } from 'fabric';
import {renderFontAnd3DModel, applyTemplateTextureTo3DModel, openBox, closeBox} from '../../extras/FabricCanvasSetup';
import { getTemplateList } from '../../Actions';
import '../../css/PCJeweller/ChooseTemplateScreen.css';
import { CustomAlert, GroupButton, ModalLoader, PCJPreview } from '../../Components';
import { PCJ_TEMPLATE_LOADER, SAVE_TEMPLATES_LIST } from '../../Actions/ActionTypes';
import { useNavigate } from 'react-router-dom';



function ChooseTemplateScreen() {
    const dispatch = useDispatch();
    const reduxState = useSelector((state) => state.template);
    const [boxOpen,setBoxOpen] = useState(false)
    const [model_3d, set3dModel] = useState(null);
    const [model_ready, setModelReady] = useState(false);
    const [templateSelected,setTemplateSelected] = useState({})

    const {
        templateListLoader,
        template_list,
        fonts_list,
        swatch,
        client_name,
        list_of_client_button,
        canvas_svg_for_3d,
        // canvas,
        client_selected_layer,
        original_font_styles,
        layer_permissions,
        show_image_picker_modal,
        template_sides_canvas_json,
        pcjTemplateLoader,
    } = reduxState;
    
    const navigate = useNavigate();

    console.log('template_list',template_list);

    useEffect(() => {
        
        const controller = new AbortController()
        dispatch(getTemplateList("1414, 1405", 1, 15, 1,'','','','','','',null,controller));

        return () => {
            settingDefault()
            controller.abort()
        }
    }, [dispatch])


    useEffect(() => {
        if (template_list.length > 0) {
            const template_side = template_list[0];
            const threejs_div = document.getElementById("threejs-div") ? document.getElementById("threejs-div") : 0;
            const threejs_client_width = parseInt(threejs_div.clientWidth, 10)
            const threejs_client_height = threejs_client_width * (window.screen.availHeight / window.screen.availWidth);
            if (document.getElementById("threejs")) {
                const threejs_canvas_div = document.getElementById("threejs");
                threejs_canvas_div.style.width = threejs_client_width;
                threejs_canvas_div.style.height = threejs_client_height;
            }
            renderTextureTo3DModel(template_side)
        }
    }, [template_list]);

    function renderTextureTo3DModel(template_side) {
        dispatch({
            type: PCJ_TEMPLATE_LOADER,
            payload: true,
        });
        const template_sides = Object.keys(template_side);
        const first_template_side = template_side[template_sides[0]];
        const template_fonts_face = first_template_side['font_face'] 
            ? first_template_side['font_face'] 
            : [];
        const model_materials = first_template_side['3d_model']['material'];
        const template_3d_model = first_template_side['3d_model']["link"];
        const cameraX = parseInt(first_template_side['3d_model']["cameraX"]);
        const cameraY = parseInt(first_template_side['3d_model']["cameraY"]);
        const cameraZ = parseInt(first_template_side['3d_model']["cameraZ"]);
        
        const threeD_model_div_id = 'threejs_preview';
        renderFontAnd3DModel(
            threeD_model_div_id,
            template_fonts_face,
            model_materials,
            template_3d_model,
            cameraX,
            cameraY,
            cameraZ,
            model_ready,
            null,
            set3dModel,
            setModelReady,
          ).then((value) => {

            dispatch({
                type: PCJ_TEMPLATE_LOADER,
                payload: false,
            });
            console.log('Model ready:', value);
          })
    }

    // This will be a reusable component
    function RenderTemplateCard({thumbnail, canvas_json_data, template_side,first_side}) {
        return (
            <div  className={`template-img ${first_side.id == templateSelected.id ? 'active' : ''}`} style={{cursor:'pointer'}}  onClick={() => {
                // renderTextureTo3DModel(template_side);
                console.log('model_3d');
                const template_side_titles = Object.keys(template_side);
                
                setTemplateSelected(template_side[template_side_titles[0]])
                const texture_url = template_side[template_side_titles[0]]['model_image_texture'];
                const model_materials = template_side[template_side_titles[0]]['3d_model']['material'];
                const material_name = model_materials[0];
                
                applyTemplateTextureTo3DModel(texture_url, model_3d, model_ready, material_name)
            }}>
                
                <img src={thumbnail} style={{width:150, height: 200}} />
                
            </div>
        )
    }

    function settingDefault(){
        dispatch({
          type: SAVE_TEMPLATES_LIST,
          payload: [],
        });
        set3dModel(null)
      }

    

    return (
        <PCJPreview>
            {(templateListLoader || pcjTemplateLoader) && <ModalLoader loadingTitle={'Loading templates...'} />}
            <div className='modal-l-editor '>
                <div className="editor-wrap1">
                    <h2 className="modal-hd2">CUSTOMIZE YOUR ENTIRE BOX</h2>
                    <div className="editor-txt1">Choose from various templates, add photos, messages <br/>or backgrounds to make the box unmistakably yours</div>
                </div>
                <div className="choose-template-wrap">
					<h2 className="modal-hd3">Choose Template</h2>
                    <div className="template-list">
                        {template_list.length > 0 && template_list.map((template_side, index) => {
                            const template_sides = Object.keys(template_side);
                            const first_template_side = template_side[template_sides[0]];
                            const thumbnail = first_template_side['thumb_image'];
                            const canvas_json_data = first_template_side['canvas_json_data'];
                            
                            return (
                                <RenderTemplateCard key={index} first_side={first_template_side} thumbnail={thumbnail} canvas_json_data={canvas_json_data}  template_side={template_side} />
                            )
                        })}
                    </div>
                </div>
                <div className='editor-wrap3 next-btn'>
                    <GroupButton className={'add-msg-btn'}><a onClick={()=>{
                        if(templateSelected.id){
                            navigate('/pcj/customise_message_screen',{state:{templateId:templateSelected.id}})                            
                        }else{
                            const template_side = template_list[0]
                            const template_sides = Object.keys(template_side);
                            const first_template_side = template_side[template_sides[0]];
                            navigate('/pcj/customise_message_screen',{state:{templateId:first_template_side.id}})
                            
                            
                        }
                        settingDefault()
                        
                    }}>Next</a></GroupButton>
                </div>
            </div>

            <div id='threejs-div' className='modal-r-3d-preview w-60'>
                <div className="threejs_preview" id="threejs_preview" style={{width:'100%',height:'100%'}}></div>
                <GroupButton  className={'open-close-tabs'}>
                    <a  onClick={()=>{
                    // boxOpen = true;
                        if(!boxOpen){
                            openBox();
                        }
                        setBoxOpen(true)
                    }}>Open Box</a>
                    <a onClick={()=>{
                    // boxOpen = false;
                        
                        setBoxOpen(false)
                        closeBox();
                    }}>
                    Close Box
                    </a>  
                </GroupButton>
            </div>

        </PCJPreview>
        
    )
}


export {ChooseTemplateScreen};