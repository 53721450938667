import {
    TOGGLE_JEWELLERY_PRODUCT_LIST_LOADER,
    SAVE_JEWELLERY_PRODUCT_LIST,
    SAVE_BRAND_NAME,
} from '../Actions/ActionTypes';
import {PRODUCT_3D_MODEL_WRAPPING} from '../extras/APIs';

import { 
    renderProductAnimation,
    renderProductAnimationReverse,
} from "../extras/3DProductWrappingCommonFunctions";

const product3Models = {
    shoes : {
        render_3d_model:PRODUCT_3D_MODEL_WRAPPING + "ShoeV1/Shoes-withoutTex.glb",
        cameraX:-610,
        cameraY:380,
        cameraZ:427,
        material_name:'shoes',
        roughness_texture_url: PRODUCT_3D_MODEL_WRAPPING + 'ShoeV1/Textures/defaultMat_Roughness.jpg',
        metalness_texture_url: PRODUCT_3D_MODEL_WRAPPING + 'ShoeV1/Textures/defaultMat_Metallic.jpg',
        ao_texture_url: PRODUCT_3D_MODEL_WRAPPING + 'ShoeV1/Textures/defaultMat_Mixed_AO.jpg',
        normal_texture_url : PRODUCT_3D_MODEL_WRAPPING + 'ShoeV1/Textures/defaultMat_Normal_OpenGL.jpg',
        product_3d_textures_urls: [
            PRODUCT_3D_MODEL_WRAPPING+ "ShoeV1/Textures/defaultMat_Base_Color_A.jpg",
            PRODUCT_3D_MODEL_WRAPPING + "ShoeV1/Textures/defaultMat_Base_Color_B.jpg", 
            PRODUCT_3D_MODEL_WRAPPING + "ShoeV1/Textures/defaultMat_Base_Color.jpg"],
        product_3d_textures_thumbnail_urls: [
            PRODUCT_3D_MODEL_WRAPPING+ "ShoeV1/Textures/Thumbnail_defaultMat_Base_Color_A.jpg",
            PRODUCT_3D_MODEL_WRAPPING + "ShoeV1/Textures/Thumbnail_defaultMat_Base_Color_B.jpg", 
            PRODUCT_3D_MODEL_WRAPPING + "ShoeV1/Textures/Thumbnail_defaultMat_Base_Color.jpg"]
    },
    hoddy : {
        render_3d_model:PRODUCT_3D_MODEL_WRAPPING + "HoddyV1/Hooded-Sweatshirt-v3-reverse-normals.glb",
        cameraX:0,
        cameraY:130,
        cameraZ:222,
        material_name:'fbx_Hooded_Sweatshirt_v2',
        roughness_texture_url: PRODUCT_3D_MODEL_WRAPPING + 'HoddyV1/Textures/hoodie_Knit_Cotton_Jersey_FRONT_2657_metallicRoughness.png',
        metalness_texture_url: PRODUCT_3D_MODEL_WRAPPING + 'HoddyV1/Textures/hoodie_Knit_Cotton_Jersey_FRONT_2657_metallicRoughness.png',
        ao_texture_url: null,
        // normal_texture_url : PRODUCT_3D_MODEL_WRAPPING + 'HoddyV1/Textures/hoodie_Knit_Cotton_Jersey_FRONT_2657_normal.jpg',
        normal_texture_url: null,
        product_3d_textures_urls: [
            PRODUCT_3D_MODEL_WRAPPING + "HoddyV1/Textures/hoodie_Knit_Cotton_Jersey_FRONT_2657_baseColor.png",
            PRODUCT_3D_MODEL_WRAPPING + "HoddyV1/Textures/hoodie_Knit_Cotton_Jersey_FRONT_2657_baseColor_3.png",
            PRODUCT_3D_MODEL_WRAPPING + "HoddyV1/Textures/hoodie_Knit_Cotton_Jersey_FRONT_2657_baseColor_4.png",
            PRODUCT_3D_MODEL_WRAPPING + "HoddyV1/Textures/hoodie_Knit_Cotton_Jersey_FRONT_2657_baseColor_2.png",
            PRODUCT_3D_MODEL_WRAPPING + "HoddyV1/Textures/hoodie_Knit_Cotton_Jersey_FRONT_2657_baseColor_5.png"],
        product_3d_textures_thumbnail_urls: [
            PRODUCT_3D_MODEL_WRAPPING + "HoddyV1/Textures/Thumbnail_hoodie_Knit_Cotton_Jersey_FRONT_2657_baseColor.jpg",
            PRODUCT_3D_MODEL_WRAPPING + "HoddyV1/Textures/Thumbnail_hoodie_Knit_Cotton_Jersey_FRONT_2657_baseColor_3.jpg",
            PRODUCT_3D_MODEL_WRAPPING + "HoddyV1/Textures/Thumbnail_hoodie_Knit_Cotton_Jersey_FRONT_2657_baseColor_4.jpg",
            PRODUCT_3D_MODEL_WRAPPING + "HoddyV1/Textures/Thumbnail_hoodie_Knit_Cotton_Jersey_FRONT_2657_baseColor_2.jpg",
            PRODUCT_3D_MODEL_WRAPPING + "HoddyV1/Textures/Thumbnail_hoodie_Knit_Cotton_Jersey_FRONT_2657_baseColor_5.jpg"
        ]
    },
    jacket : {
        render_3d_model:PRODUCT_3D_MODEL_WRAPPING + "JacketV1/leather-jacket-revised-v2.glb",
        cameraX:-2,
        cameraY:2,
        cameraZ:9,
        material_name:'ZIPPERJacket_low1',
        roughness_texture_url: PRODUCT_3D_MODEL_WRAPPING + 'JacketV1/Textures/ZIPPER_JacketMAT_roughness.jpeg',
        metalness_texture_url: PRODUCT_3D_MODEL_WRAPPING + 'JacketV1/Textures/ZIPPER_JacketMAT_metallic.jpeg',
        ao_texture_url: null,
        normal_texture_url : PRODUCT_3D_MODEL_WRAPPING + 'JacketV1/Textures/ZIPPER_JacketMAT_normal_compressed.jpeg',
        product_3d_textures_urls: [
            PRODUCT_3D_MODEL_WRAPPING + "JacketV1/Textures/ZIPPER_JacketMAT_albedo.jpeg",
            PRODUCT_3D_MODEL_WRAPPING + "JacketV1/Textures/ZIPPERJacketMAT_baseColor_2.jpg",
            PRODUCT_3D_MODEL_WRAPPING + "JacketV1/Textures/ZIPPERJacketMAT_baseColor_3.jpg",
            PRODUCT_3D_MODEL_WRAPPING + "JacketV1/Textures/ZIPPERJacketMAT_baseColor_4.jpg"],
        product_3d_textures_thumbnail_urls: [
            PRODUCT_3D_MODEL_WRAPPING + "JacketV1/Textures/ZIPPER_JacketMAT_albedo.jpeg",
            PRODUCT_3D_MODEL_WRAPPING + "JacketV1/Textures/ZIPPERJacketMAT_baseColor_2.jpg",
            PRODUCT_3D_MODEL_WRAPPING + "JacketV1/Textures/ZIPPERJacketMAT_baseColor_3.jpg",
            PRODUCT_3D_MODEL_WRAPPING + "JacketV1/Textures/ZIPPERJacketMAT_baseColor_4.jpg"]
    },
    ipad : {
        render_3d_model:PRODUCT_3D_MODEL_WRAPPING + "iPadV1/ipad_with_animation.glb",
        cameraX:-50,
        cameraY:38,
        cameraZ:45,
        material_name:'a3_ipad_0',
        roughness_texture_url: PRODUCT_3D_MODEL_WRAPPING + 'iPadV1/Textures/ipad_metallicRoughness.png',
        metalness_texture_url: PRODUCT_3D_MODEL_WRAPPING + 'iPadV1/Textures/ipad_metalness_grey.png',
        ao_texture_url: null,
        normal_texture_url : null,
        product_3d_textures_urls: [
            PRODUCT_3D_MODEL_WRAPPING + "iPadV1/Textures/ipad_baseColor_silver.png",
            PRODUCT_3D_MODEL_WRAPPING + "iPadV1/Textures/ipad_baseColor.png",
        ],
        product_3d_textures_thumbnail_urls: [
            PRODUCT_3D_MODEL_WRAPPING + "iPadV1/Textures/Thumbnail_ipad_baseColor_silver.png",
            PRODUCT_3D_MODEL_WRAPPING + "iPadV1/Textures/Thumbnail_ipad_baseColor.png",
        ]
    },
    chair : {
        render_3d_model:PRODUCT_3D_MODEL_WRAPPING + "ChairV1/chair_single_mesh.glb",
        cameraX:-28,
        cameraY:38,
        cameraZ:50,
        material_name:'Shape003',
        roughness_texture_url: PRODUCT_3D_MODEL_WRAPPING + "ChairV1/Textures/wire_067060055_roughness.jpg",
        metalness_texture_url: PRODUCT_3D_MODEL_WRAPPING + "ChairV1/Textures/wire_067060055_metallic.jpg",
        ao_texture_url: PRODUCT_3D_MODEL_WRAPPING + "ChairV1/Textures/wire_067060055_AO.jpg",
        normal_texture_url : PRODUCT_3D_MODEL_WRAPPING + "ChairV1/Textures/wire_067060055_normal.jpg",
        product_3d_textures_urls: [
            PRODUCT_3D_MODEL_WRAPPING + "ChairV1/Textures/wire_067060055_albedo.jpg",
            PRODUCT_3D_MODEL_WRAPPING + "ChairV1/Textures/wire_067060055_albedo_1.jpg",
            PRODUCT_3D_MODEL_WRAPPING + "ChairV1/Textures/wire_067060055_albedo_2.jpg",
            PRODUCT_3D_MODEL_WRAPPING + "ChairV1/Textures/wire_067060055_albedo_3.jpg",
            PRODUCT_3D_MODEL_WRAPPING + "ChairV1/Textures/wire_067060055_albedo_4.jpg"
        ],
        product_3d_textures_thumbnail_urls: [
            PRODUCT_3D_MODEL_WRAPPING + "ChairV1/Textures/Thumbnail_wire_067060055_albedo.jpg",
            PRODUCT_3D_MODEL_WRAPPING + "ChairV1/Textures/Thumbnail_wire_067060055_albedo_1.jpg",
            PRODUCT_3D_MODEL_WRAPPING + "ChairV1/Textures/Thumbnail_wire_067060055_albedo_2.jpg",
            PRODUCT_3D_MODEL_WRAPPING + "ChairV1/Textures/Thumbnail_wire_067060055_albedo_3.jpg",
            PRODUCT_3D_MODEL_WRAPPING + "ChairV1/Textures/Thumbnail_wire_067060055_albedo_4.jpg"
        ]

    },
    ring : {
        render_3d_model:PRODUCT_3D_MODEL_WRAPPING + "RingV1/ring.glb",
        cameraX:-28,
        cameraY:38,
        cameraZ:50,
        material_name:'ring',
        roughness_texture_url: PRODUCT_3D_MODEL_WRAPPING + "RingV1/Textures/PBR-MetalRough_ring_Roughness.png",
        metalness_texture_url: PRODUCT_3D_MODEL_WRAPPING + "RingV1/Textures/PBR-MetalRough_ring_Metallic.png",
        ao_texture_url:  null,
        normal_texture_url : PRODUCT_3D_MODEL_WRAPPING + "RingV1/Textures/PBR-MetalRough_ring_Normal.png",
        stone_roughness_texture_url : PRODUCT_3D_MODEL_WRAPPING + "RingV1/Textures/PBR-MetalRough_emerald_Roughness.png",
        stone_metalness_texture_url : PRODUCT_3D_MODEL_WRAPPING + "RingV1/Textures/PBR-MetalRough_emerald_Metallic_full.png",
        stone_normal_texture_url : PRODUCT_3D_MODEL_WRAPPING + "RingV1/Textures/PBR-MetalRough_emerald_Normal.png",
        product_3d_textures_urls: [
            PRODUCT_3D_MODEL_WRAPPING + "RingV1/Textures/PBR-MetalRough_ring_BaseColor.png",
            PRODUCT_3D_MODEL_WRAPPING + "RingV1/Textures/PBR-MetalRough_ring_BaseColor_White_Gold.png",                        
        ],
        product_addition_texture: [
            PRODUCT_3D_MODEL_WRAPPING + "RingV1/Textures/PBR-MetalRough_emerald_BaseColor.png",
            PRODUCT_3D_MODEL_WRAPPING + "RingV1/Textures/PBR-MetalRough_emerald_BaseColor_yellow.png"
        ],
        button:[
            {
                'btn_name': "Animate",
                btn_fun:function (){
                    renderProductAnimation(0)
                }
            },
            {
                'btn_name':'Reverse Animation',
                btn_fun:function (){
                    renderProductAnimationReverse(0)
                }
            }
        ],
    },
}



var INITIAL_STATE = {
    jewellery_product_list: [],
    jewellery_product_list_loader: false,
    jewellery_pitch_brand_name: null,
    all_3d_products_data:{...product3Models}
}

export default (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case SAVE_JEWELLERY_PRODUCT_LIST:
            return {...state, jewellery_product_list: action.payload}
        case TOGGLE_JEWELLERY_PRODUCT_LIST_LOADER:
            return {...state, jewellery_product_list_loader: action.payload}
        case SAVE_BRAND_NAME:
            return {...state, jewellery_pitch_brand_name: action.payload}
        default:
            return state;
    }
}