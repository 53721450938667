import React, { useEffect, useState } from 'react'
import { TableBody } from './TableBody'

function LayerModal({ layers }) {
  // const [permssionName,setPermissionName] = useState([])

  const main_layers = layers.layers ? layers.layers : []
  const permission_layer = layers.layer_permissions ? layers.layer_permissions : []
  // console.log('main',layers);
  return (
    <>
      <form>
        <div id="table2" className="table-outer table-cards manage-shipper manage-editor-wrap">
              
                { main_layers.length>0 ? main_layers.map((layer,index)=>{
                  const fabric_layer_id = layer.fabric_layer_id
                  return (
                    <div key={index} tableName={layer.layer_name}>
					<h2 className="layer-hd2">{layer.layer_name}</h2>
                    <ul className="modal-layer-list">
					{permission_layer[fabric_layer_id]  ?permission_layer[fabric_layer_id].map((permission_name,i)=>(
                      
					  <li key={i}>{permission_name.permission}</li>
					  
                    )):(
                      <li className="no-layer-permission">permission not given</li>
                    )}
					</ul>
                    </div>
                  )
                }):(
                  <h2 className="no-layer-permission-hd layer-hd2">No Permission Added</h2>
                )
                }
        
        </div>
      </form>
    </>
  )
}

export { LayerModal }