import React from 'react'

function ModelContainer({imgName,title,desc,linkToGo,price,mainClass}) {
    const public_url = process.env.PUBLIC_URL
  return (
    <div onClick={linkToGo} style={{cursor:'pointer'}} className={mainClass}>
        <div className='customization-type-img'><img src={imgName} /></div>
        <div className='customization-type-hd'>{title}</div>
        <div className='customization-type-txt'>{desc}</div>
        <div className='customization-type-link'><a onClick={linkToGo}><img src={public_url+'/images/PCJIcons/arrow2.png'} /></a></div>
        <div className='customization-type-price'>{price}</div>
        
    </div>
  )
}

export {ModelContainer}