import React, { useCallback, useEffect } from "react";
import Dots from "react-activity/dist/Dots";
import { useDispatch, useSelector } from "react-redux";

import {
  savedTemplateList,
  createDigitalProof,
  clearDigitalProofImage,
  deleteSaveTemplate,
} from "../Actions";
import { PRE_PRESS_PDF_API } from "../extras/APIs";
import "../css/TemplatesScreen.css";
import "../css/TemplateEditorScreen.css";
import { Slider, Modal, HeadingTitle, Button, HeadPart, CustomAlert, ModalLoader } from "../Components";


function ClientTemplatesScreen() {
  
  const reduxState = useSelector((state) => state.template);
  const {
    saved_template_list,
    save_list_template_loader,
    save_template_loader,
    digital_proof_image,
    digital_proof_loader,
  } = reduxState;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(savedTemplateList());
    // console.log('save',save_list_template_loader);
  }, [dispatch]);

  const openModal = (output_id) => {
    if (output_id) {
      dispatch(createDigitalProof(output_id));
      document.body.classList.add('modal-open')
      document.body.style.paddingRight = '17px'
    } else {
      dispatch(clearDigitalProofImage())
      document.body.classList.remove('modal-open')
      document.body.style.paddingRight = '17px'
    }
  }

  function renderImagePickerModal() {
    // console.log('image', digital_proof_image); noHeight-overflow
    try {
      if (digital_proof_image.length !== 0) {
        return (
          <>
            <Modal headTitle={'Digital Proof'} modalBodyClass={""} classGiven={`modal fade font-popup ${digital_proof_image.length !== 0 ? 'show' : ''}`} style={digital_proof_image.length !== 0 ? { display: 'block', paddingRight: '17px' } : { display: 'none' }} onPress={() => openModal()}>
              <Slider image_data={digital_proof_image} />
            </Modal>
          </>
        );
      }
    } catch (e) {
      console.log('er', e);
      CustomAlert.alert(true, 'Something Went Wrong!')
    }
  }

  const openPrePressPDF = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  function onConfirm (confirmation,data,indexCol){    
    if(confirmation){
      dispatch(deleteSaveTemplate(data, indexCol))
    }
  }
  
  const CreateSavedListTable = useCallback(() => {
    // console.log('saved',save_list_template_loader)
    return (
      <>
        <HeadingTitle title={'Manage Save Template'} />
        {renderImagePickerModal()}
        {saved_template_list.length > 0 ? (
          <>
            {/* {save_list_template_loader && (<p style={{fontSize:'50px',fontWeight:'bold',margin:'50px'}}>Loading...</p>)  } */}
            <table id="templates">
              <thead>
                <tr>
                  <th>SR No</th>
                  <th>Client Name</th>
                  <th>Template Name</th>
                  <th>Digital Proof</th>
                  <th>Prepressed PDF</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {saved_template_list.map((template, index) => {
                  if (template.svg_name !== null) {
                    // console.log('save',template)
                    const template_name = template.svg_name.split("/")[1];
                    var template_side_titles = Object.keys(template)
                    var templates = template[template_side_titles[0]]
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{template.client_name}</td>
                        <td>{template_name}</td>
                        <td>
                          <Button
                            onPress={() => {
                              openModal(template.output_id)
                              // imageConverter(template.output_svg_url,750,750,1)
                              // .then(res=>{
                              //   console.log('res',res);
                              // })
                            }}
                            name={'Create'}
                            classgiven={'table-action digital-proof'}
                          />

                        </td>
                        <td>
                          <Button
                            onPress={() => {
                              var url =
                                PRE_PRESS_PDF_API +
                                "?output_svg_id=" +
                                template.output_id;
                              openPrePressPDF(url);
                            }}
                            name="Create"
                            classgiven={'table-action prepress-pdf'}
                          />
                        </td>
                        <td>
                          {/* dispatch(deleteSaveTemplate(saved_template_list,index)) */}
                          <Button onPress={() => {
                            
                            
                            CustomAlert.yes_or_no(
                            "Are you sure you want to delete this template!",
                            "YES",
                            "NO",
                            onConfirm,
                            saved_template_list,
                            index
                            );
                            
                          // dispatch(deleteSaveTemplate(saved_template_list, index))
                          }} name="Delete" classgiven={'table-action delete-btn'} />
                        </td>
                      </tr>
                    );
                  }
                })}
              </tbody>
            </table>

          </>
        ) : (
          <h1>Nothing is saved</h1>
        )}

      </>
    );
  }, [
    dispatch,
    saved_template_list,
    save_list_template_loader,
    save_template_loader,
    digital_proof_image,
    digital_proof_loader,
  ]);


  return (
    <>
      <HeadPart>
        {save_list_template_loader && (
          <ModalLoader openModal={save_list_template_loader} loadingTitle={'Save Template Loading'} />
        )}
          {CreateSavedListTable()}
      </HeadPart>
    </>
  );
}

export { ClientTemplatesScreen };
