import React from 'react'
import { useSelector } from 'react-redux'
import {Navigate,Outlet, Route,redirect} from 'react-router-dom'

function ProtectedRoutes({element,path}) {
    const isUserLoggedIn = useSelector(state=>state.login.user_logged_in)
  return isUserLoggedIn ?  (
    <Outlet />
  ): (
    <Navigate to={'/login'} replace />
  )
}

export default ProtectedRoutes