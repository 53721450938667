import { USER_DETAILS, USER_LOADER, USER_LOGGED_IN, USER_NAME, USER_PASSWORD } from "../Actions/ActionTypes"

var INITIAL_STATE = {
    username : '',
    password:'',
    user_details : localStorage.getItem('user_details') ? JSON.parse(localStorage.getItem('user_details')) : {} ,
    user_logged_in: localStorage.getItem('logged_in') == 'true' ? true : false, // user initial state are get here,
    logging_loader:false,
}

export default (state=INITIAL_STATE,action)=>{  
    switch(action.type){
        case USER_NAME:
            return {...state,username:action.payload}
            
        case USER_PASSWORD:
            return {...state,password:action.payload}

        case USER_DETAILS:
            return {...state,user_details:action.payload}

        case USER_LOGGED_IN:
            return {...state,user_logged_in:action.payload}
        
        case USER_LOADER:
            return {...state,logging_loader:action.payload}
        
        default :
            return state
    }
}