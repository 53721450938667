import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  getJewelleryProductList,
  saveBrandName,
  getTemplateList,
} from "../../Actions";
import {
    Button,
    HeadingTitle,
    Modal,
    UploadTemplateForm,
    Pagination,
    HeadPart,
    CustomAlert,
    Loader,
    ModalLoader,
    RenderRowsAndColumn,
    Checkbox,
    TemplateCard
  } from "../../Components";

  import { JewelleryProductCard } from '../../Components/JewelleryPitch';
import { PRODUCT_3D_MODEL_WRAPPING } from "../../extras/APIs";

function Product3DOptionsScreen() {
    const {brand} = useParams();
    const navigate = useNavigate();
    const reduxState = useSelector((state) => state.jewellery_pitch);
    const {
        jewellery_product_list,
        jewellery_product_list_loader,
    } = reduxState;

    const dispatch = useDispatch();

    useEffect(() => {
        // dispatch(getJewelleryProductList());
        // dispatch(saveBrandName(brand));
        // console.log('From redux:',jewellery_product_list, jewellery_product_list_loader);
    }, [dispatch])

    function createJewelleryProductListTable() {
        return (
          <>
            {/* Here head part start */}
            <HeadPart hide_menu={true}>
              <div className="nk-block-head full-hd-wrap">
                <HeadingTitle title={"3D Sample Product List"} />
              </div>
              <div className="inside-bg-wrap">
                <div className="manage-template-wrap">
                  <div className="catergory-listing">
                    <div className="catergory-row">
                      <JewelleryProductCard 
                          key={1}
                          product_name="Shoes"
                          product_image={PRODUCT_3D_MODEL_WRAPPING + "ShoeV1/shoes_thumb.png"}
                          OnClickViewButton={() => {
                            navigate('/3dmodels/shoes');
                          }}
                      />

                      <JewelleryProductCard 
                          key={2}
                          product_name="Chair"
                          product_image={PRODUCT_3D_MODEL_WRAPPING + "ChairV1/chair_thumb.png"}
                          OnClickViewButton={() => {
                            navigate('/3dmodels/chair');
                          }}
                      />

                      <JewelleryProductCard 
                          key={3}
                          product_name="Hoodie"
                          product_image={PRODUCT_3D_MODEL_WRAPPING + "HoddyV1/hoddie_thumb.png"}
                          OnClickViewButton={() => {
                            navigate('/3dmodels/hoddy');
                          }}
                      />

                      <JewelleryProductCard 
                          key={4}
                          product_name="Jacket"
                          product_image={PRODUCT_3D_MODEL_WRAPPING + "JacketV1/jacket_thumb.png"}
                          OnClickViewButton={() => {
                            navigate('/3dmodels/jacket');
                          }}
                      />

                      <JewelleryProductCard 
                          key={5}
                          product_name="iPad"
                          product_image={PRODUCT_3D_MODEL_WRAPPING + "iPadV1/ipad_thumb.png"}
                          OnClickViewButton={() => {
                            navigate('/3dmodels/ipad');
                          }}
                      />

                    </div>
                  </div>
                </div>
              </div>
            </HeadPart>
            {/* Here head part ends  */}
          </>
        );
      }

    function renderLoader() {
        console.log('jewellery_product_list_loader',jewellery_product_list_loader);
        if (jewellery_product_list_loader) {
            return (
            <HeadPart>
                <ModalLoader
                loadingTitle={"Loading Products List"}
                openModal={jewellery_product_list_loader}
                />
            </HeadPart>
            );
        } else {
        //   return <div>{createTemplateListTable()}</div>;
            return createJewelleryProductListTable();
        }
    }

    return renderLoader();
}

export {Product3DOptionsScreen};