import React from 'react'
import { Link } from 'react-router-dom';
import { Button } from './Button'


function TemplateCard({
    all_template_sides,
    modalOpenCallback,
    template,
    template_sides,
    clientSideCallback,
    indexCol
}) {
    return (
        <>
            <div className="catergory-clm">
                <div className="catergory-clm-in">
                    <div className="catergory-img"><img src={template.thumb_image ? template.thumb_image : "images/corporate-orders.jpg"} alt="thumbnail image" /></div>
                    <div className="catergory-hd"><div>{template.name}</div></div>
                    <div className="template-info-wrap1">
                        <div className="template-row">

                            <div className="template-clm1">No of Sides</div>
                            <div className="template-clm2"> {template_sides}
                                <Link
                                    to="/template_sides"
                                    state={{
                                        main_template_id: template.id,
                                        template_name: template.name,
                                        all_template_sides,
                                        template
                                        // thumb_image: template.thumb_image,
                                        // rounded_image:template.round_thumb_image,
                                        // description: template.description,
                                        // template_width: template.width,
                                        // template_height: template.height,
                                        // template_length:template.length,
                                        // template_orientation:template.orientation,
                                        // template_industry_tags: template.industry_tags,
                                        // template_product_tags: template.product_tags,
                                        // template_theme_tags: template.theme_tags,
                                        // template_3d_mapping: template['3d_model']['3d_model_id'],
                                        // template_3d_model:template['3d_model']['link'],
                                    }}
                                    className="addmore-btn">Add More</Link>
                            </div>
                        </div>
                    </div>
                    <div className="template-edit-del-btns">
                        <Button name='Client View' style={{ width: '100%' }} classgiven="link btn-basic btn-basic2" onPress={() => clientSideCallback(indexCol)}
                        />
                    </div>
                    <div className="template-info-modal">
                        <div onClick={() => {
                            modalOpenCallback(true, indexCol)
                        }} className='pointer' >
                            <img src="images/info-icon.svg" alt="" />
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export { TemplateCard }