import React from "react";
import { Link } from "react-router-dom";
import { printer_host } from "../extras/APIs";
function ClientHeader() {
  // https://w2pnx.flexiweb2print.com/flexiprint/
  return (
    <>
      <div className="top-panel">
        <div className="container">
          <div className="top-container d-flex flex-wrap">
            <div className="logo">
              <a href={printer_host}>
                <img
                  src={`${process.env.PUBLIC_URL}/images/flexiprintlogo.png`}
                  alt="Flexi Print – Online Printing Company"
                />
              </a>
            </div>

            <div className="signin-link m-0">
              {/* <a href={printer_host} className="signin">
                Sign In
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export {ClientHeader};
