import axios from 'axios';

import {JEWELLERY_PRODUCT_LIST_API} from '../extras/APIs';
import {
    TOGGLE_JEWELLERY_PRODUCT_LIST_LOADER,
    SAVE_JEWELLERY_PRODUCT_LIST,
    SAVE_BRAND_NAME,
} from '../Actions/ActionTypes';

import { CustomAlert } from '../Components';

export const saveBrandName = (brand_name) => {
    return dispatch => {
        dispatch({
            type: SAVE_BRAND_NAME,
            payload: brand_name,
        });
    }
}
export const getJewelleryProductList = () => {
    return async dispatch => {
        try {
            dispatch({
                type: TOGGLE_JEWELLERY_PRODUCT_LIST_LOADER,
                payload: true,
            });
            const response = await axios({
              method: "post",
              url: JEWELLERY_PRODUCT_LIST_API + '?dt=' + Date.now(),
            //   data: formData && formData,
            //   headers: { "Content-Type": "multipart/form-data" },
            //   signal:controller?.signal
            });

            if(response.status === 200) {
                const response_data = response.data;
                console.log('Response:', response.data);

                dispatch({
                    type: SAVE_JEWELLERY_PRODUCT_LIST,
                    payload: response_data,
                });
                dispatch({
                    type: TOGGLE_JEWELLERY_PRODUCT_LIST_LOADER,
                    payload: false,
                });
            } else {
                dispatch({
                    type: TOGGLE_JEWELLERY_PRODUCT_LIST_LOADER,
                    payload: false,
                });
                CustomAlert.alert(true, 'Something went wrong, please try again!');
            }
        } catch (error) {
            console.log('Error');
            dispatch({
                type: TOGGLE_JEWELLERY_PRODUCT_LIST_LOADER,
                payload: false,
            });
        }
    }
}