import React, { useState } from 'react'

function ToggleButton({value1,value2,changeValueFun,changeValueFun2,currentVal}) {
    return (
        <div className="design-frnt-bck frnt-bck position-a">
            <a onClick={changeValueFun} className={value1===currentVal ? 'active' : ''}>
                {value1}
            </a>{" "}
            <a onClick={changeValueFun2} className={value2===currentVal ? 'active' :''}>{value2}</a>
        </div>
    )
}

export { ToggleButton }