import React from 'react'
import { Link } from 'react-router-dom'
import { SideBar } from './SideBar'
import { printer_host } from '../extras/APIs'
// https://w2pnx.flexiweb2print.com/flexiprint/
function Header(props) {
    const {hide_menu} = props;
    function renderMenu(hide_menu) {
        // console.log('hide_menu',hide_menu);
        if (hide_menu !== null && hide_menu === true) {
            // console.log('hide_menu in if',hide_menu);
            return (
                <>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mr-auto main-links">
                    <li className="active"><Link to="/" className="dropdown-toggle" data-toggle="dropdown">Manage Editor</Link>
                            <ul className="dropdown-menu">
                                <li className="manage-font"><Link to="/fonts_list"><span className="main">Manage Font</span><span>Manage Fonts</span></Link>
                                </li>
                                <li className="manage-3d-model"><Link to="/3DModel"><span className="main">Manage 3D Model</span><span>Manage 3D Model</span></Link>
                                </li>
                                <li className="manage-repeated-orders"><Link to="/repeated_orders"><span className="main">Repeated Orders</span><span>Repeated Orders</span></Link>
                                </li>
                                <li className="manage-client-template"><Link to="/client_template"><span className="main clientsavedtemplate">Client Saved Templated</span><span>Client Saved Templated</span></Link>
                                </li>
                                <li className="manage-client-draft"><Link to="/client_draft"><span className="main clientsavedtemplatedraft">Draft</span><span>Draft</span></Link>
                                </li>
                                <li className="manage-template"><Link to="/"><span className="main">Manage Template</span><span>Manage Template </span></Link>
                                </li>
                            </ul>
                        </li>
                        {/* <li><Link to="#" className="dropdown-toggle" data-toggle="dropdown">Setup</Link>
                            <ul className="dropdown-menu">
                                <li className="profile-management"><Link to="profile-management.html"><span className="main">Profile Management</span>
                                    <span>Using Profile Management, a Printing Company (that owns the store) can authorize specific person/s and update contact details and their logo. </span></Link>
                                </li>
                                <li className="role-management"><Link to="role-management.html"><span className="main">Roles Management</span>
                                    <span>Define and Edit roles for team members. E.g. A Production Manager, an Accountant and a Marketing Manager can be linked to menu buttons</span></Link>
                                </li>
                                <li className="user-management"><Link to="user-management.html"><span className="main">User Management</span>
                                    <span>If you are a large printing house with different functions like Sales, Accounts, Technical etc. you can create multiple user accounts.</span></Link>
                                </li>
                                <li className="mini-site"><Link to="mini-site.html"><span className="main">Mini Site</span>
                                    <span>Based on the Site / Hosting option selected by you, use this mini site configuration option to configure the look and feel by selecting colors for page Background, Text and Page Title. </span></Link>
                                </li>
                                <li className="payment-gateway"><Link to="payment-gateway-setting.html"><span className="main">Payment Gateway Setting </span>
                                    <span>We have pre-configured a popular payment gateway. You can see the list and select one or multiple gateways, which you want to incorporate on your site.</span></Link>
                                </li>
                                <li className="publish-setting"><Link to="publish-settings.html"><span className="main">Publish Settings</span>
                                    <span>No need to host the complete site with us, you can manage your online Print store by uploading readymade shop or using Widget.</span></Link>
                                </li>
                            </ul>
                        </li>
                        <li><Link to="#" className="dropdown-toggle" data-toggle="dropdown">Manage Product</Link>
                            <ul className="dropdown-menu">
                                <li className="manage-tax"><Link to="manage-tax.html"><span className="main">Manage Tax</span>
                                    <span>From here you can add/edit tax information. The tax has to entered in terms of a percentage value.</span></Link>
                                </li>
                                <li className="manage-shipper"><Link to="manage-shipper.html"><span className="main">Manage Shipper</span>
                                    <span>Add data about multiple courier company and their best rates for delivery to particular areas / zone / pin / zip codes. You can define multiple courier companies later to define zone</span></Link>
                                </li>
                                <li className="manage-zone"><Link to="manage-zone.html"><span className="main">Manage Zone</span>
                                    <span>Once you have entered a courier company, you can link specific zones with a particular courier company. Based on your knowledge about competitive zone-wise pricing</span></Link>
                                </li>
                                <li className="manage-courier-rate"><Link to="manage-courier-rate.html"><span className="main">Manage Courier Rate</span>
                                    <span>Select the name of the courier company from the drop down menu or search by name. Click “New” to insert a zone-wise rate card for that courier company. </span></Link>
                                </li>
                                <li className="manage-product-rate"><Link to="manage-product-rate.html"><span className="main">Manage Product & Rate</span>
                                    <span>Add flexiprint products</span></Link>
                                </li>
                                <li className="coupon-management"><Link to="coupon-management.html"><span className="main">Coupon Management</span>
                                    <span>Define multiple coupons. Offer unique one-time coupon codes to select few or a common coupon code for multiple users.</span></Link>
                                </li>
                            </ul>
                        </li>
                        <li><Link to="#" className="dropdown-toggle" data-toggle="dropdown">Corporate</Link>
                            <ul className="dropdown-menu">
                                <li className="corporate-client-management"><Link to="corporate-client-management.html"><span className="main">Corporate Client Management</span>
                                    <span>View a centralized list of all corporate customers along with Name, Telephone Number and Email Id of dedicated print procurement heads at the clients side.</span></Link>
                                </li>
                                <li className="editable-template-management"><Link to="editable-template-management.html"><span className="main">Editable Template Management</span>
                                    <span>View centralized list of online editable templates uploaded by you for all your corporate customers. Filter it by the Company Name drop down list, Product Group drop down list or by entering keywords.</span></Link>
                                </li>
                                <li className="non-editable-template-management"><Link to="non-editable-templates.html"><span className="main">Non Editable Templates</span>
                                    <span>View a centralized list of Non-editable templates uploaded by you for all your corporate customers. Filter it using Company Name drop down list, Product Group drop down list or by entering keywords. </span></Link>
                                </li>
                                <li className="rate-management"><Link to="rate-management.html"><span className="main">Rate Management</span>
                                    <span>View centralized list of all editable / non-editable templates uploaded by you for all your corporate customers. Filter it by typing layout name or using the Company Name drop down list.</span></Link>
                                </li>
                            </ul>
                        </li>
                        <li><Link to="#" className="dropdown-toggle" data-toggle="dropdown">Orders & Payments</Link>
                            <ul className="dropdown-menu">
                                <li className="corporate-orders"><Link to="corporate-orders.html"><span className="main">Corporate Orders</span>
                                    <span>View a centralized list of all corporate orders. Filter it based on Date Range, Order ID, Job Status, Product Group, Company Name or its Branch.</span></Link>
                                </li>
                                <li className="end-user-orders"><Link to="end-user-orders.html"><span className="main">End User Orders</span>
                                    <span>Printshop Orders listing</span></Link>
                                </li>
                                <li className="corporate-payment-management"><Link to="corporate-payment-management.html"><span className="main">Corporate Payment Management</span>
                                    <span>Corporate Client Payment Management</span></Link>
                                </li>
                                <li className="offline-orders"><Link to="#"><span className="main">Offline Orders</span>
                                    <span>Lorem Ipsum</span></Link>
                                </li>
                                <li className="send-proof"><Link to="send-proof.html"><span className="main">Send Proof</span>
                                    <span>Send Proof File to the Customer .Approve/Disapprove from front website</span></Link>
                                </li>
                            </ul>
                        </li>
                        <li><Link to="manage-quotes.html" className="dropdown-toggle" data-toggle="dropdown">Manage Quote</Link>
                            <ul className="dropdown-menu">
                                <li className="manage-paper-size"><Link to="manage-paper-size.html"><span className="main">Manage Paper Sizes</span>
                                    <span>Master to stores paper size for quote calculations</span></Link>
                                </li>
                                <li className="manage-machine-size"><Link to="manage-machine-size.html"><span className="main">Manage Machine Sizes</span>
                                    <span>Master to stores Machine Sizes for quote calculations</span></Link>
                                </li>
                                <li className="manage-paper-names"><Link to="manage-paper-names.html"><span className="main">Manage Paper Names</span>
                                    <span>Master to stores Paper Names for quote calculations</span></Link>
                                </li>
                                <li className="manage-paper-weight"><Link to="manage-paper-weight.html"><span className="main">Manage Paper Weight</span>
                                    <span>Master to stores Paper Weight for quote calculations</span></Link>
                                </li>
                                <li className="manage-finishing-items"><Link to="manage-finishing-items.html"><span className="main">Manage Finishing Items</span>
                                    <span>Master to stores Finishing Items for quote calculations</span></Link>
                                </li>
                                <li className="manage-product-groups"><Link to="manage-product-groups.html"><span className="main">Manage Product Groups</span>
                                    <span>Master to stores Product Groups for quote calculations</span></Link>
                                </li>
                                <li className="manage-printers"><Link to="manage-printers.html"><span className="main">Manage Printers</span>
                                    <span>Master to stores printers</span></Link>
                                </li>
                                <li className="manage-quotes"><Link to="manage-quotes.html"><span className="main">Manage Quotes</span>
                                    <span>Quote creation by chacking printer rates</span></Link>
                                </li>
                            </ul>
                        </li>
                        <li><Link to="crm-dashboard.html" className="dropdown-toggle" data-toggle="dropdown">CRM</Link>
                            <ul className="dropdown-menu">
                                <li className="crm-dashboard"><Link to="crm-dashboard.html"><span className="main">CRM Dashboard</span>
                                    <span>Yes-flexiprint Customized</span></Link>
                                </li>
                                <li className="manage-support-request"><Link to="manage-support-request.html"><span className="main">Manage Support Request</span>
                                    <span>Lists Support request from flexiprint.in support form submission</span></Link>
                                </li>
                            </ul>
                        </li>
                        <li><Link to="manage-sample-kit.html" className="dropdown-toggle" data-toggle="dropdown">Marketing</Link>
                            <ul className="dropdown-menu">
                                <li className="manage-sample-kit"><Link to="manage-sample-kit.html"><span className="main">Manage Sample Kit</span>
                                    <span>Lists Free Sample kit requests from flexiprint.in</span></Link>
                                </li>
                                <li className="corporate-request"><Link to="corporate-requests.html"><span className="main">Corporate Requests </span>
                                    <span>Lists Corporate Registration requests</span></Link>
                                </li>
                            </ul>
                        </li> */}
                       
                    </ul>
                </div>

                <div className="welcome-user"><div className="User-area">
                        <div className="User-avtar">
                            <img src="images/user-name-icon.png" />
                        </div>
                        <div className="User-Dropdown">
                            <div className="User-Dropdown-inside">
                                <div className="profile-img">
                                    <div className="User-avtar">
                                        <img src="images/user-name-icon.png" />
                                        <div className="change-profile-img">
                                            <Link to="/">
                                                <img src="images/change-profile-img.svg" />
                                            </Link>
                                        </div>
                                    </div>

                                </div>
                                <div className="User-name">
                                    Milind Padhye
                                </div>
                                <div className="User-logout">
                                    <Link to="#">Logout</Link>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </>
            );
        }
    }
    return (
        <>
            <div className="header-panel main-header">
                <div className="container d-flex align-items-center">
                    <div className="logo mr-auto"><a href={printer_host}><img src={"/images/flexiprintlogo.png"} alt="Flexi Print – Online Printing Company" /></a></div>
                    <a href="#" className="sidebar-toggle" data-toggle="push-menu" role="button">
                        <span className="sr-only">Toggle navigation</span>
                    </a>
                    <div className="right-panel">
                        <div className="main-navigation">
                            <div className="main-navigation-part-1">
                                <div id="menu_area" className="menu-area">
                                    <div className="container-new">
                                        <nav className="navbar navbar-light navbar-expand-lg mainmenu">
                                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                                <span className="navbar-toggler-icon"></span>
                                            </button>
                                            {renderMenu(hide_menu)}
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export { Header }