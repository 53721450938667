import {
  loadCanvasFromJSON,
  savingClientName,
  setSideCanvasJSON,
  canvasToJSON,
  clientCanvasObjectModified,
  selectedLayer,
  clientCanvasSelection,
} from "../Actions";
import {initAligningGuidelines} from "../extras/CanvasObjectControls";
import {FONT_OBSERVER_LOADER, INIT_CANVAS, PCJ_TEMPLATE_LOADER, SAVE_ORIGINAL_FONT_STYLES} from "../Actions/ActionTypes";
import {CanvasZoom} from "../extras/PanandZoom";
import {ThreeDPreview2} from '../extras/ThreeDpreview2'
const threedOut = new ThreeDPreview2();

export function create3DScene(
    template_3d_model,
    cameraX,
    cameraY,
    cameraZ,
    threeD_model_div_id,
    setBrowserError,
    set3dModel,
    setModelReady
  ) {
  return new Promise((resolve, reject) => {
    threedOut.createThreeScene(
      template_3d_model,
      cameraX,
      cameraY,
      cameraZ,
      threeD_model_div_id,
      setBrowserError,
      set3dModel,
      setModelReady
    ).then(model => resolve(model)).catch(error => reject(error));
  })
}

export function renderFontAnd3DModel(
  threeD_model_div_id,
  template_fonts_face,
  model_materials,
  template_3d_model,
  cameraX,
  cameraY,
  cameraZ,
  model_ready,
  setBrowserError,
  set3dModel,
  setModelReady,
) {
  var mount;
  // console.log('threeD_model_div_id',threeD_model_div_id);
  return new Promise(resolve => {
    let fontFacePromise = new Promise(function (
      fontFaceResolve,
      fontFaceReject
    ) {
      var sheet = window.document.styleSheets[0];
      for (var i = 0; i < template_fonts_face.length; i++) {
        // console.log('Perm list', template_font_rules);
        for (var j = 0; j < template_fonts_face[i].length; j++) {
          sheet.insertRule(template_fonts_face[i][j], sheet.cssRules.length);
        }
      }
      // console.log('wait start');
      fontFaceResolve(true); // when successful
  });

    let Model3dPromise = new Promise(function (modelResolve, modelReject) {
      console.log('model_ready in FCS:', model_ready);
      if(!model_ready) {
        if (template_3d_model !== 0) {
          mount = document.getElementById(threeD_model_div_id);
          // threedOut.createThreeScene(
          create3DScene(
            template_3d_model,
            cameraX,
            cameraY,
            cameraZ,
            threeD_model_div_id,
            setBrowserError,
            set3dModel,
            setModelReady
          ).then(model => {
            set3dModel(model);
            modelResolve(model)
          })
        } else {
          modelResolve(false);
        }
      } else {
        console.log('IGNORING');
        modelResolve(model_ready);
      }
        // modelResolve(true);
    });

    Promise.all([fontFacePromise, Model3dPromise]).then((value) => resolve(value));
  });
}

export const applyTemplateTextureTo3DModel = (texture_url, model_3d, model_ready, material_name) => {
  var side_name = 'FRONT';
  var canvas_svg_for_3d_test = {};
  canvas_svg_for_3d_test[side_name] = texture_url
  console.log('canvas_svg_for_3d:::',canvas_svg_for_3d_test);
  threedOut.makeTexture(model_ready, model_3d, canvas_svg_for_3d_test, material_name, side_name);
}

export const fabricCanvasSetup = (
  threeD_model_div_id,
  fabric_canvas_div_id,
  template_sides,
  template_fonts,
  template_assigned_layers,
  template_id,
  all_template_sides,
  canvas_json,
  template_fonts_face,
  model_materials,
  template_3d_model,
  cameraX,
  cameraY,
  cameraZ,
  swatch,
  client_name,
  activeSideIndex,
  navigate_from_draft,
  original_font_applied,
  clip_layer_id,
  canvas_svg_for_3d,
  dispatch,
  setBackgroundId,
  setTemplateFontFace,
  setTemplateId,
  setOriginalFont,
  setLayers,
  setSwatchList,
  setTemplateSides,
  setAllTemplateSides,
  setToggleChange,
  setBrowserError,
  set3dModel,
  setModelReady,
  setMoving,
  closingDropDown = null,
) => {
  
  dispatch({
    type: PCJ_TEMPLATE_LOADER,
    payload: true,
  });

  dispatch({
    type: FONT_OBSERVER_LOADER,
    payload: true
  })

  var background_layer_id = template_assigned_layers.BG;
  setBackgroundId(background_layer_id);
  setTemplateFontFace(template_fonts_face);
  setTemplateId(template_id);
  setOriginalFont(template_fonts);
  setLayers(template_assigned_layers);
  // console.log('swatch', templateFontFace);
  setSwatchList(swatch);
  setTemplateSides(template_sides);
  setAllTemplateSides(all_template_sides);
  setToggleChange(template_sides[activeSideIndex]);
// console.log('clip ',clip_layer_id);
  // let fontFacePromise = new Promise(function (
  //     fontFaceResolve,
  //     fontFaceReject
  //   ) {
  //     var sheet = window.document.styleSheets[0];
  //     for (var i = 0; i < template_fonts_face.length; i++) {
  //       // console.log('Perm list', template_font_rules);
  //       for (var j = 0; j < template_fonts_face[i].length; j++) {
  //         sheet.insertRule(template_fonts_face[i][j], sheet.cssRules.length);
  //       }
  //     }
  //     // console.log('wait start');
  //     fontFaceResolve(true); // when successful
  // });

  // let Model3dPromise = new Promise(function (modelResolve, modelReject) {
  //     if (template_3d_model !== 0) {
  //       mount = document.getElementById(threeD_model_div_id);
  //       threedOut.createThreeScene(
  //         template_3d_model,
  //         cameraX,
  //         cameraY,
  //         cameraZ,
  //         threeD_model_div_id,
  //         setBrowserError,
  //         set3dModel,
  //         setModelReady
  //       ).then(model => {
  //         modelResolve(model)
  //       })
  //     } else {
  //       modelResolve(false);
  //     }
  //     // modelResolve(true);
  // });

  // Promise.all([fontFacePromise, Model3dPromise]).then(
  renderFontAnd3DModel(
    threeD_model_div_id,
    template_fonts_face,
    model_materials,
    template_3d_model,
    cameraX,
    cameraY,
    cameraZ,
    false,
    setBrowserError,
    set3dModel,
    setModelReady,
  ).then(
  (value) => {
      const model = value[1];
      set3dModel(model);
      var template_sides_json = {};
      for (let i = 0; i < template_sides.length; i++) {
          template_sides_json[template_sides[i]] = null;
      }

      if (navigate_from_draft) {
          dispatch(savingClientName(client_name));
          // const original_font_applied = location.state.original_font_applied;
          dispatch({
              type: SAVE_ORIGINAL_FONT_STYLES,
              payload: original_font_applied,
          });
      }
      console.log('FABRIC CANVAS SETUP clip_layer_id',clip_layer_id);
      dispatch(
          loadCanvasFromJSON(
              fabric_canvas_div_id,
              canvas_json,
              template_fonts,
              background_layer_id,
              template_sides_json,
              clip_layer_id,
              template_sides[activeSideIndex],
              null,
              navigate_from_draft
          )
          ).then((canvas_details) => {
          const biggest_object = canvas_details[1];
          var new_canvas = canvas_details[0];
          console.log('canvas_svg_for_3d',canvas_svg_for_3d);
          // console.log('client height',document.getElementById("canvas-here").clientWidth);
          // new_canvas.setHeight(document.getElementById("canvas-here").clientHeight)
          // new_canvas.setWidth(document.getElementById("canvas-here").clientWidth)
          // new_canvas.__eventListeners["after:render"] = [];
          new_canvas.on("after:render", function () {
              if (model) {
              const template_active_side = template_sides[activeSideIndex];
              const material_name =
                  model_materials[
                  activeSideIndex
                  ];
              clientCanvasSelection(
                  dispatch,
                  new_canvas,
                  biggest_object,
                  template_active_side,
                  model,
                  material_name,
                  canvas_svg_for_3d,
                  threedOut
              );
              dispatch({
                  type: INIT_CANVAS,
                  payload: new_canvas,
              });
              }
              selectedLayer(
              new_canvas.getActiveObject(),
              dispatch,
              new_canvas
              );
          });
          dispatch({
            type: PCJ_TEMPLATE_LOADER,
            payload: false,
          });
          canvasEvents(new_canvas, biggest_object, setMoving, dispatch,closingDropDown);
          });
      },
      (error) => {
        console.log("error in font face,", error)
        dispatch({
          type: PCJ_TEMPLATE_LOADER,
          payload: false,
        });
      }
  );
  
}

export function onSideChanged(
  selected_side_index,
  fabric_canvas_div_id,
  canvas,
  model_3d,
  model_materials,
  canvas_svg_for_3d,
  template_sides,
  activeSideIndex,
  template_sides_canvas_json,
  all_template_sides,
  clip_layer_id,
  template_fonts,
  template_assigned_layers,
  original_font_applied,
  draft,
  dispatch,
  setMoving,
  setActiveSideIndex,
  setBackgroundId,
  setOriginalFont,
  setLayers,
  setTemplateSides,
  setToggleChange,
  closingDropDown=null,
) {
  if(activeSideIndex != selected_side_index){
      dispatch({
        type: PCJ_TEMPLATE_LOADER,
        payload: true,
      });
      dispatch({
        type: FONT_OBSERVER_LOADER,
        payload: true
      })

      let subscribe = true;
      const existing_side_canvas_json = canvasToJSON(canvas);
      const existing_template_side = template_sides[activeSideIndex];
      dispatch(
        setSideCanvasJSON(
          existing_side_canvas_json,
          template_sides_canvas_json,
          existing_template_side
        )
      );
      
      
    
      setActiveSideIndex(selected_side_index);
      const active_template_side =
        all_template_sides[template_sides[selected_side_index]];
    
      // const template_fonts = !location.state.draft
      //   ? active_template_side.fonts
      //   : location.state.template_fonts;
      // const template_assigned_layers = !location.state.draft
      //   ? active_template_side.layers
      //   : location.state.layers;
    
      console.log('template_assigned_layers',template_assigned_layers);
      var background_layer_id = template_assigned_layers.BG;
      // console.log('template', template_fonts);
      setBackgroundId(background_layer_id);
    
      setOriginalFont(template_fonts);
      // setLayers(location.state.layers);
      setLayers(template_assigned_layers);
      setTemplateSides(template_sides);
      setToggleChange(template_sides[selected_side_index]);
      console.log('selected_side_index', template_sides[selected_side_index], selected_side_index);
      // const canvas_json = !location.state.draft ? active_template_side.canvas_json : location.state.all_template_sides;
      const canvas_json = all_template_sides[template_sides[selected_side_index]].canvas_json;
      var outline_layer_id = template_assigned_layers.outline;
      canvas.off("after:render", () => {
        console.log("remove after render");
      });
      canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
      canvas.requestRenderAll();
      if (draft) {
        dispatch({
          type: SAVE_ORIGINAL_FONT_STYLES,
          // payload: location.state.original_font_applied,
          payload: original_font_applied,
        });
      }
    
      dispatch(
        loadCanvasFromJSON(
          fabric_canvas_div_id,
          canvas_json,
          template_fonts,
          background_layer_id,
          template_sides_canvas_json,
          clip_layer_id,
          template_sides[selected_side_index],
          canvas,
          draft
        )
      )
      .then((canvas_details) => {
        if(subscribe){
          const biggest_object = canvas_details[1];
          var new_canvas = canvas_details[0];
          new_canvas.__eventListeners["after:render"] = [];
          new_canvas.on("after:render", function () {
            if (model_3d) {
              const template_active_side = template_sides[selected_side_index];
              const material_name =
                model_materials[selected_side_index];
                // location.state.template_3d_model
              // console.log('material_name', location.state.template_3d_model);
              clientCanvasSelection(
                dispatch,
                new_canvas,
                biggest_object,
                template_active_side,
                model_3d,
                material_name,
                canvas_svg_for_3d,
                threedOut,
              );
    
    
              // dispatch({
              //   type: INIT_CANVAS,
              //   payload: new_canvas,
              // });
            }
            selectedLayer(new_canvas.getActiveObject(), dispatch, new_canvas);
          });
          // canvasEvents(new_canvas, biggest_object);
          canvasEvents(new_canvas, biggest_object, setMoving, dispatch,closingDropDown);
          dispatch({
            type: PCJ_TEMPLATE_LOADER,
            payload: false,
          });
        }
      }).catch(e=>{
        dispatch({
          type: PCJ_TEMPLATE_LOADER,
          payload: false,
        });
        subscribe = false
        
      })
  }
  
}

export function canvasEvents(new_canvas, biggest_object, setMoving, dispatch, closingDropDown = null) {
  new_canvas.__eventListeners["mouse:up"] = [];
  new_canvas.on("mouse:up", function (opt) {
    setMoving(false)
    new_canvas.forEachObject(function (o) {
      o.setCoords();
    });
    new_canvas.requestRenderAll();
  });

  new_canvas.__eventListeners["mouse:wheel"] = [];
  new_canvas.on("mouse:wheel", function (opt) {
    CanvasZoom(
      opt,
      new_canvas,
      biggest_object.height,
      biggest_object.width,
      dispatch
    );
  });

  new_canvas.on('object:moving', () => {
    setMoving(true)    
    if (closingDropDown) {
      closingDropDown();
    }
    
  })

  // new_canvas.__eventListeners["object:modified"] = [];
  // If we are unsetting above event its giving error in snapping code, hence ignoring the above event removal
  new_canvas.on("object:modified", () => {
    clientCanvasObjectModified(dispatch, new_canvas);
  });

  initAligningGuidelines(new_canvas, dispatch); 
}

export function openBox() {
console.log('threedOut in fabricCanvasSetup',threedOut);
threedOut.playClipByIndex(0)
}

export function closeBox() {
console.log('threedOut in fabricCanvasSetup',threedOut);
threedOut.playClipReverseByIndex(0)
}