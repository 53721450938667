import React, { useEffect } from 'react'

function Modal({ headTitle, children, classGiven, onPress, modalDialogClass, style, topModalref, modalDialogStyle, modalBodyClass, notCrossIcon, notHeader, footerWant,footerTitle,footerOnPress }) {
	useEffect(() => {
		return () => {
			document.body.classList.remove('modal-open')
			document.body.style.paddingRight = '0'
		}
	}, [])
	return (
		<div ref={topModalref} id="basicModal" className={classGiven} style={style}>
			<div className={`modal-dialog ${modalDialogClass ? modalDialogClass : ''}`} style={modalDialogStyle}>
				<div className="modal-content" >
					{!notHeader && (<div className="modal-header">
						<h5 className="modal-title hd2 m-0 p-0" >{headTitle}</h5>
						{!notCrossIcon && (
							<button className="close" onClick={onPress}>
								<span >&times;</span>
							</button>
						)}
					</div>)}
					<div className={modalBodyClass ? `modal-body ${modalBodyClass}` : "modal-body"}>
						{children}
					</div>
					{footerWant && (
						<div className="modal-footer">
							<button
								onClick={footerOnPress}
								type="button"
								className="btn btn-primary">
								{footerTitle}
							</button>
						</div>
					)}
				</div>
			</div>
		</div>
	)
}

export { Modal }