// TemplateDesignNameScreen
import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams,Link } from "react-router-dom";
import { onDesignNameChanged, getTemplateList, savingClientName } from "../Actions";
import "../css/TemplateDesignNameScreen.css";
import { useLocation } from "react-router-dom";
import { Button, ClientHeader, ClientListButton, HeadPart, ModalLoader } from "../Components";
import { navigateToEditor } from "../extras/CommonNavigations";
import { printer_host } from "../extras/APIs";

function TemplateDesignNameScreen() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [proceed, setProceed] = useState(false);
  

  const [printer_server_identifier, setPrinterServerIdentifier] = useState(0);

  const reduxState = useSelector((state) => state.template);
  const {
    template_design_name,
    template_name,
    referrer,
    templateListLoader,
    template_list,
    fonts_list,
    swatch,
    client_name,
    list_of_client_button,
  } = reduxState;
  const { param } = useParams();
  useEffect(() => {
    var referrer_url = document.referrer;
    console.log('document.referrer',referrer_url, document);
    referrer_url = referrer_url.replace("http://", "");
    referrer_url = referrer_url.replace("https://", "");
    referrer_url = referrer_url.replace("/", "");
    console.log('reffere',document.referrer);
    console.log("referrer_url", referrer_url, referrer);

    // if (referrer_url !== "" && referrer_url === referrer) {
    //   const user_id_length = param[0];
    //   console.log('PARAM:', param);
    //   setPrinterServerIdentifier(param);

    //   var ignore_total_characters = 0;

    //   if (user_id_length !== "0") {
    //     // Logged in user
    //     ignore_total_characters = 1 + parseInt(user_id_length) + 1;
    //     // For above: User id char + user id length + new/repeat order char
    //   } else {
    //     // Not Logged in user
    //     ignore_total_characters = 1 + 10 + 40 + 1;
    //     // For above: User id char(0) + 10 digit timestamp + 40 char session id + new/repeat order char
    //   }

    //   const template_details_char_length =
    //     param.length - ignore_total_characters;
    //   const template_customization_id = param.slice(
    //     param.length - template_details_char_length,
    //     param.length
    //   );

    //   // const template_is_rounded =
      
    //   //   template_customization_id.slice(0, 2) == "00" ? false : true;
    //   const template_selected_die = template_customization_id.slice(0, 2);
    //   const template_id = template_customization_id.slice(
    //     2,
    //     template_customization_id.length
    //   );

    //   console.log("template_id", template_id, template_selected_die);

    //   dispatch(getTemplateList(template_id, 1, 15, [], [], [], template_selected_die));
    // } else {
    //   console.log("Wrong Referrer");
    // }

      // const user_id_length = param[0];
      const cart_id_length = param[0];
      console.log('PARAM:', param);
      setPrinterServerIdentifier(param);

      var ignore_total_characters = 0;

      // if (user_id_length !== "0") {
      //   // Logged in user
      //   ignore_total_characters = 1 + parseInt(user_id_length) + 1;
      //   // For above: User id char + user id length + new/repeat order char
      // } else {
      //   // Not Logged in user
      //   ignore_total_characters = 1 + 10 + 40 + 1;
      //   // For above: User id char(0) + 10 digit timestamp + 40 char session id + new/repeat order char
      // }

      // Logged in user
      ignore_total_characters = 1 + parseInt(cart_id_length) + 1;
      // For above: User id char + user id length + new/repeat order char

      const template_details_char_length =
        param.length - ignore_total_characters;
      const template_customization_id = param.slice(
        param.length - template_details_char_length,
        param.length
      );

      // const template_is_rounded =
      
      //   template_customization_id.slice(0, 2) == "00" ? false : true;
      const template_selected_die = (template_customization_id.slice(0, 2) == '00') ? '-1' : template_customization_id.slice(0, 2);
      console.log('template_selected_die:',template_selected_die);
      const template_id = template_customization_id.slice(
        2,
        template_customization_id.length
      );

      console.log("template_id", template_id, template_selected_die);
      

      if (template_selected_die == '') {
        dispatch(getTemplateList(template_id));
      } else {
        dispatch(getTemplateList(template_id, 1, 15, 0, '', '', "", template_selected_die));
      }

  }, [dispatch]);

  function splitInputParam(param) {
    const user_id_length = param[0];
    if (user_id_length === "0") {
      console.log("User not logged in");
    } else {
      console.log("User logged in");
    }

    return param;
  }

  function renderLoader() {
    if (templateListLoader && proceed) {
      return (
        <ModalLoader
          loadingTitle={"Loading Templates"}
          openModal={templateListLoader}
        />
      );
    }
  }

  const navigateWhenTemplateIsReady = useCallback(() => {
    console.log("templateListLoader", templateListLoader);
    if (!templateListLoader && proceed) {
      console.log("Navigate Now");

      const template_sides_title = Object.keys(template_list);
      const first_template_side = template_list[template_sides_title[0]];
      
      
      // setTemplateName(template_name);
      const template_layer_dies = first_template_side.template_layers.layer_dies;
      console.log('template_layer_dies',template_layer_dies);
      var selected_template_die_layer;
      if (template_layer_dies.length === 1) {
        selected_template_die_layer = first_template_side.template_layers.layer_dies[0].fabric_layer_id;
      } else {
        selected_template_die_layer = '';
      }

      console.log('filtered',selected_template_die_layer);

      navigateToEditor(
        navigate,
        selected_template_die_layer,
        first_template_side,
        template_sides_title,
        template_list,
        fonts_list,
        swatch,
        printer_server_identifier,
      );
    }
  }, [templateListLoader, proceed]);
  
  return (
    <>
      <div className="upload-section blank-design1">
        <ClientHeader />
        {renderLoader()}
        {navigateWhenTemplateIsReady()}

        <div className="blank-design1-panel">
          <div className="design-container">
            <div className="upload-container1 d-flex flex-wrap">
              <div className="back-arrow-design">
                  <span onClick={()=>navigate(-1)}><img src={`${process.env.PUBLIC_URL}/images/arrow-dw6.svg`}></img></span>
              </div>
            </div>
            <div className="create-steps d-flex flex-wrap">
              <div className="upload-steps">
                <h2 className="hd5">
                  Create your {template_name} in 3 easy steps.
                </h2>
                <ClientListButton buttonList={list_of_client_button} active='Design' />
                <div className="start-design-panel">
                  <div className="start-design1 d-flex">
                    <input
                      type={"text"}
                      className="design-input"
                      placeholder="My design"
                      value={client_name}
                      onChange={(event) => dispatch(savingClientName(event.target.value))}
                    />
                    <span className="tooltip">
                      <img src={`${process.env.PUBLIC_URL}/images/edit-blue.svg`} alt="tooltip" />
                      <span className="tooltiptext label-info">Start by giving your design a name</span>
                    </span>
                  </div>
                  <div className="start-design1-btn">
                    <Button 
                    classgiven={'submit-btn1'}
                    name="START YOUR DESIGN"
                    onPress={()=>{
                      setProceed(true)
                      if(templateListLoader){
                        console.log('still loading');
                      }else{
                        console.log('ready');
                      }
                    }}
                    
                    />
                  </div>
                  <div className="need-help">
                    <a href={printer_host+'help-faqs'}>Need Help</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <h1>DESIGN YOUR TEMPLATE</h1>
        <br/>
        <label>Design Name:</label>
        <input type="text" className="desgin-input" onChange={(event) => {dispatch(onDesignNameChanged(event.target.value))}} value={template_design_name}></input>
        <Button classgiven={'submit-btn1'} onPress={() => {
            setProceed(true);
            // if(templateListLoader) {
            //     console.log('Still Loading');
            // } else {
            //     console.log('Ready');
            // }
        }} name="Upload Artwork" /> */}
        {/* </div> */}
      </div>
    </>
  );
}

export { TemplateDesignNameScreen };
