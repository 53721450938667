export const navigateToEditor = (navigate, die_id, template, template_side, template_sides_json, fonts_list, swatch, printer_server_identifier = 0) => {
    navigate('/editor', {
        state: {
            "draft": false,
            'template_json_file': template.canvas_json,
            'type': 'user',
            'template_id': template.id,
            'template_fonts': template.fonts,
            'fonts_list': fonts_list,
            'layers': template.layers,
            'template_sides': template_side,
            'swatch': swatch,
            'template_font_face': template.font_face,
            'all_template_sides': template_sides_json,
            "clip_layer_id": die_id,
            "template_3d_model": template['3d_model'],
            "printer_server_identifier": printer_server_identifier,
        }
    })
}

export const navigateToEditorFromDraft = (navigate, jsonData,client_name,template_id) => {
    navigate('/editor', {
        state: {
            "draft":true,
            "all_template_sides":jsonData.all_template_side,
            "template_id":template_id,
            "client_name":client_name,
            "template_fonts":jsonData.original_template_fonts,
            "fonts_list":jsonData.fonts_list,
            "original_font_applied":jsonData.original_font_applied,
            "layers":jsonData.layers,
            "template_sides":jsonData.template_sides,
            'template_name':jsonData.templates_name,
            "template_font_face":jsonData.font_face,
            'swatch':jsonData.swatch,
            "clip_layer_id":jsonData.clip_layer_id,
            "template_3d_model":jsonData.template_3d_model,
        }
    })
}


export const gettingIndex = (array,item_to_find) => {
    const index_of_element = array.findIndex((item, index) => {
        if (item.permission == item_to_find) {
            return true
        }
    })
    return index_of_element
}
