import React from "react";
import { useSelector } from "react-redux";
import { ClientHeader, ClientListButton } from "../Components";
// import '../style-editor.css'
function ReviewTemplate() {
  const reduxState = useSelector((state) => state.template);
  const {
    list_of_client_button
  } = reduxState;
  console.log('list_of_client_button',list_of_client_button);
  return (
    <>
      <div className="upload-section height-120">
        <ClientHeader />

        <div className="design-section review">
          <div className="design-container">
            <div className="upload-steps upload-steps2">
              <ClientListButton buttonList={list_of_client_button} active="Review" completed={'Design'} />
            </div>
            <div className="upload-container1 upload-review d-flex flex-wrap review1 mrtp-21">
              <div className="back-arrow w-1 position-r">
                <a href="">
                  <img src="images/arrow-dw6.svg" alt="" />
                </a>
                <div className="design-frnt-bck frnt-bck position-a">
                  <a href="" className="active">
                    FRONT
                  </a>{" "}
                  <a href="">BACK</a>
                </div>
              </div>
              <div className="design-review edit-design w-2">
                <a href="">
                  My design{" "}
                  <img className="edit-icon" src="images/edit.svg" alt="" />
                </a>
                <div className="design-review-row1 d-flex flex-wrap">
                  <div className="design-carousel">
                    <div
                      id="demo"
                      className="carousel slide"
                      data-ride="carousel"
                      data-interval="false"
                    >
                      {/* <!-- Indicators --> */}
                      <ul className="carousel-indicators">
                        <li
                          data-target="#demo"
                          data-slide-to="0"
                          className="active"
                        >
                          <img src="images/front-img.jpg" alt="" />
                        </li>
                        <li data-target="#demo" data-slide-to="1">
                          {" "}
                          <img src="images/back-img.jpg" alt="" />
                        </li>
                      </ul>

                      {/* <!-- The slideshow --> */}
                      <div className="carousel-inner">
                        <div className="carousel-item active">
                          <img src="images/front-img.jpg" alt="" />
                          <span className="img-info review-txt">Front</span>
                        </div>
                        <div className="carousel-item">
                          <img src="images/back-img.jpg" alt="" />
                          <span className="img-info review-txt">Back</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="continue-btns d-flex flex-wrap mrgt-75">
                  <a className="bt1" href="">
                    CONTINUE SHOPPING
                  </a>
                  <a href="" className="active hr1">
                    ADD TO CART
                  </a>
                </div>
              </div>
              <div className="next-cncl w-1 hide-m">
                <div className="w-100 download-pdf">
                  <a href="">Download PDF proof</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export { ReviewTemplate };
