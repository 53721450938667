import React from 'react'
import { Link } from 'react-router-dom';
import { Button } from '../Button'


function JewelleryProductCard({
    product_name,
    product_image,
    OnClickViewButton,
}) {
    console.log('Here123',product_name,product_image);
    return (
        <>
            <div className="catergory-clm">
                <div className="catergory-clm-in">
                    <div className="catergory-img"><img src={product_image ? product_image : "../images/corporate-orders.jpg"} alt="thumbnail image" /></div>
                    <div className="catergory-hd"><div>{product_name}</div></div>
                    <div className="template-edit-del-btns">
                        <Button name='View Button' style={{ width: '100%' }} classgiven="link btn-basic btn-basic2" onPress={() => OnClickViewButton()}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export { JewelleryProductCard }