import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FontName from 'fontname';
import { useLocation, useNavigate } from "react-router-dom";
import Dots from "react-activity/dist/Dots";
import { uploadFonts } from '../Actions';
import { Button, HeadingTitle, HeadPart, Modal, ModalLoader, PercentageLoader, TableBody } from '../Components';

function UploadFontFamilyScreen() {
  const location = useLocation();

  const [fonts, setFonts] = useState([]);
  const [fontFamily, setFontFamily] = useState(null);
  const [fontFamilyName, setFontFamilyName] = useState('');
  const [fromListFont, setFromListFont] = useState(false);
  const [missingFontList, setMissingFontList] = useState({});
  const [missingFontIndex, setMissingFontIndex] = useState(0);
  const reduxState = useSelector(state => state.template);
  const { missing_fonts_list, fontUploadLoader, template_id, upload_percentage } = reduxState;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    try {
      var fromFontList = location.state.fromFontList === null ? fromListFont : location.state.fromFontList
      if (!fromFontList) {
      } else {
        const fontFamily = location.state.font_family
        const missingFont = location.state.missing_font
        setFromListFont(location.state.fromFontList)
        // console.log('something',fontFamily);
        setFontFamilyName(fontFamily)
        setMissingFontList(missingFont)
      }

    } catch (e) {
      console.log('error in upload font family screen', e);
    }
    // console.log(`font family ${fontFamily} missingFOnt ${missingFontName}`);
  }, [dispatch])
  function handleFontFileSelected(event, weight, index) {
    console.log('Weight:', weight);
    var weightOfFont
    if (weight.includes('-')) {
      if (weight.toLowerCase().includes('regular')) {
        weightOfFont = weight.split("-")[1]
      } else {
        weightOfFont = weight.split("-").join(' ')
      }
    } else {
      weightOfFont = weight
    }

    const stripped_weight = weight.replace(" ", '');
    const e = event;
    const fontFile = e.target.files[0]; // File
    const file_name_array = e.target.value.split('.');
    const file_type = file_name_array[file_name_array.length - 1].toLowerCase();
    if (file_type === 'ttf') {
      const reader = new FileReader();
      reader.onload = e => {
        const buffer = e.target.result; // ArrayBuffer
        try {
          const fontMeta = FontName.parse(e.target.result)[0];
          // console.log('FONT META:', fontMeta);
          // const metaPostScriptName = fontMeta.postScriptName.toLowerCase();
          const metaPostScriptName = fontMeta.postScriptName
          const metaSubFamilyArray = metaPostScriptName.split('-');
          console.log('fontMeta', metaPostScriptName);
          // const metaSubFamily = metaSubFamilyArray[metaSubFamilyArray.length - 1];
          const metaFontFullName = fontMeta.fullName;
          // const metaSubFamily = fontMeta.fontSubfamily.toLowerCase();
          // ((a < b) ? 'minor' : 'major');
          const metaSubFamily = ((fontMeta.typoSubfamilyName) ? fontMeta.typoSubfamilyName.toLowerCase() : fontMeta.fontSubfamily.toLowerCase());
          const metaFontFamily = fontMeta.fontFamily;

          console.log(metaSubFamily, metaFontFamily, weight);
          if (fromListFont && metaFontFamily !== fontFamilyName) {
            // console.log('here', metaFontFamily, fontFamilyName);
            alert('Upload the same font family')
          } else {
            const italicFont = metaFontFullName.toLowerCase().includes('italic');

            if ((metaSubFamily === weightOfFont || metaSubFamily === stripped_weight) || (metaSubFamily === metaFontFamily.toLowerCase() && weight === 'regular')) {
              const new_fonts = fonts;

              if (fontFamily === null) {
                setFontFamily(metaFontFamily);
              }             
              if (!fromListFont) {
                new_fonts[index] = {
                  'name': metaPostScriptName,
                  'template_id': 0,
                  file: event.target.files[0],
                  'fontWeight': fontMeta.fontSubfamily,
                  'aiFontName': metaPostScriptName,
                  'coralFontName': metaFontFullName,
                  'fontFamily': fontMeta.fontFamily,
                  'italic': italicFont ? 1 : 0,
                }
              } else {
                new_fonts[missingFontIndex] = {
                  'name': metaPostScriptName,
                  'template_id': 0,
                  file: event.target.files[0],
                  'fontWeight': fontMeta.fontSubfamily,
                  'aiFontName': metaPostScriptName,
                  'coralFontName': metaFontFullName,
                  'fontFamily': fontMeta.fontFamily,
                  'italic': italicFont ? 1 : 0,
                }
                setMissingFontIndex(missingFontIndex + 1);
              }              
              setFonts(new_fonts);
              console.log(event.target.prototype);
              event.target.disabled = true;
              console.log(fontFamily, fonts);
            } else {
              event.target.value = null;
              e.target.value = null;              
              alert('The font file you selected has sub family ' + metaSubFamily + ' we need ' + weight);
            }
          }          
        } catch (e) {
          // FontName may throw an Error
          event.target.value = null;          
          console.log('FontName error:', e);
          alert('Font file seems to be corrupt, please upload another file or contact the admin for help!');
        }
      };
      reader.readAsArrayBuffer(fontFile);      
      console.log('Fonts:', fonts);
    } else {
      event.target.value = null;
      alert('We only accept TTF Font files, support for OTF coming soon!');
    }
  }

  function handleSubmit(event) {
    var upload_fonts = fonts.filter(function (el) {
      return el != null;
    });
    if (!fromListFont & upload_fonts.length === 4) {
      if (upload_fonts.length === 4) {
        dispatch(uploadFonts(event, fonts, null, navigate));
      } else {
        event.preventDefault();
        alert('Please upload all fonts to proceed');
      }
    } else {
      if (upload_fonts.length >= 1) {
        dispatch(uploadFonts(event, fonts, null, navigate));
      } else {
        event.preventDefault();
        alert('Please upload atleast one font to proceed');
      }
      console.log('Proceed', fonts, upload_fonts);

    }
  }

  function renderFontFamilyName() {
    if (fontFamily) {
      return (
        <tr>
          <th width="">Font Name: {fontFamily}</th>
          <th width="" data-title="Action"><Button onPress={() => window.location.reload()} name="Discard Font Family" style={{ backgroundColor: 'red', height: '3rem' }} classgiven={'link btn-basic btn-basic2'} /></th>
        </tr>
      );
    } if (fromListFont) {      
      return (
        <tr>
          <th width="">Font Name: {fontFamilyName}</th>
          <th width="" data-title="Action">It is Uploaded</th>
        </tr>                        
      )
    }
    else {
      return (
        <tr>
          <th width="">Font Name</th>
          <th width="" data-title="Action">Upload</th>
        </tr>
      )
    }

  }

  function renderTemplateFont() {
    if (fontUploadLoader) {
      return (
        <>
        <ModalLoader loadingTitle={'Font Uploading'} openModal={fontUploadLoader}>
          {upload_percentage && (
            <PercentageLoader percentage={upload_percentage} />
          )}
          </ModalLoader>
          {/* <Dots /> */}
        </>
      );
    } else {
      return (
        <>
          <form onSubmit={handleSubmit} encType="multipart/form-data">
            <div id="table2" className="table-outer table-cards manage-shipper manage-editor-wrap">
              <table width="" cellSpacing="1">
                <thead>
                  {renderFontFamilyName()}
                </thead>

                <tbody>

                  {Object.keys(missingFontList).map((val, index) => {                    
                    if (!missingFontList[val]) {                      
                      return (                       
                        <TableBody key={index+1} tableName={`${val} TTF`} inputType={'files'} onChangefun={(e)=>handleFontFileSelected(e, val, index - 1)} />
                      )
                    }
                  })}
                </tbody>
              </table>
            </div>            
            <input type="submit" className='table-action' value="Upload Fonts" />
          </form>
        </>
      )
    }
  }

  function renderFontUploads(missing_fonts_list) {   
    if (fontUploadLoader) {
      return (
        <>
          {upload_percentage && (
            <PercentageLoader percentage={upload_percentage} />
          )}
          <Dots />
        </>
      );
    } else {
      return (
        <>
          <form onSubmit={handleSubmit} encType="multipart/form-data">
            <div id="table2" className="table-outer table-cards manage-shipper manage-editor-wrap">
              <table width="" cellSpacing="1">
                <thead>
                  {renderFontFamilyName()}                  
                </thead>

                <tbody>
                  <TableBody tableName={'Regular TTF'} inputType={'files'} onChangefun={(e)=>handleFontFileSelected(e, 'regular', 0)} />
                  <TableBody tableName={'Regular Italic TTF'} inputType={'files'} onChangefun={(e)=>handleFontFileSelected(e, 'italic', 1)} />
                  <TableBody tableName={'Bold TTF'} inputType={'files'} onChangefun={(e)=>handleFontFileSelected(e, 'bold', 2)} />
                  <TableBody tableName={'Bold Italic TTF'} inputType={'files'} onChangefun={(e)=>handleFontFileSelected(e, 'bold italic', 3)} />                 
                </tbody>

              </table>
            </div>
            <input type="submit" className='table-action' value="Upload Fonts" />
          </form>          
        </>
      )
    }
  }

  return (
    <>
    <HeadPart>
      <HeadingTitle title={'Manage Font'} />
      {!fromListFont ? renderFontUploads(missing_fonts_list) : renderTemplateFont()}<br />
      </HeadPart>
    </>
  );
}

export { UploadFontFamilyScreen };
