import React from 'react'

function PCJPreview({className,children}) {
  return (
    <div className={className  ? className : `modal-3d-preview`}>
        <div className="modal-3d-preview-wrap">{children}</div>
    </div>
  )
}

export {PCJPreview}