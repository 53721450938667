import React, { useState, memo } from 'react'
import '../css/TemplatesScreen.css';
import '../css/TemplateEditorScreen.css';
function ColorPicker({ colorList, colorPickedCallback }) {
  const [mouseEnter, setMouseEnter] = useState(-1)
  return (
    <>
      <li style={{ border: '0' }}>
        {colorList.map((item, index) => (
          <span onClick={() => colorPickedCallback(item.hexColor)} key={index} style={{ backgroundColor: item.hexColor }} className='clr-prt'></span>
        ))}
        <span onClick={() => colorPickedCallback('')} style={{ backgroundColor: '#000',color:'#fff' }} className='clr-prt'><img src={''} /></span>
        {/* <span  className='clr-prt'>X</span> */}
      </li>
    </>
  )
}


export default memo(ColorPicker)