// +----+-------------------------+--------+
// -  1 - is Rotate               -      object.setControlVisible('mtr', false); -
// -  2 - is Depth Change         -      object.set('isDepthChange', false); -
// -  3 - is scale                -      object.hasControls = false; -
// -  4 - is moveable             -      object.lockMovementX = object.lockMovementY = false; -
// -  5 - is flip                 -      object.lockScalingFlip -
// -  6 - is deletable            -      object.set('isDeleteable', false); -
// -  7 - is Image Replace        -      1 -
// -  8 - is Font Changable       -      1 -
// -  9 - is Font Color Changable -      1 -
// - 10 - is BG Color Changable   -      1 -
// - 11 - is Maskable             -      1 -
// - 12 - is Rounded Mask         -      1 -
// +----+-------------------------+--------+

export const enableAllPermissionsOnObject = (object) => {
  object.setControlVisible('mtr', true);
  object.set('isDepthChange', true);
  object.hasControls = true;
  object.lockMovementX = object.lockMovementY = false;
  object.lockScalingFlip = false;
  object.set('isDeleteable', true);
  object.set('isRotatable', true);
  object.set('isImageReplaceable', true);
  object.set('isFontStyleChangeable', true);
  object.set('isFontColorChangeable', true);
  object.set('isBGColorChangable', true); 
  object.set('isImageReplaceable', true);
  object.set('isMaskable',true)
  object.set('isRoundedMask',true)

  return object;
}

export const disableAllPermissionsOnObject = (object) => {
  object.setControlVisible('mtr', false);
  object.set('isDepthChange', false);
  object.hasControls = false;
  object.lockMovementX = object.lockMovementY = true;
  object.lockScalingFlip = true;
  object.set('isDeleteable', false);
  object.set('isRotatable', false);
  object.set('isImageReplaceable', false);
  object.set('isFontStyleChangeable', false);
  object.set('isFontColorChangeable', false);
  object.set('isBGColorChangable', false); 
  object.set('isImageReplaceable', false);
  object.set('isMaskable',false)
  object.set('isRoundedMask',false)

  return object;
}

export const setIsRotatable = (object) => {
  // console.log(object.get('id'), "is seIsRotatable");
  if (object.isControlVisible('mtr')) {
    object.setControlVisible('mtr', false);
  } else {
    object.setControlVisible('mtr', true);
    object.set('isRotatable', true);
  }
  return object;
}

export const setIsFlipable = (object) => {
  console.log(object.get('id'), "is setIsFlipable");
  if (object.lockScalingFlip) {
    object.lockScalingFlip = false;
  } else {
    object.lockScalingFlip = true;
  }


  return object;
}

export const setIsScalable = (object) => {
  console.log(object.get('id'), "is setIsScalable");
  if (object.hasControls) {
    object.hasControls = false;
  } else {
    object.hasControls = true;
  }


  return object;
}

export const setIsMoveable = (object) => {
  console.log(object.get('id'), "is setIsMoveable");
  if (object.lockMovementX) {
    object.lockMovementX = object.lockMovementY = false;
  } else {
    object.lockMovementX = object.lockMovementY = true;
  }


  return object;
}

export const setIsDepthChange = (object) => {
  console.log(object.get('id'), "is setIsDepthChange");
  if (object.get('isDepthChange')) {
    object.set('isDepthChange', false);
  } else {
    object.set('isDepthChange', true);
  }


  return object;
}

export const setIsDeleteable = (object) => {
  console.log(object.get('id'), "is setIsDeleteable");
  if (object.get('isDeleteable')) {
    object.set('isDeleteable', false);
  } else {
    object.set('isDeleteable', true);
  }


  return object;
}

export const setIsFontStyleChangeable = (object) => {
  if (object.get('isFontStyleChangeable')) {
    object.set('isFontStyleChangeable', false);
  } else {
    object.set('isFontStyleChangeable', true);
  }

  return object;
}

export const setIsFontColorChangeable = (object) => {
  if (object.get('isFontColorChangeable')) {
    object.set('isFontColorChangeable', false);
  } else {
    object.set('isFontColorChangeable', true);
  }

  return object;
}

export const setIsImageReplaceable = (object) => {
  if (object.get('isImageReplaceable')) {
    object.set('isImageReplaceable', false);
  } else {
    object.set('isImageReplaceable', true);
  }

  return object;
}

export const setIsBGColorChangeable = (object) => {
  if(object.get("isBGColorChangable")){
    object.set('isBGColorChangable',false)
  }else{
    object.set('isBGColorChangable',true)
  }
  return object
}

export const setIsMaskable = (object) => {
  if (object.get('isMaskable')) {
    object.set('isMaskable', false);
  } else {
    object.set('isMaskable', true);
  }

  return object; // moveable and has control has been enabled on client side
}

export const setIsRoundedMask = (object) => {
  if(object.get('isRoundedMask')){
    object.set('isRoundedMask',false)
  }else{
    object.set('isRoundedMask',true)
  }

  return object // is rounded corner mask
}
