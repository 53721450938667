import React, { useEffect, useState, useCallback } from "react";
import Cropper from "react-easy-crop";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  loadCanvasFromJSON,
  deleteObject,
  showImagePickerModal,
  convertImageToCMYK,
  importImageInCanvas,
  addImagePickerModal,
  save_svg,
  savingClientName,
  saveSvgDraft,
  saveTemplateSides,
  setSideCanvasJSON,
  canvasToJSON,
  checkJsonIsEmpty,
  getBiggestObject,
  centerObjectToCanvas,
  clientCanvasObjectModified,
  getTemplateList,
  selectedLayer,
  clientCanvasSelection,
  getAllTemplateSideJSONs,
  canvasToImage,
} from "../Actions";
import "../css/TemplatesScreen.css";
import "../css/TemplateEditorScreen.css";
import {
  flipHorizontal,
  flipVertical,
  showHideGrid,
  textBold,
  textItalic,
  textAlign,
  changeFontColor,
  changeFontFamily,
  addText,
  addRectangle,
  addCircle,
  addLine,
  sendForward,
  sendBackward,
  changeBGColor,
  resetFontStyle,
  
  textUnderline,
  changeBorderBGColor,
  
  rotateDirection,
  animateFunctionOfJquery,
  
} from "../extras/CanvasObjectControls";
import {fabricCanvasSetup, canvasEvents, onSideChanged, openBox, closeBox} from '../extras/FabricCanvasSetup';
import { ThreeDPreview2 } from '../extras/ThreeDpreview2'
import { fabric } from "fabric";
import Dots from "react-activity/dist/Dots";
import { useLocation } from "react-router-dom";
import getCroppedImg, { blobToBase64 } from "../extras/CropImage";
import ColorPicker from "../Components/ColorPicker";
import { resetScroll, zooming_in_out, CanvasZoom } from "../extras/PanandZoom";
import {  SAVE_ALL_FONTS_LIST } from "../Actions/ActionTypes";
import {
  ImagePreviewer,
  Modal,
  ModalLoader,
  EditableLabel,
  ToggleButton,
  ClientHeader,
  ClientListButton,
  GroupButton,
  ClientButton,
  ClientObjectButton,
  ImageUploading,
} from "../Components";
import { printer_host } from "../extras/APIs";
import { gettingIndex } from "../extras/CommonNavigations";


var left = '0px'
var top = '0px'
var template_3d_model;
var fillColor = ''
var borderColor = ''
var fontColor = ''
var cameraX, cameraY, cameraZ;
var model_materials;

function TemplateEditorScreen() {
  const public_url = process.env.PUBLIC_URL
  const location = useLocation();
  const [uploadImage, setUploadImage] = useState(null);
  const [uploadImageType, setUploadImageType] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [rotation, setRotation] = useState(0);
  const [aspectRatio, setAspectRatio] = useState(1 / 1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [templateFontFace, setTemplateFontFace] = useState([]);
  const [showColorPickerTextModal, setShowColorPickerTextModal] =
    useState(false);
  const [fontListShow, setFontListShow] = useState(false);

  const [showColorPickerBGModal, setShowColorPickerBGModal] = useState(false);
  const [showBorderModal, setShowBorderModal] = useState(false);
  const [templateId, setTemplateId] = useState("");
  const [imagePreview, setImagePreview] = useState([]);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [backgroundId, setBackgroundId] = useState("");

  const [toggleChange, setToggleChange] = useState("");
  const [originalFont, setOriginalFont] = useState([]);
  const [layers, setLayers] = useState({});
  const [activeSideIndex, setActiveSideIndex] = useState(0);
  const [template_sides, setTemplateSides] = useState([]);
  const [all_template_sides, setAllTemplateSides] = useState({});
  const [swatchlist, setSwatchList] = useState([]);
  const [model_3d, set3dModel] = useState(null);
  const [model_ready, setModelReady] = useState(false);
  const [browserError, setBrowserError] = useState(false);
  const [moving,setMoving] = useState(false);
  const [shapesDropdownToggle, setShapeDropDownToggle] = useState(false);

  const [boxOpen,setBoxOpen] = useState(false)
  // Image Cropping utility
  

  // Variables needed for threejs
  // var scene;
  // var camera;
  // var renderer;
  // var controls;
  // var light;
  // var frameId;
  

  const navigate = useNavigate();
  const reduxState = useSelector((state) => state.template);
  const {
    canvas,
    layer_permissions,
    client_selected_layer,
    original_font_styles,
    swatch,
    show_image_picker_modal,
    change_image,
    image_loader,
    add_image_modal,
    upload_percentage,
    client_name,
    fonts_list,
    template_sides_canvas_json,
    show_hide_grid,
    font_loader,
    client_canvas_state,
    save_template_loader,
    referrer,
    canvas_svg_for_3d,
    list_of_client_button,
    templateListLoader,
  } = reduxState;
  const dispatch = useDispatch();
  const canvasWidth = window.innerWidth * 0.75;
  const canvasHeight = window.innerHeight * 0.7;
  // var fonts_list;

  var outline_layer_id;
  var background_layer_id;
  // var designable_layer_id;

  // var template_3d_model;
  // var cameraX, cameraY, cameraZ;
  // var model_materials;

  useEffect(() => {
    let subscribe = true;
    try {
      if(subscribe){

        const canvas_div = document.getElementById("canvas-div");
        const canvas_client_width = parseInt(canvas_div.clientWidth, 10)
        //const canvas_client_height = canvas_client_width * (window.screen.availHeight/window.screen.availWidth);
        const canvas_client_height = parseInt(canvas_div.clientHeight, 10)
        const fabric_canvas_div = document.getElementById("canvas");
        fabric_canvas_div.width = canvas_client_width;
        fabric_canvas_div.height = canvas_client_height;
  
        // Dynamic ThreeJS Canvas Height Width Assignment
        const threejs_div = document.getElementById("threejs-div") ? document.getElementById("threejs-div") : 0;
        const threejs_client_width = parseInt(threejs_div.clientWidth, 10)
        const threejs_client_height = threejs_client_width * (window.screen.availHeight / window.screen.availWidth);
        if (document.getElementById("threejs")) {
          const threejs_canvas_div = document.getElementById("threejs");
          threejs_canvas_div.style.width = threejs_client_width;
          threejs_canvas_div.style.height = threejs_client_height;
        }

        const all_template_sides = location.state.all_template_sides;

        const template_sides = location.state.template_sides;

        const activeSideTemplateLayerDies = all_template_sides[template_sides[activeSideIndex]]['template_layers']['layer_dies'];
        // const active
        const selectedClip = location.state.clip_layer_id;
        const selectedDieIndex = gettingIndex(activeSideTemplateLayerDies,selectedClip);
        const clip_layer_id = selectedDieIndex >-1 ? activeSideTemplateLayerDies[selectedDieIndex]['fabric_layer_id'] : '';
        
        console.log('selectedDie',activeSideTemplateLayerDies[selectedDieIndex],selectedDieIndex,all_template_sides);

        console.log('acitve',activeSideTemplateLayerDies);
        const navigate_from_draft = location.state.draft ? location.state.draft : false;
        const original_font_applied = location.state.original_font_applied;
        
        console.log('CLIP LAYER ID:',clip_layer_id);
        var template_fonts = location.state.template_fonts;
        const template_assigned_layers = location.state.layers;
        console.log('template_assigned_layers',template_assigned_layers);
        const template_id = location.state.template_id;
        const swatch = location.state.swatch;
        

        console.log('template_sides',template_sides);
        
        const template_fonts_face = location.state.template_font_face
          ? location.state.template_font_face
          : [];
        model_materials = location.state.template_3d_model["material"];
        template_3d_model = location.state.template_3d_model["link"];
        cameraX = parseInt(location.state.template_3d_model["cameraX"]);
        cameraY = parseInt(location.state.template_3d_model["cameraY"]);
        cameraZ = parseInt(location.state.template_3d_model["cameraZ"]);
        animateFunctionOfJquery();
        var canvas_json = !navigate_from_draft ? location.state.template_json_file : location.state.all_template_sides
        
        dispatch({
          type: SAVE_ALL_FONTS_LIST,
          payload: location.state.fonts_list,
        });

        fabricCanvasSetup(
          "threejs",
          "canvas",
          template_sides,
          template_fonts,
          template_assigned_layers,
          template_id,
          all_template_sides,
          canvas_json,
          template_fonts_face,
          model_materials,
          template_3d_model,
          cameraX,
          cameraY,
          cameraZ,
          swatch,
          client_name,
          activeSideIndex,
          navigate_from_draft,
          original_font_applied,
          clip_layer_id,
          canvas_svg_for_3d,
          dispatch,
          setBackgroundId,
          setTemplateFontFace,
          setTemplateId,
          setOriginalFont,
          setLayers,
          setSwatchList,
          setTemplateSides,
          setAllTemplateSides,
          setToggleChange,
          setBrowserError,
          set3dModel,
          setModelReady,
          setMoving,
          closingDropDown,
        )
      }
      // Dynamic FabricJS Canvas Height Width Assignment

    } catch (e) {
      subscribe=false
      navigate("/");
      console.log("redirecting", e);
    }

    return () => {
      selectedLayer(null,dispatch)
      subscribe = false
      const navigate_from_draft = location.state.draft
        ? location.state.draft
        : false;
      //setting to deafult
      if (navigate_from_draft) {
        console.log("saving screen", navigate_from_draft);
      }
      // dispatch(savingClientName(""));
    };
  }, [dispatch]);



  // function canvasEvents(new_canvas, biggest_object) {
  //   new_canvas.__eventListeners["mouse:up"] = [];
  //   new_canvas.on("mouse:up", function (opt) {
  //     setMoving(false)
  //     new_canvas.forEachObject(function (o) {
  //       o.setCoords();
  //     });
  //     new_canvas.requestRenderAll();
  //   });

  //   new_canvas.__eventListeners["mouse:wheel"] = [];
  //   new_canvas.on("mouse:wheel", function (opt) {
  //     CanvasZoom(
  //       opt,
  //       new_canvas,
  //       biggest_object.height,
  //       biggest_object.width,
  //       dispatch
  //     );
  //   });

  // if(canvas){
  //   canvas.on('object:moving',()=>{
  //     // console.log('movieing');
  //     closingDropDown();
  //   })

  // }

  //   new_canvas.on('object:moving', () => {
  //     setMoving(true)
  //     closingDropDown()
  //   })

  //   // new_canvas.__eventListeners["object:modified"] = [];
  //   // If we are unsetting above event its giving error in snapping code, hence ignoring the above event removal
  //   new_canvas.on("object:modified", () => {
  //     clientCanvasObjectModified(dispatch, new_canvas);
  //   });

  //   initAligningGuidelines(new_canvas, dispatch);
  // }

  function undo(dispatch) {
    console.log(client_canvas_state);
    if (client_canvas_state[1] !== null) {
      // console.log('Undo');
      canvas.loadFromJSON(client_canvas_state[1]);
      // canvas.requestRenderAll();
      clientCanvasObjectModified(dispatch, canvas, true);
    } else {
      console.log("Nothing to Undo");
    }
    console.log('canvas',);
  }

  function deleteCanvasObject() {
    const delete_object = canvas.getActiveObject();
    if (delete_object) {
      if (delete_object.isDeleteable) {
        var proceed = false;
        proceed = window.confirm(
          "Are you sure you want to delete the object ?"
        );
        if (proceed) {
          dispatch(deleteObject(canvas, layer_permissions, "user"));
          clientCanvasObjectModified(dispatch, canvas);
        }
      } else {
        alert("You are not allowed to delete this object");
      }
    } else {
      alert("Select a layer first");
    }
  }

  function changeFont(font_name_index, canvas, selected_object) {
    const selected_font = fonts_list[font_name_index];
    const object_bold = selected_object.get("fontWeight");
    const object_italic = selected_object.get("fontStyle");
    changeFontFamily(
      canvas,
      dispatch,
      selected_font,
      object_bold,
      object_italic
    );
    setFontListShow(false);
  }

  function changeTextColor(color) {
    changeFontColor(canvas, dispatch, color);
    fontColor = color
    setShowColorPickerTextModal(false);
  }

  function changeObjectBGColor(color) {
    changeBGColor(canvas, dispatch, color);
    fillColor = color
    setShowColorPickerBGModal(false);
  }

  function changeObjectBorderColor(color) {
    changeBorderBGColor(canvas, dispatch, color);
    borderColor = color
    showBorderBGModal(false);
  }

  const closingDropDown = useCallback(()=> {
    setFontListShow(false);
    setShowColorPickerTextModal(false);
    setShowColorPickerBGModal(false);
    setShowBorderModal(false);

  },[moving])

  function textFormatting(canvas) {
    // fonts_list = location.state && location.state.fonts_list ;
    // font
    if (client_selected_layer && !moving) {
      if (client_selected_layer.get("type") === "i-text") {
        if (client_selected_layer.get("isFontStyleChangeable")) {
          // var corrdsOfText = canvas.getAbsoluteCoords(client_selected_layer,canvas.getZoom())
          // var buttons = document.getElementsByClassName('tool-shadow')[0]
          // var zoom = canvas.getZoom()
          var object_rect = client_selected_layer.getBoundingRect()
          // buttons.style.left = (corrdsOfText.left - buttons.)
          // console.log('corrdsOfText', client_selected_layer.getBoundingRect());
          // buttons.clientLeft = (corrdsOfText.left - 85/2) + 'px';
          // buttons. = (corrdsOfText.top - 18/2) + 'px';+
          console.log();
          left = (object_rect.left - 200) + 'px'
          top = (object_rect.top + 30) + 'px'
          // var object_font_family = client_selected_layer.get("fontFamily");
          // object_font_family = fonts_list.indexOf(
          //   object_font_family.replaceAll("'", "")
          // );
          return (
            <>
              <div className="btn-group">
                <GroupButton
                  className={`btn-group ${fontListShow ? "show" : ""}`}
                >
                  <ClientButton
                    onPress={() => setFontListShow(!fontListShow)}
                    className={`dropdown-toggle`}
                  >
                    {"Fonts"}
                    <span className="caret"></span>
                  </ClientButton>
                  <ul
                    className={`dropdown-menu ${fontListShow ? "show" : ""}`}
                    style={{
                      position: "absolute",
                      willChange: "transform",
                      left: "0",
                      right: "0",
                      transform: "translate3d(0px,3px,0px)",
                    }}
                  >
                    {fonts_list.length > 0 ? (
                      fonts_list.map((font, index) => {
                        return (
                          <li
                            key={index}
                            value={index}
                            onClick={() => changeFont(index, canvas, client_selected_layer)}
                          >
                            <a
                              // onClick={() =>changeFont(index, canvas, client_selected_layer)}
                              style={{ paddingLeft: "0px" }}>
                              {font}
                            </a>
                          </li>
                        );
                      })
                    ) : (
                      <li>
                        <a style={{ paddingLeft: "0px", }}>No Fonts are there</a>
                      </li>
                    )}
                  </ul>
                </GroupButton>
                <GroupButton className={`btn-group`}>
                  <ClientButton
                    style={{ color: fontColor }}
                    onPress={() =>
                      setShowColorPickerTextModal(!showColorPickerTextModal)
                    }
                    className={"dropdown-toggle"}
                  >
                    Color<span className="caret"></span>
                  </ClientButton>
                  {textColorPicker(canvas)}
                </GroupButton>
              </div>
              <div className="btn-group">
                <GroupButton className={"btn-group"}>
                  {/* <a className="btn btn-default" style={{cursor:'pointer'}}><i className="fa fa-bold"></i></a> */}
                  {renderBold()}
                  {renderItalic()}
                  {renderUnderLine()}
                  <ClientButton
                    onPress={() =>
                      setShowColorPickerBGModal(!showColorPickerBGModal)
                    }
                    className={"dropdown-toggle"}
                  >
                    <img src="images/icons/icon-color-picker.svg" />
                  </ClientButton>
                  {BGColorPicker(canvas)}
                </GroupButton>
              </div>
              <div className="btn-group">
                <ClientButton
                  onPress={() => textAlign(canvas, dispatch, "left")}
                >
                  <li className="fa fa-align-left"></li>
                </ClientButton>
                <ClientButton
                  onPress={() => textAlign(canvas, dispatch, "center")}
                >
                  <li className="fa fa-align-center"></li>
                </ClientButton>
                <ClientButton
                  onPress={() => textAlign(canvas, dispatch, "right")}
                >
                  <li className="fa fa-align-right"></li>
                </ClientButton>
                <ClientButton
                  onPress={() => textAlign(canvas, dispatch, "justify")}
                >
                  <li className="fa fa-align-justify"></li>
                </ClientButton>
                <ClientButton
                  onPress={() => {
                    resetFontStyle(
                      original_font_styles,
                      canvas,
                      dispatch,
                      client_selected_layer
                    )
                    fontColor = ''
                  }
                  }
                >
                  <li className="fa fa-refresh"></li>
                </ClientButton>
                <ClientButton
                  onPress={() => deleteCanvasObject()}
                >
                  <li className="fa fa-trash"></li>
                </ClientButton>
              </div>
            </>
          );
        }
      }
    }
  }

  function renderBold() {
    if (client_selected_layer.get("fontWeight") === "bold") {
      return (
        <ClientButton
          onPress={() => textBold(canvas, dispatch, original_font_styles)}
        >
          <li style={{ fontWeight: "bold" }} className="fa fa-bold"></li>
        </ClientButton>
      );
    } else {
      return (
        <ClientButton
          onPress={() => textBold(canvas, dispatch, original_font_styles)}
        >
          <li className="fa fa-bold"></li>
        </ClientButton>
      );
    }
  }

  function renderItalic() {
    if (client_selected_layer.get("fontStyle") === "italic") {
      // console.log('original font styles',original_font_styles);
      return (
        <ClientButton
          onPress={() => textItalic(canvas, dispatch, original_font_styles)}
        >
          <em>
            <i className="fa fa-italic"></i>
          </em>
        </ClientButton>
      );
    } else {
      return (
        <ClientButton
          onPress={() => textItalic(canvas, dispatch, original_font_styles)}
        >
          <i className="fa fa-italic"></i>
        </ClientButton>
      );
    }
  }

  function renderUnderLine() {
    return (
      <ClientButton onPress={() => textUnderline(canvas, dispatch)}>
        <li className="fa fa-underline"></li>
      </ClientButton>
    );
  }

  function textColorPicker(canvas) {
    return (
      <ul
        style={{
          position: "absolute",
          willChange: "transform",
          left: "0",
          right: "0",
          transform: "translate3d(0px,3px,0px)",
        }}
        className={`dropdown-menu color-palette ${showColorPickerTextModal ? "show" : ""}`}
      >
        <ColorPicker
          colorList={swatchlist}
          colorPickedCallback={changeTextColor}
        />
      </ul>
    );
  }

  function showBorderBGModal(openModal) {
    if (!showBorderModal) {
      setShowBorderModal(openModal);
      document.body.classList.add("modal-open");
      document.body.style.paddingRight = "17px";
    } else {
      setShowBorderModal(openModal);
      document.body.classList.remove("modal-open");
      document.body.style.paddingRight = "0px";
    }
  }

  function BGColorPicker(canvas) {
    return (
      <ul
        style={{
          position: "absolute",
          willChange: "transform",
          left: "0",
          right: "0",
          transform: "translate3d(0px,3px,0px)",
        }}
        className={`dropdown-menu color-palette ${showColorPickerBGModal ? "show" : ""}`}
      >
        <ColorPicker
          colorList={swatchlist}
          colorPickedCallback={changeObjectBGColor}
        />
      </ul>
    );
  }

  function borderColorChange() {
    return (
      <ul
        style={{
          position: "absolute",
          willChange: "transform",
          left: "0",
          right: "0",
          transform: "translate3d(0px,3px,0px)",
        }}
        className={`dropdown-menu color-palette ${showBorderModal ? 'show' : ''}`}
      >
        <ColorPicker
          colorPickedCallback={changeObjectBorderColor}
          colorList={swatchlist}
        />
      </ul>
    );
  }

  // function showtextModal(openModal) {
  //   if (!showColorPickerTextModal) {
  //     setShowColorPickerTextModal(openModal)
  //     document.body.classList.add('modal-open')
  //     document.body.style.paddingRight = '17px'
  //   } else {
  //     setShowColorPickerTextModal(openModal)
  //     document.body.classList.remove('modal-open')
  //     document.body.style.paddingRight = '0px'
  //   }
  // }

  // function textColorPicker(canvas) {
  //   return (
  //     <>
  //       <Button name={'Change Text Color'} onPress={() => showtextModal(true)} classgiven={'link btn-basic btn-basic2'} />
  //       <Modal
  //         headTitle={'Color Picker'}
  //         classGiven={`modal fade font-popup ${showColorPickerTextModal ? 'show' : ''}`}
  //         style={showColorPickerTextModal ? { display: 'block', paddingRight: '17px' } : { display: 'none' }}
  //         onPress={() => showtextModal(false)}>
  //         <ColorPicker
  //           colorList={swatchlist}
  //           colorPickedCallback={changeTextColor}
  //         />
  //       </Modal>
  //     </>
  //   );
  //   // if (client_selected_layer) {
  //   //   if (client_selected_layer.get("type") === "i-text") {
  //   //     const object_current_font_color = client_selected_layer.get("fill");
  //   //     if (client_selected_layer.get("isFontColorChangeable")) {
  //   //     }
  //   //   }
  //   // }
  // }

  // function showBgModal(openModal) {
  //   if (!showColorPickerBGModal) {
  //     setShowColorPickerBGModal(openModal)
  //     document.body.classList.add('modal-open')
  //     document.body.style.paddingRight = '17px'
  //   } else {
  //     setShowColorPickerBGModal(openModal)
  //     document.body.classList.remove('modal-open')
  //     document.body.style.paddingRight = '0px'
  //   }
  // }

  // function BGColorPicker(canvas) {
  //   if (client_selected_layer) {
  //     const object_current_color = client_selected_layer.get("fill");
  //     // console.log('client',client_selected_layer)
  //     if (client_selected_layer.get("isBGColorChangable")) {
  //       return (
  //         <div style={{ marginTop: '10px' }}>
  //           <Button name={"Change Background Color"} onPress={() => showBgModal(true)} />
  //           <Modal headTitle={'Color Picker'}
  //             classGiven={`modal fade font-popup ${showColorPickerBGModal ? 'show' : ''}`}
  //             style={showColorPickerBGModal ? { display: 'block', paddingRight: '17px' } : { display: 'none' }}
  //             onPress={() => showBgModal(false)}
  //           >
  //             <ColorPicker
  //               colorPickedCallback={changeObjectBGColor}
  //               colorList={swatchlist}
  //             />
  //           </Modal>
  //         </div>
  //       );
  //     }
  //   }
  // }

  const jsonToSvg = (jsonObject, big_width, big_height, type_to_save) => {
    // console.log('json',jsonObject.clipPath.width);
    return new Promise(async (resolve, reject) => {
      var canvas = new fabric.Canvas(null, {
        width: big_width,
        height: big_height,
      });
      canvas.loadFromJSON(jsonObject, function (obj) {
        const objects = canvas.getObjects();

        // console.log('Canvas clippath', canvas.clipPath);
        const outline_layer = location.state.layers.outline;
        // const background_layer = backgroundId
        var clipLayer = location.state.clip_layer_id
        var clipPath;
        var dottedLayer;
        for (let i = 0; i < objects.length; i++) {
          if (clipLayer) {
            if (objects[i].get('layer_id') == clipLayer) {
              console.log('not clip later');
              clipPath = objects[i];
            }
          } else {
            if (!clipLayer && (outline_layer || backgroundId)) {
              // console.log('not clip later');
              if (objects[i].get("layer_id") == outline_layer) {
                clipPath = objects[i];
              }
            }
          }

          if (objects[i].get("id") == "border_1") {
            canvas.remove(objects[i]);
          }

          if (objects[i].get("id") == "border_2") {
            canvas.remove(objects[i]);
          }

          if (objects[i].get("id") == "border_3") {
            canvas.remove(objects[i]);
          }

          if (objects[i].get("id") == "border_4") {
            canvas.remove(objects[i]);
          }
        }
        var biggest_object
        // console.log('canvas center',canvas.toDataURL('image/png'));
        if (clipLayer) {
          biggest_object = clipPath.getBoundingRect()
        } else {
          biggest_object = getBiggestObject(objects)
        }

        // console.log('bbigest',big);
        if (type_to_save === "image") {
          const image = canvasToImage(canvas, biggest_object);
          resolve(image);
        } else {
          canvas.clipPath = canvas.clipPath;
          canvas.requestRenderAll();
          if ((outline_layer || clipLayer || backgroundId) && clipPath) {
            // const outline_svg = clipPath.toSVG() + "</svg>";
            // var canvas_svg = canvas.toSVG();
            // canvas_svg = canvas_svg.replace("</svg>", outline_svg);
            // const objects = 
            const outline_svg = "</defs>" + clipPath.toSVG();
            var canvas_svg = canvas.toSVG({
              viewBox: {
                x: biggest_object.left,
                y: biggest_object.top,
                width: biggest_object.width,
                height: biggest_object.height
              },
              width: biggest_object.width,
              height: biggest_object.height,
              encoding:'ISO-8859-1'
            });
            console.log('svg',canvas_svg);
            canvas_svg = canvas_svg.replace("</defs>", outline_svg);

            canvas_svg = canvas_svg.replace(
              '<rect x="0" y="0" width="100%" height="100%" fill="pink"></rect>',
              ""
            );
            // console.log('clipPath',canvas_svg);
            // console.log('canvas',outline_svg);
          } else {
            var canvas_svg = canvas.toSVG({
              viewBox: {
                x: biggest_object.left,
                y: biggest_object.top,
                width: biggest_object.width,
                height: biggest_object.height
              },
              width: biggest_object.width,
              height: biggest_object.height
            });
          }
          // var canvas_data = canvas.toDataURL({format:'image/jpeg'})
          // console.log('svg',canvas_svg);
          resolve(canvas_svg);
        }
        // canvas.clipPath = null
        // centerObjectToCanvas(canvas)
      });
    });
  };

  async function savingAllSides(template_side, type_to_save) {
    if (!checkJsonIsEmpty(template_side).includes(false)) {
      const canvas_svg = {};
      const canvasJson = {};
      const nameOfFile = {};
      const json = {};
      for (const [key, value] of Object.entries(template_side)) {
        // console.log('all');
        const canvas = {};
        // const svgObject = await jsonToSvg(value,biggest_object[key].width,biggest_object[key].height)
        const svgObject = await jsonToSvg(value, 750, 750, type_to_save);
        canvas.svgObject = svgObject;
        canvas.svgName = !location.state.draft
          ? location.state.all_template_sides[key].name
          : location.state.template_name[key];
        canvas_svg[key] = canvas;
        nameOfFile[key] = !location.state.draft
          ? encodeURI(location.state.all_template_sides[key].name)
          : location.state.template_name[key];

        json[key] = value;
        // canvas_svg[]
        // console.log('canvas name',canvas);
      }

      canvasJson["all_template_side"] = json;
      canvasJson["templates_name"] = nameOfFile;
      canvasJson["original_font_applied"] = original_font_styles;
      canvasJson["original_template_fonts"] = originalFont;
      canvasJson["layers"] = layers;
      canvasJson["template_sides"] = template_sides;
      canvasJson["swatch"] = swatchlist;
      canvasJson["font_face"] = templateFontFace;
      canvasJson['fonts_list'] = fonts_list;
      canvasJson['clip_layer_id'] = location.state.clip_layer_id
      canvasJson['template_3d_model'] = location.state.template_3d_model
      if (type_to_save === "image") {
        previewModal(true);
        setImagePreview(canvas_svg);
      } else {
        // console.log('lc',canvas_svg,templateId,canvasJson,client_name);
        const printer_server_identifier = location.state.printer_server_identifier;
        console.log('printer_server_identifier on screen:',printer_server_identifier);
        if (!printer_server_identifier) {
          console.log('Admin side operations');
          dispatch(
            save_svg(canvas_svg, templateId, canvasJson, client_name,previewModal, 0, navigate)
          );
        } else {
          console.log('Printer side operations');
          dispatch(
            save_svg(canvas_svg, templateId, canvasJson, client_name,previewModal, printer_server_identifier)
          );
        }
        // previewModal(false)
      }
      // console.log('template', templateId, location.state.client_name);
    } else {
      alert("Edit all the sides to proceed");
    }
  }

  // function selectImagePicker(event) {
  //   setUploadImage(URL.createObjectURL(event.target.files[0]));
  //   setUploadImageType(event.target.files[0].type);
  //   // dispatch(showImagePickerModal(false))
  // }

  // function renderImageConversionLoader() {
  //   if (image_loader) {
  //     return <Dots />;
  //   }
  //   if (add_image_modal) {
  //     return (
  //       <Cropper
  //         image={uploadImage}
  //         crop={crop}
  //         zoom={zoom}
  //         aspect={aspectRatio} // W/H
  //         // aspect={1/1}
  //         onCropChange={setCrop}
  //         onCropComplete={onCropComplete}
  //         onZoomChange={setZoom}
  //         rotation={rotation}
  //       // objectFit={'auto-cover'}
  //       />
  //     );
  //   } else {
  //     const selected_object_width = client_selected_layer.get("width");
  //     const selected_object_height = client_selected_layer.get("height");

  //     return (
  //       <Cropper
  //         image={uploadImage}
  //         crop={crop}
  //         zoom={zoom}
  //         aspect={selected_object_width / selected_object_height} // W/H
  //         onCropChange={setCrop}
  //         onCropComplete={onCropComplete}
  //         onZoomChange={setZoom}
  //         rotation={rotation}
  //       // objectFit={'auto-cover'}
  //       />
  //     );
  //   }
  // }

  // function imageRotation(directionOfRotation) {
  //   const rotateby = 90;
  //   if (directionOfRotation === "right") {
  //     setRotation(rotation + rotateby);
  //   } else {
  //     setRotation(rotation - rotateby);
  //   }
  // }

  // function settingAspectRatio(aspectRatio) {
  //   if (aspectRatio === "1/1") {
  //     setAspectRatio(1 / 1);
  //   } else if (aspectRatio === "3/4") {
  //     setAspectRatio(3 / 4);
  //   } else {
  //     setAspectRatio(4 / 3);
  //   }
  // }


  // function renderImageCropper() {
  //   if (uploadImage) {
  //     if (show_image_picker_modal) {
  //       return (
  //         <>
  //           <div
  //             style={{ display: "flex", marginTop: "5px", marginBottom: "5px" }}
  //           >
  //             <button
  //               style={{ marginRight: "10px" }}
  //               onClick={() => imageRotation("right",rotation,setRotation)}
  //             >
  //               Change by right
  //             </button>
  //             <button onClick={() => imageRotation("left",rotation,setRotation)}>
  //               Change by Left
  //             </button>
  //           </div>
  //           <div
  //             style={{
  //               position: "relative",
  //               width: "100%",
  //               height: 400,
  //               background: "#333",
  //             }}
  //           >
  //             {renderImageConversionLoader()}
  //           </div>
  //         </>
  //       ); // Here masking of image happen
  //     } else {
  //       return (
  //         <>
  //           <div
  //             style={{ display: "flex", marginTop: "5px", marginBottom: "5px" }}
  //           >
  //             <button
  //               style={{ marginRight: "10px" }}
  //               onClick={() => imageRotation("right",rotation,setRotation)}
  //             >
  //               Change by right
  //             </button>
  //             <button onClick={() => imageRotation("left",rotation,setRotation)}>
  //               Change by Left
  //             </button>
  //             <button onClick={() => settingAspectRatio("1/1",setAspectRatio)}>
  //               Aspect ratio 1:1
  //             </button>
  //             <button onClick={() => settingAspectRatio("3/4",setAspectRatio)}>
  //               Aspect ratio 3:4
  //             </button>
  //             <button onClick={() => settingAspectRatio("4/3",setAspectRatio)}>
  //               Aspect ratio 4:3
  //             </button>
  //           </div>
  //           <div
  //             style={{
  //               position: "relative",
  //               width: "100%",
  //               height: 400,
  //               background: "#333",
  //             }}
  //           >
  //             {renderImageConversionLoader()}
  //           </div>
  //         </>
  //       ); // Here adding image into Canvas happen
  //     }
  //   }
  // }

  function changeImageModal(openModal) {
    if (!show_image_picker_modal) {
    
      document.body.classList.add("modal-open");
      document.body.style.paddingRight = "17px";
    } else {
      document.body.classList.remove("modal-open");
      document.body.style.paddingRight = "0px";
    }
    dispatch(showImagePickerModal(openModal));
    
  }

  function renderImagePickerModal() {
    if (show_image_picker_modal) {
      return (
        <>
          <Modal
            headTitle={"Image Picker"}
            classGiven={`modal fade add-img-popup ${show_image_picker_modal ? "show" : ""
              }`}
            onPress={() => changeImageModal(false)}
            notHeader={true}
            modalDialogClass={show_image_picker_modal && "modal-dialog-centered"}
            style={
              show_image_picker_modal
                ? { display: "block", paddingRight: "17px" }
                : { display: "none" }
            }
            footerWant={true}
            footerTitle={"Cancel"}
            footerOnPress={() => changeImageModal(false)}
          >
            <ImageUploading
              handleUploading={handleSubmit}                 
              show_image_picker_modal={show_image_picker_modal}                 
              client_selected_layer={client_selected_layer} 
              image_loader={image_loader} 
              skip_cropping={false}            
            />
            {/* <div className="container">{renderImageCropper()}</div>
            {image_loader && <Dots />}
            <form onSubmit={(e) => handleSubmit(e)}>
              <input
                type="file"
                accept="image/jpeg,image/png,image/jpg"
                onChange={(event) => selectImagePicker(event)}
              />
              <br />
              <input type="submit" value="Upload File" />
            </form> */}
          </Modal>
        </>
      );
    }
  }
  function add_image(openModal) {
    if (!add_image_modal) {
      
      document.body.classList.add("modal-open");
      // document.body.style.paddingRight = '17px'
    } else {
      document.body.classList.remove("modal-open");
      document.body.style.paddingRight = "0px";
      
    }
    dispatch(addImagePickerModal(openModal));
  }

  const addImageModal = () => {
    if (add_image_modal) {
      return (
        <>
          <Modal
            headTitle={"Image Picker"}
            onPress={() => add_image(false)}
            notHeader={true}
            classGiven={`modal fade add-img-popup ${add_image_modal ? "show" : ""
              }`}
            modalDialogClass={add_image_modal && "modal-dialog-centered"}
            style={add_image_modal ? { display: "block" } : { display: "none" }}
            footerWant={true}
            footerTitle={"Cancel"}
            footerOnPress={() => add_image(false)}
          >
            <ImageUploading 
              handleUploading={handleSubmit}           
              add_image_modal={add_image_modal}                 
              client_selected_layer={client_selected_layer} 
              image_loader={image_loader} 
              skip_cropping={false}
            />
            {/* <h4>Upload Images</h4>

            {!uploadImage ? (
              <div className="add-field">
                <input
                  type={"file"}
                  accept="image/jpeg,image/png,image/jpg"
                  onChange={(event) => selectImagePicker(event)}
                />
                <div className="button-part">
                  <button>Select File</button>
                  <div>.png .jpg .pdf .ai</div>
                  <div>or drag a file here to upload</div>
                  <div>
                    <span className="red-span">Important:</span> To avoid delays
                    in processing your order, please upload logo and graphics
                    with high resolution (300dpi).
                  </div>
                </div>
              </div>
            ) : (
              <>{renderImageCropper()}</>
            )}
            {image_loader ? (
              <Dots />
            ) : (
              <input
                type={"submit"}
                onClick={(e) => handleSubmit(e)}
                value="Upload File"
                className="upload-file-btn btn-basic"
              />
            )} */}
            {/* <div className="container">{renderImageCropper()}</div>
            <form onSubmit={(e) => handleSubmit(e)}>
              <input
                type="file"
                accept="image/jpeg,image/png,image/jpg"
                onChange={(event) => selectImagePicker(event)}
              />
              <br />
              {image_loader ? <Dots /> : (
                <input type={"submit"} value="Upload File" />
              )}
            </form> */}
          </Modal>
        </>
      );
    }
  };

  async function handleSubmit(event, uploadImage,uploadImageType,croppedAreaPixels,rotation,aspectRatio,skip_cropping) {
    event.preventDefault();
    add_image(false);
    try {
      if (add_image_modal) {
        if (aspectRatio > 0) {
          if (skip_cropping) {
            const base64image = await blobToBase64(uploadImage);

            dispatch(
              convertImageToCMYK(base64image, uploadImageType, canvas, null)
            );
          } else {
            const croppedImage = await getCroppedImg(
              uploadImage,
              uploadImageType,
              croppedAreaPixels,
              rotation
            );
            setCroppedImage(croppedImage);
            dispatch(
              convertImageToCMYK(croppedImage, uploadImageType, canvas, null)
            );
          }
          // here cropped image
        }
      } else {
        const croppedImage = await getCroppedImg(
          uploadImage,
          uploadImageType,
          croppedAreaPixels,
          rotation
        );

        setCroppedImage(croppedImage);

        dispatch(
          convertImageToCMYK(
            croppedImage,
            uploadImageType,
            canvas,
            client_selected_layer
          )
        );
      }
      
    } catch (e) {
      console.error(e);
    }
  }

  // function allValueSettingToDefault() {
  //   setUploadImage(null);
  //   setRotation(0);
  //   setAspectRatio(1 / 1);
  //   // setZoom(1);
  // }

  function renderChangeImageOption(canvas) {
    if (client_selected_layer) {
      if (client_selected_layer.get("isImageReplaceable")) {
        return (
          <button
            style={{ marginRight: 10, marginTop: 15 }}
            onClick={() => changeImageModal(true)}
          >
            Change Image
          </button>
        );
      } else if (client_selected_layer.get("isMaskable")) {
        return (
          <button
            style={{ marginRight: 10, marginTop: 15 }}
            onClick={() => dispatch(showImagePickerModal(true))}
          >
            Select Image
          </button>
        );
      }
    }
  }

  function toggleButtonsOptions() {
    // const template_sides = Object.keys(all_template_sides)
    if (template_sides.length === 2) {
      return (
        <ToggleButton
          value1={template_sides[0]}
          value2={template_sides[1]}
          changeValueFun={(e) => {
            canvas.__eventListeners["after:render"] = [];
            sideChange(0);
            // onSideChanged(0);
            console.log('e',0);
          }}
          changeValueFun2={(e) => {
            canvas.__eventListeners["after:render"] = [];
            // onSideChanged(1);
            sideChange(1);
            // console.log('e',1);
          }}
          currentVal={toggleChange}
        />
      );
    }
    if (template_sides.length > 2) {
      return <div className="frnt-bck select-side-option">{renderTemplateSidesDropdown()}</div>;
    }
    // console.log('all',template_sides);
  }

  const sideChange = (index) => {
    const current_template = location.state.all_template_sides[template_sides[index]];

    console.log('current_template',current_template);
    
    const currentLayersDiesList = current_template['template_layers']['layer_dies'];

    const dieSelected = location.state.clip_layer_id ? location.state.clip_layer_id : '';
    
    const selectedDieLayerIndex = gettingIndex(currentLayersDiesList,dieSelected);

    // const clip_layer_id = location.state.clip_layer_id ? location.state.clip_layer_id:
    //   '';
    const clip_layer_id = selectedDieLayerIndex > -1 ? currentLayersDiesList[selectedDieLayerIndex]['fabric_layer_id'] : '';
    // const clip_layer_id = location.state.clip_layer_id && current_template['template_layers']['layer_dies'].length !== 0 ?
    // current_template['template_layers']['layer_dies'][0]['fabric_layer_id'] :
    //   '';
      console.log('ON side changed CLIP LAYER ID:',selectedDieLayerIndex,location.state.layers,location.state.clip_layer_id,clip_layer_id);
    onSideChanged(
      index,
      "canvas",
      canvas,
      model_3d,
      model_materials,
      canvas_svg_for_3d,
      template_sides,
      activeSideIndex,
      template_sides_canvas_json,
      all_template_sides,
      clip_layer_id,
      current_template.fonts,
      current_template.layers,
      // location.state.original_font_applied,
      null,
      location.state.draft,
      dispatch,
      setMoving,
      setActiveSideIndex,
      setBackgroundId,
      setOriginalFont,
      setLayers,
      setTemplateSides,
      setToggleChange,
    )
  }

  const savingInToDraft = async () => {
    const canvasJson = {};
    const nameOfFile = {};
    const json = {};
    if (!checkJsonIsEmpty(template_sides_canvas_json).includes(false)) {
      for (const [key, value] of Object.entries(template_sides_canvas_json)) {
        nameOfFile[key] = !location.state.draft
          ? encodeURI(location.state.all_template_sides[key].name)
          : location.state.template_name[key];

        json[key] = value;
      }

      // canvasJson["all_template_side"] = json;
      // canvasJson["templates_name"] = nameOfFile;
      // canvasJson["original_font_applied"] = original_font_styles;
      // canvasJson["original_template_fonts"] = originalFont;
      // canvasJson["layers"] = layers;
      // canvasJson["template_sides"] = template_sides;
      // canvasJson["swatch"] = swatchlist;
      // canvasJson["font_face"] = templateFontFace;
      // canvasJson['template_3d_model'] = location.state.template_3d_model
      // // console.log('canvas',canvasJson);
      // dispatch(
      //   saveSvgDraft(canvasJson, templateId, client_name, nameOfFile, navigate)
      // );
    } else {
      for (const [key, value] of Object.entries(template_sides_canvas_json)) {
        if (value) {
          const jsonCanvas = canvasToJSON(canvas);

          nameOfFile[key] = !location.state.draft
            ? encodeURI(location.state.all_template_sides[key].name)
            : location.state.template_name[key];
          json[key] = jsonCanvas;
        } else {
          const response = await fetch(all_template_sides[key].canvas_json);

          nameOfFile[key] = !location.state.draft
            ? encodeURI(location.state.all_template_sides[key].name)
            : location.state.template_name[key];

          json[key] = await response.json();
        }
      }
    }
    canvasJson["all_template_side"] = json;
    canvasJson["templates_name"] = nameOfFile;
    canvasJson["original_font_applied"] = original_font_styles;
    canvasJson["original_template_fonts"] = originalFont;
    canvasJson["layers"] = layers;
    canvasJson["template_sides"] = template_sides;
    canvasJson["swatch"] = swatchlist;
    canvasJson["font_face"] = templateFontFace;
    canvasJson['fonts_list'] = fonts_list
    canvasJson['clip_layer_id'] = location.state.clip_layer_id
    canvasJson['template_3d_model'] = location.state.template_3d_model

    dispatch(
      saveSvgDraft(canvasJson, templateId, client_name, nameOfFile, navigate)
    );
  };

  function savingSvg(type_of_save) {
    const existing_side_canvas_json = canvasToJSON(canvas);

    const existing_template_side = template_sides[activeSideIndex];
    dispatch(
      setSideCanvasJSON(
        existing_side_canvas_json,
        template_sides_canvas_json,
        existing_template_side
      )
    );
    if (!location.state.draft) {
      if (!client_name) {
        alert("please enter your name for saving");
      } else {
        if (type_of_save === "save") {
          // previewModal(false)
          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;
          savingAllSides(template_sides_canvas_json);
        } else if (type_of_save === "preview_svg") {
          savingAllSides(template_sides_canvas_json, "image");
        } else {
          savingInToDraft();
        }
      }
    } else {
      if (type_of_save === "save") {
        savingAllSides(template_sides_canvas_json);
      } else if (type_of_save === "preview_svg") {
        savingAllSides(template_sides_canvas_json, "image");
      } else {
        savingInToDraft();
      }
    }
  }

  // function onSideChanged(selected_side_index) {
  //   let subscribe = true;
  //   const existing_side_canvas_json = canvasToJSON(canvas);
  //   const existing_template_side = template_sides[activeSideIndex];
  //   dispatch(
  //     setSideCanvasJSON(
  //       existing_side_canvas_json,
  //       template_sides_canvas_json,
  //       existing_template_side
  //     )
  //   );
  //   setActiveSideIndex(selected_side_index);
  //   const active_template_side =
  //     all_template_sides[template_sides[selected_side_index]];
  //   const clip_layer_id = location.state.clip_layer_id
  //   const template_fonts = !location.state.draft
  //     ? active_template_side.fonts
  //     : location.state.template_fonts;
  //   const template_assigned_layers = !location.state.draft
  //     ? active_template_side.layers
  //     : location.state.layers;

  //   background_layer_id = template_assigned_layers.BG;
  //   // console.log('template', template_fonts);
  //   setBackgroundId(background_layer_id);

  //   setOriginalFont(template_fonts);
  //   setLayers(location.state.layers);
  //   setTemplateSides(template_sides);
  //   setToggleChange(template_sides[selected_side_index]);
  //   console.log('selected_side_index', template_sides[selected_side_index], selected_side_index);
  //   const canvas_json = !location.state.draft ? active_template_side.canvas_json : location.state.all_template_sides;
  //   outline_layer_id = template_assigned_layers.outline;
  //   canvas.off("after:render", () => {
  //     console.log("remove after render");
  //   });
  //   canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
  //   canvas.requestRenderAll();
  //   if (location.state.draft) {
  //     dispatch({
  //       type: SAVE_ORIGINAL_FONT_STYLES,
  //       payload: location.state.original_font_applied,
  //     });
  //   }
  //   dispatch(
  //     loadCanvasFromJSON(
  //       "canvas",
  //       canvas_json,
  //       template_fonts,
  //       background_layer_id,
  //       template_sides_canvas_json,
  //       clip_layer_id,
  //       template_sides[selected_side_index],
  //       canvas,
  //       location.state.draft
  //     )
  //   )
  //   .then((canvas_details) => {
  //     if(subscribe){
  //       const biggest_object = canvas_details[1];
  //       var new_canvas = canvas_details[0];
  //       new_canvas.__eventListeners["after:render"] = [];
  //       new_canvas.on("after:render", function () {
  //         if (model_3d) {
  //           const template_active_side = template_sides[selected_side_index];
  //           const material_name =
  //             location.state.template_3d_model["material"][selected_side_index];
  //           // console.log('material_name', location.state.template_3d_model);
  //           clientCanvasSelection(
  //             dispatch,
  //             new_canvas,
  //             biggest_object,
  //             template_active_side,
  //             model_3d,
  //             material_name,
  //             canvas_svg_for_3d,
  //             threedOut,
  //           );
  
  
  //           // dispatch({
  //           //   type: INIT_CANVAS,
  //           //   payload: new_canvas,
  //           // });
  //         }
  //         selectedLayer(new_canvas.getActiveObject(), dispatch, new_canvas);
  //       });
  //       // canvasEvents(new_canvas, biggest_object);
  //       canvasEvents(new_canvas, biggest_object, setMoving, dispatch);
  //     }
  //   }).catch(e=>(subscribe = false))
  // }

  function renderTextBox() {
    if (!location.state.draft) {
      return (
        <>
          <EditableLabel
            onChange={(e) => dispatch(savingClientName(e.target.value))}
            changeValue={client_name}
          />
        </>
      );
    } else {
      return (
        <>
          <p>Template Desing name was: {location.state.client_name}</p>
        </>
      );
    }
  }

  function renderTemplateSidesDropdown() {
    return (
      <select

        onChange={(event) => {
          canvas.__eventListeners["after:render"] = [];
          // onSideChanged(event.target.value);
          sideChange(event.target.value);
        }}
      >
        {template_sides &&
          template_sides.map((side, index) => {
            return (
              <option key={index} value={index}>
                {side}
              </option>
            );
          })}
      </select>
    );
  }

  function previewModal(openModal) {
    if (!showPreviewModal) {
      setShowPreviewModal(openModal);
      document.body.classList.add("modal-open");
      // document.body.style.paddingRight = '17px'
    } else {
      setShowPreviewModal(openModal);
      // updateControls()
      // createThreeScene(te)
      document.body.classList.remove("modal-open");
      document.body.style.paddingRight = "0px";
    }
  }

  function objectProperty() {
    if (
      client_selected_layer &&
      (client_selected_layer.get("isBGColorChangable") ||
        client_selected_layer.get("isImageReplaceable") ||
        client_selected_layer.get("isMaskable") ||
        client_selected_layer.get("isDeleteable")
      )
      && (
        client_selected_layer.get('type') !== 'i-text'
      )
      && !moving
    ) {      
      var object_property = client_selected_layer.getBoundingRect()
      left = (object_property.left + 140) + 'px'
      top = (object_property.top - 100) + 'px'
      return (
        <div
          style={{ left: left, top: top }}
          className="object-element-wrap">
          <ClientObjectButton
            nameOfObject={"Fill"}
            onPress={() => setShowColorPickerBGModal(!showColorPickerBGModal)}
            className={"fill-element"}
            style={{ background: fillColor }}
          />
          {BGColorPicker(canvas)}
          <ClientObjectButton
            nameOfObject={"Border"}
            className={"border-element"}
            onPress={() => setShowBorderModal(!showBorderModal)}
            style={{ background: borderColor }}
          />
          {borderColorChange()}
          <ClientObjectButton nameOfObject={"Weight"}>
            <img src={public_url+"/images/weight.svg"} />
          </ClientObjectButton>
          <ClientObjectButton onPress={() => deleteCanvasObject()} nameOfObject={"Delete"}>
            <img src={public_url+"/images/material-delete.svg"} />
          </ClientObjectButton>

          {client_selected_layer.get("isImageReplaceable") && (
            <ClientObjectButton
              onPress={() => changeImageModal(true)}
              nameOfObject="Change Image"
            >
              <img src={public_url+"/images/image-img.svg"} />
            </ClientObjectButton>
          )}

          {client_selected_layer.get("isMaskable") && (
            <ClientObjectButton nameOfObject={'Select Image'} onPress={() => changeImageModal(true)}>
              <span>
                <img src={public_url+"/images/image-img.svg"} />
              </span>
            </ClientObjectButton>
          )}
        </div>
      );
    }else{
      
    }
  }

  function shapesDropDown() {
    return (
      <div className="shape-menu-container">
        <div id="" className="shape-menu">
          <div className="shape-menu-list">
            <div onClick={() => addRectangle(canvas, dispatch)} className="shape-list">
              <span className="shape-svg">
                <img src="images/icons/rectangle.svg" />
              </span>
              <span className="shape-ttxt">Add Rectangle</span>
            </div>
            <div onClick={() => addCircle(canvas, dispatch)} className="shape-list">
              <span className="shape-svg">
                <img src="images/icons/ellipse.svg" />
              </span>
              <span className="shape-ttxt">Add Ellipse</span>
            </div>
            <div onClick={() => addLine(canvas, dispatch, "vertical")} className="shape-list">
              <span className="shape-svg">
                <img src="images/icons/vertical-line.svg" />
              </span>
              <span className="shape-ttxt">Add Vertical Line</span>
            </div>
            <div onClick={() => addLine(canvas, dispatch, "horizontal")} className="shape-list">
              <span className="shape-svg">
                <img src="images/icons/horizontal-lines.svg" />
              </span>
              <span className="shape-ttxt">Add Horizontal Line</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function renderSliderOptions() {
    return (
      <>
        
        {addImageModal()}
        {renderImagePickerModal()}
        <div className="editor-options-clm1 editor-clm-1">
          <div className="editor-options-img">
            <div className="add-clm1" onClick={() => add_image(true)}>
              <span>
                <img src={"images/image-img.svg"} />
              </span>
              <span>Image</span>
            </div>
            <div className="add-clm1" onClick={() => addText(canvas, dispatch)}>
              <span>
                <img src={"images/text-img.svg"} />
              </span>
              <span>Text</span>
            </div>
            <div
              className="add-clm1"
              onClick={() => {
                setShapeDropDownToggle(!shapesDropdownToggle)
              }}
            >
              <span>
                <img src={"images/img-shapes.svg"} />
              </span>
              <span>Shape</span>
            </div>

          </div>
          <div className="editor-options-txt">Add</div>
        </div>
        {/* <div className="editor-options-clm1 editor-clm-2">
          <div className="editor-options-img dropbtn">
            <div className="add-clm1" onClick={() => add_image(true)}>
              <span><img src={'images/image-img.svg'} /></span>
              <span>Background Color</span>
            </div>            
          </div>
          <div className="editor-options-txt">Add</div>
        </div> */}
        <div className="editor-options-clm1 editor-clm-3">
          <div className="editor-options-img">
            <div
              className="add-clm1"
              onClick={() => flipHorizontal(canvas, dispatch)}
            >
              <span>
                <img src={public_url+"/images/flip-horizontal-img.svg"} />
              </span>
              <span>Flip Horizontal</span>
            </div>
            <div
              className="add-clm1"
              onClick={() => flipVertical(canvas, dispatch)}
            >
              <span>
                <img src={public_url+"/images/flip-vertical-img.svg"} />
              </span>
              <span>Flip Vertical</span>
            </div>
            <div
              className="add-clm1"
              onClick={() => rotateDirection(canvas, dispatch, 'clockwise')}
            >
              <span>
                <img src={public_url+"/images/rotate-cw-img.svg"} />
              </span>
              <span>Rotate CW</span>
            </div>
            <div
              className="add-clm1"
              onClick={() => rotateDirection(canvas, dispatch, 'counter_clockwise')}
            >
              <span>
                <img src={public_url+"/images/rotate-ccw-img.svg"} />
              </span>
              <span>Rotate CCW</span>
            </div>
          </div>
          <div className="editor-options-txt">TRANSFORM</div>
        </div>
        <div className="editor-options-clm1 editor-clm-4">
          <div className="editor-options-img">
            <div
              className="add-clm1"
              onClick={() => sendForward(canvas, dispatch)}
            >
              <span>
                <img src={public_url+"/images/bring-forward-img.svg"} />
              </span>
              <span>Bring Forward</span>
            </div>
            <div
              className="add-clm1"
              onClick={() => sendBackward(canvas, dispatch)}
            >
              <span>
                <img src={public_url+"/images/send-backward-img.svg"} />
              </span>
              <span>Send backward</span>
            </div>
          </div>
          <div className="editor-options-txt">ARRANGE</div>
        </div>
        <div className="editor-options-clm1 editor-clm-5">
          <div className="editor-options-img">
            <div
              className="add-clm1"
              onClick={() => zooming_in_out("in", canvas, dispatch)}
            >
              <span>
                <img src={public_url+"/images/zoom-in-img1.svg"} />
              </span>
              <span>Zoom In</span>
            </div>
            <div
              className="add-clm1"
              onClick={() => zooming_in_out("out", canvas, dispatch)}
            >
              <span>
                <img src={public_url+"/images/zoom-out-img1.svg"} />
              </span>
              <span>Zoom Out</span>
            </div>
            <div
              className="add-clm1"
              onClick={() => showHideGrid(canvas, dispatch)}
            >
              <span>
                <img src={public_url+"/images/grid-on-off-img.svg"} />
              </span>
              <span>Grid ON/OFF</span>
            </div>
            <div
              className="add-clm1"
              onClick={() => resetScroll(canvas, dispatch)}
            >
              <span>
                <img src={public_url+'/images/icons/reset-scroll.svg'} />
              </span>
              <span>Reset Scroll</span>
            </div>
            <div
              className="add-clm1"
              onClick={() => undo(dispatch)}
            >
              <span>
                <img src={public_url+'/images/icons/undo.svg'} />
              </span>
              <span>Undo</span>
            </div>
          </div>
          <div className="editor-options-txt">VIEW</div>
        </div>
      </>
    );
  }

  return (
    <div className="upload-section">
      <ClientHeader />
      
      {image_loader && (
        <ModalLoader openModal={image_loader} loadingTitle={"Loading Image"} />
      )}
      {font_loader && (
        <ModalLoader openModal={font_loader} loadingTitle={"Loading Canvas"} />
      )}
      {save_template_loader && (
        <ModalLoader openModal={save_template_loader} loadingTitle={'Saving Template'} />
      )}
      {templateListLoader && (
        <ModalLoader openModal={save_template_loader} loadingTitle={'Saving Template Draft'} />
      )}

      {(showPreviewModal) && (
        <Modal
          headTitle={"Preview of Saving"}
          notHeader={true}
          classGiven={`modal fade font-popup ${showPreviewModal ? "show" : ""}`}
          modalDialogStyle={{
            maxWidth: "100%",
            marginTop: "-0.5rem",
            marginLeft: "0",
          }}
          modalBodyClass={"review-modal"}
          style={
            showPreviewModal
              ? { display: "block", overflowY: "hidden" }
              : { display: "none" }
          }
          onPress={() => previewModal(false)}
        >
          <>
            <ImagePreviewer
              onPressSave={() => savingSvg("save")}
              onPressBack={() => {
                previewModal(false);
              }}
              images={imagePreview}

              model_3d={model_3d}
            />
          </>
        </Modal>
      )}
      <div className="design-section review tabbing">
        <div className="design-container">
          <div className="upload-steps upload-steps2 upload-steps3">
            <ClientListButton
              buttonList={list_of_client_button}
              active="Design"
            />
          </div>
          <div className="upload-container1 d-flex flex-wrap">
            <div style={{ cursor: 'pointer' }} onClick={() => navigate(-1)} className="back-arrow-design back-arrow-align">
              <span>
                <img src={`${public_url}/images/arrow-dw6.svg`} />
              </span>
            </div>
            <div className="edit-design tabbing-section">
              {location.state && renderTextBox()}
              {/* <a onClick={() => savingSvg("draft")} className="save-link">
                <span>
                  <img
                    className="edit-icon"
                    src="images/save-icon.svg"
                    alt="save-icon"
                  />
                </span>
              </a> */}
              {toggleButtonsOptions()}
              <div id="canvas-here" className="tabbing-in w-100 h-100">
                <div className="design-container w3-animate-opacity">
                  {/* <div className="Front"></div>
                  <div className="Back"></div> */}
                  <div className="design-canvas design-canvas-new" id="canvas-div">
                    {client_selected_layer &&
                      client_selected_layer.get("type") === "i-text" && (
                        <div id="menuBarDiv" className="row-fluid">
                          <div className="tool-shadow" style={{ left: left, top: top }}>
                            {textFormatting(canvas)}
                          </div>
                        </div>
                      )}
                    <div className="dv-tb">
                      <canvas
                        id="canvas"
                        className="canvasStyle"
                      // width={500}
                      // height={500}

                      ></canvas>
                    </div>
                    {objectProperty()}
                  </div>
                </div>
              </div>
            </div>
            <div className="next-cncl next-cncl-align">
              <div className="w-100">
                <a onClick={() => savingSvg("preview_svg")} className="active">
                  NEXT
                </a>
                <a href={printer_host} className="cncl-btn-color">CANCEL</a>
              </div>
            </div>
          </div>
          <div className={`scroll-fixed`}>
            <div className="close-btn">
              <a>
                <img src={public_url+"/images/close-icon-btn.png"}/>
              </a>
            </div>
            <p className="hint-txt1">Hint:</p>
            <p className="blue-txt1">Ctrl + Scroll to Zoom:</p>
            <p className="blue-txt1">Shift + Scroll to Pan Horizontal:</p>
          </div>
        </div>
      </div>
      {shapesDropdownToggle && (
          <>
            {shapesDropDown()}
          </>
        )}
      <div className="design-container">
        <div className="pollSlider">
          <div className="editor-options-wrap">{renderSliderOptions()}</div>
          <div id="pollSlider-button"></div>
        </div>
      </div>
      {location.state.template_3d_model["link"] !== 0 && (
        <div style={{ height: "350px" }} className="drawer-wrapper">
          <div className="hd-3d-view">3D Preview</div>
          <div style={{ height: "100%" }} id="drawer-left" className="closed">
            <div style={{ height: "38px" }} className="drawer-left-trigger">
              <span className="glyphicon glyphicon-chevron-right"></span>
            </div>
            <div id="threejs-div" className="drawer-left-content">
              <div id="threejs"
                // style={{ height: 400, width: 400 }}
                style={{ height: "100%", width: "100%" }}
              >
                <button 
                  onClick={() => {
                    if(!boxOpen){
                      openBox()
                      setBoxOpen(true)
                    }
                    
                    }}>Open</button>
                <button
                 onClick={() => {
                  closeBox()
                  setBoxOpen(false)
                  }}>Close
              </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
  // return (
  //   <>
  //     {/* {(template_3d_model !== 0) ? makeTexture(model_ready, model_3d, canvas_svg_for_3d, location.state.template_3d_model['material'][activeSideIndex], template_sides[activeSideIndex]) : null} */}
  //     {/* {(template_3d_model !== 0) ? addTextureToModel(template_sides, location.state.template_3d_model['material'], model_ready, model_3d, canvas_svg_for_3d) : null} */}
  //     {showPreviewModal && (
  //       <Modal
  //         headTitle={'Preview of Saving'}
  //         notHeader={true}
  //         classGiven={`modal fade font-popup ${showPreviewModal ? 'show' : ''}`}
  //         modalDialogStyle={{ maxWidth: '100%', marginTop: '-0.5rem', marginLeft: '0' }}
  //         modalBodyClass={'review-modal'}
  //         style={showPreviewModal ? { display: 'block', overflowY:'hidden' } : { display: 'none' }}
  //         onPress={() => previewModal(false)}>
  //         <ImagePreviewer
  //           onPressSave={() => savingSvg("save")}
  //           onPressBack={() => {
  //             previewModal(false);
  //           }}
  //           images={imagePreview}
  //           model_3d={model_3d}
  //         />
  //       </Modal>
  //     )}
  //     {save_template_loader && (
  //       <ModalLoader loadingTitle={'Uploading SVG'} openModal={save_template_loader}>
  //         <PercentageLoader percentage={upload_percentage} />
  //       </ModalLoader>
  //     )}
  //     {renderImagePickerModal()}
  //     <div className="toppane">
  //       {addImageModal()}

  //       <button
  //         style={{ marginRight: 10, marginTop: 15, backgroundColor:''}}
  //         onClick={() => resetScroll(canvas, dispatch)}
  //       >
  //         Reset Scroll
  //       </button>
  //       <br />
  //       <button
  //         style={{ marginRight: 10, marginTop: 15 }}
  //         onClick={() => flipHorizontal(canvas, dispatch)}
  //       >
  //         Flip Horizontal
  //       </button>
  //       <br />
  //       <button
  //         style={{ marginRight: 10, marginTop: 15 }}
  //         onClick={() => flipVertical(canvas, dispatch)}
  //       >
  //         Flip Vertically
  //       </button>
  //       <br />
  //       {/* <button
  //         style={{ marginRight: 10, marginTop: 15 }}
  //         onClick={() => savingSvg("save")}
  //       >
  //         Save
  //       </button> */}
  //       <br />
  //       <button
  //         style={{ marginRight: 10, marginTop: 15 }}
  //         onClick={() => savingSvg("draft")}
  //       >
  //         Save To Draft
  //       </button>
  //       <br />
  //       <button
  //         style={{ marginRight: 10, marginTop: 15 }}
  //         onClick={() => deleteCanvasObject()}
  //       >
  //         Delete Object
  //       </button>
  //       <br />
  //       <button
  //         style={{ marginRight: 10, marginTop: 15 }}
  //         onClick={() => showHideGrid(canvas, dispatch)}
  //       >
  //         Show/Hide Grid
  //       </button>
  //       <br />
  //       <button
  //         style={{ marginRight: 10, marginTop: 15 }}
  //         onClick={() => addText(canvas, dispatch)}
  //       >
  //         Add Text
  //       </button>
  //       <br />
  //       <button
  //         style={{ marginRight: 10, marginTop: 15 }}
  //         onClick={() => addRectangle(canvas, dispatch)}
  //       >
  //         Add Rect
  //       </button>
  //       <br />
  //       <button
  //         style={{ marginRight: 10, marginTop: 15 }}
  //         onClick={() => addLine(canvas, dispatch, "horizontal")}
  //       >
  //         Add Horizontal Line
  //       </button>
  //       <br />
  //       <button
  //         style={{ marginRight: 10, marginTop: 15 }}
  //         onClick={() => addLine(canvas, dispatch, "vertical")}
  //       >
  //         Add Vertical Line
  //       </button>
  //       <br />
  //       <button
  //         style={{ marginRight: 10, marginTop: 15 }}
  //         onClick={() => add_image(true)}
  //       >
  //         Add Image
  //       </button>
  //       <button
  //         style={{ marginRight: 10, marginTop: 15 }}
  //         onClick={() => addCircle(canvas, dispatch)}
  //       >
  //         Add Circle
  //       </button>
  //       <br />
  //       <button
  //         style={{ marginRight: 10, marginTop: 15 }}
  //         onClick={() => sendForward(canvas, dispatch)}
  //       >
  //         Send Forward
  //       </button>
  //       <br />
  //       <button
  //         style={{ marginRight: 10, marginTop: 15 }}
  //         onClick={() => sendBackward(canvas, dispatch)}
  //       >
  //         Send Backward
  //       </button>
  //       <button
  //         style={{ marginRight: 10, marginTop: 15 }}
  //         onClick={() => undo(dispatch)}
  //       >
  //         Undo
  //       </button>
  //       <Button onPress={()=>zooming_in_out('in',canvas,dispatch)} name="Zoom in" />
  //       <Button onPress={()=>zooming_in_out('out',canvas,dispatch)} name="Zoom Out" />
  //       {renderChangeImageOption(canvas)}
  //     </div>
  //     <div className="toppane">
  //       {location.state && renderTextBox()}
  //       {renderTemplateSidesDropdown()}
  //       {textFormatting(canvas)}
  //       {textColorPicker(canvas)}
  //       {BGColorPicker(canvas)}
  //       {borderColorChange()}

  //       <Button onPress={() => savingSvg('preview_svg')} name={"Next"} classgiven="link btn-basic btn-basic2" />

  //     </div>
  //     {font_loader && (
  //       <ModalLoader loadingTitle={'Loading.. '} openModal={font_loader} />
  //     )}

  //     <div className="subContainer" style={{flexDirection: 'row'}}>
  //       <div className="middlepane" id="wrapper">
  //         <canvas
  //           id="canvas"
  //           className="canvasStyle"
  //           // width={canvasWidth}
  //           // height={canvasHeight}
  //           width={750}
  //           height={750}
  //         />
  //       </div>
  //       {(template_3d_model !== 0) && (
  //         <div id="threejs" style={{height: 600, width: 600}}></div>
  //       )}
  //     </div>
  //   </>
  // );
}

export { TemplateEditorScreen };
