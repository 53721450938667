import { Carousel,Image } from 'react-bootstrap';
import React, { useState } from 'react'
import ButtonSlider from './ButtonSlider'
import './css/slider.css';
function Slider({image_data}) {
    const [slideIndex,setSlideIndex] = useState(1)

    const nextSlide = () => {
        if(slideIndex !== image_data.length){
            setSlideIndex(slideIndex + 1)
        }else if(slideIndex === image_data.length){
            setSlideIndex(1)
        }
    }

    const prevSlide = () => {
        if(slideIndex !== 1){
            setSlideIndex(slideIndex - 1)
        }else if(slideIndex === 0){
            setSlideIndex(image_data.length)
        }
    }

    const moveDot = index => {
        setSlideIndex(index)
    }
  return (
    <Carousel controls={image_data.length >1 ? true :false} variant={'dark'}>
        {image_data.map((img,index)=>(
            <Carousel.Item key={index} >
            <Image src={img} className="d-block w-100 h-25" />
            </Carousel.Item>
        ))}
    </Carousel>
    // <div className='container-slider'>
    //     {image_data.map((img,index)=>(
    //         // console.log('i',img)
    //         <div 
    //         key={index}
    //         className={slideIndex===index+1 ? 'slide active-anim' : 'slide'}
    //         >
    //             <img
    //             src={img} 
    //             />
    //         </div>
    //     ))}
    //     {image_data.length >1 && (
    //     <>
    //     <ButtonSlider moveSlide={nextSlide} direction={'next'} />
    //     <ButtonSlider moveSlide={prevSlide} direction={'prev'} />
    //     </>
    //     )}

    //     <div className='container-dots'>
    //         {image_data.map((item,index)=>(
    //             <div
    //             key={index}
    //             onClick={()=>moveDot(index+1)}
    //             className={slideIndex===index+1 ? "dot active" :'dot'}
    //             >
    //             </div>
    //         ))}
    //     </div>
    // </div>
  )
}

export {Slider}