import React from "react";


function Pagination({ total_pages, current_page, onPageChanged, maxPageLimit, minPageLimit, previousClick, nextClick }) {
    const pages = Array.apply(null, { length: total_pages }).map(Number.call, Number);
    function renderPreviousPageButton() {

        return (
            <li className={`page-item ${current_page - 1 === 0 && 'disabled'}`} >
                <button
                    onClick={() => previousClick(current_page - 1)}
                    className="page-link-prev page-link">
                    <em className="icon ni ni-chevrons-left"></em>
                    <span>Prev</span>
                </button>
            </li>
        )
    }
    function renderNextPageButton() {

        return (
            <li className={`page-item ${current_page === total_pages && "disabled"}`}>
                <button
                    onClick={() => nextClick(current_page + 1)}
                    className="page-link-next page-link">
                    <span>Next</span>
                    <em className="icon ni ni-chevrons-right"></em>
                </button>
            </li>
        )
    }
    return (
        <nav className="pagination-main pagination-template" aria-label="Page navigation example">
            <ul className="pagination">
                {renderPreviousPageButton()}
                {minPageLimit>=1 && (
                    <li className="page-item"><button className="page-link" onClick={()=>previousClick(current_page-1)}>&hellip;</button></li>
                )}
                {
                    pages.map((page, index) => {
                        if (page + 1 <= maxPageLimit && page + 1 > minPageLimit) {
                            return (
                                <li className={`${page + 1 === current_page && "active"} page-item`} key={page}>
                                    <button onClick={() => onPageChanged(page + 1)} className="page-link">{page + 1}</button>
                                </li>
                            )
                        }
                    })
                }
                {total_pages>maxPageLimit && (
                    <li className="page-item"><button className="page-link" onClick={()=>nextClick(current_page+1)}>&hellip;</button></li>
                )}
                {renderNextPageButton()}
            </ul>
        </nav>
    )
}




export { Pagination };
