import React, { useCallback, useEffect, useState } from 'react'
import Dots from "react-activity/dist/Dots";
import Cropper from 'react-easy-crop';
import { imageRotation, settingAspectRatio } from '../extras/CanvasObjectControls';
function ImageUploading({ show_image_picker_modal, add_image_modal, handleUploading, client_selected_layer, image_loader, skip_cropping }) {

    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [rotation, setRotation] = useState(0);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [uploadImage, setUploadImage] = useState(null);
    const [aspectRatio, setAspectRatio] = useState(1 / 1);
    const [uploadImageType, setUploadImageType] = useState(null);
    
    useEffect(()=>{
        allValueSettingToDefault()
    },[])

    function allValueSettingToDefault() {
        setUploadImage(null);
        setRotation(0);
        setCrop({ x: 0, y: 0 })
        setAspectRatio(1 / 1);
        setZoom(1);
    }

    const onCropComplete = useCallback(
        (croppedArea, croppedAreaPixels) => {
            // console.log('onCropComplete',croppedArea, croppedAreaPixels)
            if (croppedAreaPixels.width) {
                setCroppedAreaPixels(croppedAreaPixels);
            }
        },
        [croppedAreaPixels, rotation]
    );

    function selectImagePicker(event) {
        setUploadImage(URL.createObjectURL(event.target.files[0]));
        setUploadImageType(event.target.files[0].type)
    }

    function renderImageConversionLoader() {
        let selected_object_width, selected_object_height
        if (client_selected_layer) {
            selected_object_width = client_selected_layer.get("width");
            selected_object_height = client_selected_layer.get("height");
        }

        return (
            <>
                {image_loader && <Dots />}
                <Cropper
                    image={uploadImage}
                    crop={crop}
                    zoom={zoom}
                    aspect={add_image_modal ? aspectRatio : (selected_object_width / selected_object_height)} // W/H

                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                    rotation={rotation}
                />


            </>
        )
    }

    function renderImageCropper() {
        if (uploadImage) {
            return (
                <>
                    {show_image_picker_modal ? (

                        <div
                            style={{ display: "flex", marginTop: "5px", marginBottom: "5px" }}
                        >
                            <button
                                style={{ marginRight: "10px" }}
                                onClick={() => imageRotation("right", rotation, setRotation)}
                            >
                                Change by right
                            </button>
                            <button onClick={() => imageRotation("left", rotation, setRotation)}>
                                Change by Left
                            </button>
                        </div>


                    ) : (

                        <div
                            style={{ display: "flex", marginTop: "5px", marginBottom: "5px" }}
                        >
                            <button
                                style={{ marginRight: "10px" }}
                                onClick={() => imageRotation("right", rotation, setRotation)}
                            >
                                Change by right
                            </button>
                            <button onClick={() => imageRotation("left", rotation, setRotation)}>
                                Change by Left
                            </button>
                            <button onClick={() => settingAspectRatio("1/1", setAspectRatio)}>
                                Aspect ratio 1:1
                            </button>
                            <button onClick={() => settingAspectRatio("3/4", setAspectRatio)}>
                                Aspect ratio 3:4
                            </button>
                            <button onClick={() => settingAspectRatio("4/3", setAspectRatio)}>
                                Aspect ratio 4:3
                            </button>
                        </div>

                    )}

                    <div
                        style={{
                            position: "relative",
                            width: "100%",
                            height: 400,
                            background: "#333",
                        }}
                    >
                        {renderImageConversionLoader()}
                    </div>

                </>
            )
        }
    }

    return (
        <>

            <h4>Upload Images</h4>

            {!uploadImage ? (
                <div className="add-field">
                    <input
                        type={"file"}
                        accept="image/jpeg,image/png,image/jpg"
                        onChange={(event) => selectImagePicker(event)}
                    />
                    <div className="button-part">
                        <button>Select File</button>
                        <div>.png .jpg .pdf .ai</div>
                        <div>or drag a file here to upload</div>
                        <div>
                            <span className="red-span">Important:</span> To avoid delays
                            in processing your order, please upload logo and graphics
                            with high resolution (300dpi).
                        </div>
                    </div>
                </div>
            ) : (
                <>{renderImageCropper()}</>

            )}
            {image_loader ? (
                <Dots />
            ) : (
                <input
                    type={"submit"}
                    onClick={(e) => handleUploading(e, uploadImage, uploadImageType, croppedAreaPixels, rotation, aspectRatio, skip_cropping)}
                    value="Upload File"
                    className="upload-file-btn btn-basic"
                />
            )}

        </ >
    )
}

export { ImageUploading }