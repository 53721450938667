import axios from "axios"
import { USER_DETAILS, USER_LOADER, USER_LOGGED_IN } from "./ActionTypes"
import { LOGIN_API } from "../extras/APIs"
import { CustomAlert } from "../Components"
import { async } from "q"


export const onChangeUserAction =  (userTyping,dispathType) => async dispatch => {
    dispatch({
        type: dispathType,
        payload:userTyping,
    })
}

export const loginAuth = (data) => async dispatch => {
    dispatch({
        type:USER_LOADER,
        payload:true,
    })
    try{
        const response = await axios({
            method:'post',
            url:LOGIN_API,
            headers: { "Content-Type": "multipart/form-data" },
            data,            
        })
        const response_data =  response.data

        if(response_data.success){
            
            localStorage.setItem('user_details',JSON.stringify(response_data.response))            
            localStorage.setItem('logged_in','true');

            dispatch({
                type:USER_DETAILS,
                payload:response_data.response
            })
            dispatch({
                type:USER_LOGGED_IN,
                payload:true,
            })
        }else{
            CustomAlert.alert(true,response_data.message)
        }
        
        dispatch({
            type:USER_LOADER,
            payload:false,
        })
    }catch(e){
        console.log('login errror ',e);
        dispatch({
            type:USER_LOADER,
            payload:false,
        })
    }
}

export const logout = () => async dispatch => {
    localStorage.removeItem('user_details');
    localStorage.removeItem('logged_in');
    dispatch({
        type:USER_DETAILS,
        payload:{}
    })
    dispatch({
        type:USER_LOGGED_IN,
        payload:false,
    })
}