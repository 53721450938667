import {INIT_CANVAS} from '../Actions/ActionTypes';
import { fabric } from 'fabric';
var global_vpt

export const OnMouseDownForPan = (opt, global_canvas) => {
  var evt = opt.e;
  if (evt.altKey === true) {
    global_canvas.isDragging = true;
    global_canvas.selection = false;
    global_canvas.lastPosX = evt.clientX;
    global_canvas.lastPosY = evt.clientY;
  }
}

export const OnMouseMoveForPan = (opt, global_canvas) => {
  if (global_canvas.isDragging) {
    var e = opt.e;
    var vpt = global_canvas.viewportTransform;
    vpt[4] += e.clientX - global_canvas.lastPosX;
    vpt[5] += e.clientY - global_canvas.lastPosY;
    global_canvas.requestRenderAll();
    global_canvas.lastPosX = e.clientX;
    global_canvas.lastPosY = e.clientY;
  }
}

export const OnMouseUpForPan = (opt, global_canvas) => {
  // on mouse up we want to recalculate new interaction
  // for all objects, so we call setViewportTransform
  global_canvas.setViewportTransform(global_canvas.viewportTransform);
  global_canvas.isDragging = false;
  global_canvas.selection = true;
}

export const checkTrackpad = (event)=>{
  var trackpad = false
  if(event.wheelDeltaY===(event.deltaY*-3)){
    trackpad = true
  }
  // else if(event.deltaMode===0){
  //   trackpad = true
  // }
  return trackpad
}

export const CanvasZoom = (opt, canvas, objectHeight, objectWidth, dispatch) => {
  // console.log('trackpad',opt.e.deltaY);
  if (opt.e.shiftKey) {
    // Horizontal Scrolling
    // console.log('opt ',opt.e);
    // var panMove = 0
    var vpt = canvas.viewportTransform;
    if(opt.e.deltaY<=0 ){    
      vpt[4] -= 2.8 + opt.e.deltaX;
      
    }
    if(opt.e.deltaY>0){
      vpt[4] += 2.8 + opt.e.deltaX;
    }

    // vpt[4] -= 2.8 + opt.e.deltaX;
    // panMove -=0.8+opt.e.deltaX
    // new fabric.Point(units,0) ;
    // canvas.relativePan(panMove)
    canvas.requestRenderAll();
  }
  else if(opt.e.ctrlKey){
    var delta = opt.e.deltaY;
    var zoom = canvas.getZoom();
    zoom *= 0.999 ** delta;
    if (zoom > 20) zoom = 20;
    if (zoom < 0.01) zoom = 0.01;
    canvas.zoomToPoint(
      new fabric.Point(opt.e.offsetX, opt.e.offsetY),
      zoom,
  );
    opt.e.preventDefault();
    opt.e.stopPropagation();
    var vpt = canvas.viewportTransform;
    if (zoom < 400 / 1000) {
      vpt[4] = 200 - 1000 * zoom / 2;
      vpt[5] = 200 - 1000 * zoom / 2;
    } else {
      if (vpt[4] >= 0) {
        vpt[4] = 0;
      } else if (vpt[4] < canvas.getWidth() - 1000 * zoom) {
        vpt[4] = canvas.getWidth() - 1000 * zoom;
      }
      if (vpt[5] >= 0) {
        vpt[5] = 0;
      } else if (vpt[5] < canvas.getHeight() - 1000 * zoom) {
        vpt[5] = canvas.getHeight() - 1000 * zoom;
      }
    }
    canvas.forEachObject(function (o) {
      // console.log('objecvr',o);
      o.setCoords();
    })
    canvas.requestRenderAll();
  }
  else {
    // Vertical Scrolling
    var vpt = canvas.viewportTransform;
    // console.log("object height",canvas.getScaleX());
    vpt[5] -= 0.5 + opt.e.deltaY;

    // global_vpt = vpt
    // if(vpt[5]<-objectHeight){
    //   vpt[5] = -objectHeight
    // }
    // else if(vpt[5]>objectHeight){
    //   // console.log('upmoving');
    //   vpt[5] =  objectHeight
    // }
    // console.log('this fucntion is calling more times',vpt[5])
    // if (vpt[5] < 0) {
    //   vpt[5] = 0
    // } else if (vpt[5] > global_canvas.getHeight() - objectHeight) {
    //   vpt[5] = global_canvas.getHeight() - objectHeight;
    // }
    canvas.requestRenderAll();
  }
  canvas.discardActiveObject();
  canvas.renderAll(); 
  // global_vpt = vpt
  dispatch({
    type: INIT_CANVAS,
    payload: canvas,
  })
}

export const resetScroll = (canvas,dispatch) => {
//  console.log("global vpt",global_vpt)
  // global_vpt[5] = 0
  // global_vpt[4] = 0
  canvas.forEachObject(obj=>obj.rotate(0))
  canvas.setViewportTransform([1,0,0,1,0,0])
  // canvas.setZoom(1)
  canvas.requestRenderAll()
  dispatch({
    type: INIT_CANVAS,
    payload: canvas,
  })
}


export const zooming_in_out = (zoom,canvas,dispatch) => {
  let zoomingValue = 0
  if(zoom==='in'){
    zoomingValue = canvas.getZoom()*(1.1)      
  }else{
    zoomingValue = canvas.getZoom()/(1.1)
  }
  canvas.zoomToPoint(
    new fabric.Point((canvas.width/2),(canvas.height/2)),
    zoomingValue
  )    
  canvas.requestRenderAll()
  dispatch({
    type: INIT_CANVAS,
    payload: canvas,
  })
}

export const oldZoomingFunction = (opt,global_canvas,dispatch) => {

  if (opt.e.shiftKey) {
    // Horizontal Scrolling
    var vpt = global_canvas.viewportTransform;
    vpt[4] -= 0.2 + opt.e.deltaX;
    // if (vpt[4] < 0) {
    //   vpt[4] = 0;
    // } else if (vpt[4] > global_canvas.getWidth() - objectWidth) {
    //   vpt[4] = global_canvas.getWidth() - objectWidth;
    // }
    global_canvas.requestRenderAll();
  } else if (opt.e.ctrlKey) {
    // Zoom in / Zoom out
    // var delta = opt.e.deltaY;
    // var zoom = global_canvas.getZoom();
    // zoom *= 0.999 ** delta;
    // if (zoom > 20) zoom = 20;
    // if (zoom < 0.01) zoom = 0.01;
    // global_canvas.setZoom(zoom);
    // opt.e.preventDefault();
    // opt.e.stopPropagation();

    var delta = opt.e.deltaY;
    var zoom = global_canvas.getZoom();
    zoom *= 0.999 ** delta;
    if (zoom > 20) zoom = 20;
    if (zoom < 0.01) zoom = 0.01;
    global_canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
    opt.e.preventDefault();
    opt.e.stopPropagation();
    var vpt = global_canvas.viewportTransform;
    if (zoom < 400 / 1000) {
      vpt[4] = 200 - 1000 * zoom / 2;
      vpt[5] = 200 - 1000 * zoom / 2;
    } else {
      if (vpt[4] >= 0) {
        vpt[4] = 0;
      } else if (vpt[4] < global_canvas.getWidth() - 1000 * zoom) {
        vpt[4] = global_canvas.getWidth() - 1000 * zoom;
      }
      if (vpt[5] >= 0) {
        vpt[5] = 0;
      } else if (vpt[5] < global_canvas.getHeight() - 1000 * zoom) {
        vpt[5] = global_canvas.getHeight() - 1000 * zoom;
      }
    }
  } else {
    // Vertical Scrolling
    var vpt = global_canvas.viewportTransform;
    // console.log(vpt[5], global_canvas.getHeight());
    vpt[5] -= 0.2 + opt.e.deltaY;
    global_vpt = vpt
    // if (vpt[5] < 0) {
    //   vpt[5] = 0
    // } else if (vpt[5] > global_canvas.getHeight() - objectHeight) {
    //   vpt[5] = global_canvas.getHeight() - objectHeight;
    // }
    global_canvas.requestRenderAll();
  }
dispatch({
  type: INIT_CANVAS,
  payload: global_canvas,
})

}
