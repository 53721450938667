import React,{useCallback, useEffect,useState} from 'react'
import Dots from "react-activity/dist/Dots";
import { useDispatch, useSelector } from 'react-redux';
import { deleteSaveDraft, getClientDraft, savedTemplateList, } from '../Actions';
import { useNavigate,useLocation  } from "react-router-dom";
import axios from 'axios';
import { TOGGLE_TEMPLATE_LIST_LOADER } from '../Actions/ActionTypes';
import { Button, HeadingTitle } from '../Components';
import { navigateToEditor, navigateToEditorFromDraft } from '../extras/CommonNavigations';

function ClientTemplateDraft() {
  const location = useLocation()
  const reduxState = useSelector((state) => state.template);
  const {
    template_list_draft,
    templateListLoader
  } = reduxState;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(()=>{
    dispatch(getClientDraft())
    // console.log('loc',navigate.toString());
    // const template_json_file = location.state.template_json_file
    // dispatch(savedTemplateList());
    // const client_name = location.state.client_name
    // setClient(client_name)
    console.log('template ',template_list_draft)
  },[dispatch])

  const jsonFileGetting = async(json_file,client_name,template_id,template_name) => {
    dispatch({
      type:TOGGLE_TEMPLATE_LIST_LOADER,
      payload:true,
    })
    const json_response = await axios({
      url:json_file,
      method:'get'
    })
    if(json_response.status===200){      
      const json_data = json_response.data
      console.log('navigate',json_data)
      navigateToEditorFromDraft(navigate,json_data,client_name,template_id)
      // navigateToEditor(navigate,json_data.clip_layer_id,template_id)
      // navigate('/editor',{
      //   state:{
      //     "draft":true,
      //     "all_template_sides":json_data.all_template_side,
      //     'type': 'user',
      //     "template_id":template_id,
      //     "client_name":client_name,
      //     "template_fonts":json_data.original_template_fonts,
      //     "original_font_applied":json_data.original_font_applied,
      //     "layers":json_data.layers,
      //     "template_sides":json_data.template_sides,
      //     'template_name':json_data.templates_name,
      //     "template_font_face":json_data.font_face,
      //     'swatch':json_data.swatch,
      //     "clip_layer_id":json_data.clip_layer_id,
      //     "template_3d_model":json_data.template_3d_model,
      //   }
      // })
      // navigateToEditor(navigate, isRounded, first_template_side, template_sides_title, template_list, fonts_list, swatch);
      dispatch({
        type:TOGGLE_TEMPLATE_LIST_LOADER,
        payload:false,
      })
    }
    // console.log('json response',json_response.data.canvas);
  }


  const clientDraft = useCallback((template_list)=>{
    return (
      <>
        <HeadingTitle title={'Manage Draft'} />
        
        {/* {save_template_loader && <Dots />} */}
        <table id="templates">
          <thead>
            <tr>
              <th>SR No</th>
              <th>Client Name</th>
              <th>Template Name</th>
              <th>Edit Template</th>
              <th>Delete Template</th>
            </tr>
          </thead>
          <tbody>
            {template_list.length >0 ?template_list.map((template, index) => {
              // console.log('template',template);
              if (template.svg_name !== null) {
                const template_name = template.svg_name.split("/")[1];
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{template.client_name}</td>
                    <td>{template_name}</td>
                    <td>
                      <Button
                        onPress={() => {
                          jsonFileGetting(template.json_file,template.client_name,template.template_id,template_name)
                        }}
                        name='Edit'
                      />                       
                    </td>
                    <td><Button classgiven={'link btn-basic btn-basic2'} onPress={()=>dispatch(deleteSaveDraft(template_list_draft,index))} name="Delete" /></td>
                  </tr>
                );
              }
            }):(
              <tr><td colSpan={5}>Nothing is Draft</td></tr>
            )}
          </tbody>
        </table>
      </>
    )
  },[templateListLoader,template_list_draft])
  function saveTemplateLoader() {
    if(templateListLoader) return <Dots />
    else {
      return (
        <>
        {clientDraft(template_list_draft)}
        </>
      )
    }
  }
  return (
    <div>
      {saveTemplateLoader()}
      </div>
  )
}

export {ClientTemplateDraft}