import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  
} from "../../Actions";
import { 
    changeProductWrap,
    render3DModel,
    renderProductAnimation,
    renderProductAnimationReverse,
} from "../../extras/3DProductWrappingCommonFunctions";
import {
    HeadPart,
    HeadingTitle,
    ModalLoader,
    Button,
} from "../../Components";
import {PRODUCT_3D_MODEL_WRAPPING} from '../../extras/APIs';

function ImageTextureToRingScreen() {
    const navigate = useNavigate();
    const reduxState = useSelector((state) => state.jewellery_pitch);
    const {} = reduxState;

    const [model_3d, set3dModel] = useState(null);
    const [model_ready, setModelReady] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        const cameraX = -28;
        const cameraY = 38;
        const cameraZ = 50;
        render3DModel(PRODUCT_3D_MODEL_WRAPPING + "RingV1/ring.glb", cameraX, cameraY, cameraZ, set3dModel, setModelReady);
    }, [dispatch])

    function createJewelleryProductListTable() {
        const material_name = 'ring';
        const roughness_texture_url = PRODUCT_3D_MODEL_WRAPPING + "RingV1/Textures/PBR-MetalRough_ring_Roughness.png";
        const metalness_texture_url = PRODUCT_3D_MODEL_WRAPPING + "RingV1/Textures/PBR-MetalRough_ring_Metallic.png";
        const ao_texture_url = null;
        const normal_texture_url = PRODUCT_3D_MODEL_WRAPPING + "RingV1/Textures/PBR-MetalRough_ring_Normal.png";
        
        const stone_roughness_texture_url = PRODUCT_3D_MODEL_WRAPPING + "RingV1/Textures/PBR-MetalRough_emerald_Roughness.png";
        const stone_metalness_texture_url = PRODUCT_3D_MODEL_WRAPPING + "RingV1/Textures/PBR-MetalRough_emerald_Metallic_full.png";
        const stone_normal_texture_url = PRODUCT_3D_MODEL_WRAPPING + "RingV1/Textures/PBR-MetalRough_emerald_Normal.png";
        
        // const ao_texture_url = PRODUCT_3D_MODEL_WRAPPING + "RingV1/Textures/wire_067060055_AO.jpg";
        // const stone_ao_texture_url = PRODUCT_3D_MODEL_WRAPPING + "RingV1/Textures/wire_067060055_AO.jpg";
        // const roughness_texture_url = null;
        // const metalness_texture_url = null;
        // const normal_texture_url = null;
        return (
          <>
            {/* Here head part start */}
            <HeadPart>
              <div className="nk-block-head full-hd-wrap">
                <HeadingTitle title={"Shoes Color Options"} />
              </div>
              <Button 
                onPress={() => {
                    const texture_url = PRODUCT_3D_MODEL_WRAPPING + "RingV1/Textures/PBR-MetalRough_ring_BaseColor.png";
                    changeProductWrap(
                        model_3d,
                        // threedOut,
                        texture_url,
                        material_name,
                        roughness_texture_url,
                        metalness_texture_url,
                        ao_texture_url,
                        normal_texture_url,
                        false,
                        1,
                    );
                    
                    const stone_texture_url = PRODUCT_3D_MODEL_WRAPPING + "RingV1/Textures/PBR-MetalRough_emerald_BaseColor.png";
                    changeProductWrap(
                        model_3d,
                        // threedOut,
                        stone_texture_url,
                        "emerald",
                        stone_roughness_texture_url,
                        stone_metalness_texture_url,
                        ao_texture_url,
                        stone_normal_texture_url,
                        true,
                        0.8,
                        // null,
                        // null,
                        // null,
                        // null,
                    );
                }} 
                classgiven="btn-basic" 
                name={'Style 1'} 
              />

              <Button 
                onPress={() => {
                    const texture_url = PRODUCT_3D_MODEL_WRAPPING + "RingV1/Textures/PBR-MetalRough_ring_BaseColor_White_Gold.png";
                    changeProductWrap(
                        model_3d,
                        // threedOut,
                        texture_url,
                        material_name,
                        roughness_texture_url,
                        metalness_texture_url,
                        ao_texture_url,
                        normal_texture_url,
                    );
                    
                    const stone_texture_url = PRODUCT_3D_MODEL_WRAPPING + "RingV1/Textures/PBR-MetalRough_emerald_BaseColor_yellow.png";
                    changeProductWrap(
                        model_3d,
                        // threedOut,
                        stone_texture_url,
                        "emerald",
                        stone_roughness_texture_url,
                        stone_metalness_texture_url,
                        ao_texture_url,
                        stone_normal_texture_url,
                        true,
                        0.8,
                        // null,
                        // null,
                        // null,
                        // null,
                    );
                }} 
                classgiven="btn-basic" 
                name={'Style 2'} 
              />

              <Button 
                onPress={() => {
                    const texture_url = PRODUCT_3D_MODEL_WRAPPING + "RingV1/Textures/defaultMat_Base_Color.jpg";
                    // changeProductWrap(texture_url);
                    // changeProductWrap(
                    //     model_3d,
                    //     // threedOut,
                    //     texture_url,
                    //     material_name,
                    //     roughness_texture_url,
                    //     metalness_texture_url,
                    //     ao_texture_url,
                    //     normal_texture_url,
                    // );

                    renderProductAnimation(0);
                }} 
                classgiven="btn-basic" 
                name={'Animate'} 
              />

                <Button 
                    onPress={() => {
                        renderProductAnimationReverse(0);
                    }} 
                    classgiven="btn-basic" 
                    name={'Animate'} 
              />
              <div className="inside-bg-wrap">
                <div className="manage-template-wrap">
                    <div className="catergory-listing">
                        <div id='threejs-div' className='modal-r-3d-preview w-60'>
                            <div className="threejs_preview" id="threejs_preview" style={{width:500,height:500}}></div>
                        </div>
                    </div>
                </div>
              </div>
            </HeadPart>
            {/* Here head part ends  */}
          </>
        );
    }

    function renderLoader() {
        // console.log('jewellery_product_list_loader',jewellery_product_list_loader);
        if (false) { //Add Loader here
            return (
            <HeadPart>
                <ModalLoader
                loadingTitle={"Loading Products List"}
                openModal={false} //Add Loader Variable here
                />
            </HeadPart>
            );
        } else {
        //   return <div>{createTemplateListTable()}</div>;
            return createJewelleryProductListTable();
        }
    }

    return renderLoader();
}

export {ImageTextureToRingScreen};
