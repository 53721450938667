import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FontName from 'fontname';
import { useNavigate } from "react-router-dom";
import Dots from "react-activity/dist/Dots";
import { getFontsList } from '../Actions';
import { Button, HeadingTitle, HeadPart, ModalLoader } from '../Components';

function FontList() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const reduxState = useSelector(state => state.template);
    const { fonts_list, upload_percentage, getting_font_loader } = reduxState;
    useEffect(() => {
        dispatch(getFontsList())
    }, [dispatch])

    // function navigation(){}

    const font_list = (list_of_font) => {
        return (
            <>
                <div className='fonts-list-wrap'>
                    {Object.keys(list_of_font).length > 0 ? Object.keys(list_of_font).map((value, index) => {           
                        return (
                            <div className='fonts-list-clm' key={index}>
                                <h2>{index + 1}: {value}</h2>
                                {Object.keys(list_of_font[value]).map((val, ind) => {
                                    if (list_of_font[value][val]) {
                                        return (
                                            <div className={`${val}-font`} key={ind}>
                                                {value} {val} is There
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div className={`${val}-font`} key={ind}>
                                                {value} {val} not there
                                            </div>
                                        )

                                    }
                                })}
                                {Object.values(list_of_font[value]).includes(null) && (
                                    <div className='font-bt-div'>
                                    <Button
                                    classgiven={'font-btn'}
                                        onPress={() => {
                                            navigate('/add_fonts', {
                                                state: {
                                                    'fromFontList': true,
                                                    'font_family': value,
                                                    'missing_font': list_of_font[value],
                                                }
                                            })
                                            console.log('val', list_of_font[value]);
                                        }}
                                        name="click here to add"                                        
                                    />
                                    </div>
                                )}
                            </div>

                        )
                    }) : (
                        <p>No fonts Added yet</p>
                    )
                    }

                </div>
            </>
        )
    }

    const loader = () => {
        if (getting_font_loader) {
            return (
                <ModalLoader loadingTitle={'Fonts List Loading'} openModal={getting_font_loader} />
            )
        } else {
            return (
                <>
                    <HeadPart>
                        <HeadingTitle title={'Fonts List'} >
                            <div className='nk-block'>
                                <Button classgiven={'link btn-basic new btn-basic2'} onPress={() => navigate('/add_fonts', { state: { fromFontList: false } })} name='Add Fonts Family' />
                            </div>
                        </HeadingTitle>

                        <div className='inside-bg-wrap mrb-15'>
                                {font_list(fonts_list)}
                        </div>
                    </HeadPart>
                </>
            )
        }
    }

    return (
        <>
            {loader()}
        </>
    )
}

export { FontList }