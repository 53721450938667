const host = "https://editor.flexiweb2print.com/fp-editor-apis-server"
// const main_url = "https://editor.flexiweb2print.com/"
// const host = "http://editor.flexiweb2print.com/dev/fp-editor-apis-server"
// const pdf_lib_host = "https://pdfg.flexiweb2print.com/badal/fp-editor-apis-server";
// const host = "http://192.168.4.35:1024/fp-editor-apis-server";
// export const printer_host = "https://www.flexiprint.in/";


const model_of_3d_host = window.location.origin
export const printer_host = document.referrer.includes('localhost') 
                                ? document.referrer + 'flexiprint-ecommerce/' 
                                    : document.referrer.includes('w2pnx.flexiweb2print.com') 
                                        ?  document.referrer + 'flexiprint/' 
                                            :  document.referrer;
// console.log('here ',document.referrer);
export const TEMPLATE_UPLOAD_API = `${host}/template_upload.php`;
export const UPLOAD_FONT_API = `${host}/uploadFont.php`;
export const UPLOAD_LAYERS_API = `${host}/template_layer_permissions.php`;
export const GET_TEMPLATE_LIST_API = `${host}/template_list.php`;
export const DELETE_TEMPLATE_API = `${host}/delete_template.php`;
export const DELETE_SAVE_DRAFT_API = `${host}/deleteSaveDraft.php`;
export const DELETE_SAVE_TEMPLATE_API = `${host}/deleteSaveTemplate.php`;
export const GET_TEMPLATE_JSON_API = `${host}/template_json.php`;
export const IMAGE_CONVERTER_API = `${host}/image_convertor.php`;
export const EDIT_TEMPLATE_API = `${host}/Edit_template.php`;
export const SAVE_TEMPLATE_API  = `${host}/save_template_svg.php`;
export const CLIENT_DRAFT_API = `${host}/save_template_draft.php`;
export const SAVED_DESIGN_TEMPLATE_LIST_API = `${host}/template_list_output.php`;
export const SAVED_DESIGN_TEMPLATE_LIST_DRAFT_API = `${host}/template_list_draft.php`;
export const CREATE_DIGITAL_PROOF_API = `${host}/create_digital_proof.php`;
export const PRE_PRESS_PDF_API = `${host}/generate_pdf.php`;
export const GET_TEMPLATE_SIDES_API = `${host}/template_sides_list.php`;
export const GET_REPEATED_ORDERS_LIST = `${host}/repeated_order_list.php`;
export const GRID_IMAGE= `${host}/grid.png`;
export const FONTS_LIST= `${host}/fonts_list.php`;
export const GET_3D_MODEL_LIST = `${host}/3d_model_list.php`;
export const SAVE_3D_MODEL = `${host}/add_3d_model_list.php`; //Change PHP file name of server and here
export const UPDATE_3D_MODEL_CAMERA_API = `${host}/update_3d_model_camera.php`;
export const UPDATE_3D_MODEL_MATERIAL_ORDER_API = `${host}/update_model_material_order.php`;
export const MASTER_DIE_API = `${host}/masterdies.php`;
export const CHAT_GPT_API = `${host}/chatgptMessage.php`;

export const ADD_TEMPLATE_TO_CART = `${printer_host}add_template_to_cart`;



// JEWELLERY PITCH APIS
export const JEWELLERY_PRODUCT_LIST_API = `${host}/jewellery_pitch/jewellery_products_list.php`;
export const JEWELLERY_TEMPLATE_TEXTURE_URL = `${host}/jewellery_pitch/brand_box_templates/`;
export const PRODUCT_3D_MODEL_WRAPPING = `${model_of_3d_host}/3dModelFiles/`;

// LOGIN Apis
export const LOGIN_API = `${host}/login.php`;


