import React from 'react'

function TableBody({tableName,inputType,onChangefun,style,inputClass,classgiven,children,colspan,tdStyle,accept,dataTitle}) {
    function renderInputType (type) {
        if(type==='files'){
            return (
                <input type={'file'} accept={accept} className={inputClass} onChange={onChangefun} />
            )
        }else{
            return (
                <>                
                {children}
                </>
            )
        }
    }
    return (
            <tr style={style} className={classgiven}>
                <td colSpan={colspan} data-title={dataTitle ? dataTitle : "Font Name"} style={tdStyle}>{tableName}</td>
                <td data-title="Action">
                    {renderInputType(inputType)}
                </td>
            </tr>    
    )
}

export { TableBody }