import React from 'react'

function ClientButton({className,onPress,style,children}) {
  return (
    <a onClick={onPress} className={`btn btn-default ${className ? className : ''}` } style={{...style,cursor:'pointer'}}>
        {children}
    </a>
  )
}

export {ClientButton}