import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  
} from "../../Actions";
import {PRODUCT_3D_MODEL_WRAPPING} from '../../extras/APIs';
import { 
    changeProductWrap,
    render3DModel,
    renderProductAnimation,
    renderProductAnimationReverse,
} from "../../extras/3DProductWrappingCommonFunctions";
import {
    HeadPart,
    HeadingTitle,
    ModalLoader,
    Button,
} from "../../Components";

function ImageTextureToBedroomScreen() {
    const navigate = useNavigate();
    const reduxState = useSelector((state) => state.jewellery_pitch);
    const {} = reduxState;

    const [model_3d, set3dModel] = useState(null);
    const [model_ready, setModelReady] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        const cameraX = -28;
        const cameraY = 38;
        const cameraZ = 0;
        // render3DModel(PRODUCT_3D_MODEL_WRAPPING + "BedroomV1/apartment.glb", cameraX, cameraY, cameraZ, set3dModel, setModelReady);
        render3DModel(PRODUCT_3D_MODEL_WRAPPING + "BedroomV1/bedroom.glb", cameraX, cameraY, cameraZ, set3dModel, setModelReady);

        // render3DModel(PRODUCT_3D_MODEL_WRAPPING + "BungalowV1/ghar_with_bricks.glb", cameraX, cameraY, cameraZ, set3dModel, setModelReady);
    }, [dispatch])

    function createJewelleryProductListTable() {
        // const threejs_client_width = parseInt(threejs_div.clientWidth, 10)
        // const threejs_client_height = threejs_client_width * (window.screen.availHeight / window.screen.availWidth);

        // console.log('H,W:', threejs_client_height, threejs_client_width);

        const chair_material_name = 'chair_mesh';
        const chair_roughness_texture_url = PRODUCT_3D_MODEL_WRAPPING + "ChairV1/Textures/wire_067060055_roughness.jpg";
        const chair_metalness_texture_url = PRODUCT_3D_MODEL_WRAPPING + "ChairV1/Textures/wire_067060055_metallic.jpg";
        const chair_normal_texture_url = PRODUCT_3D_MODEL_WRAPPING + "ChairV1/Textures/wire_067060055_normal.png";
        const chair_ao_texture_url = PRODUCT_3D_MODEL_WRAPPING + "ChairV1/Textures/wire_067060055_AO.jpg";
        return (
          <>
            {/* Here head part start */}
            <HeadPart hide_menu={true}>
              <div className="nk-block-head full-hd-wrap">
                <HeadingTitle title={"Chair Color Options"} />
              </div>
              <Button 
                onPress={() => {
                    const details_material_name = 'Line040';
                    const details_texture_url = PRODUCT_3D_MODEL_WRAPPING + "BedroomV1/Textures/table_base_color.jpg";
                    // const details_roughness_texture_url = PRODUCT_3D_MODEL_WRAPPING + "BedroomV1/Textures/details_Roughness.png";
                    const details_roughness_texture_url = null;
                    const details_metalness_texture_url = null;
                    const details_ao_texture_url = null;
                    // const details_normal_texture_url = PRODUCT_3D_MODEL_WRAPPING + "BedroomV1/Textures/details_Normal_OpenGL.png";
                    const details_normal_texture_url = null;
                    changeProductWrap(
                        model_3d,
                        // threedOut,
                        details_texture_url,
                        details_material_name,
                        details_roughness_texture_url,
                        details_metalness_texture_url,
                        details_ao_texture_url,
                        details_normal_texture_url,
                    );
                }} 
                classgiven="btn-basic" 
                name={'Init'} 
            />

            <Button 
                onPress={() => {
                    const walls_material_name = 'Cube057';
                    const walls_texture_url = PRODUCT_3D_MODEL_WRAPPING + "BedroomV1/Textures/walls_Base_color_gold_bricks.jpg";
                    const walls_roughness_texture_url = PRODUCT_3D_MODEL_WRAPPING + "BedroomV1/Textures/walls_Roughness.png";
                    const walls_metalness_texture_url = null;
                    const walls_ao_texture_url = null;
                    const walls_normal_texture_url = PRODUCT_3D_MODEL_WRAPPING + "BedroomV1/Textures/walls_Normal_OpenGL.png";

                    changeProductWrap(
                        model_3d,
                        // threedOut,
                        walls_texture_url,
                        walls_material_name,
                        walls_roughness_texture_url,
                        walls_metalness_texture_url,
                        walls_ao_texture_url,
                        walls_normal_texture_url,
                    );

                }} 
                classgiven="btn-basic" 
                name={'Golden Wall Bricks'} 
            />

            <Button 
                onPress={() => {
                    const walls_material_name = 'Cube057';
                    const walls_texture_url = PRODUCT_3D_MODEL_WRAPPING + "BedroomV1/Textures/walls_Base_color.jpg";
                    const walls_roughness_texture_url = PRODUCT_3D_MODEL_WRAPPING + "BedroomV1/Textures/walls_Roughness.png";
                    const walls_metalness_texture_url = null;
                    const walls_ao_texture_url = null;
                    const walls_normal_texture_url = PRODUCT_3D_MODEL_WRAPPING + "BedroomV1/Textures/walls_Normal_OpenGL.png";

                    changeProductWrap(
                        model_3d,
                        // threedOut,
                        walls_texture_url,
                        walls_material_name,
                        walls_roughness_texture_url,
                        walls_metalness_texture_url,
                        walls_ao_texture_url,
                        walls_normal_texture_url,
                    );

                }} 
                classgiven="btn-basic" 
                name={'Original Wall Bricks'} 
            />

            <Button 
                onPress={() => {
                    const chair_texture_url = PRODUCT_3D_MODEL_WRAPPING + "ChairV1/Textures/wire_067060055_albedo.jpg";
                    changeProductWrap(
                        model_3d,
                        // threedOut,
                        chair_texture_url,
                        chair_material_name,
                        chair_roughness_texture_url,
                        chair_metalness_texture_url,
                        chair_ao_texture_url,
                        chair_normal_texture_url,
                    );
                }} 
                classgiven="btn-basic" 
                name={'Chair Style 1'} 
              />

              <Button 
                onPress={() => {
                    const chair_texture_url = PRODUCT_3D_MODEL_WRAPPING + "ChairV1/Textures/wire_067060055_albedo_1.jpg";
                    // changeProductWrap(texture_url);
                    changeProductWrap(
                        model_3d,
                        // threedOut,
                        chair_texture_url,
                        chair_material_name,
                        chair_roughness_texture_url,
                        chair_metalness_texture_url,
                        chair_ao_texture_url,
                        chair_normal_texture_url,
                    );

                    // renderProductAnimation(0);
                }} 
                classgiven="btn-basic" 
                name={'Chair Style 2'} 
              />

              <Button 
                onPress={() => {
                    const chair_texture_url = PRODUCT_3D_MODEL_WRAPPING + "ChairV1/Textures/wire_067060055_albedo_2.jpg";
                    // changeProductWrap(texture_url);
                    changeProductWrap(
                        model_3d,
                        // threedOut,
                        chair_texture_url,
                        chair_material_name,
                        chair_roughness_texture_url,
                        chair_metalness_texture_url,
                        chair_ao_texture_url,
                        chair_normal_texture_url,
                    );
                }} 
                classgiven="btn-basic" 
                name={'Chair Style 3'} 
              />

            <Button 
                onPress={() => {
                    const chair_texture_url = PRODUCT_3D_MODEL_WRAPPING + "ChairV1/Textures/wire_067060055_albedo_3.jpg";
                    // changeProductWrap(texture_url);
                    changeProductWrap(
                        model_3d,
                        // threedOut,
                        chair_texture_url,
                        chair_material_name,
                        chair_roughness_texture_url,
                        chair_metalness_texture_url,
                        chair_ao_texture_url,
                        chair_normal_texture_url,
                    );
                }} 
                classgiven="btn-basic" 
                name={'Chair Style 4'} 
            />

            <Button 
                onPress={() => {
                    const chair_texture_url = PRODUCT_3D_MODEL_WRAPPING + "ChairV1/Textures/wire_067060055_albedo_4.jpg";
                    // changeProductWrap(texture_url);
                    changeProductWrap(
                        model_3d,
                        // threedOut,
                        chair_texture_url,
                        chair_material_name,
                        chair_roughness_texture_url,
                        chair_metalness_texture_url,
                        chair_ao_texture_url,
                        chair_normal_texture_url,
                    );
                }} 
                classgiven="btn-basic" 
                name={'Chair Style 5'} 
            />

              <div className="inside-bg-wrap">
                <div className="manage-template-wrap">
                    <div className="catergory-listing" id="catergory-listing" style={{height: window.screen.height - 250}}>
                        <div id='threejs-div'>
                            <div className="threejs_preview" id="threejs_preview" style={{width: window.screen.width ,height: window.screen.height - 250, backgroundImage: "radial-gradient(circle, white, lightgrey, darkgrey)"}}></div>
                        </div>
                    </div>
                </div>
              </div>
            </HeadPart>
            {/* Here head part ends  */}
          </>
        );
    }

    function renderLoader() {
        // console.log('jewellery_product_list_loader',jewellery_product_list_loader);
        if (false) { //Add Loader here
            return (
            <HeadPart>
                <ModalLoader
                loadingTitle={"Loading Products List"}
                openModal={false} //Add Loader Variable here
                />
            </HeadPart>
            );
        } else {
        //   return <div>{createTemplateListTable()}</div>;
            return createJewelleryProductListTable();
        }
    }

    return renderLoader();
}

export {ImageTextureToBedroomScreen};
