import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import '../../css/PCJeweller/CustomMessageScreen.css';
import {
  getTemplateList,
  deleteObject,
  clientCanvasObjectModified,
  showImagePickerModal,
  convertImageToCMYK,
  addImagePickerModal,
  selectedLayer,
} from '../../Actions';
import { fabricCanvasSetup, onSideChanged, openBox, closeBox } from '../../extras/FabricCanvasSetup';
import {
  ToggleButton,
  GroupButton,
  ClientButton,
  ClientObjectButton,
  PCJToggleOption,
  PCJPreview,
  Modal,
  ModalLoader,
  ImageUploading,
} from "../../Components";
import ColorPicker from "../../Components/ColorPicker";
import {
  textBold,
  textItalic,
  textAlign,
  changeFontColor,
  changeFontFamily,
  changeBGColor,
  resetFontStyle,
  textUnderline,
  changeBorderBGColor,
  fontSizeIncrementDecrement,
  imageRotation,
  settingAspectRatio,
} from "../../extras/CanvasObjectControls";
import { PCJ_TEMPLATE_LOADER, SAVE_TEMPLATES_LIST, TOGGLE_TEMPLATE_LIST_LOADER } from '../../Actions/ActionTypes';
import { zooming_in_out } from '../../extras/PanandZoom';
import Dots from "react-activity/dist/Dots";
import Cropper from 'react-easy-crop';
import { useCallback } from 'react';
import getCroppedImg, { blobToBase64 } from '../../extras/CropImage';
import { useLocation, useNavigate } from 'react-router-dom';

var left = '0px'
var top = '0px'
var template_3d_model;
var fillColor = ''
var borderColor = ''
var fontColor = ''
var cameraX, cameraY, cameraZ;
var model_materials;
var template_fonts;

// var boxOpen = false;

function CustomMessageScreen() {
  const public_url = process.env.PUBLIC_URL
  const [toggleChange, setToggleChange] = useState("");
  const [originalFont, setOriginalFont] = useState([]);
  const [layers, setLayers] = useState({});
  const [activeSideIndex, setActiveSideIndex] = useState(0);
  const [template_sides, setTemplateSides] = useState([]);
  const [all_template_sides, setAllTemplateSides] = useState({});

  // const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [rotation, setRotation] = useState(0);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [uploadImage, setUploadImage] = useState(null);
  const [aspectRatio, setAspectRatio] = useState(1 / 1);
  const [uploadImageType, setUploadImageType] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const [swatchlist, setSwatchList] = useState([]);
  const [model_3d, set3dModel] = useState(null);
  const [model_ready, setModelReady] = useState(false);
  const [browserError, setBrowserError] = useState(false);
  const [moving, setMoving] = useState(false);
  const [backgroundId, setBackgroundId] = useState("");
  const [templateFontFace, setTemplateFontFace] = useState([]);
  const [templateId, setTemplateId] = useState("");
  const [isexpanded, setIsexpanded] = useState(false);
  const [fontListShow, setFontListShow] = useState(false);
  const [boxOpen, setBoxOpen] = useState(false)
  const [showColorPickerTextModal, setShowColorPickerTextModal] =
    useState(false);
  const [showColorPickerBGModal, setShowColorPickerBGModal] = useState(false);
  const [showBorderModal, setShowBorderModal] = useState(false);


  const navigate = useNavigate()

  // const onCropComplete = useCallback(
  //   (croppedArea, croppedAreaPixels) => {
  //     // console.log('onCropComplete',croppedArea, croppedAreaPixels)
  //     if (croppedAreaPixels.width) {
  //       setCroppedAreaPixels(croppedAreaPixels);
  //     }
  //   },
  //   [croppedAreaPixels, rotation]
  // );

  // const [boxOpen, setBoxOpen] = useState(true);

  const reduxState = useSelector((state) => state.template);
  const dispatch = useDispatch();

  var clip_layer_id;

  const {
    templateListLoader,
    template_list,
    fonts_list,
    swatch,
    client_name,
    list_of_client_button,
    canvas_svg_for_3d,
    add_image_modal,
    image_loader,
    canvas,
    client_selected_layer,
    original_font_styles,
    layer_permissions,
    show_image_picker_modal,
    template_sides_canvas_json,
    pcjTemplateLoader,
  } = reduxState;
  const location = useLocation();
  useEffect(() => {
    let subscribe = true
    const controller = new AbortController()
    
    try{
      if (subscribe) {
        // 1382
        const template_id = location.state.templateId
        dispatch(getTemplateList(template_id, 1, 15, 1, '', '', '', '', '', '', null, controller));
      }
      
    }catch(e){
      console.log('e');
      navigate('/pcj')
    }

    return () => {
      settingDefault()
      subscribe = false
      selectedLayer(null,dispatch)
      console.log('it is calling');
      controller.abort()
    }


  }, [dispatch])

    
  

  useEffect(() => {
    // console.log('template_list',template_list)

    if (template_list.length != 0) {


      const canvas_div = document.getElementById("canvas-div");
      const canvas_client_width = parseInt(canvas_div.clientWidth, 10)
      //const canvas_client_height = canvas_client_width * (window.screen.availHeight/window.screen.availWidth);
      const canvas_client_height = parseInt(canvas_div.clientHeight, 10)
      const fabric_canvas_div = document.getElementById("canvas");
      fabric_canvas_div.width = canvas_client_width;
      fabric_canvas_div.height = canvas_client_height;

      // Dynamic ThreeJS Canvas Height Width Assignment
      const threejs_div = document.getElementById("threejs-div") ? document.getElementById("threejs-div") : 0;
      const threejs_client_width = parseInt(threejs_div.clientWidth, 10)
      const threejs_client_height = threejs_client_width * (window.screen.availHeight / window.screen.availWidth);
      if (document.getElementById("threejs")) {
        const threejs_canvas_div = document.getElementById("threejs");
        threejs_canvas_div.style.width = threejs_client_width;
        threejs_canvas_div.style.height = threejs_client_height;
      }


      const template_sides = Object.keys(template_list);
      setTemplateSides(template_sides);
      const first_template_side = template_list[template_sides[0]];
      console.log(model_materials, template_3d_model, cameraX, cameraY, cameraZ,template_list[template_sides[0]]);
      template_fonts = first_template_side['fonts'];
      const template_assigned_layers = first_template_side['layers'];
      const template_id = first_template_side.id;
      // var all_template_sides = {}
      // all_template_sides[template_sides[0]] = JSON.parse(first_template_side.canvas_json_data);
      var canvas_json = first_template_side.canvas_json;
      const template_fonts_face = first_template_side['font_face']
        ? first_template_side['font_face']
        : [];
      model_materials = first_template_side['3d_model']['material'];
      template_3d_model = first_template_side['3d_model']["link"];
      cameraX = parseInt(first_template_side['3d_model']["cameraX"]);
      cameraY = parseInt(first_template_side['3d_model']["cameraY"]);
      cameraZ = parseInt(first_template_side['3d_model']["cameraZ"]);
      
      // clip_layer_id = first_template_side['template_layers']['layer_dies'][0]['fabric_layer_id'];
      clip_layer_id = first_template_side['template_layers']['layer_dies'].length >0 ? first_template_side['template_layers']['layer_dies'][0]['fabric_layer_id'] : ''
      // clip_layer_id = '';
      console.log('clip_layer_id clip_layer_id',clip_layer_id);
      // swatch is coming from redux
      // dispatch({
      //     type: SAVE_ALL_FONTS_LIST,
      //     payload: location.state.fonts_list,
      //   });

      fabricCanvasSetup(
        "threejs",
        "canvas",
        template_sides,
        template_fonts,
        template_assigned_layers,
        template_id,
        template_list,
        canvas_json,
        template_fonts_face,
        model_materials,
        template_3d_model,
        cameraX,
        cameraY,
        cameraZ,
        swatch,
        client_name,
        activeSideIndex,
        null,
        null,
        clip_layer_id,
        canvas_svg_for_3d,
        dispatch,
        setBackgroundId,
        setTemplateFontFace,
        setTemplateId,
        setOriginalFont,
        setLayers,
        setSwatchList,
        setTemplateSides,
        setAllTemplateSides,
        setToggleChange,
        setBrowserError,
        set3dModel,
        setModelReady,
        setMoving,
        closingDropDown,
      )
    }
  }, [template_list.length]);

  useEffect(() => {
    
    setFontListShow(false)
    setShowColorPickerTextModal(false)
    setShowColorPickerBGModal(false);
    showBorderBGModal(false);
    

    return () => {
      // setMoving(false)
    }

  }, [moving,client_selected_layer])

  // console.log('client',client_selected_layer);

  function TextFormatting() {
    // fonts_list = location.state && location.state.fonts_list ;
    // font
    // console.log('client',client_selected_layer);
    if (client_selected_layer && !moving) {
      if (client_selected_layer.get("type") === "i-text") {
        if (client_selected_layer.get("isFontStyleChangeable")) {
          // var corrdsOfText = canvas.getAbsoluteCoords(client_selected_layer,canvas.getZoom())
          // var buttons = document.getElementsByClassName('tool-shadow')[0]
          // var zoom = canvas.getZoom()
          var object_rect = client_selected_layer.getBoundingRect()
          // buttons.style.left = (corrdsOfText.left - buttons.)
          // console.log('corrdsOfText', client_selected_layer.getBoundingRect());
          // buttons.clientLeft = (corrdsOfText.left - 85/2) + 'px';
          // buttons. = (corrdsOfText.top - 18/2) + 'px';+

          left = (object_rect.left - 200) + 'px'
          top = (object_rect.top + 30) + 'px'
          // var object_font_family = client_selected_layer.get("fontFamily");
          // object_font_family = fonts_list.indexOf(
          //   object_font_family.replaceAll("'", "")
          // );
          return (
            <div className='editor-text'>
              <div className="btn-group">
                <GroupButton
                  className={`btn-group-inside-1 ${fontListShow ? "show" : ""}`}
                >
                  <ClientButton
                    onPress={() => setFontListShow(!fontListShow)}
                    className={`dropdown-toggle`}
                  >
                    {"Fonts"}
                    <span className="caret"></span>
                  </ClientButton>
                  <ul
                    className={`dropdown-menu ${fontListShow ? "show" : ""}`}
                    style={{
                      position: "absolute",
                      willChange: "transform",
                      left: "0",
                      right: "0",
                      transform: "translate3d(0px,3px,0px)",
                    }}
                  >
                    {fonts_list.length > 0 ? (
                      fonts_list.map((font, index) => {
                        return (
                          <li
                            key={index}
                            value={index}
                            onClick={() => changeFont(index, canvas, client_selected_layer)}
                          >
                            <a
                              // onClick={() =>changeFont(index, canvas, client_selected_layer)}
                              style={{ paddingLeft: "0px" }}>
                              {font}
                            </a>
                          </li>
                        );
                      })
                    ) : (
                      <li>
                        <a style={{ paddingLeft: "0px", }}>No Fonts are there</a>
                      </li>
                    )}
                  </ul>
                </GroupButton>
                {/* <GroupButton className={`btn-group`}>
                      <ClientButton
                        style={{ color: fontColor }}
                        onPress={() =>
                          setShowColorPickerTextModal(!showColorPickerTextModal)
                        }
                        className={"dropdown-toggle"}
                      >
                        Color<span className="caret"></span>
                      </ClientButton>
                      {textColorPicker(canvas)}
                    </GroupButton> */}
              </div>
              <div className="btn-group">
                <GroupButton className={'btn-group-inside-2'}>
                  <ClientButton>
                    <span onClick={() => fontSizeIncrementDecrement(canvas, dispatch, 'increase')} style={{ userSelect: 'none' }}>+</span>
                    <img src={public_url + '/images/font-size.png'} style={{ maxWidth: '20px', maxHeight: '20px' }} />
                    <span onClick={() => fontSizeIncrementDecrement(canvas, dispatch, 'decrease')} style={{ userSelect: 'none' }}>-</span>

                  </ClientButton>

                </GroupButton>
              </div>
              <div className="btn-group">
                <GroupButton className={"btn-group-inside-3"}>
                  {/* <a className="btn btn-default" style={{cursor:'pointer'}}><i className="fa fa-bold"></i></a> */}
                  <ClientButton
                    style={{ color: fontColor }}
                    
                    onPress={() =>
                      setShowColorPickerTextModal(!showColorPickerTextModal)
                    }
                    className={"dropdown-toggle btn-1"}
                  >
                    <img src={public_url + "/images/text-color-icon.png"} style={{ maxWidth: '20px', maxHeight: '20px' }} />
                  </ClientButton>
                  {textColorPicker(canvas)}
                  
                  {renderBold()}
                  {renderItalic()}
                  {/* {renderUnderLine()} */}
                </GroupButton>
              </div>
              <div className="btn-group">
                <ClientButton
                  onPress={() => textAlign(canvas, dispatch, "left")}
                >
                  <li className="fa fa-align-left"></li>
                </ClientButton>
                <ClientButton
                  onPress={() => textAlign(canvas, dispatch, "center")}
                >
                  <li className="fa fa-align-center"></li>
                </ClientButton>
                <ClientButton
                  onPress={() => textAlign(canvas, dispatch, "right")}
                >
                  <li className="fa fa-align-right"></li>
                </ClientButton>
                <ClientButton
                  onPress={() => textAlign(canvas, dispatch, "justify")}
                >
                  <li className="fa fa-align-justify"></li>
                </ClientButton>
                {client_selected_layer.get('isDeleteable') && (
                  <ClientButton
                    onPress={() => deleteCanvasObject()}
                  >
                    <li className="fa fa-trash"></li>
                  </ClientButton>
                )}
                {/* <ClientButton
                      onPress={() => {
                        resetFontStyle(
                          original_font_styles,
                          canvas,
                          dispatch,
                          client_selected_layer
                        )
                        fontColor = ''
                      }
                      }
                    >
                      <li className="fa fa-refresh"></li>
                    </ClientButton>
                    <ClientButton
                      onPress={() => deleteCanvasObject()}
                    >
                      <li className="fa fa-trash"></li>
                    </ClientButton> */}
              </div>
            </div>
          );
        }
      }
    }
  }

  // DUPLICATE STARTS
  function changeFont(font_name_index, canvas, selected_object) {
    const selected_font = fonts_list[font_name_index];
    const object_bold = selected_object.get("fontWeight");
    const object_italic = selected_object.get("fontStyle");
    changeFontFamily(
      canvas,
      dispatch,
      selected_font,
      object_bold,
      object_italic
    );
    setFontListShow(false);
  }

  function textColorPicker() {
    return (
      <ul
        style={{
          position: "absolute",
          willChange: "transform",
          left: "0",
          right: "0",
          transform: "translate3d(0px,3px,0px)",
        }}
        className={`dropdown-menu color-palette ${showColorPickerTextModal ? "show" : ""}`}
      >
        <ColorPicker
          colorList={swatchlist}
          colorPickedCallback={changeTextColor}
        />
      </ul>
    );
  }

  function renderBold() {
    if (client_selected_layer.get("fontWeight") === "bold") {
      return (
        <ClientButton
          onPress={() => textBold(canvas, dispatch, original_font_styles)}
        >
          <li style={{ fontWeight: "bold" }} className="fa fa-bold"></li>
        </ClientButton>
      );
    } else {
      return (
        <ClientButton
          onPress={() => textBold(canvas, dispatch, original_font_styles)}
        >
          <li className="fa fa-bold"></li>
        </ClientButton>
      );
    }
  }

  function renderItalic() {
    if (client_selected_layer.get("fontStyle") === "italic") {
      // console.log('original font styles',original_font_styles);
      return (
        <ClientButton
          onPress={() => textItalic(canvas, dispatch, original_font_styles)}
        >
          <em>
            <i className="fa fa-italic"></i>
          </em>
        </ClientButton>
      );
    } else {
      return (
        <ClientButton
          onPress={() => textItalic(canvas, dispatch, original_font_styles)}
        >
          <i className="fa fa-italic"></i>
        </ClientButton>
      );
    }
  }

  // function renderUnderLine() {
  //   return (
  //     <ClientButton onPress={() => textUnderline(canvas, dispatch)}>
  //       <li className="fa fa-underline"></li>
  //     </ClientButton>
  //   );
  // }

  function BGColorPicker() {
    return (
      <ul
        style={{
          position: "absolute",
          willChange: "transform",
          left: "0",
          right: "0",
          transform: "translate3d(0px,3px,0px)",
        }}
        className={`dropdown-menu color-palette ${showColorPickerBGModal ? "show" : ""}`}
      >
        <ColorPicker
          colorList={swatchlist}
          colorPickedCallback={changeObjectBGColor}
        />
      </ul>
    );
  }

  function deleteCanvasObject() {
    const delete_object = canvas.getActiveObject();
    if (delete_object) {
      if (delete_object.isDeleteable) {
        var proceed = false;
        proceed = window.confirm(
          "Are you sure you want to delete the object ?"
        );
        if (proceed) {
          dispatch(deleteObject(canvas, layer_permissions, "user"));
          clientCanvasObjectModified(dispatch, canvas);
        }
      } else {
        alert("You are not allowed to delete this object");
      }
    } else {
      alert("Select a layer first");
    }
  }

  function changeTextColor(color) {
    changeFontColor(canvas, dispatch, color);
    fontColor = color
    setShowColorPickerTextModal(false);
  }

  function changeObjectBGColor(color) {
    changeBGColor(canvas, dispatch, color);
    fillColor = color
    setShowColorPickerBGModal(false);
  }

  function changeObjectBorderColor(color) {
    changeBorderBGColor(canvas, dispatch, color);
    borderColor = color
    showBorderBGModal(false);
  }

  function showBorderBGModal(openModal) {
    if (!showBorderModal) {
      setShowBorderModal(openModal);
      document.body.classList.add("modal-open");
      document.body.style.paddingRight = "17px";
    } else {
      setShowBorderModal(openModal);
      document.body.classList.remove("modal-open");
      document.body.style.paddingRight = "0px";
    }
  }

  function borderColorChange() {
    return (
      <ul
        style={{
          position: "absolute",
          willChange: "transform",
          left: "0",
          right: "0",
          transform: "translate3d(0px,3px,0px)",
        }}
        className={`dropdown-menu color-palette ${showBorderModal ? 'show' : ''}`}
      >
        <ColorPicker
          colorPickedCallback={changeObjectBorderColor}
          colorList={swatchlist}
        />
      </ul>
    );
  }

  function changeImageModal(openModal) {
    if (!show_image_picker_modal) {
      document.body.classList.add("modal-open");
      document.body.style.paddingRight = "17px";
    } else {
      document.body.classList.remove("modal-open");
      document.body.style.paddingRight = "0px";
      
    }
    dispatch(showImagePickerModal(openModal));
  }

  function add_image(openModal) {
    if (!add_image_modal) {
      document.body.classList.add("modal-open");      
    } else {

      document.body.classList.remove("modal-open");
      document.body.style.paddingRight = "0px";
      
    }
    dispatch(addImagePickerModal(openModal));
  }

  // function allValueSettingToDefault() {
  //   setUploadImage(null);
  //   setRotation(0);
  //   setAspectRatio(1 / 1);
  //   setZoom(1);
  // }

  function renderImagePickerModal() {
    if (show_image_picker_modal) {
      return (
        <>
          <Modal
            headTitle={"Image Picker"}
            notHeader={true}
            classGiven={`modal upload-image-modal fade add-img-popup ${show_image_picker_modal ? "show" : ""
              }`}
            onPress={() => changeImageModal(false)}
            modalDialogClass={show_image_picker_modal && "modal-dialog-centered"}
            style={
              show_image_picker_modal
                ? { display: "block", paddingRight: "17px" }
                : { display: "none" }
            }
            footerWant={true}
            footerTitle={"Cancel"}
            footerOnPress={() => changeImageModal(false)}
          >
            <>              
              <ImageUploading 
                handleUploading={handleSubmit}                 
                show_image_picker_modal={show_image_picker_modal}                 
                client_selected_layer={client_selected_layer} 
                image_loader={image_loader} 
                skip_cropping={false}
              />
            </>            
          </Modal>
        </>
      );
    }
  }

  const addImageModal = () => {
    if (add_image_modal) {
      return (
        <>
          <Modal
            headTitle={"Image Picker"}
            onPress={() => add_image(false)}
            notHeader={true}
            classGiven={`modal upload-image-modal fade add-img-popup ${add_image_modal ? "show" : ""
              }`}
            modalDialogClass={add_image_modal && "modal-dialog-centered"}
            style={add_image_modal ? { display: "block", paddingRight: "17px" } : { display: "none" }}
            footerWant={true}
            footerTitle={"Cancel"}
            footerOnPress={() => add_image(false)}
          >
            <ImageUploading 
              handleUploading={handleSubmit}               
              add_image_modal={add_image_modal}               
              client_selected_layer={client_selected_layer} 
              image_loader={image_loader} 
              skip_cropping={false}
            />            
          </Modal>
        </>
      );
    }
  };


  async function handleSubmit(e, uploadImage,uploadImageType,croppedAreaPixels,rotation,aspectRatio,skip_cropping) {
    e.preventDefault()
    add_image(false);
    try {
      if (add_image_modal) {
        if (aspectRatio > 0) {
          if (skip_cropping) {
            const base64image = await blobToBase64(uploadImage);

            dispatch(
              convertImageToCMYK(base64image, uploadImageType, canvas, null)
            );
          } else {
            const croppedImage = await getCroppedImg(
              uploadImage,
              uploadImageType,
              croppedAreaPixels,
              rotation
            );
            setCroppedImage(croppedImage);
            dispatch(
              convertImageToCMYK(croppedImage, uploadImageType, canvas, null)
            );
          }
          // here cropped image
        }
      } else {
        const croppedImage = await getCroppedImg(
          uploadImage,
          uploadImageType,
          croppedAreaPixels,
          rotation
        );

        setCroppedImage(croppedImage);

        dispatch(
          convertImageToCMYK(
            croppedImage,
            uploadImageType,
            canvas,
            client_selected_layer
          )
        );
      }
      
    } catch (e) {
      console.error(e);
    }
  }


  function objectProperty() {
    // console.log('client_selected_layer',
    //     // client_selected_layer, 
    //     client_selected_layer?.get("isBGColorChangable"),
    //     client_selected_layer?.get('type'),
    //     moving,
    // );
    if (
      client_selected_layer &&
      (client_selected_layer.get("isBGColorChangable") ||
        client_selected_layer.get("isImageReplaceable") ||
        client_selected_layer.get("isMaskable") ||
        client_selected_layer.get("isDeleteable")
      )
      && (
        client_selected_layer.get('type') !== 'i-text'
      )
      && !moving
    ) {
      var object_property = client_selected_layer.getBoundingRect()
      left = (object_property.left + 140) + 'px'
      top = (object_property.top - 100) + 'px'
      return (
        <div
          //   style={{ left: left, top: top }}
          className="object-element-wrap">
          <ClientObjectButton
            nameOfObject={"Fill"}
            onPress={() => setShowColorPickerBGModal(!showColorPickerBGModal)}
            className={"fill-element"}
            style={{ background: fillColor }}
          />
          {BGColorPicker(canvas)}
          <ClientObjectButton
            nameOfObject={"Border"}
            className={"border-element"}
            onPress={() => setShowBorderModal(!showBorderModal)}
            style={{ background: borderColor }}
          />
          {borderColorChange()}
          {/* <ClientObjectButton nameOfObject={"Weight"}>
                <img src={public_url+"/images/weight.svg"} />
              </ClientObjectButton> */}
          <ClientObjectButton onPress={() => deleteCanvasObject()} nameOfObject={"Delete"}>
            <img src={public_url + "/images/material-delete.svg"} />
          </ClientObjectButton>

          {client_selected_layer.get("isImageReplaceable") && (
            <ClientObjectButton
              onPress={() => changeImageModal(true)}
              nameOfObject="Change Image"
            >
              <img src={public_url + "/images/image-img.svg"} />
            </ClientObjectButton>
          )}

          {client_selected_layer.get("isMaskable") && (
            <ClientObjectButton nameOfObject={'Select Image'} onPress={() => changeImageModal(true)}>
              <span>
                <img src={public_url + "/images/image-img.svg"} />
              </span>
            </ClientObjectButton>
          )}
        </div>
      );
    }
  }

  //   DUPLICATE ENDS

  

  const sideChange = (index) => {
    // const clip_layer_id = current_template['template_layers']['layer_dies'].length !== 0 ?
    // current_template['template_layers']['layer_dies'][0]['fabric_layer_id'] :
    //   '';
    //   console.log('CLIP LAYER ID:',location.state.layers);

    const current_template_side = template_list[template_sides[index]];
    console.log('current_template',current_template_side);
    clip_layer_id = current_template_side['template_layers']['layer_dies'].length !== 0 ?
      current_template_side['template_layers']['layer_dies'][0]['fabric_layer_id'] :
      clip_layer_id;
    // clip_layer_id = '';
    console.log('clip_layer_id',clip_layer_id);

    onSideChanged(
      index,
      "canvas",
      canvas,
      model_3d,
      model_materials,
      canvas_svg_for_3d,
      template_sides,
      activeSideIndex,
      template_sides_canvas_json,
      all_template_sides,
      clip_layer_id,
      template_fonts,
      current_template_side.layers,
      original_font_styles,
      null, // draft
      dispatch,
      setMoving,
      setActiveSideIndex,
      setBackgroundId,
      setOriginalFont,
      setLayers,
      setTemplateSides,
      setToggleChange,
      closingDropDown,

    )
  }

  const closingDropDown = useCallback(()=> {
    setFontListShow(false);
    setShowColorPickerTextModal(false);
    setShowColorPickerBGModal(false);
    setShowBorderModal(false);

  },[moving])


  function settingDefault(e) {
    // dispatch({
    //   type: PCJ_TEMPLATE_LOADER,
    //   payload: false,
    // });
    dispatch({
      type: SAVE_TEMPLATES_LIST,
      payload: [],
    });
    set3dModel(null)
  }
  
  return (
    <PCJPreview >
      {renderImagePickerModal()}
      {addImageModal()}
      {(pcjTemplateLoader || templateListLoader) && <ModalLoader loadingTitle={'Loading template..'} />}
      <div className={`modal-l-editor left-panel-2 w-40 ${isexpanded ? 'closing' : ""}`}>

        <div className="editor-wrap1">
          <h2 className="modal-hd2">WRITE A GIFT MESSAGE</h2>
          <div className="editor-txt1">Personalize your box with a custom gift message <br />for your loved ones</div>


        </div>

        <TextFormatting />
        <div id='canvas-div' className='editor-wrap2'>
          <canvas className="canvas" id="canvas" ></canvas>
          {/* {textFormatting(canvas)} */}
          {objectProperty()}
          <GroupButton className={'zoom_btn'}>
            <a onClick={() => zooming_in_out("in", canvas, dispatch)}><img src={public_url + "/images/zoom-in-img.svg"} alt={'zoom-in'} /></a>
            <a onClick={() => zooming_in_out("out", canvas, dispatch)}><img src={public_url + "/images/zoom-out-img.svg"} alt={'zoom-out'} /></a>
          </GroupButton>
        </div>

        <div className='editor-wrap3'>
          <div className="add-msg-btn"><a >ADD MESSAGE</a></div>
          <div className="customization-type-price">+ Rs. 50</div>
        </div>

      </div>

      <div id='threejs-div' className={`modal-r-3d-preview w-60 ${isexpanded ? 'expanded' : ''}`}>
        {/* <ToggleButtonsOptions /> */}
        <div className="threejs" id="threejs" style={{ width: '100%', height: '100%' }}></div>
        <div className="back-arrow"><a onClick={() => setIsexpanded(!isexpanded)}><img className={isexpanded ? 'icon-collapse' : 'expand'} src={public_url + "/images/PCJIcons/arrow-back.svg"} /></a></div>
        <GroupButton className={'open-close-tabs'}>
          {template_sides?.map((sideName, sideIndex) => (
            <a key={sideIndex} className={activeSideIndex == sideIndex ? 'active' : ''} onClick={() => {
              // console.log('CLICKED');
              // console.log('sideIndex',sideIndex);
              canvas.__eventListeners["after:render"] = [];
              if (sideIndex == 0) {
                if (boxOpen) {
                  closeBox()
                  setBoxOpen(false)
                }
              } else {
                if (!boxOpen) {
                  openBox()
                  setBoxOpen(true)
                }
              }
              sideChange(sideIndex);
              // if(boxOpen){
              //   closeBox()
              //   setBoxOpen(false)
              // }else{
              //   openBox()
              //   setBoxOpen(true)
              // }
            }}>{sideName}</a>
          ))}          
        </GroupButton>

      </div>
    </PCJPreview>
  )
}

export { CustomMessageScreen }