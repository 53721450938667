import React, {useEffect,useState} from 'react'
import {useDispatch,useSelector} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getTemplateList } from '../Actions'



function EditingScreen() {
    const templateState = useSelector(state=>state.template);
    const {permission_list} = templateState
    const dispatch = useDispatch()
    const navigate = useNavigate()
    useEffect(()=>{
        if(permission_list.length ===0) navigate('/')
        // dispatch(getTemplateList())
        // setPermission(permission_list)
        console.log('permission',permission_list)
        // console.log('template',permission)
        // mergingAllPermission()
    },[dispatch])

    
  

  return (
      <>
    <div>EditingScreen</div>
    {permission_list.map((item,index)=>(
        <div key={index}>
        <button><p >{item.permission}</p></button>
        </div>
    ))}
    <button onClick={()=>{console.log('')}}>update the template</button>
    <canvas id="edit_canvas"></canvas>
    </>
  )
}

export {EditingScreen}